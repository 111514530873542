import React, { useContext, useEffect, useState } from "react";
import {
  TableCell,
  TableRow,
  Box,
  Typography,
  Tooltip,
  Checkbox,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircleOutlined";

import moment from "moment";
import TruncateTextCustom from "../../../../utilities/TruncateTextCustom";
import FormatTitleCase from "../../../../utilities/FormatTitleCase";
import ProjectBar from "../global/ProjectBar";

import icon_high from "../../../../assets/images/SVG/icon_high.svg";
import icon_medium from "../../../../assets/images/SVG/icon_medium.svg";
import icon_low from "../../../../assets/images/SVG/icon_low.svg";
import ContextProject from "../../context/ContextProject";
import AnchorTaskitem from "../anchor/AnchorTaskitem";
import ImageSmallUser from "../../../../components/ImageSmallUser";
import { DRAWER_OPEN, SET_TASK_DETAILS } from "../../context/types";
import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";

const checkStyle = makeStyles((theme) => ({
  // ========================================
  // Rounded Icon
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    border: "1px solid #888",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",

    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    borderRadius: 20,
    backgroundColor: "rgba(16,107,163,.5)", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "rgba(16,107,163,.3)", // #106ba3 #b1354a
    },
  },
  completedIcon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    border: "1px solid #888",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23888'/%3E%3C/svg%3E\")",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedCompleteIcon: {
    borderRadius: 20,
    backgroundColor: "rgba(16,107,163,.5)", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23106ba3'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "rgba(16,107,163,.3)", // #106ba3 #b1354a
    },
  },
}));

const ListTableTask = ({ classes, row, project, taskGroupName }) => {
  const classCheck = checkStyle();
  const project_id = window.location.pathname.split("/")[2];
  const completed = row.is_complete?.id === "2" ? true : false;
  const {
    selectTask,
    setSelectTask,
    projectTState,
    projectTDispatch,
    setTriggerTask,
  } = useContext(ContextProject);
  const isSelected = (id) => selectTask.indexOf(id) !== -1;
  const isItemSelected = isSelected(row.id);

  /// STATTE
  const [loadStatus, setLoadStatus] = useState(false);
  const [loadPriority, setLoadPriority] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ====================================================================
  // ~~~~~~~~~~~ DATA FORM UPDATE DIRECTLY ON LIST ~~~~~~~~~~~
  // ====================================================================
  const dataUpdate = {
    Todolist: {
      title: row?.title,
      description: row.description === null ? row.description : "",
      remarks: row?.remarks === null ? row.remarks : "",
      owner_id: row.owner_id,
      status: row.status !== null ? row.status.id : null,
      priority: row.priority !== null ? row.priority.id : null,
      due_date: moment(row.due_date).format("YYYY-MM-DD HH:mm:ss"),
      start_date: moment(row.start_date).format("YYYY-MM-DD HH:mm:ss"),
      tags: row.tags !== null ? row.tags : "",
      actual_result: row.actual_result !== null ? Number(row.actual_result) : 0,
      target_result: Number(row.target_result),
      project_id,
      task_group_id: row.task_group_id,
      predecessor_id: row.dependencies,
      custom_phase_id: row?.custom_phase_id,
    },
  };

  /* =========================
              Handle Multiple Select
              ============================= */
  const handleChangeCheckbox = (e, item) => {
    const selectedIndex = selectTask.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectTask, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectTask.slice(1));
    } else if (selectedIndex === selectTask.length - 1) {
      newSelected = newSelected.concat(selectTask.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectTask.slice(0, selectedIndex),
        selectTask.slice(selectedIndex + 1)
      );
    }

    setSelectTask(newSelected);
  };

  const handleDetails = () => {
    projectTDispatch({
      type: DRAWER_OPEN,
      payload: { drawerTask: true, actionTask: "update" },
    });
    projectTDispatch({
      type: SET_TASK_DETAILS,
      payload: { taskDetails: row },
    });

    const task = JSON.stringify(row);

    localStorage.setItem("task_details", task);
  };

  // ==================================================
  // handle progress bar
  // ==================================================
  const method = project?.achievement_calculation_method;
  // gap
  const _gap_scope =
    row.scope_gap_result !== null ? Number(row.scope_gap_result) : 0;
  const _gap_schedule =
    row.schedule_gap_result !== null ? Number(row.schedule_gap_result) : 0;

  // result & expected
  const _scope_res = row.scope_result !== null ? Number(row.scope_result) : 0;
  const _scope_expected_res =
    row.scope_expected_result !== null ? Number(row.scope_expected_result) : 0;
  const _schedule_res =
    row.schedule_result !== null ? Number(row.schedule_result) : 0;
  const _schedule_expected_res =
    row.schedule_expected_result !== null
      ? Number(row.schedule_expected_result)
      : 0;
  const targetResult = Number(row?.target_result);

  // status
  const status12 = row?.status?.id !== "DONE" || row.is_complete?.id === "1";
  const status34 = row?.status?.id === "DONE" || row.is_complete?.id === "2";

  // date
  const dateBefore = moment(
    row?.end_date === null ? new Date() : row?.end_date
  ).isBefore(row?.due_date, "day");
  const dateSame = moment(row?.due_date).isSame(
    row?.end_date === null ? new Date() : row?.end_date,
    "day"
  );
  const dateAfter = moment(
    row?.end_date === null ? new Date() : row?.end_date
  ).isAfter(row?.due_date, "day");

  let PROGRESS_SCOPE;
  let PROGRESS_SCHEDULE;
  let GAP_SCOPE;
  let GAP_SCHEDULE;
  let COLOR_SCOPE_BOLD;
  let COLOR_SCOPE_LIGHT;
  let BG_SCOPE_BOLD;
  let BG_SCOPE_LIGHT;
  let COLOR_SCHEDULE_BOLD;
  let COLOR_SCHEDULE_LIGHT;
  let BG_SCHEDULE_BOLD;
  let BG_SCHEDULE_LIGHT;
  let TEXT_SCOPE;
  let TEXT_SCHEDULE;

  // ============================================================
  // ~~~~~~~~~~~~~~~ SCHEDULE ~~~~~~~~~~~~~~~
  // ============================================================

  // ============================================================
  // SCNERIO #1 - not DONE & Incomplete & is today or b4 today
  if (status12 && (dateBefore || dateSame)) {
    let getProgress = row?.actual_duration / row?.planned_duration;
    COLOR_SCHEDULE_BOLD = "#4e9ebf";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = getProgress >= 1 ? "#74CB80" : "#4e9ebf";
    BG_SCHEDULE_LIGHT = "#74CB80";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    GAP_SCHEDULE = 1;
    TEXT_SCHEDULE = _schedule_res;
  }

  // ============================================================
  // SCNERIO #2 - Color Red
  if (status12 && dateAfter) {
    COLOR_SCHEDULE_BOLD = "#4e9ebf";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#F16C7D";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    GAP_SCHEDULE = 1;
    TEXT_SCHEDULE = _schedule_res;
  }

  // ============================================================
  // SCNERIO #3 - Color Green
  if (status34 && (dateBefore || dateSame)) {
    let getProgress = row?.actual_duration / row?.planned_duration;

    COLOR_SCHEDULE_BOLD = "#4e9ebf";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = getProgress === 1 ? "#74CB80" : "#4e9ebf";
    BG_SCHEDULE_LIGHT = "#74CB80";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    GAP_SCHEDULE = 1;
    TEXT_SCHEDULE = _schedule_res;
  }

  // ============================================================
  // SCNERIO #4 - color Red
  if (status34 && dateAfter) {
    let getProgress = row?.planned_duration / row?.actual_duration;

    COLOR_SCHEDULE_BOLD = "#4e9ebf";
    COLOR_SCHEDULE_LIGHT = "#c4c7c7";
    BG_SCHEDULE_BOLD = "#F16C7D";
    BG_SCHEDULE_LIGHT = "#c4c7c7";
    PROGRESS_SCHEDULE = _schedule_res > 1 ? _schedule_res - 1 : _schedule_res;
    GAP_SCHEDULE = 1;
    TEXT_SCHEDULE = _schedule_res;
  }

  // ============================================================
  // ~~~~~~~~~~~~~~~ SCOPE ~~~~~~~~~~~~~~~
  // ============================================================
  // SCENARIO #1 - color green
  if (_gap_scope >= 0) {
    // if (status12) {
    COLOR_SCOPE_BOLD = "#4e9ebf";
    COLOR_SCOPE_LIGHT = "#4e9ebf";
    BG_SCOPE_BOLD = "#74CB80";
    BG_SCOPE_LIGHT = "#4e9ebf";
    PROGRESS_SCOPE = _scope_expected_res;
    GAP_SCOPE = _scope_res;
    TEXT_SCOPE = _scope_res;
  }

  if (_gap_scope < 0) {
    // if (status12) {
    COLOR_SCOPE_BOLD = "#4e9ebf";
    COLOR_SCOPE_LIGHT = "#c4c7c7";
    BG_SCOPE_BOLD = "#F16C7D";
    // BG_SCOPE_LIGHT = dateBefore ? "#c4c7c7" : "#F16C7D";
    BG_SCOPE_LIGHT = "#c4c7c7";
    PROGRESS_SCOPE = _scope_res;
    GAP_SCOPE = _scope_expected_res;
    TEXT_SCOPE = _scope_res;
  }

  /* =========================
    Handle STATUS
    ============================= */
  const handleStatus = (e) => {
    setLoadStatus(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        status: e.target.value,
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setLoadStatus(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadStatus(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  /* =========================
    Handle PRIORITY
    ============================= */
  const handlePriority = (e) => {
    setLoadPriority(true);

    const data = {
      Todolist: {
        ...dataUpdate.Todolist,
        priority: e.target.value,
      },
    };

    AxiosConfig.put(`${URL_API}/todolist/${row.id}`, data)
      .then((res) => {
        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setLoadPriority(false);
        }
      })
      .catch((error) => {
        // console.log("error", error);
        setLoadPriority(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  // ===================== | LATE BADGE | =====================
  let color;
  let bgColor;

  if (row?.status_label?.code === "late") {
    bgColor = "#fad2d8";
    color = "#C8203C";
  }

  if (row?.status_label?.code === "due_today") {
    bgColor = "#F6EDC3";
    color = "#B68812";
  }

  if (row?.status_label?.code === "new") {
    bgColor = "#CFEAF1";
    color = "#4194AC";
  }

  if (row?.status_label?.code === "on_track" || row?.status_label === null) {
    bgColor = "#D9EBDD";
    color = "#1E874D";
  }

  // // console.log("ROW", row);

  return (
    <TableRow
      selected={isItemSelected}
      className={completed ? classes.tableRowGrey : classes.tableRow}
    >
      <TableCell
        className={classes.txtBody}
        align="left"
        style={{ width: "100%" }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {project?.project_type === "TEAM" && (
              <Checkbox
                size="small"
                className={classCheck.checkboxRoot}
                checkedIcon={
                  <span
                    className={
                      completed
                        ? classCheck.checkedCompleteIcon
                        : classCheck.checkedIcon
                    }
                  />
                }
                icon={
                  <span
                    className={
                      completed ? classCheck.completedIcon : classCheck.icon
                    }
                  />
                }
                disableRipple
                color="default"
                checked={isItemSelected}
                onClick={(e) => handleChangeCheckbox(e, row)}
              />
            )}
            <Typography
              variant="subtitle1"
              className={classes.projectName}
              style={{
                // minWidth: 300,
                marginLeft: 10,
              }}
              onClick={handleDetails}
            >
              {/* {FormatTitleCase(row.title)} */}
              {row.title}
            </Typography>
          </Box>

          <Box ml={1}>
            <Box
              bgcolor={bgColor}
              px={1}
              py={0.25}
              fontSize={10}
              color={color}
              borderRadius={5}
            >
              {row?.status_label === null
                ? "On Track"
                : row?.status_label?.label}
            </Box>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ borderLeft: "1px solid #D9D9D9", minWidth: 135 }}
      >
        <Tooltip
          title={`${row.owner.member.first_name} ${row.owner.member.last_name}`}
        >
          {row.owner.member.self.rel.photo_url_thumbnail === null ? (
            <Box
              className={classes.justHover}
              display="flex"
              alignItems="center"
            >
              <AccountCircleIcon style={{ color: "#888" }} />
              <span style={{ color: "#555", fontSize: 14, marginLeft: 5 }}>
                {TruncateTextCustom(
                  `${row.owner.member.first_name} ${row.owner.member.last_name}`,
                  5
                )}
              </span>
            </Box>
          ) : (
            <Box
              className={classes.justHover}
              display="flex"
              alignItems="center"
            >
              <ImageSmallUser
                item={row.owner.member.self.rel.photo_url_thumbnail}
                index={1}
              />
              <span style={{ color: "#555", fontSize: 14, marginLeft: 5 }}>
                {TruncateTextCustom(
                  `${row.owner.member.first_name} ${row.owner.member.last_name}`,
                  5
                )}
              </span>
            </Box>
          )}
        </Tooltip>
      </TableCell>
      {projectTState.hideTaskGroup && (
        <TableCell
          align="center"
          className={classes.txtBody}
          style={{ minWidth: 80 }}
        >
          <Tooltip title={taskGroupName} className={classes.justHover}>
            <span style={{ color: "#555", fontSize: 13 }}>
              {TruncateTextCustom(taskGroupName, 7)}
            </span>
          </Tooltip>
        </TableCell>
      )}
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ minWidth: 80 }}
      >
        <Select
          fullminWidth
          value={row?.status?.id}
          onChange={handleStatus}
          disableUnderline={true}
        >
          {statusCollections.map((status, i) => {
            let bgColor;
            let color;

            switch (status.id) {
              case "TO DO":
                bgColor = "#DFDFDF";
                color = "#777777";
                break;
              case "DOING":
                bgColor = "#CFEAF1";
                color = "#4194AC";
                break;
              case "DONE":
                bgColor = "#D9EBDD";
                color = "#1E874D";
                break;
              case "ON HOLD":
                bgColor = "#F6EDC3";
                color = "#B68812";
                break;
              case "WAITING":
                bgColor = "#E2DAE5";
                color = "#92739F";
                break;
              default:
                bgColor = "#555555";
                color = "#fff";
            }

            return (
              <MenuItem value={status.id} key={i}>
                <span
                  className={classes.statusItem}
                  style={{
                    backgroundColor: bgColor,
                    color,
                  }}
                >
                  {loadStatus && (
                    <CircularProgress
                      size={13}
                      style={{ color, marginRight: 5 }}
                    />
                  )}{" "}
                  <span>{status.name}</span>
                </span>
              </MenuItem>
            );
          })}
        </Select>
      </TableCell>
      <TableCell
        align="center"
        className={classes.txtBody}
        style={{ minWidth: 100 }}
      >
        <Box display="flex" alignItems="center">
          <Select
            fullminWidth
            value={row.priority?.id}
            onChange={handlePriority}
            disableUnderline={true}
          >
            {priorityCollections.map((priority, i) => {
              let icon;

              switch (priority.id) {
                case "HIGH":
                  icon = icon_high;
                  break;
                case "MEDIUM":
                  icon = icon_medium;
                  break;
                case "LOW":
                  icon = icon_low;
                  break;
                default:
                  icon = null;
              }

              return (
                <MenuItem value={priority.id} key={i}>
                  <Box display="flex" alignItems="center">
                    {loadPriority && (
                      <CircularProgress
                        size={13}
                        style={{ color: "#d64253", marginRight: 5 }}
                      />
                    )}
                    <img src={icon} height={16} />
                    <span
                      style={{
                        color: "#4D4D4D",
                        fontSize: 14,
                        marginLeft: 5,
                      }}
                    >
                      {priority.name}
                    </span>
                  </Box>
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </TableCell>
      <TableCell
        align="left"
        className={classes.txtBody}
        style={{ minWidth: 120 }}
      >
        <span
          style={{
            color: row?.status?.id === "DONE" ? "#1E874D" : "#555",
            fontSize: 14,
          }}
        >
          {moment(row?.due_date).format("DD-MM-YYYY")}
        </span>
      </TableCell>
      <TableCell
        align="left"
        className={classes.txtBody}
        style={{ minWidth: 130 }}
      >
        <span
          style={{
            color: row?.status?.id === "DONE" ? "#1E874D" : "#555",
            fontSize: 14,
          }}
        >
          {row?.end_date !== null
            ? moment(row?.end_date).format("DD-MM-YYYY")
            : "Not Set"}
        </span>
      </TableCell>
      {method !== "SCHEDULE" && (
        <TableCell style={{ minWidth: 150 }}>
          {row?.scope_result !== null ? (
            <ProjectBar
              percentage={PROGRESS_SCOPE}
              gap={GAP_SCOPE}
              text={TEXT_SCOPE}
              bold={COLOR_SCOPE_BOLD}
              light={COLOR_SCOPE_LIGHT}
              bg={BG_SCOPE_BOLD}
              dash={BG_SCOPE_LIGHT}
            />
          ) : (
            <ProjectBar
              percentage={0}
              gap={1}
              text="N/A"
              bold="#c4c7c7"
              light="#c4c7c7"
              bg="#c4c7c7"
              dash="#c4c7c7"
            />
          )}
        </TableCell>
      )}
      {method !== "SCOPE" && (
        <TableCell style={{ minWidth: 150 }}>
          {row?.schedule_result !== null ? (
            <ProjectBar
              percentage={PROGRESS_SCHEDULE}
              gap={GAP_SCHEDULE}
              text={TEXT_SCHEDULE}
              bold={COLOR_SCHEDULE_BOLD}
              light={COLOR_SCHEDULE_LIGHT}
              bg={BG_SCHEDULE_BOLD}
              dash={BG_SCHEDULE_LIGHT}
            />
          ) : (
            <ProjectBar
              percentage={0}
              gap={1}
              text="N/A"
              bold="#c4c7c7"
              light="#c4c7c7"
              bg="#c4c7c7"
              dash="#c4c7c7"
            />
          )}
        </TableCell>
      )}
      <TableCell
        align="right"
        className={classes.txtBody}
        style={{ minWidth: 30 }}
      >
        <AnchorTaskitem classes={classes} task={row} project={project} />
      </TableCell>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </TableRow>
  );
};

export default ListTableTask;

const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    sort_order: 0,
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    sort_order: 1,
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    sort_order: 2,
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    sort_order: 3,
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    sort_order: 3,
  },
];

const priorityCollections = [
  {
    id: "HIGH",
    code: "HIGH",
    name: "High",
    sort_order: 0,
  },
  {
    id: "MEDIUM",
    code: "MEDIUM",
    name: "Medium",
    sort_order: 1,
  },
  {
    id: "LOW",
    code: "LOW",
    name: "Low",
    sort_order: 2,
  },
];
