import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";

import axiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import handleError from "../../screen/Report/components/global/handleError";
import DialogError from "../../components/DialogError";

const ActionNotification = ({ classes, unread, setTrigerNotif }) => {
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleDeleteAll = async () => {
    try {
      const response = await axiosConfig.delete(
        `${URL_API}/account-management/user/notifications/delete-all`
      );

      if (response.status === 200) {
        setTrigerNotif(Math.floor(Math.random() * 1000 + 1));
      }
    } catch (error) {
      // Handle Error
      const { listError, dialogErr, resStatus, errorMessage } =
        handleError(error);
      // Error State
      setOpenDialogError(dialogErr);
      setTextErrorMessage(errorMessage);
      setListError(listError);
      setErrorStatus(resStatus);
    }
  };

  const handleReadAll = async () => {
    try {
      const response = await axiosConfig.put(
        `${URL_API}/account-management/user/notifications/mark-all-as-read`
      );

      if (response.status === 200) {
        setTrigerNotif(Math.floor(Math.random() * 1000 + 1));
      }
    } catch (error) {
      // Handle Error
      const { listError, dialogErr, resStatus, errorMessage } =
        handleError(error);
      // Error State
      setOpenDialogError(dialogErr);
      setTextErrorMessage(errorMessage);
      setListError(listError);
      setErrorStatus(resStatus);
    }
  };

  return (
    <Box
      mr={2}
      mt={1}
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      minWidth={300}
    >
      <Box display="flex" alignItems="center">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnDelete}
          onClick={handleDeleteAll}
        >
          Delete all
        </Button>
        <Button
          size="small"
          variant="outlined"
          className={classes.btnMarkAll}
          onClick={handleReadAll}
        >
          Mark all as readed
        </Button>
      </Box>

      <Typography variant="caption" style={{ color: "#3d3a6f" }}>
        <b>Unread ({unread})</b>
      </Typography>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default ActionNotification;
