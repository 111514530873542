import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import InfoRoundedIcon from "@material-ui/icons/InfoRounded";

import { styles, theme } from "./Style/StyleCompanySetting";

import AxiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";
// import handleError from "../../Report/components/global/handleError";

import Language from "./GeneralSetting/Language";
import Achievements from "./GeneralSetting/Achievements";
import ActionSettings from "./GeneralSetting/ActionSettings";
import PeriodSetting from "./GeneralSetting/PeriodSetting";
// import LimitSettings from "./GeneralSetting/LimitSettings";
import PerformanceSettings from "./GeneralSetting/PerformanceSettings";
import DialogConfirmApply from "./GeneralSetting/ActionSettings/DialogConfirmApply";
import moment from "moment";

const useStyles = makeStyles(styles);

const ViewGeneralSetting = () => {
  const classes = useStyles();

  // ======================================================================
  // STATE
  // ======================================================================
  const [achievementGoal, setAchievementGoal] = useState({ min: 0, max: 100 });
  const [achievementMa, setAchievementMa] = useState({ min: 0, max: 100 });
  const [goalFactor, setGoalFactor] = useState(50);
  const [maFactor, setMaFactor] = useState(50);

  // original value state
  const [orilGoalAchieve, setOriGoalAchieve] = useState({
    min: 0,
    max: 100,
    status: "enabled",
  });
  const [oriMaAchieve, setOriMaAchieve] = useState({
    min: 0,
    max: 100,
    status: "enabled",
  });
  const [oriGoalFactor, setOriGoalFactor] = useState(50);
  const [oriMaFactor, setOriMaFactor] = useState(50);
  const [masterPeriod, setMasterPeriod] = useState([]);
  const [period, setPeriod] = useState("");

  // condition state
  const [enabledGoal, setEnabledGoal] = useState(false);
  const [enabledMa, setEnabledMa] = useState(false);
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [openDialogConfirmApply, setOpenDialogConfirmApply] = useState(false);
  const [loadPeriod, setLoadPeriod] = useState(false);
  const [lockSetting, setLockSetting] = useState(false);

  // -------- Handling Button Appy Enable/Disable --------
  const [readyData, setReadyData] = useState(null);
  const [disabledApply, setDisabledApply] = useState(true);

  // ======================================================================
  // SIDE EFFECT
  // ======================================================================
  useEffect(() => {
    let format = "hh:mm:ss";

    // let time = moment() gives you current time. no format required.
    let time = moment(new Date(), format),
      beforeTime = moment("04:00:00", format),
      afterTime = moment("18:00:00", format);

    if (time.isBetween(beforeTime, afterTime)) {
      // // console.log("is between");
      setLockSetting(true);
    } else {
      // // console.log("is not between");
      setLockSetting(false);
    }
  }, []);

  useEffect(() => {
    // -------- GET Goal Settings --------
    AxiosConfig.get(`${URL_API}/account-management/general-settings/goal`)
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES GS Goal", res);
        const general_setting = result.fields.general_setting_id.default_value;
        if (res.status === 200) {
          const value = general_setting[0].value;
          if (value !== null) {
            setAchievementGoal(value);
            setOriGoalAchieve(value);
            if (value.status !== undefined) {
              setEnabledGoal(value.status === "enabled" ? true : false);
            }
          } else {
            setAchievementGoal(achievementGoal);
            setOriGoalAchieve(orilGoalAchieve);
          }
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });

    // -------- GET MA Settings --------
    AxiosConfig.get(`${URL_API}/account-management/general-settings/ma`)
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES GS MA", res);
        const general_setting = result.fields.general_setting_id.default_value;
        if (res.status === 200) {
          const value = general_setting[0].value;
          if (value !== null) {
            setAchievementMa(value);
            setOriMaAchieve(value);

            if (value.status !== undefined) {
              setEnabledMa(value.status === "enabled" ? true : false);
            }
          } else {
            setAchievementMa(achievementMa);
            setOriMaAchieve(oriMaAchieve);
          }
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });

    // -------- GET Master Period --------
    setLoadPeriod(true);
    AxiosConfig.get(`${URL_API}/swd/master-period`)
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          setMasterPeriod(result);
        }
        setLoadPeriod(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoadPeriod(false);
      });

    // -------- GET Overall Performance Settings --------
    AxiosConfig.get(`${URL_API}/account-management/general-settings/general`)
      .then((res) => {
        const result = res.data.data.fields.general_setting_id.default_value;
        // // console.log("RES GS MA", res);

        const filterFactor = result.filter((el) => el.id === "3");
        const filterPeriod = result.filter((el) => el.id === "4");

        if (res.status === 200) {
          setGoalFactor(filterFactor[0].value.goal);
          setMaFactor(filterFactor[0].value.ma);
          setOriGoalFactor(filterFactor[0].value.goal);
          setOriMaFactor(filterFactor[0].value.ma);

          setPeriod(filterPeriod[0].value.period_id);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
      });
  }, [isModalResponse200]);

  // ======================================================================
  // LISTEN CHANGE STATE FOR APPLY BTN
  useEffect(() => {
    if (readyData !== null && readyData === "open") {
      setDisabledApply(false);
    } else {
      setDisabledApply(true);
    }
  }, [
    readyData,
    achievementGoal,
    achievementMa,
    goalFactor,
    maFactor,
    enabledGoal,
    enabledMa,
    isModalResponse200,
  ]);

  // ======================================================================
  // Func | Methods
  const handlePeriod = (data) => {
    setPeriod(data);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box mt={5} mx={8}>
        <Typography variant="h4" className={classes.title333}>
          <b>General Settings</b>
        </Typography>

        {lockSetting && (
          <Box mt={2} display="flex" alignItems="center">
            <InfoRoundedIcon style={{ color: "#00ACE6", fontSize: 18 }} />
            <Typography
              variant="subtitle2"
              className={classes.title888}
              style={{ marginLeft: 8 }}
            >
              General settings are locked during working hours (04:00 - 18:00)
            </Typography>
          </Box>
        )}

        <Box my={lockSetting ? 0 : 3} />

        <Language classes={classes} lockSetting={lockSetting} />

        <Box my={5}>
          <Divider />
        </Box>

        <Achievements
          classes={classes}
          achievementGoal={achievementGoal}
          achievementMa={achievementMa}
          setAchievementGoal={setAchievementGoal}
          setAchievementMa={setAchievementMa}
          enabledGoal={enabledGoal}
          setEnabledGoal={setEnabledGoal}
          enabledMa={enabledMa}
          setEnabledMa={setEnabledMa}
          onReadyData={setReadyData}
          lockSetting={lockSetting}
        />

        <Box my={5}>
          <Divider />
        </Box>

        <PeriodSetting
          classes={classes}
          masterPeriod={masterPeriod}
          onPeriod={handlePeriod}
          period={period}
          loadPeriod={loadPeriod}
          onReadyData={setReadyData}
          lockSetting={lockSetting}
        />

        {/* <Box my={5}>
          <Divider />
        </Box>

        <LimitSettings classes={classes} /> */}

        <Box my={5}>
          <Divider />
        </Box>

        <PerformanceSettings
          classes={classes}
          goalFactor={goalFactor}
          maFactor={maFactor}
          onGoalFactor={setGoalFactor}
          onMaFactor={setMaFactor}
          onReadyData={setReadyData}
          lockSetting={lockSetting}
        />

        <Box my={5}>
          <Divider />
        </Box>

        <ActionSettings
          classes={classes}
          setOpenDialogConfirmApply={setOpenDialogConfirmApply}
          disabledApply={disabledApply}
          lockSetting={lockSetting}
        />

        <DialogConfirmApply
          classes={classes}
          openDialogConfirmApply={openDialogConfirmApply}
          setOpenDialogConfirmApply={setOpenDialogConfirmApply}
          achievementGoal={achievementGoal}
          achievementMa={achievementMa}
          goalFactor={goalFactor}
          maFactor={maFactor}
          period={period}
          enabledGoal={enabledGoal}
          enabledMa={enabledMa}
          setModalResponse200={setModalResponse200}
          // original value from DB (no update)
          orilGoalAchieve={orilGoalAchieve}
          oriMaAchieve={oriMaAchieve}
          oriGoalFactor={oriGoalFactor}
          oriMaFactor={oriMaFactor}
          lockSetting={lockSetting}
        />
      </Box>
    </MuiThemeProvider>
  );
};

export default ViewGeneralSetting;
