import React from "react";
import { Button, Box } from "@material-ui/core";
import MyLang from "../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../constants/config-lang";

const ActionSettings = ({
  classes,
  setOpenDialogConfirmApply,
  disabledApply,
  lockSetting,
}) => {
  return (
    <Box mb={4} display="flex" justifyContent="flex-end">
      <Button
        variant="contained"
        size="medium"
        className={classes.button1}
        disabled={disabledApply || lockSetting}
        onClick={() => setOpenDialogConfirmApply(true)}
      >
        {MyLang() ? bahasa.btnApply : english.btnApply}
      </Button>
    </Box>
  );
};

export default ActionSettings;
