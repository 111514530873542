import React, { useEffect, useState } from "react";
import {
  Button,
  Popper,
  Grow,
  Paper,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";

import classNames from "classnames";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import FilterQuick from "./filterItem/FilterQuick";
import AnchorFilterTask from "./AnchorFilterTask";

import icon_filter from "../../../../assets/images/icon_filter.png";

const AnchorQuickTask = ({ classes }) => {
  const project_id = window.location.pathname.split("/")[2];

  const [anchorEl, setAnchorEl] = useState(null);
  const [quickFilters, setQuickFilters] = useState(true);
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [listGroup, setListGroup] = useState([]);

  useEffect(() => {
    if (anchorEl !== null) {
      AxiosConfig.get(`${URL_API}/todolist/create?project_id=${project_id}`)
        .then((res) => {
          const result = res.data.data;

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
          }
        })
        .catch((error) => {
          // console.log("Error", error);
        });

      AxiosConfig.get(`${URL_API}/project/${project_id}/task-group`)
        .then((res) => {
          const result = res.data.data;
          if (res.status === 200) {
            setListGroup(result);
          }
        })
        .catch((error) => {
          // Handle Error
          // console.log("Error", error);
        });
    }
  }, [anchorEl]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setQuickFilters(true);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
    setQuickFilters(true);
  };

  const handleCustomFilter = () => {
    setQuickFilters(false);
  };

  return (
    <div>
      <Tooltip title="Filter Task">
        <Button
          size="small"
          variant="outlined"
          className={classes.btnIcon}
          onClick={handleShow}
        >
          <img src={icon_filter} height={24} />
        </Button>
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <ClickAwayListener onClickAway={handleCloseAway}>
              <Paper className={classes.dropdown}>
                {quickFilters && (
                  <FilterQuick
                    classes={classes}
                    onCustomFilter={handleCustomFilter}
                    onClose={handleClose}
                  />
                )}
                {!quickFilters && (
                  <AnchorFilterTask
                    classes={classes}
                    onClose={handleClose}
                    ownerCollections={ownerCollections}
                    listGroup={listGroup}
                  />
                )}
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AnchorQuickTask;
