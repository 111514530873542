import React, { Fragment } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const IconTargetType = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Fragment>
      <ListItemIcon
        style={{
          marginLeft: 8,
        }}
      >
        <IconButton
          style={{ backgroundColor: "#fed9ff", padding: "7px", opacity: 0.5 }}
        >
          <EventAvailableIcon style={{ color: "orangered" }} />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            {MyLang() ? bahasa.targetType : english.targetType}
          </Typography>
        }
        secondary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            <b>{goalDetailState.targetType.name}</b>
          </Typography>
        }
      />
    </Fragment>
  );
};

export default IconTargetType;
