import React, { useEffect, useReducer, useState } from "react";
import ContextAttendanceDashboard from "./ContextAttendanceDashboard";
import {
  attendanceDashboardReducer,
  initialAttendanceDashboard,
} from "./reducer/attendanceDashboardReducer";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import { VERIFICATION_METHOD } from "./types";

const ContextGlobalAttendanceDashboard = (props) => {
  // ========== |REDUCER| ==========
  const [attendanceDashboardState, attendanceDashboardDispatch] = useReducer(
    attendanceDashboardReducer,
    initialAttendanceDashboard
  );

  const [verificationMethod, setVerificationMethod] = useState([]);

  useEffect(() => {
    getVerificationMethod();
  }, []);

  const getVerificationMethod = async () => {
    try {
      const { data } = await axiosConfig
        .get(`${URL_API}/human-resource/attendance/verification-method`)
        .then((res) => {
          return res.data;
        });
      setVerificationMethod(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ContextAttendanceDashboard.Provider
      value={{
        verificationMethod,
        setVerificationMethod,
        attendanceDashboardState,
        attendanceDashboardDispatch,
      }}
    >
      {props.children}
    </ContextAttendanceDashboard.Provider>
  );
};

export default ContextGlobalAttendanceDashboard;
