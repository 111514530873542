import React, { useContext, useState } from "react";
import { Collapse } from "@material-ui/core";

import InputCard from "./InputCard";

import "./style/inputContainer.css";
import ContextProject from "../../context/ContextProject";

const InputContainer = ({ listId, type, objCustomPhase, objTaskGroup }) => {
  const { dataKanban } = useContext(ContextProject);
  const list = dataKanban.lists[listId];
  const createPolicy = list?.options?.policy?.create;

  const [open, setOpen] = useState(false);

  return (
    <>
      {type === "card" && (
        <div className="input-container">
          <Collapse in={open}>
            <InputCard
              setOpen={setOpen}
              open={open}
              listId={listId}
              type={type}
              objCustomPhase={objCustomPhase}
              objTaskGroup={objTaskGroup}
              createPolicy={createPolicy}
            />
          </Collapse>
          <Collapse in={!open}>
            <div className="input-content">
              <button onClick={() => setOpen(!open)} disabled={!createPolicy}>
                {/* {type === "card" ? "+ Add Card" : "+ Add List"} */}Add Card
              </button>
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};

export default InputContainer;
