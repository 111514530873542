import React, { Fragment, useState, useContext, useEffect } from "react";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  TableRow,
  TableCell,
  Box,
  Avatar,
  Checkbox,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

import clsx from "clsx";
import moment from "moment";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import AttachmentRoundedIcon from "@material-ui/icons/AttachmentRounded";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";

import {
  StyledMenuItem,
  StyledMenu,
} from "../../../../components/StyledMenuDropdown";

import { navigate } from "hookrouter";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import ContextGlobalDrive from "../../context/ContextDrive";
import FormatBytes from "../../../../utilities/FormatBytes";

import DialogInfoFile from "../components/DialogInfoFiles";
import DialogDeleteFile from "../components/DialogDeleteFile";
import DialogRenameFile from "../components/DialogRenameFile";
import DialogPreviewImage from "../components/DialogPreviewImage";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";

import DialogMoveFile from "../components/DialogMoveFile";
import DialogPreviewVideo from "../components/DialogPreviewVideo";
import DialogDownloadFile from "../components/DialogDownloadFile";
import DialogCopyFile from "../components/DialogCopyFile";
import DialogPreviewPDF from "../components/DialogPreviewPDF";
import DialogPreviewDocument from "../components/DialogPreviewDocument";
import DialogPreviewText from "../components/DialogPreviewText";

function FilesList({ classes, item, isFileSelected }) {
  const context = useContext(ContextGlobalDrive);

  /* ====================================================
    LOCAL STATE
  ==================================================== */
  const [anchorElFile, setAnchorElFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadFile, setDownloadFile] = useState(null);
  const [image, setImage] = useState("");

  const [openDialogInfoFile, setOpenDialogInfoFile] = useState(false);
  const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
  const [openDialogRenameFile, setOpenDialogRenameFile] = useState(false);
  const [openDialogMoveFile, setOpenDialogMoveFile] = useState(false);
  const [openDialogPreviewImage, setOpenDialogPreviewImage] = useState(false);
  const [openDialogPreviewVideo, setOpenDialogPreviewVideo] = useState(false);
  const [openDialogDownloadFile, setOpenDialogDownloadFile] = useState(false);
  const [openDialogCopyFile, setOpenDialogCopyFile] = useState(false);
  const [openDialogPreviewPDF, setOpenDialogPreviewPDF] = useState(false);
  const [openDialogPreviewDocument, setOpenDialogPreviewDocument] =
    useState(false);
  const [openDialogPreviewText, setOpenDialogPreviewText] = useState(false);

  /* ====================================================
    SIDE EFFECT
  ==================================================== */

  useEffect(() => {
    setLoading(true);
    axiosConfig
      .get(`${URL_API}/drive/file/${item.id}?mode=thumbnail`, {
        responseType: "blob",
      })
      .then((response) => {
        // // console.log("Response Thumbnail", response);

        if (response.status === 200) {
          setImage(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);
      });
  }, []);

  const handleShowAnchorFile = (event) => {
    setAnchorElFile(event.currentTarget);
  };

  const handleCloseAnchorFile = () => {
    setAnchorElFile(null);
  };

  const handleDialogInfoFile = () => {
    setOpenDialogInfoFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDeleteFile = () => {
    setOpenDialogDeleteFile(true);
    setAnchorElFile(null);
  };

  const handleDialogRenameFile = () => {
    setOpenDialogRenameFile(true);
    setAnchorElFile(null);
  };

  const handleDialogMoveFile = () => {
    setOpenDialogMoveFile(true);
    setAnchorElFile(null);
  };

  const handleDialogDownloadFile = () => {
    setOpenDialogDownloadFile(true);
    setAnchorElFile(null);
  };

  const handleDialogCopyFile = () => {
    setOpenDialogCopyFile(true);
    setAnchorElFile(null);
  };

  const handleFileLocation = () => {
    if (item.folder_id === null) {
      navigate("/drive");
      setAnchorElFile(null);
    } else {
      let breadcrumb = localStorage.getItem("breadcrumb");
      breadcrumb = breadcrumb ? breadcrumb.split("/") : [];
      breadcrumb.push(item.folder_name);
      localStorage.setItem("breadcrumb", breadcrumb.toString());

      let directory_id = localStorage.getItem("directory_id");
      directory_id = directory_id ? directory_id.split("/") : [];
      directory_id.push(item.folder_id);
      localStorage.setItem("directory_id", directory_id.toString());

      navigate(`/drive/${item.folder_id}`);
      setAnchorElFile(null);
    }
  };

  // Handle Preview Image
  const handlePreviewFile = (data) => {
    const imageType = data.mime_type.includes("image");
    const videoType = data.mime_type.includes("video");
    const pdfType = data.mime_type.includes("pdf");
    const textType = data.mime_type.includes("text");
    const docType = data.extension;

    if (imageType) {
      setOpenDialogPreviewImage(true);
      setAnchorElFile(null);
    } else if (videoType) {
      setOpenDialogPreviewVideo(true);
      setAnchorElFile(null);
    } else if (pdfType) {
      setOpenDialogPreviewPDF(true);
      setAnchorElFile(null);
    } else if (
      docType === "doc" ||
      docType === "docx" ||
      docType.includes("ppt") ||
      docType === "xlsx" ||
      docType === "xls"
    ) {
      setOpenDialogPreviewDocument(true);
      setAnchorElFile(null);
    } else if (textType) {
      setOpenDialogPreviewText(true);
      setAnchorElFile(null);
    }

    // console.log(data.mime_type);
  };

  // Handle Select Checkbox
  const handleChangeCheckbox = (item) => {
    const selectedIndex = context.selectedFile.indexOf(item.id);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(context.selectedFile, item.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(context.selectedFile.slice(1));
    } else if (selectedIndex === context.selectedFile.length - 1) {
      newSelected = newSelected.concat(context.selectedFile.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        context.selectedFile.slice(0, selectedIndex),
        context.selectedFile.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected Folders", newSelected);

    context.setSelectedFile(newSelected);
  };

  // Check is Folder selected or not
  const isItemFileSelected = isFileSelected(item.id);

  // Blob Type to URL => for thumbnail
  const blobUrlImage = new Blob([image], { type: item.mime_type });

  // ============== Pollicy ==============
  const user_login = localStorage.getItem("status_user_login");
  const superAdmin = JSON.parse(user_login);
  const role = superAdmin.userRole[0].name;

  let deleteAllowed = false;
  let updateAllowed = false;

  // // console.log("PLISI", context.detailFolder);

  context.detailFolder.policy !== undefined &&
    context.detailFolder.policy.length > 0 &&
    context.detailFolder.policy.map((isAllowed) => {
      if (isAllowed.actionName === "delete") {
        deleteAllowed = isAllowed.result;
      } else if (isAllowed.actionName === "update") {
        updateAllowed = isAllowed.result;
      }
    });

  if (role === "superadmin") {
    deleteAllowed = true;
    updateAllowed = true;
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell padding="checkbox">
          {context.toggleSelect && (
            <Checkbox
              style={{ marginTop: -10 }}
              onClick={() => handleChangeCheckbox(item)}
              className={classes.checkboxRoot}
              checked={isItemFileSelected}
              disableRipple
              color="default"
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              icon={<span className={classes.icon} />}
              inputProps={{ "aria-label": "decorative checkbox" }}
            />
          )}
        </TableCell>

        <TableCell>
          <Box display="flex" alignItems="center">
            {item.mime_type.includes("image") ? (
              <Avatar
                variant="rounded"
                src={image !== "" && URL.createObjectURL(blobUrlImage)}
                style={{
                  width: 30,
                  height: 30,
                }}
              >
                {loading && (
                  <CircularProgress size={14} style={{ color: "#d1354a" }} />
                )}
              </Avatar>
            ) : item.mime_type === "application/pdf" ? (
              <img
                src={pdf_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type === "application/msword" ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
              <img
                src={word_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type === "application/vnd.ms-powerpoint" ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
              <img
                src={ppt_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type === "application/vnd.ms-excel" ||
              item.mime_type ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <img
                src={excel_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type.includes("video") ? (
              <img
                src={video_icon}
                style={{
                  width: 20,
                  margin: `0 5px`,
                }}
              />
            ) : item.mime_type === "text/plain" ? (
              <img
                src={text_icon}
                style={{
                  width: 25,
                  margin: `0 2px`,
                }}
              />
            ) : (
              <InsertDriveFileIcon color="inherit" fontSize="default" />
            )}

            <Typography
              variant="subtitle2"
              className={classes.folderNameText}
              style={{ marginLeft: 15, marginRight: 7.5 }}
              onClick={() => handlePreviewFile(item)}
            >
              {item.name}
            </Typography>

            {item.goalResultEvidence !== undefined &&
              item.goalResultEvidence.length > 0 && (
                <Tooltip title="File ini digunakan sebagai bukti untuk Goal">
                  <AttachmentRoundedIcon
                    fontSize="small"
                    style={{ color: "#d1354a", marginRight: 5 }}
                  />
                </Tooltip>
              )}

            {item.measuredActivityResultEvidence !== undefined &&
              item.measuredActivityResultEvidence.length > 0 && (
                <Tooltip title="File ini digunakan sebagai bukti untuk Measured Activity">
                  <AttachmentRoundedIcon
                    fontSize="small"
                    style={{ color: "#009432" }}
                  />
                </Tooltip>
              )}
          </Box>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {moment(item.modified_at).format("DD MMM YYYY, HH:mm")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {item.extension}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {FormatBytes(item.size)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "#555" }}
          >
            {item.owner !== undefined &&
              item.owner !== null &&
              `${item.owner.member.first_name} ${item.owner.member.last_name}`}
          </Typography>
        </TableCell>

        <TableCell align="right">
          <IconButton onClick={handleShowAnchorFile}>
            <MoreVertIcon />
          </IconButton>

          <StyledMenu
            id="customized-menu"
            anchorEl={anchorElFile}
            keepMounted
            open={Boolean(anchorElFile)}
            onClose={handleCloseAnchorFile}
          >
            <StyledMenuItem
              style={{ width: 200 }}
              onClick={handleDialogInfoFile}
            >
              <ListItemIcon>
                <VisibilityOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    View Info
                  </Typography>
                }
              />
            </StyledMenuItem>
            {updateAllowed && (
              <StyledMenuItem onClick={handleDialogRenameFile}>
                <ListItemIcon>
                  <EditOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Rename
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleFileLocation}>
              <ListItemIcon>
                <FolderOpenIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Show file location
                  </Typography>
                }
              />
            </StyledMenuItem>
            {/* {updateAllowed && (
              <StyledMenuItem onClick={handleDialogCopyFile}>
                <ListItemIcon>
                  <FileCopyOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}>
                      Copy File
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )} */}
            {deleteAllowed && (
              <StyledMenuItem onClick={handleDialogDeleteFile}>
                <ListItemIcon>
                  <DeleteOutlinedIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Delete File
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            {deleteAllowed && (
              <StyledMenuItem onClick={handleDialogMoveFile}>
                <ListItemIcon>
                  <SyncAltIcon
                    fontSize="small"
                    className={classes.hoverColor}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.rightMenuText}
                    >
                      Move File
                    </Typography>
                  }
                />
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleDialogDownloadFile}>
              <ListItemIcon>
                <CloudDownloadOutlinedIcon
                  fontSize="small"
                  className={classes.hoverColor}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    className={classes.rightMenuText}
                  >
                    Download
                  </Typography>
                }
              />
            </StyledMenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>

      <DialogInfoFile
        openDialogInfoFile={openDialogInfoFile}
        setOpenDialogInfoFile={setOpenDialogInfoFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogDeleteFile
        openDialogDeleteFile={openDialogDeleteFile}
        setOpenDialogDeleteFile={setOpenDialogDeleteFile}
        item={item}
        classes={classes}
      />

      <DialogRenameFile
        openDialogRenameFile={openDialogRenameFile}
        setOpenDialogRenameFile={setOpenDialogRenameFile}
        item={item}
        classes={classes}
      />

      <DialogPreviewImage
        openDialogPreviewImage={openDialogPreviewImage}
        setOpenDialogPreviewImage={setOpenDialogPreviewImage}
        itemId={item.id}
        itemType={item.mime_type}
        itemName={item.name}
        origin="Drive"
      />

      <DialogMoveFile
        openDialogMoveFile={openDialogMoveFile}
        setOpenDialogMoveFile={setOpenDialogMoveFile}
        classes={classes}
        item={item}
      />

      <DialogPreviewVideo
        openDialogPreviewVideo={openDialogPreviewVideo}
        setOpenDialogPreviewVideo={setOpenDialogPreviewVideo}
        itemId={item.id}
        itemName={item.name}
      />

      <DialogDownloadFile
        openDialogDownloadFile={openDialogDownloadFile}
        setOpenDialogDownloadFile={setOpenDialogDownloadFile}
        item={item}
        classes={classes}
        origin="Drive"
      />

      <DialogCopyFile
        openDialogCopyFile={openDialogCopyFile}
        setOpenDialogCopyFile={setOpenDialogCopyFile}
        classes={classes}
        item={item}
      />

      <DialogPreviewPDF
        openDialogPreviewPDF={openDialogPreviewPDF}
        setOpenDialogPreviewPDF={setOpenDialogPreviewPDF}
        itemId={item.id}
        itemName={item.name}
        itemType={item.mime_type}
      />

      <DialogPreviewDocument
        openDialogPreviewDocument={openDialogPreviewDocument}
        setOpenDialogPreviewDocument={setOpenDialogPreviewDocument}
        itemId={item.id}
        itemName={item.name}
        origin="Drive"
      />

      <DialogPreviewText
        openDialogPreviewText={openDialogPreviewText}
        setOpenDialogPreviewText={setOpenDialogPreviewText}
        itemId={item.id}
        itemName={item.name}
        classes={classes}
        item={item}
        origin="Drive"
      />
    </Fragment>
  );
}

export default FilesList;
