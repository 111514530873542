import React, { useState, useContext } from "react";
import {
  Paper,
  Box,
  Typography,
  IconButton,
  Menu,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import clsx from "clsx";

import ListItemMenu from "./ListItemMenu";
import ContextReports from "../../../../context/ContextReports";
import { SET_SUMMARY_TAB, GET_DATA_DETAIL } from "../../../../context/types";

import Table from "./Table";
import TableDetail from "./TableDetail";

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#282560",
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#282560",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const TableSection = ({ classes }) => {
  const { tableSmrState, tableSmrDispatch } = useContext(ContextReports);

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [rate, setRate] = useState({ goal: false, ma: false });

  const handleTabs = (e, newValue) => {
    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: newValue },
    });

    if (newValue === 0) {
      tableSmrDispatch({
        type: GET_DATA_DETAIL,
        payload: {
          loadDetail: false,
          dataDetail: {
            goals: [],
            mas: [],
          },
        },
      });
    }
  };

  const handleShowAnchorMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleCloseAnchorMenu = () => {
    setAnchorMenu(null);
  };

  const handleRate = (event) => {
    setRate({ ...rate, [event.target.name]: event.target.checked });
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}>
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3} paddingBottom={0}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between">
          <Typography className={classes.title333} variant="subtitle1">
            <b>Table</b>
          </Typography>

          <Box display="flex">
            <FormControlLabel
              label={
                <Typography variant="caption" clasName={classes.title555}>
                  Goal Input
                </Typography>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={rate.goal}
                  name="goal"
                  onChange={handleRate}
                />
              }
            />

            <FormControlLabel
              label={
                <Typography variant="caption" clasName={classes.title555}>
                  MA Input
                </Typography>
              }
              control={
                <Checkbox
                  className={classes.checkboxRoot}
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={rate.ma}
                  name="ma"
                  onChange={handleRate}
                />
              }
            />

            <IconButton
              size="small"
              onClick={handleShowAnchorMenu}
              style={{ marginLeft: 30 }}>
              <MoreHorizIcon fontSize="small" style={{ color: "#888" }} />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorMenu}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorMenu)}
              onClose={handleCloseAnchorMenu}>
              <ListItemMenu
                classes={classes}
                setAnchorMenu={setAnchorMenu}
                rate={rate}
              />
            </Menu>
          </Box>
        </Box>

        {/* ====== Table ====== */}
        <Box marginTop={4}>
          <StyledTabs value={tableSmrState.summaryTab} onChange={handleTabs}>
            <StyledTab label="Summary" />
            <StyledTab label="Detail" />
          </StyledTabs>
          <TabPanel value={tableSmrState.summaryTab} index={0}>
            <Table classes={classes} rate={rate} />
          </TabPanel>
          <TabPanel value={tableSmrState.summaryTab} index={1}>
            <TableDetail classes={classes} />
          </TabPanel>
        </Box>
      </Box>
    </Paper>
  );
};

export default TableSection;
