import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Autocomplete } from "@material-ui/lab";
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogEdit = (props) => {
  const {
    classes,
    isModalEdit,
    setModalEdit,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterDataBreak,
    setMasterDataBreak,
    dataBreak,
    setDataBreak,
    listError,
    handleUpdate,
  } = props;

  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [locale, setLocale] = useState("id");

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Edit Daftar Istirahat</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Tulis Nama Istirahat</b>
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    className={classes.textField}
                    size="small"
                    variant="outlined"
                    fullWidth
                    placeholder="e.g. Istirahat Siang,Istirahat Sore..."
                    value={dataBreak.title}
                    onChange={(event, newValue) => {
                      let dataBreakTemporary = dataBreak;
                      dataBreakTemporary.title = event.target.value;
                      setDataBreak({...dataBreakTemporary});
                    }}
                    error={listError.some(item => 'title' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'title') {
                        return item.description;
                      }
                    })}
                  />
                </div>
              </Grid>
            </Grid>
            <Box my={2.5} />
            <Grid container spacing={2}>
              <Grid item md={6} style={{ textAlign: "left" }}>
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Waktu Istirahat Mulai</b>
                  </Typography>
                  <Box my={0.5} />
                  <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                    <TimePicker
                      placeholder="Waktu Istirahat Mulai"
                      value={dataBreak.break_start_date}
                      onChange={(date) => {
                        let dataBreakTemporary = dataBreak;
                        dataBreakTemporary.break_start_date = date;
                        dataBreakTemporary.break_start = moment(date).format("HH:mm");
                        setDataBreak({...dataBreakTemporary});
                      }}
                      autoOk={true}
                      ampm={false}
                      inputVariant="outlined"
                      size="small"
                      className={classes.textField}
                      format="HH:mm"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              size="small"
                            >
                              <EventAvailableIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Grid>
              <Grid item md={6} style={{ textAlign: "left" }}>
                  <div>
                    <Typography variant="subtitle2" className={classes.title333}>
                      <b>Waktu Istirahat Berakhir</b>
                    </Typography>
                    <Box my={0.5} />
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                      <TimePicker
                        placeholder="Waktu Istirahat Berakhir"
                        value={dataBreak.break_end_date}
                        onChange={(date) => {
                          let dataBreakTemporary = dataBreak;
                          dataBreakTemporary.break_end_date = date;
                          dataBreakTemporary.break_end = moment(date).format("HH:mm");
                          setDataBreak({...dataBreakTemporary});
                        }}
                        autoOk={true}
                        ampm={false}
                        inputVariant="outlined"
                        size="small"
                        className={classes.textField}
                        format="HH:mm"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton
                                size="small"
                              >
                                <EventAvailableIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalEdit(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={handleUpdate}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogEdit;