/**
 * Import Utilities
 */
import moment from "moment";
import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";

import { withStyles } from "@material-ui/core/styles";

import Hidden from "@material-ui/core/Hidden";
import { URL_API } from "../../../constants/config-api";
import { formatHariKe } from "./Utilities/PeriodUtil";
import { useDebouncedCallback } from "use-debounce";
//import { GET_LABEL_REGISTER_GROUP, POST_REGISTER_GROUP } from '../../.././../constants/config-';

/**
 * Icons
 */
import MoreVert from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";

/**
 * Import List component
 */
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

/**
 * Import Card component
 */
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

/**
 * Import Core
 */
import {
  Container,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  Box,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";

/**
 * Import Button
 */
import ButtonBase from "@material-ui/core/ButtonBase";

/**
 * Import CRUD Component
 */
import CreatePeriod from "./ComponentViewPeriode/CreatePeriod";
import DetailPeriod from "./ComponentViewPeriode/DetailPeriod";
import UpdatePeriod from "./ComponentViewPeriode/UpdatePeriod";
import DeletePeriod from "./ComponentViewPeriode/DeletePeriod";

/**
 * Import image
 */
import Group_2463 from "../../../assets/images/Group_2463.png";
import PictDefaultDashboard from "../../../assets/images/Mask_Group_2.png";
import ErrorIconDialog from "../../../assets/images/icon-info-24px.svg";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import DialogInfoSchedule from "../../../components/DialogInfoSchedule";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  rootDateRange: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginTop: 6,
    marginBottom: 10,
    /*width: 400, */
  },
  container: {
    backgroundColor: "white",
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "800px",
  },
  button: {
    borderRadius: 5,
    background:
      "linear-gradient(1deg, #c1272d, #c1272d 30%, #d1354a 67%, #f28181)",
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
  },
  title: {
    fontFamily: "Roboto",
  },
  subTitle: {
    color: "gray",
  },
  divSearch: {
    border: 0,
    borderRadius: 20,
    background: "white",
    padding: 10,
    width: "90%",
    marginTop: 10,
  },
  textSearch: {
    width: "100%",
    fontSize: 16,
    border: 0,
    backgroundColor: "white",
    outline: "none",
  },
  periodListleftTopHeader: {
    background:
      "linear-gradient(1deg, #0083ff, #0083ff 30%, #00b8ff 67%, #00b8ff)",
    color: "white",
    marginTop: theme.spacing(7.5),
    marginLeft: theme.spacing(7.5),
    padding: theme.spacing(2),
  },
  periodListLeftMenu: {
    marginLeft: theme.spacing(7.5),
    paddingLeft: theme.spacing(7.5),
  },
  periodListLeftItem: {
    marginTop: 0,
    marginBottom: 0,
  },
  periodListRightItem: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderLeft: "4px rgb(240, 170, 0) solid",
  },
  periodRightDetail: {
    marginTop: theme.spacing(7.5),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  labelSearch: {
    fontWeight: "bold",
    fontSize: 17,
  },
  multiSelect: {
    padding: 0,
    margin: 0,
  },

  layoutEmptyState: {
    marginTop: theme.spacing(11),
  },
  textFieldCustom: {
    /*
		margin: theme.spacing(1),
		padding : theme.spacing(1), */
    padding: 8,
    border: "1px solid rgba(242, 38, 19, 1)",
    borderRadius: 4,
    "&:focus": {
      borderRadius: 4,
      outline: "none",
      //boxShadow: '0 0 0 0.1rem rgba(242, 38, 19, 1)',
      boxShadow: "0 0 3px rgba(242, 38, 19, 1)",
      border: "1px solid rgba(242, 38, 19, 1)",
    },
  },
  iconButton: {
    padding: 6,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
  buttonBase: {
    margin: theme.spacing(1),
    margin: 0,
    flex: 1,
  },
  buttonMargin: {
    margin: theme.spacing(1),
  },
  periodListleftTopHeaderSearch: {
    backgroundColor: "white",
    borderRadius: "30px",
    height: "40px",
  },
});

//menu item style
// const StyledMenu = withStyles({
//   paper: {
//     border: "1px solid #d3d4d5",
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: "bottom",
//       horizontal: "center",
//     }}
//     transformOrigin={{
//       vertical: "top",
//       horizontal: "center",
//     }}
//     {...props}
//   />
// ));

// const StyledMenuItem = withStyles((theme) => ({
//   root: {
//     "&:focus": {
//       backgroundColor: theme.palette.primary.main,
//       "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

const ViewPeriode = (props) => {
  const { classes } = props;

  /*
		````````````````````````````````````
		DEFINE GENERAL STATE

		````````````````````````````````````
	*/
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });
  const userToken = localStorage.getItem("userToken");
  const [userTokenState, setUserTokenState] = useState("");
  const [periodListLeftItemLoader, setPeriodListLeftItemLoader] =
    useState(true);
  const [periodListRightItemLoader, setPeriodListRightItemLoader] =
    useState(true);
  const [timeScalingList, setTimeScalingList] = useState([]);

  // Inof Schedulr
  const [openDialogInfoSchedule, setOpenDialogInfoSchedule] = useState(false);
  const [originSchedule, setOriginSchedule] = useState("");

  /*
        ````````````````````````````````````
        GET PERIODE / MASTER PERIOD LIST

        ````````````````````````````````````
    */
  const [listPeriode, setListPeriode] = useState([]);
  useEffect(() => {
    setUserTokenState(userToken);
    setTimeScalingList([
      {
        name: "Minggu",
        code: "weekly",
      },
      {
        name: "Bulan",
        code: "monthly",
      },
      {
        name: "Triwulan",
        code: "quarterly",
      },
      {
        name: "Tahun",
        code: "yearly",
      },
    ]);
    if (userToken !== undefined) {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      // GET LIST PERIODE
      axios
        .get(URL_API + `/swd/master-period`)
        .then((response) => {
          // console.log("Response Original PERIODE   : ", response);
          setListPeriode(response.data.data);
          setPeriodListLeftItemLoader(false);
          setPeriodListRightItemLoader(false);
        })
        .catch((error) => {
          setPeriodListLeftItemLoader(false);
          setPeriodListRightItemLoader(false);

          if (error.response !== undefined) {
            let resData = error.response.data;

            // when bad request
            if (resData.info.status == 400) {
              setErrorStatus(resData.info.status);
              setOpenErrorDialog(true);
              setErrorTitle("Validation Error");
              setErrorContent(resData.info.errors);
              console.log(resData.info.errors);
            } else if (resData.info.status == 403) {
              setErrorStatus(resData.info.status);
              setOpenErrorDialog(true);
              setErrorTitle("Unaithorized Action");
              setErrorContent({ description: "Forbidden" });
            } else if (resData.info.status == 404) {
              setErrorStatus(resData.info.status);
              setOpenErrorDialog(true);
              setErrorTitle("Page Not Found");
              setErrorContent({ description: "The request does not exists" });
            } else {
              setErrorStatus(resData.info.status);
              setOpenErrorDialog(true);
              setErrorTitle("Internal Server Error");
              setErrorContent({ description: "whoops, something went wrong!" });
            }
          } else {
            setErrorStatus(-1);
            setOpenErrorDialog(true);
            setErrorTitle("Cannot connect to server");
            setErrorContent({
              description:
                "Sorry, we cannot reach the server. Please check your connection",
            });
          }
          // console.log("Error : ", error.response);
        });

      // GET FILTER OPTIONS
      axios
        .get(URL_API + `/swd/master-period/options`)
        .then(function (response) {
          // console.log("Response Original PERIODE OPTIONS  : ", response);
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
        });
    }
  }, []);

  /*
		````````````````````````````````````
		HANDLE PERIOD DETAIL

		````````````````````````````````````
	*/
  const [periodDetail, setPeriodDetail] = useState(null);
  const handleViewPeriod = (srcId) => {
    setPeriodDetail(null);
    if (listPeriode !== undefined && listPeriode.length > 0) {
      let len = listPeriode.length;
      for (let i = 0; i < len; i++) {
        if (listPeriode[i]["id"] == srcId) {
          setPeriodDetail(listPeriode[i]);
          setOpenDetail(true);
          // console.log("View Period Detail : ", listPeriode[i]);
          break;
        }
      }
    }
  };

  /*
		```````````````````````````````````
		HANDLE CREATE PERIOD

		```````````````````````````````````
	*/
  const [openCreateForm, setOpenCreateForm] = useState(false);

  /**
   * Handling to open create form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const handleOpenCreateForm = (evt) => {
    // setOpenCreateForm(true);
    setOriginSchedule("create");
    setOpenDialogInfoSchedule(true);
  };

  /**
   * Handling to close create form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */

  /*
		``````````````````````````````````
		HANDLE CLICK VIEW PERIOD MORE LEFT MENU

		``````````````````````````````````
	*/
  const [selectedViewPeriodMoreMenuItem, setSelectedViewPeriodMoreMenuItem] =
    useState(null);
  const [anchorClickViewPeriodMoreMenu, setAnchorClickViewPeriodMoreMenu] =
    useState(null);
  const [openViewPeriodMoreMenu, setOpenViewPeriodMoreMenu] = useState(false);

  /**
   * Handling to click more menu in left menu.
   *
   * @param {object} evt object event.
   * @param {object} item object of period data.
   * @return {void}
   *
   */
  const handleClickViewPeriodMoreMenu = (evt, item) => {
    setOpenViewPeriodMoreMenu(true);
    setAnchorClickViewPeriodMoreMenu(evt.currentTarget);
    setSelectedViewPeriodMoreMenuItem(item);
  };

  /**
   * Handling to close more menu in left menu.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const handleCloseViewPeriodMoreMenu = (evt) => {
    setOpenViewPeriodMoreMenu(false);
  };

  /*
		`````````````````````````````````
		HANDLE CLICK DETAIL PERIOD

		````````````````````````````````
	*/

  /**
   * Handling to open detail
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = (evt, item) => {
    setOpenDetail(true);
    setSelectedDetail(item);
  };

  /**
   * Handling to close detail.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const [selectedDetail, setSelectedDetail] = useState(null);
  const handleCloseDetail = (evt) => {
    setOpenDetail(false);
    setSelectedDetail(null);
    setPeriodDetail(null);
  };

  /*
		`````````````````````````````````
		HANDLE CLICK UPDATE PERIOD

		````````````````````````````````
	*/
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [selectedUpdatePeriod, setSelectedUpdatePeriod] = useState(null);
  /**
   * Handling to open update form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const handleOpenUpdateForm = (evt, item) => {
    // setOpenUpdateForm(true);
    setOriginSchedule("edit");
    setOpenDialogInfoSchedule(true);
    setSelectedUpdatePeriod(item);
  };

  /**
   * Handling to close update form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const handleCloseUpdateForm = (evt) => {
    setOpenUpdateForm(false);
    setSelectedUpdatePeriod(null);
  };

  /*
		`````````````````````````````````
		HANDLE CLICK DELETE PERIOD

		````````````````````````````````
	*/

  /**
   * Handling to open delete form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const [openDeleteForm, setOpenDeleteForm] = useState(false);
  const [selectedDeletePeriod, setSelectedDeletePeriod] = useState(null);
  const handleOpenDeleteForm = (evt, item) => {
    // setOpenDeleteForm(true);
    setOriginSchedule("delete");
    setOpenDialogInfoSchedule(true);
    setSelectedDeletePeriod(item);
  };

  /**
   * Handling to close delete form.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const handleCloseDeleteForm = (evt) => {
    setOpenDeleteForm(false);
    setSelectedDeletePeriod(null);
  };

  /**
   * apply filter to existing collections.
   *
   * @return {void}
   *
   */
  const [filter, setFilter] = useState(null);
  const applyFilter = () => {
    refreshCollections();
  };
  useEffect(() => {
    setPeriodListLeftItemLoader(true);
    setPeriodListRightItemLoader(true);
    setListPeriode([]);
    applyFilter();
  }, [filter]);

  /**
   * Handling to search.
   *
   * @param {object} evt object event.
   * @return {void}
   *
   */
  const [handleSearch, cancel] = useDebouncedCallback(
    // to memoize debouncedFunction we use useCallback hook.
    // In this case all linters work correctly
    useCallback((value) => {
      if (value) {
        setFilter({ search: value });
      } else {
        setFilter({ search: undefined });
      }
    }, []),
    1000,

    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 5000 }
  );

  // const handleSearch = (evt) => {
  // 	if (evt.target.value) {
  // 		setFilter({ search: evt.target.value });
  // 	} else {
  // 		setFilter({ search: undefined });
  // 	}
  // }

  /**
   * Refresh collections
   *
   * @return {void}
   */
  const refreshCollections = () => {
    if (userToken !== undefined) {
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + userToken,
      };

      //*Close period
      handleCloseDetail();
      setOpenDetail(false);

      axios.defaults.headers.common = header;

      let queryString = "";

      if (filter) {
        if (filter.search) {
          if (queryString) {
            queryString =
              queryString + "&options[filter][search]=" + filter.search;
          } else {
            queryString = "?options[filter][search]=" + filter.search;
          }
        }
      }

      // GET LIST PERIODE
      axios
        .get(URL_API + `/swd/master-period` + queryString)
        .then((response) => {
          // console.log("Response Original PERIODE   : ", response);
          setListPeriode(response.data.data);
          setPeriodListLeftItemLoader(false);
          setPeriodListRightItemLoader(false);
        })
        .catch((error) => {
          setPeriodListLeftItemLoader(false);
          setPeriodListRightItemLoader(false);
          // console.log("Error : ", error.response);
        });
    }
  };

  /**
   * Handling error dialog
   *
   */
  const [errorStatus, setErrorStatus] = useState(0);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorContent, setErrorContent] = useState({});
  const handleCloseErrorDialog = (evt) => {
    setErrorStatus(0);
    setOpenErrorDialog(false);
    setErrorTitle("");
    setErrorContent({});
  };

  return (
    <Container className={classes.container}>
      {
        // <!-- Error Dialog -->
        errorStatus !== 0 && (
          <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
            <DialogTitle style={{ textAlign: "center" }}>
              <img
                src={ErrorIconDialog}
                style={{ height: "96px" }}
                alt="Pict Info "
              />
              <br />
              <br />
              <Typography className={classes.title} style={{ color: "black" }}>
                <b>{errorTitle}</b>
              </Typography>

              {console.log(Object.keys(errorContent))}
              {console.log(errorContent)}
              {errorContent !== null &&
                Object.keys(errorContent).length > 0 &&
                Object.keys(errorContent).map((row, i) => (
                  <div key={"error-lists-" + row} className={classes.title}>
                    {errorContent[row]}
                  </div>
                ))}
            </DialogTitle>

            <DialogContent style={{ textAlign: "center" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={handleCloseErrorDialog}
                    size="medium"
                    className={classes.button}
                  >
                    Saya Mengerti
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>

            <br />
            <br />
          </Dialog>
        )
        // <!-- /Error Dialog -->
      }

      <Grid container>
        {
          // <!-- Period Menu List -->
        }
        <Grid item md={3} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className={classes.periodListleftTopHeader}>
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Periode
                  </Typography>
                  <input
                    className={`${classes.periodListleftTopHeaderSearch} ${classes.title}`}
                    placeholder="Cari Periode"
                    margin="normal"
                    variant="standard"
                    onChange={(evt) => handleSearch(evt.target.value)}
                    style={{ border: "none", width: "100%", padding: "10px" }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.periodListLeftMenu}>
              <List dense={true}>
                <ListItem className={classes.periodListLeftItem} button>
                  <ListItemIcon>
                    <StarBorderIcon />
                  </ListItemIcon>
                  <ListItemText
                    onClick={(evt) => refreshCollections()}
                    primary="All"
                  />
                </ListItem>
              </List>
              <Divider />
              {periodListLeftItemLoader == true && (
                <Grid
                  container
                  style={{ marginTop: "100px", marginLeft: "50px" }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6} md={3}>
                    <CircularProgress />
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              )}

              {listPeriode.length > 0
                ? listPeriode.map((item, i) => (
                    <List dense={true} key={item["id"]}>
                      <ListItem
                        className={classes.periodListLeftItem}
                        onClick={() => handleViewPeriod(item["id"])}
                        button
                      >
                        <ListItemIcon>
                          <img src={Group_2463} />
                        </ListItemIcon>
                        <ListItemText
                          className={classes.title}
                          primary={item.name}
                        />

                        <IconButton
                          aria-label="more"
                          aria-controls={
                            "view-period-more-menu-item-" + item["id"]
                          }
                          aria-haspopup="true"
                          onClick={(evt) =>
                            handleClickViewPeriodMoreMenu(evt, item)
                          }
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <MoreVert />
                        </IconButton>
                        <Menu
                          id={"view-period-more-menu-item-" + item["id"]}
                          anchorEl={
                            selectedViewPeriodMoreMenuItem &&
                            selectedViewPeriodMoreMenuItem["id"] == item["id"]
                              ? anchorClickViewPeriodMoreMenu
                              : null
                          }
                          open={
                            selectedViewPeriodMoreMenuItem &&
                            selectedViewPeriodMoreMenuItem["id"] == item["id"]
                              ? openViewPeriodMoreMenu
                              : false
                          }
                          onClose={handleCloseViewPeriodMoreMenu}
                        >
                          <MenuItem
                            // disabled
                            key={"Edit"}
                            onClick={(evt) => handleOpenUpdateForm(evt, item)}
                          >
                            {"Edit"}
                          </MenuItem>
                          <MenuItem
                            key={"Delete"}
                            disabled={item.name.includes("This Year")}
                            onClick={(evt) => handleOpenDeleteForm(evt, item)}
                          >
                            {"Delete"}
                          </MenuItem>
                        </Menu>
                      </ListItem>
                    </List>
                  ))
                : null}
            </Grid>
          </Grid>
        </Grid>
        {
          // <!-- /Period Menu List -->
        }

        {
          // <!-- Period List Right -->
        }
        <Hidden mdUp>
          <Grid item xs={1}></Grid>
        </Hidden>

        <Grid item md={8} xs={11} className={classes.periodRightDetail}>
          {
            // <!-- Period Action -->
            !openDetail && (
              <div>
                <Grid container>
                  <Grid item md={6} xs={12}></Grid>

                  <Grid item md={6} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleOpenCreateForm}
                      className={classes.button}
                    >
                      Tambah Periode
                    </Button>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    &nbsp;
                  </Grid>
                </Grid>

                <Divider light />
              </div>
            )
            // <!-- /Period Action
          }
          {
            // <-- List Period -->
          }
          <Grid container>
            <Grid item xs={12}>
              {periodListRightItemLoader == true && (
                <Grid
                  container
                  style={{ marginTop: "100px", marginLeft: "150px" }}
                >
                  <Grid item xs={3}></Grid>
                  <Grid item xs={6} md={3}>
                    <CircularProgress />
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              )}

              {
                // when the periodDetail is not clicked
                listPeriode.length > 0 &&
                periodDetail == undefined &&
                periodDetail == null
                  ? listPeriode.map((item, i) => (
                      <Paper
                        className={classes.periodListRightItem}
                        key={item["id"]}
                      >
                        <Grid container>
                          <Grid item md={10} xs={12}>
                            <ButtonBase
                              onClick={() => handleViewPeriod(item["id"])}
                            >
                              <span
                                className={`${classes.title}`}
                                style={{ fontWeight: "bold" }}
                              >
                                {item.name}
                              </span>
                              &nbsp;
                              <span
                                className={`${classes.title}`}
                                style={{ color: "gray" }}
                              >
                                {"( " +
                                  moment(item.start_date).format(
                                    "DD MMMM YYYY"
                                  ) +
                                  " - " +
                                  moment(item.end_date).format("DD MMMM YYYY") +
                                  " )"}
                              </span>
                            </ButtonBase>
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <span
                              className={`${classes.title}`}
                              style={{ fontWeight: "bold" }}
                            >
                              Hari Ke {item.days_to}/{item.days_total}
                              {/* {formatHariKe(item.start_date, item.end_date)} */}
                            </span>
                          </Grid>
                        </Grid>
                      </Paper>
                    ))
                  : null
              }

              {listPeriode.length == 0 && periodListRightItemLoader == false && (
                <div>
                  <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                      <img
                        src={PictDefaultDashboard}
                        className={classes.layoutEmptyState}
                        style={{ height: 200 }}
                        alt="Default Dashboard Pict"
                      />
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}></Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid
                      item
                      xs={4}
                      className={classes.title}
                      style={{ textAlign: "center" }}
                    >
                      {!filter ? (
                        <Typography className={classes.title}>
                          Data periode belum tersedia. Silakan tambah periode
                          terlebih dahulu.
                        </Typography>
                      ) : (
                        <Typography className={classes.title}>
                          Maaf Pencarian Tidak dapat Ditemukan
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
          {
            // <-- /View Period
          }

          {/* == Info Schedule == */}
          {originSchedule === "create" && (
            <DialogInfoSchedule
              openDialogInfoSchedule={openDialogInfoSchedule}
              setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
              onOpenDialog={setOpenCreateForm}
            />
          )}

          {originSchedule === "delete" && (
            <DialogInfoSchedule
              openDialogInfoSchedule={openDialogInfoSchedule}
              setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
              onOpenDialog={setOpenDeleteForm}
            />
          )}

          {originSchedule === "edit" && (
            <DialogInfoSchedule
              openDialogInfoSchedule={openDialogInfoSchedule}
              setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
              onOpenDialog={setOpenUpdateForm}
            />
          )}

          {
            // <!-- Dialog Create Form -->
            openCreateForm === true && (
              <CreatePeriod
                classes={classes}
                openCreateForm={openCreateForm}
                setOpenCreateForm={setOpenCreateForm}
                setListPeriode={setListPeriode}
                listPeriode={listPeriode}
              />
            )
            // <-- /Dialog Create Form -->
          }

          {
            // <!-- View Detail -->
            openDetail === true && (
              <DetailPeriod
                classes={classes}
                item={periodDetail}
                setItem={setPeriodDetail}
                open={openDetail}
                setOpen={setOpenDetail}
                handleOpenCreateForm={handleOpenCreateForm}
              />
            )
            // <!-- /View Detail -->
          }

          {
            // <!-- Dialog Update Form -->
            openUpdateForm === true && (
              <UpdatePeriod
                classes={classes}
                item={selectedUpdatePeriod}
                setItem={setSelectedUpdatePeriod}
                open={openUpdateForm}
                setOpen={setOpenUpdateForm}
                list={listPeriode}
                setList={setListPeriode}
                openViewPeriodMoreMenu={openViewPeriodMoreMenu}
                setOpenViewPeriodMoreMenu={setOpenViewPeriodMoreMenu}
              />
            )
            // <!-- /Dialog Update Form -->
          }

          {
            // <!-- Dialog Delete Form -->
            openDeleteForm == true && (
              <DeletePeriod
                classes={classes}
                selectedPeriod={selectedDeletePeriod}
                setSelectedDeletePeriod={setSelectedDeletePeriod}
                openDeleteForm={openDeleteForm}
                setOpenDeleteForm={setOpenDeleteForm}
                setListPeriode={setListPeriode}
                listPeriode={listPeriode}
                openViewPeriodMoreMenu={openViewPeriodMoreMenu}
                setOpenViewPeriodMoreMenu={setOpenViewPeriodMoreMenu}
              />
            )
            // <!-- /Dialog Delete Form -->
          }
        </Grid>
      </Grid>
    </Container>
  );
};

export default withStyles(styles)(ViewPeriode);
