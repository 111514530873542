import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  Grid,
  Button,
  MenuList,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";

// Icons Library
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import HourglassEmptyRoundedIcon from "@material-ui/icons/HourglassEmptyRounded";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import CheckBoxOutlineBlankRoundedIcon from "@material-ui/icons/CheckBoxOutlineBlankRounded";

import classNames from "classnames";
// Date Library
import MomentUtils from "@date-io/moment";
import moment from "moment";

// API Library
import ContextGoal from "../Context/ContextGoal";
import { URL_API } from "../../../../constants/config-api";
import AxiosConfig from "../../../../constants/config-axios";

// COmponent Library
import ListItemDropdownCreate from "./ListItemDropdownCreate";
import handleError from "../../../Report/components/global/handleError";
import DialogError from "../../../../components/DialogError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

// Compontn Dialog Create Goal
// 70-Goal (Create) PF - Function Number
const DialogCreateGoal = ({ classes, openCreateGoal, setOpenCreateGoal }) => {
  const context = useContext(ContextGoal);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [anchorEl, setAnchorEl] = useState(null);

  const [goalName, setGoalName] = useState("");
  const [description, setDescription] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [periodId, setPeriodId] = useState(null);
  const [timeFrame, setTimeFrame] = useState({
    name: "Pilih waktu",
    code: "",
    id: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());
  const [startValue, setStartValue] = useState("0");
  const [targetValue, setTargetValue] = useState(null);
  const [targetRangeValue, setTargetRangeValue] = useState("0");
  const [evidence, setEvidence] = useState(false);

  const [loadInterval, setLoadInterval] = useState(false);
  const [isInterval, setIsInterval] = useState(false);

  const [achievementType, setAchievementType] = useState({
    code: "swd_goal_achievement_type_more_oriented",
    id: "1",
    name: "more oriented",
  });
  const [calculationMethod, setCalculationMethod] = useState({
    code: "swd_goal_calculation_method_sum",
    id: "1",
    name: "sum",
  });
  const [targetType, setTargetType] = useState({
    code: "swd_goal_target_type_daily",
    id: "1",
    name: "Daily",
  });
  const [valueType, setValueType] = useState({
    code: "numeric",
    id: "1",
    name: "numeric",
  });
  const [interval, setInterval] = useState({
    code: "daily",
    name: "Daily",
  });

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  //conditional
  const [advanced, setAdvanced] = useState(false);
  const [loading, setLoading] = useState(false);
  //collections
  const [ownerCollections, setOwnerCollections] = useState([]);
  const [timeframeCollections, setTimeframeCollections] = useState([]);
  const [periodCollections, setPeriodCollections] = useState([]);
  const [achievementTypeCollections, setAchievementTypeCollections] = useState(
    []
  );
  const [calculationMethodCollections, setCalculationMethodCollections] =
    useState([]);
  const [targetTypeCollections, setTargetTypeCollections] = useState([]);
  const [valueTypeCollections, setValueTypeCollections] = useState([]);
  const [intervalCollections, setIntervalCollections] = useState(listInterval);

  const [weeks, setWeeks] = useState({
    goal_ma_id: "",
    category: "goal",
    interval: "weekly",
    schedule_type: "day",
    scheduled_at: "friday-00:00",
    input_start: "-",
    input_finish: "-",
    result_locked: false,
    reminder: "",
    auto_insert_result: "0",
    weekly_details: {
      selected_weeks: [],
    },
  });
  const [months, setMonths] = useState({
    goal_ma_id: "",
    category: "goal",
    interval: "monthly",
    schedule_type: "date",
    scheduled_at: "25-00:00",
    input_start: "-",
    input_finish: "-",
    result_locked: false,
    reminder: "",
    auto_insert_result: "0",
    monthly_details: {
      selected_months: [],
    },
  });

  // ERROR STATE
  const [isOpenSnackbarError, setOpenSnackbarError] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops ada yang salah"
  );
  // Error Individuals
  const [errorName, setErrorName] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorOwner, setErrorOwner] = useState(null);
  const [errorPeriod, setErrorPeriod] = useState(null);
  const [errorTimeFrame, setErrorTimeFrame] = useState(null);
  const [errorStartDate, setErrorStartDate] = useState(null);
  const [errorEndDate, setErrorEndDate] = useState(null);
  const [errorTargetValue, setErrorTargetValue] = useState(null);
  const [errorTargetRangeValue, setErrorTargetRangeValue] = useState(null);
  const [errorTargetType, setErrorTargetType] = useState(null);

  let HOURLY = {
    goal_ma_id: "",
    category: "goal",
    interval: "hourly",
    schedule_type: "minute",
    scheduled_at: "00",
    input_start: "-",
    input_finish: "-",
    result_locked: false,
    reminder: "",
    auto_insert_result: "0",
    hourly_details: {
      selected_days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      selected_times: [
        {
          start: "08:00",
          finish: "17:00",
        },
      ],
    },
  };

  let DAILY = {
    goal_ma_id: "",
    category: "goal",
    interval: "daily",
    schedule_type: "hour",
    scheduled_at: "00:00",
    input_start: "-",
    input_finish: "-",
    result_locked: false,
    reminder: "",
    auto_insert_result: "0",
    daily_details: {
      selected_days: [
        {
          day: "monday",
          scheduled_at: "00:01",
        },
        {
          day: "tuesday",
          scheduled_at: "00:01",
        },
        {
          day: "wednesday",
          scheduled_at: "00:01",
        },
        {
          day: "thursday",
          scheduled_at: "00:01",
        },
        {
          day: "friday",
          scheduled_at: "00:01",
        },
        {
          day: "saturday",
          scheduled_at: "00:01",
        },
        {
          day: "sunday",
          scheduled_at: "00:01",
        },
      ],
    },
  };

  // API - Goal Create
  useEffect(() => {
    if (openCreateGoal) {
      AxiosConfig.get(`${URL_API}/swd/goal/create`)
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Crete Goal", result);

          if (res.status === 200) {
            setOwnerCollections(result.ownerCollections);
            setTimeframeCollections(result.timeframeCollections);
            setPeriodCollections(result.periodCollections);

            setAchievementTypeCollections(result.achievementTypeCollections);
            setCalculationMethodCollections(
              result.calculationMethodCollections
            );
            setTargetTypeCollections(result.targetTypeCollections);
            setValueTypeCollections(result.valueTypeCollections);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateGoal]);

  useEffect(() => {
    if (openCreateGoal) {
      setLoadInterval(true);

      let sched_at;
      let start_date = moment(startDate).format("YYYY-MM-DD");
      let end_date = moment(endDate).format("YYYY-MM-DD");

      if (interval.code === "hourly") {
        sched_at = "00";
      }
      if (interval.code === "daily") {
        sched_at = "00:01";
      }
      if (interval.code === "weekly") {
        sched_at = "friday-00:01";
      }
      if (interval.code === "monthly") {
        sched_at = "25-00:01";
      }

      AxiosConfig.get(
        `${URL_API}/swd/update-frequency/create?interval=${interval.code}&scheduled_at=${sched_at}&input_start=${start_date}&input_finish=${end_date}`
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Get Interval", result);

          if (res.status === 200) {
            const allweeks = result.fields?.weekly_details?.default_value;
            const allmonths = result.fields?.monthly_details?.default_value;

            const mapWeeks =
              allweeks?.length > 0
                ? allweeks?.map((el) => {
                    return { ...el, selected: true };
                  })
                : [];
            const mapMonths =
              allmonths?.length > 0
                ? allmonths?.map((el) => {
                    return { ...el, selected: true };
                  })
                : [];

            setWeeks({
              ...weeks,
              weekly_details: {
                selected_weeks: mapWeeks,
              },
            });
            setMonths({
              ...months,
              monthly_details: {
                selected_months: mapMonths,
              },
            });

            setLoadInterval(false);
          }
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          setLoadInterval(false);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [openCreateGoal, interval, startDate, endDate, periodId]);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGoalName = (e) => {
    setGoalName(e.target.value);
    setErrorName(null);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setErrorDescription(null);
  };

  const handleTargetValue = (e) => {
    setTargetValue(e.target.value);
    setErrorTargetValue(null);
  };

  const handleTargetRangeValue = (e) => {
    setTargetRangeValue(e.target.value);
    setErrorTargetRangeValue(null);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
    setErrorStartDate(null);

    if (moment(date).isBefore(moment(endDate))) {
      setErrorEndDate(null);
    }
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    setErrorEndDate(null);

    if (moment(date).isAfter(moment(startDate))) {
      setErrorStartDate(null);
    }
  };

  const handleChooseOwner = (e, data) => {
    if (data !== null) {
      setOwnerId(data.id);
      setErrorOwner(null);
    }
    setErrorOwner(null);
  };

  const handleChoosePeriod = (e, data) => {
    if (data !== null) {
      setPeriodId(data.id);
      setStartDate(data.start_date);
      setEndDate(data.end_date);
    } else {
      setPeriodId(null);
    }
    // // console.log("PER", data);
  };

  const handleTimeFrame = (data) => {
    setTimeFrame(data);
    handleClose();
    setErrorTimeFrame(null);
  };

  const handleAchievementType = (data) => {
    setAchievementType(data);
  };

  const handleCalculationMethod = (data) => {
    setCalculationMethod(data);

    if (data.id === "2") {
      setTargetType({
        code: "swd_goal_target_type_final",
        id: "2",
        name: "Final",
      });
    }
  };

  const handleTargetType = (data) => {
    setTargetType(data);
    setErrorTargetType(null);
  };

  const handleValueType = (data) => {
    setValueType(data);
  };

  const handleInterval = (data) => {
    setInterval(data);
  };

  const handleCreateGoal = () => {
    setLoading(true);

    // Activity Log Variable
    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    // Payload Create Goal
    const data = {
      Goal: {
        name: goalName,
        // owner_id: ownerList.length > 0 && ownerId === '' ?  ownerList[0].id : ownerId,
        owner_id: ownerId,

        input_method_id:
          calculationMethod.id === "1"
            ? "2"
            : calculationMethod.id === "2"
            ? "2"
            : "1",
        achievement_type_id: achievementType.id,
        calculation_method_id: calculationMethod.id,
        value_type_id: valueType.id,
        target_type_id: targetType.id,
        period_id: periodId !== null ? periodId : null,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date:
          timeFrame.code === "date_range"
            ? moment(endDate).format("YYYY-MM-DD")
            : timeFrame.code === "period"
            ? moment(endDate).format("YYYY-MM-DD")
            : moment(dueDate).format("YYYY-MM-DD"),
        start_value: startValue,
        target_value: targetValue,
        target_range_value: targetValue === "0" ? targetRangeValue : null,
        description,
        time_frame_id: timeFrame !== "" ? timeFrame.id : null,
        is_evidence_required: evidence ? "1" : "0", //*0 === Tidak butuh evidence, 1 === Butuh evidence
        goal_category: "OPERATIONAL",
        set_no_data_as_zero: isInterval ? "1" : "0",
      },
    };

    if (data.Goal.period_id === null) {
      delete data.Goal.period_id;
    }

    if (
      data.Goal.period_id !== null &&
      data.Goal.start_date === null &&
      data.Goal.end_date === "Invalid date"
    ) {
      delete data.Goal.start_date;
      delete data.Goal.end_date;
    }

    if (data.Goal.start_date === null) {
      delete data.Goal.start_date;
    }

    if (
      data.Goal.target_range_value === null ||
      data.Goal.target_range_value === ""
    ) {
      delete data.Goal.target_range_value;
    }

    // console.log("DATA", data);

    // PF Function: 70-(Goal Create) - Submit Goal
    AxiosConfig.post(`${URL_API}/swd/goal`, data)
      .then((res) => {
        const result = res.data.data;
        // console.log("RES crete Gol", res);

        if (res.status === 200) {
          // ============================================================================
          // ~~~~~~~~~~~ INTERVAL ~~~~~~~~~~~
          // ============================================================================
          let SELECTED_INTERVAL;

          let dataHourly = { ...HOURLY, goal_ma_id: result.id };
          let dataDaily = { ...DAILY, goal_ma_id: result.id };
          let dataWeekly = { ...weeks, goal_ma_id: result.id };
          let dataDMonthly = {
            ...months,
            goal_ma_id: result.id,
          };

          if (interval.code === "hourly") SELECTED_INTERVAL = dataHourly;
          if (interval.code === "daily") SELECTED_INTERVAL = dataDaily;
          if (interval.code === "weekly") SELECTED_INTERVAL = dataWeekly;
          if (interval.code === "monthly") SELECTED_INTERVAL = dataDMonthly;

          // Payload ACtivity Log
          // PF Function: 70-(Goal Create) - Insert Activity Log
          const dataLog = {
            user_activity_log: {
              module: "goal",
              event: "create",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: result?.id,
              entity_name: result?.name,
              remarks: `Goal (${result?.name}) 1`,
              comment: "",
            },
          };

          setDataRemarks(dataLog);

          // // console.log("TO freq");
          // // console.log("GOAL ID", result.id);

          // PF Function: 70-(Goal Create) - Set Interval
          AxiosConfig.post(
            `${URL_API}/swd/update-frequency/store`,
            SELECTED_INTERVAL
          )
            .then((res) => {
              // console.log("res freq", res);
              // setOpenCreateGoal(false);
            })
            .catch((error) => {
              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });

          // context.setSuccessCreateGoal(Math.floor(Math.random() * 1000 + 1));
          context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
          // setLoading(false);
          // setOpenCreateGoal(false);
          setOpenRemarksLog(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        // // console.log("Error cret Goal", error.response);

        // Handle Error
        const { listError } = handleError(error);

        // // console.log("listError", listError);

        if (listError.length > 0) {
          listError.forEach((err) => {
            // // console.log("ERRRRR", err);

            if (err.key === "name") {
              setErrorName(err);
            }
            if (err.key === "owner_id") {
              setErrorOwner(err);
            }
            if (err.key === "period_id") {
              setErrorPeriod(err);
            }
            if (err.key === "target_value") {
              setErrorTargetValue(err);
            }
            if (err.key === "target_range_value") {
              setErrorTargetRangeValue(err);
            }
            if (err.key === "time_frame_id") {
              setErrorTimeFrame(err);
            }
            if (err.key === "start_date") {
              setErrorStartDate(err);
            }
            if (err.key === "end_date") {
              setErrorEndDate(err);
            }
            if (err.key === "target_type_id") {
              setErrorTargetType(err);
            }
          });
        }

        if (
          [502, 503, 500, 403, 429, 405, 404, 401].includes(
            error.response?.status
          )
        ) {
          setOpenDialogError(true);
          setErrorStatus(error.response.status);
          setListError([]);

          if (error.response?.data?.hasOwnProperty("message")) {
            setTextErrorMessage(error.response.data.message);
          }
          if (error.response?.data?.info?.hasOwnProperty("message")) {
            setTextErrorMessage(error.response.data.info.message);
          }
        }
      });
  };
  // // console.log("CEK WEEK", weeks);
  // // console.log("CEK MONTHS", months);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={openCreateGoal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            // backgroundImage: `url(${bgTitle})`,
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
        >
          <Typography variant="h4">
            {MyLang() ? bahasa.create : english.create} Goal
          </Typography>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <Box p={1} my={1}>
            <Typography variant="subtitle2" className={classes.title333}>
              {MyLang() ? `${bahasa.name} Goal` : `Goal ${english.name}`}
            </Typography>
            <Box my={0.5} />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              placeholder={
                MyLang() ? bahasa.placeholderTitle : english.placeholderTitle
              }
              value={goalName}
              onChange={handleGoalName}
              error={errorName !== null}
              helperText={
                errorName !== null && (
                  <Typography variant="caption" style={{ color: "#d1354a" }}>
                    ⚠️ {errorName.description}
                  </Typography>
                )
              }
            />

            <Box my={2.5} />

            <Typography variant="subtitle2" className={classes.title333}>
              {MyLang() ? bahasa.description : english.description}
            </Typography>
            <Box my={0.5} />
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder={
                MyLang() ? bahasa.placeholderDesc : english.placeholderDesc
              }
              value={description}
              onChange={handleDescription}
              error={errorDescription !== null}
              helperText={
                errorDescription !== null && (
                  <Typography variant="caption" style={{ color: "#d1354a" }}>
                    ⚠️ {errorDescription.description}
                  </Typography>
                )
              }
            />

            <Box my={2.5} />

            <Grid container spacing={2}>
              <Grid item md={4}>
                <Typography variant="subtitle2" className={classes.title333}>
                  {MyLang() ? bahasa.timeFrame : english.timeFrame}
                </Typography>
                <Box my={0.5} />
                <Box
                  height={38}
                  border={`1.5px solid ${
                    errorTimeFrame === null ? "#e1e1e1" : "#d1354a"
                  }`}
                  borderRadius={5}
                  padding={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    color="#888"
                    style={{ textTransform: "capitalize" }}
                  >
                    {timeFrame.name}
                  </Box>
                  <IconButton size="small" onClick={handleShow}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </Box>

                <Popper
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  transition
                  disablePortal
                  placement="bottom"
                  className={classNames({
                    [classes.popperClose]: !anchorEl,
                    [classes.popperResponsive]: true,
                  })}
                >
                  {() => (
                    <Grow>
                      <ClickAwayListener onClickAway={handleCloseAway}>
                        <Paper className={classes.dropdown}>
                          <MenuList role="menu" className={classes.menuList}>
                            {timeframeCollections.length > 0 &&
                              timeframeCollections
                                .filter((el) => el.code !== "due_date")
                                .map((item, i) => {
                                  return (
                                    <MenuItem
                                      key={i}
                                      onClick={() => handleTimeFrame(item)}
                                      className={classes.dropdownItem}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <Box display="flex" alignItems="center">
                                        {item.code === "due_date" && (
                                          <EventAvailableIcon
                                            fontSize="small"
                                            style={{
                                              color: "#555",
                                              marginRight: 7,
                                            }}
                                          />
                                        )}
                                        {item.code === "date_range" && (
                                          <DateRangeRoundedIcon
                                            fontSize="small"
                                            style={{
                                              color: "#555",
                                              marginRight: 7,
                                            }}
                                          />
                                        )}
                                        {item.code === "period" && (
                                          <HourglassEmptyRoundedIcon
                                            fontSize="small"
                                            style={{
                                              color: "#555",
                                              marginRight: 7,
                                            }}
                                          />
                                        )}
                                        <span style={{ color: "#555" }}>
                                          {" "}
                                          {item.name}
                                        </span>
                                      </Box>
                                    </MenuItem>
                                  );
                                })}
                          </MenuList>
                        </Paper>
                      </ClickAwayListener>
                    </Grow>
                  )}
                </Popper>
              </Grid>
              <Grid item md={timeFrame.code === "period" ? 8 : 4}>
                {timeFrame.code === "date_range" && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      {MyLang() ? bahasa.startDate : english.startDate}
                    </Typography>
                    <Box my={0.5} />
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale="id">
                      <DatePicker
                        autoOk
                        value={startDate}
                        onChange={handleStartDate}
                        animateYearScrolling
                        variant="inline" // dialog, static, inline
                        disableToolbar={false}
                        format="DD/MM/YYYY"
                        placeholder={
                          MyLang() ? bahasa.chooseDate : english.chooseDate
                        }
                        inputVariant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <DateRangeRoundedIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={errorStartDate !== null}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                {timeFrame.code === "due_date" && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      {MyLang() ? bahasa.dueDate : english.dueDate}
                    </Typography>
                    <Box my={0.5} />
                    <MuiPickersUtilsProvider utils={LocalizedUtils} locale="id">
                      <DatePicker
                        autoOk
                        value={dueDate}
                        onChange={setDueDate}
                        animateYearScrolling
                        variant="inline" // dialog, static, inline
                        disableToolbar={false}
                        format="DD/MM/YYYY"
                        placeholder={
                          MyLang() ? bahasa.chooseDate : english.chooseDate
                        }
                        inputVariant="outlined"
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton size="small">
                                <EventAvailableIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </>
                )}
                {timeFrame.code === "period" && (
                  <>
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      {MyLang() ? bahasa.period : english.period}
                    </Typography>
                    <Box my={0.5} />
                    <Autocomplete
                      id="combo-box-demo"
                      options={periodCollections}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={(e, period) => handleChoosePeriod(e, period)}
                      noOptionsText={
                        MyLang() ? bahasa.noPeriod : english.noPeriod
                      }
                      popupIcon={<ArrowDropDownIcon />}
                      renderInput={(params) => (
                        <TextField
                          style={{ fontSize: 13 }}
                          {...params}
                          fullWidth
                          variant="outlined"
                          size="small"
                          label={
                            MyLang()
                              ? bahasa.searchPeriod
                              : english.searchPeriod
                          }
                          error={errorPeriod !== null}
                          helperText={
                            errorPeriod !== null && (
                              <Typography
                                variant="caption"
                                style={{ color: "#d1354a" }}
                              >
                                ⚠️ {errorPeriod.description}
                              </Typography>
                            )
                          }
                        />
                      )}
                    />
                  </>
                )}
              </Grid>
              {timeFrame.code !== "period" && (
                <Grid item md={4}>
                  {timeFrame.code === "date_range" && (
                    <>
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        {MyLang() ? bahasa.endDate : english.description}
                      </Typography>
                      <Box my={0.5} />
                      <MuiPickersUtilsProvider
                        utils={LocalizedUtils}
                        locale="id"
                      >
                        <DatePicker
                          autoOk
                          value={endDate}
                          onChange={handleEndDate}
                          animateYearScrolling
                          variant="inline" // dialog, static, inline
                          disableToolbar={false}
                          format="DD/MM/YYYY"
                          placeholder={
                            MyLang() ? bahasa.chooseDate : english.chooseDate
                          }
                          inputVariant="outlined"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <IconButton size="small">
                                  <DateRangeRoundedIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          error={errorEndDate !== null}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                  )}
                </Grid>
              )}
              <Box ml={3}>
                {errorTimeFrame !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: "#d1354a" }}
                  >
                    ⚠️ {errorTimeFrame.description}
                  </Typography>
                )}

                {errorStartDate !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: "#d1354a" }}
                  >
                    ⚠️ {errorStartDate.description}
                  </Typography>
                )}

                {errorEndDate !== null && (
                  <Typography
                    variant="caption"
                    component="p"
                    style={{ color: "#d1354a" }}
                  >
                    ⚠️ {errorEndDate.description}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Box my={2.5} />

            <Typography variant="subtitle2" className={classes.title333}>
              {MyLang() ? bahasa.owner : english.owner}
            </Typography>
            <Box my={0.5} />
            <Autocomplete
              id="combo-box-demo"
              options={ownerCollections}
              getOptionLabel={(option) =>
                `${option.member.first_name} ${option.member.last_name}`
              }
              onChange={(e, owner) => handleChooseOwner(e, owner)}
              noOptionsText={MyLang() ? bahasa.noOwner : english.noOwner}
              popupIcon={<ArrowDropDownIcon />}
              renderInput={(params) => (
                <TextField
                  style={{ fontSize: 13 }}
                  {...params}
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={MyLang() ? bahasa.searchOwner : english.searchOwner}
                  error={errorOwner !== null}
                  helperText={
                    errorOwner !== null && (
                      <Typography
                        variant="caption"
                        style={{ color: "#d1354a" }}
                      >
                        ⚠️ {errorOwner.description}
                      </Typography>
                    )
                  }
                />
              )}
            />

            <Box my={2.5} />

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="subtitle2" className={classes.title333}>
                  {MyLang() ? bahasa.startVal : english.startVal}
                </Typography>
                <Box my={0.5} />
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder={
                    MyLang() ? bahasa.initialVal : english.initialVal
                  }
                  value={startValue}
                  onChange={(e) => setStartValue(e.target.value)}
                />
              </Grid>

              <Grid item md={6}>
                <Typography variant="subtitle2" className={classes.title333}>
                  {MyLang() ? bahasa.targetVal : english.targetVal}
                </Typography>
                <Box my={0.5} />
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  type="number"
                  placeholder={
                    MyLang() ? bahasa.finalScore : english.finalScore
                  }
                  value={targetValue}
                  onChange={handleTargetValue}
                  error={errorTargetValue !== null}
                  helperText={
                    errorTargetValue !== null && (
                      <Typography
                        variant="caption"
                        style={{ color: "#d1354a" }}
                      >
                        ⚠️ {errorTargetValue.description}
                      </Typography>
                    )
                  }
                />
              </Grid>
            </Grid>

            {Number(startValue) === Number(targetValue) && <Box my={2.5} />}

            {Number(startValue) === Number(targetValue) && (
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography variant="subtitle2" className={classes.title333}>
                    {MyLang() ? bahasa.targetRangeVal : english.targetRangeVal}
                  </Typography>
                  <Box my={0.5} />
                  <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    placeholder={
                      MyLang() ? bahasa.initialVal : english.initialVal
                    }
                    value={targetRangeValue}
                    onChange={handleTargetRangeValue}
                    error={errorTargetRangeValue !== null}
                    helperText={
                      errorTargetRangeValue !== null && (
                        <Typography
                          variant="caption"
                          style={{ color: "#d1354a" }}
                        >
                          ⚠️ {errorTargetRangeValue.description}
                        </Typography>
                      )
                    }
                  />
                </Grid>

                <Grid item md={6}></Grid>
              </Grid>
            )}

            <Box my={2.5} />

            <FormControlLabel
              label={
                <Typography variant="subtitle2" className={classes.title333}>
                  {MyLang() ? bahasa.advencedOption : english.advencedOption}
                </Typography>
              }
              control={
                <Checkbox
                  checked={advanced}
                  onChange={() => setAdvanced((prev) => !prev)}
                  checkedIcon={
                    <CheckBoxRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                  icon={
                    <CheckBoxOutlineBlankRoundedIcon
                      style={{ color: "#0F68D2", fontSize: 18 }}
                    />
                  }
                />
              }
            />

            {/* ========================== | Advanced Area | ========================== */}

            {advanced && (
              <>
                <Box my={2.5} />

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title={
                        MyLang()
                          ? bahasa.achievementType
                          : english.achievementType
                      }
                      collections={achievementTypeCollections}
                      selection={achievementType}
                      onSelect={handleAchievementType}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title={MyLang() ? bahasa.valueType : english.valueType}
                      collections={valueTypeCollections}
                      selection={valueType}
                      onSelect={handleValueType}
                    />
                  </Grid>
                </Grid>

                <Box my={2.5} />
                <ListItemDropdownCreate
                  classes={classes}
                  title={MyLang() ? bahasa.calcMethod : english.calcMethod}
                  collections={calculationMethodCollections}
                  selection={calculationMethod}
                  onSelect={handleCalculationMethod}
                />
                {calculationMethod.id === "2" && (
                  <FormControlLabel
                    label={
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        {MyLang() ? bahasa.setDataZero : english.setDataZero}
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={isInterval}
                        onChange={() => setIsInterval((prev) => !prev)}
                        checkedIcon={
                          <CheckBoxRoundedIcon
                            style={{ color: "#0F68D2", fontSize: 18 }}
                          />
                        }
                        icon={
                          <CheckBoxOutlineBlankRoundedIcon
                            style={{ color: "#0F68D2", fontSize: 18 }}
                          />
                        }
                      />
                    }
                  />
                )}
                <Box my={2.5} />

                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title={MyLang() ? bahasa.targetType : english.targetType}
                      collections={targetTypeCollections}
                      selection={targetType}
                      onSelect={handleTargetType}
                    />
                    {errorTargetType !== null && (
                      <Typography
                        variant="caption"
                        style={{ color: "#d1354a" }}
                      >
                        ⚠️ {errorTargetType.description}
                      </Typography>
                    )}
                    {errorTargetType === null &&
                      calculationMethod.id === "2" &&
                      targetType.id === "1" && (
                        <Typography
                          variant="caption"
                          style={{ color: "#d1354a" }}
                        >
                          ⚠️ Jenis Target yang dipilih tidak valid.
                        </Typography>
                      )}
                  </Grid>
                  <Grid item md={6}>
                    <ListItemDropdownCreate
                      classes={classes}
                      title="Interval"
                      collections={intervalCollections}
                      selection={interval}
                      onSelect={handleInterval}
                    />
                  </Grid>
                </Grid>

                <Box my={2.5} />

                <FormControlLabel
                  label={
                    <Typography
                      variant="subtitle2"
                      className={classes.title333}
                    >
                      {MyLang()
                        ? bahasa.eveidenceRequired
                        : english.eveidenceRequired}
                    </Typography>
                  }
                  control={
                    <Checkbox
                      checked={evidence}
                      onChange={() => setEvidence((prev) => !prev)}
                      checkedIcon={
                        <CheckBoxRoundedIcon
                          style={{ color: "#0F68D2", fontSize: 18 }}
                        />
                      }
                      icon={
                        <CheckBoxOutlineBlankRoundedIcon
                          style={{ color: "#0F68D2", fontSize: 18 }}
                        />
                      }
                    />
                  }
                />
              </>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setOpenCreateGoal(false)}
            >
              {MyLang() ? bahasa.btnCancel : english.btnCancel}
            </Button>
            <Box mx={1} />
            <Button
              variant={loading ? "contained" : "outlined"}
              className={classes.button1}
              onClick={handleCreateGoal}
              disabled={loadInterval}
            >
              {loading
                ? MyLang()
                  ? bahasa.btnCreating
                  : english.btnCreating
                : MyLang()
                ? bahasa.btnCreate
                : english.btnCreate}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setOpenCreateGoal}
        onTriggerRemarks={context.setTriggerGoals}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </ThemeProvider>
  );
};

export default DialogCreateGoal;

const listInterval = [
  {
    name: "Hourly",
    code: "hourly",
  },
  {
    name: "Daily",
    code: "daily",
  },
  {
    name: "Weekly",
    code: "weekly",
  },
  {
    name: "Monthly",
    code: "monthly",
  },
];
