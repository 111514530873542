import React, { useContext, useState } from "react";
import DialogCreate from "./DialogCreate";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  LinearProgress,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { URL_API } from "../../../../../constants/config-api";
import axiosConfig from "../../../../../constants/config-axios";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../../components/StyledMenuDropdown";
import DialogDelete from "./DialogDelete";
import DialogEdit from "./DialogEdit";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});
function ViewTabLocation({
  classes,
  isModalProgress,
  setModalProgress,
  dataLocationList,
  setDataLocationList,
  getLocationList,
}) {
  const context = useContext(ContextAttendanceSetting);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoaderEmpty, setLoaderEmpty] = useState(false);
  const [masterDataLocation, setMasterDataLocation] = useState({
    fields: {},
    masterCityCollections: [],
    masterCountryCollections: [],
    masterLocationStaticTypeCollections: [],
    masterPostalCodeCollections: [],
  });
  const [dataLocation, setDataLocation] = useState({
    id: "",
    location_name: "",
    location_static_type_id: "",
    location_static_type: {},
    country_id: "",
    country: {},
    city_id: "",
    city: {},
    postal_code_id: "",
    postal_code: {},
    address: "",
    location_coordinates_lat_min: "",
    location_coordinates_long_min: "",
    radius: "",
  });

  const resetDataLocation = () => {
    let dataLocationTemporary = dataLocation;
    dataLocationTemporary.id = "";
    dataLocationTemporary.location_name = "";
    dataLocationTemporary.location_static_type_id = "";
    dataLocationTemporary.location_static_type = {};
    dataLocationTemporary.country_id = "";
    dataLocationTemporary.country = {};
    dataLocationTemporary.city_id = "";
    dataLocationTemporary.city = {};
    dataLocationTemporary.postal_code_id = "";
    dataLocationTemporary.postal_code = {};
    dataLocationTemporary.address = "";
    dataLocationTemporary.location_coordinates_lat_min = "";
    dataLocationTemporary.location_coordinates_long_min = "";
    dataLocationTemporary.radius = "";
    setDataLocation({ ...dataLocationTemporary });
  };

  const handleShowModalCreate = () => {
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/location-static/create`)
      .then(function (response) {
        resetDataLocation();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataLocation;
        masterData.masterLocationStaticTypeCollections =
          response.data.data.masterLocationStaticTypeCollections;
        masterData.masterPostalCodeCollections = [];
        masterData.masterCountryCollections =
          response.data.data.masterCountryCollections;
        masterData.masterCityCollections = [];
        setMasterDataLocation(masterData);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const checkPostalCode = async () => {
    let statusPostalCode = false;
    await fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        dataLocation.location_coordinates_lat_min +
        "," +
        dataLocation.location_coordinates_long_min +
        "&key=AIzaSyBC60Frrtjsa148QKyqtK9pPOqCJ_i4t-M"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status == "OK") {
          if (responseJson.results[0]) {
            for (var i = 0; i < responseJson.results.length; i++) {
              for (
                var j = 0;
                j < responseJson.results[i].address_components.length;
                j++
              ) {
                var types = responseJson.results[i].address_components[j].types;

                for (var typeIdx = 0; typeIdx < types.length; typeIdx++) {
                  if (
                    types[typeIdx] === "postal_code" &&
                    dataLocation.postal_code.name ===
                      responseJson.results[i].address_components[j].short_name
                  ) {
                    statusPostalCode = true;
                  }
                  if (
                    responseJson.results[i].formatted_address
                      .toLowerCase()
                      .includes(dataLocation.city.name.toLowerCase())
                  ) {
                    statusPostalCode = true;
                  }
                }
              }
            }
          }
        }
      });
    return statusPostalCode;
  };

  const createLocation = async () => {
    context.setModalProgress(true);
    setLoader(true);
    if (
      dataLocation.postal_code_id &&
      dataLocation.location_coordinates_lat_min &&
      dataLocation.location_coordinates_long_min
    ) {
      let statusPostalCode = await checkPostalCode();
      if (statusPostalCode) {
        doCreateLocation();
      } else {
        setLoader(false);
        context.setErrorStatus(400);
        context.setModalProgress(false);
        context.setTextErrorInformation("Validasi error");
        context.setListError([
          {
            code: "VALIDATION_ERROR",
            key: "postal_code_id",
            description:
              "Kode Pos tidak sesuai dengan Garis Lintang dan Garis Bujur.",
          },
        ]);
        context.setOpenDialogError(true);
      }
    } else {
      setLoader(false);
      context.setErrorStatus(400);
      context.setModalProgress(false);
      context.setTextErrorInformation("Validasi error");
      let dataErrors = [];
      if (!dataLocation.postal_code_id) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "postal_code_id",
          description: "Field Kode Pos dibutuhkan",
        });
      }
      if (!dataLocation.location_coordinates_lat_min) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "location_coordinates_lat_min",
          description: "Field Garis Lintang dibutuhkan",
        });
      }
      if (!dataLocation.location_coordinates_long_min) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "location_coordinates_long_min",
          description: "Field Garis Bujur dibutuhkan",
        });
      }
      context.setListError(dataErrors);
      context.setOpenDialogError(true);
      context.setOpenDialogError(true);
    }
  };

  const doCreateLocation = () => {
    let data = {
      LocationStatic: dataLocation,
    };
    axiosConfig
      .post(`${URL_API}/human-resource/location-static/create`, data)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalCreate(false);
        context.setMessages("Data lokasi berhasi disimpan");
        context.setSnackbarResponse200(true);
        resetDataLocation();
        refreshList(null, 0, 0, null);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (error.response.status === 400) {
            context.setErrorStatus(400);
            if (
              error.response.data &&
              error.response.data.info &&
              error.response.data.info.message
            ) {
              context.setTextErrorInformation(error.response.data.info.message);
              if (error.response.data.info.errors) {
                context.setListError(error.response.data.info.errors);
              }
            } else {
              context.setTextErrorInformation("Request tidak tepat !");
            }
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataLocationList.options.paging.limit * newPage,
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 0, parseInt(event.target.value));
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleShowModalDelete = (e, item) => {
    handleDropdownClose();
    let dataLocationTemporary = dataLocation;
    dataLocationTemporary.id = item.id;
    dataLocationTemporary.location_name = item.location_name;
    setDataLocation({ ...dataLocationTemporary });
    context.setModalDelete(true);
  };

  const handleDelete = () => {
    setLoader(true);
    context.setModalProgress(true);
    if (context.userTokenState !== "") {
      axiosConfig
        .delete(URL_API + `/human-resource/location-static/${dataLocation.id}`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalDelete(false);
          context.setMessages("Berhasil di hapus !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, 0, 0, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const handleShowModalEdit = (e, item) => {
    handleDropdownClose();
    context.setModalProgress(true);
    setLoader(true);
    axiosConfig
      .get(`${URL_API}/human-resource/location-static/${item.id}/update`)
      .then(function (response) {
        resetDataLocation();
        // console.log("Response Original : ", response.data);
        let masterData = masterDataLocation;
        masterData.masterLocationStaticTypeCollections =
          response.data.data.masterLocationStaticTypeCollections;
        masterData.masterPostalCodeCollections = [];
        masterData.masterCountryCollections =
          response.data.data.masterCountryCollections;
        masterData.masterCityCollections = [];
        setMasterDataLocation(masterData);
        getDataLocation(item);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const getDataLocation = (item) => {
    axiosConfig
      .get(`${URL_API}/human-resource/location-static/${item.id}`)
      .then(function (response) {
        resetDataLocation();
        // console.log("Response Original : ", response.data);
        let dataLocationTemporary = dataLocation;
        dataLocationTemporary.id = response.data.data.id;
        dataLocationTemporary.location_name = response.data.data.location_name;
        dataLocationTemporary.location_static_type_id =
          response.data.data.location_static_type_id;
        dataLocationTemporary.location_static_type =
          response.data.data.location_static_type;
        dataLocationTemporary.country_id = response.data.data.country_id;
        dataLocationTemporary.country = response.data.data.country;
        dataLocationTemporary.city_id = response.data.data.city_id;
        dataLocationTemporary.city = response.data.data.city;
        dataLocationTemporary.postal_code_id =
          response.data.data.postal_code_id;
        dataLocationTemporary.postal_code = response.data.data.postal_code;
        dataLocationTemporary.address = response.data.data.address;
        dataLocationTemporary.location_coordinates_lat_min =
          response.data.data.location_coordinates_lat_min;
        dataLocationTemporary.location_coordinates_long_min =
          response.data.data.location_coordinates_long_min;
        dataLocationTemporary.radius = response.data.data.radius;
        setDataLocation({ ...dataLocationTemporary });
        context.setModalEdit(true);
        setLoader(false);
        context.setModalProgress(false);
        context.setModalEdit(true);
      })
      .catch(function (error) {
        setLoader(false);
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleUpdate = async () => {
    setLoader(true);
    context.setModalProgress(true);
    if (
      dataLocation.postal_code_id &&
      dataLocation.location_coordinates_lat_min &&
      dataLocation.location_coordinates_long_min
    ) {
      let statusPostalCode = await checkPostalCode();
      if (statusPostalCode) {
        doHandleUpdate();
      } else {
        setLoader(false);
        context.setErrorStatus(400);
        context.setModalProgress(false);
        context.setTextErrorInformation("Validasi error");
        context.setListError([
          {
            code: "VALIDATION_ERROR",
            key: "postal_code_id",
            description:
              "Kode Pos tidak sesuai dengan Garis Lintang dan Garis Bujur.",
          },
        ]);
        context.setOpenDialogError(true);
      }
    } else {
      setLoader(false);
      context.setErrorStatus(400);
      context.setModalProgress(false);
      context.setTextErrorInformation("Validasi error");
      let dataErrors = [];
      if (!dataLocation.postal_code_id) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "postal_code_id",
          description: "Field Kode Pos dibutuhkan",
        });
      }
      if (!dataLocation.location_coordinates_lat_min) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "location_coordinates_lat_min",
          description: "Field Garis Lintang dibutuhkan",
        });
      }
      if (!dataLocation.location_coordinates_long_min) {
        dataErrors.push({
          code: "VALIDATION_ERROR",
          key: "location_coordinates_long_min",
          description: "Field Garis Bujur dibutuhkan",
        });
      }
      context.setListError(dataErrors);
      context.setOpenDialogError(true);
      context.setOpenDialogError(true);
    }
  };

  const doHandleUpdate = () => {
    if (context.userTokenState !== "") {
      let data = {
        LocationStatic: dataLocation,
      };
      axiosConfig
        .put(
          URL_API + `/human-resource/location-static/${dataLocation.id}`,
          data
        )
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          context.setModalEdit(false);
          context.setMessages("Berhasil di edit !");
          context.setSnackbarResponse200(true);
          context.setModalProgress(false);
          refreshList(null, null, null, null);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
          context.setModalProgress(false);
          if (error.response !== undefined) {
            if (error.response.status === 429) {
              context.setErrorStatus(429);
              context.setTextErrorInformation("Too Many Request !");
              context.setOpenDialogError(true);
            }
            if (error.response.status === 400) {
              context.setErrorStatus(400);
              if (
                error.response.data &&
                error.response.data.info &&
                error.response.data.info.message
              ) {
                context.setTextErrorInformation(
                  error.response.data.info.message
                );
                if (error.response.data.info.errors) {
                  context.setListError(error.response.data.info.errors);
                }
              } else {
                context.setTextErrorInformation("Request tidak tepat !");
              }
              context.setOpenDialogError(true);
            }
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  const refreshList = (search, offset, page, limit) => {
    let dataLocationListTemporary = dataLocationList;
    dataLocationListTemporary.options.filter.search =
      search !== null
        ? search
        : dataLocationListTemporary.options.filter.search;
    dataLocationListTemporary.options.paging.offset =
      offset !== null
        ? offset
        : dataLocationListTemporary.options.paging.offset;
    dataLocationListTemporary.options.paging.page =
      page !== null ? page : dataLocationListTemporary.options.paging.page;
    dataLocationListTemporary.options.paging.limit =
      limit !== null ? limit : dataLocationListTemporary.options.paging.limit;
    setDataLocationList({ ...dataLocationListTemporary });
    getLocationList();
  };

  return (
    <div>
      <Box>
        <Grid container spacing={3} style={{ marginBottom: "1em" }}>
          <Grid item md={6}>
            <Typography
              component={"span"}
              style={{ display: "inline-block", marginTop: "3px" }}
              className={classes.title333}
            >
              <b>Lokasi</b>
            </Typography>
          </Grid>
          <Grid item md={6} style={{ textAlign: "right" }}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                handleShowModalCreate();
              }}
            >
              Tambah Lokasi
            </Button>
          </Grid>
        </Grid>
        <Paper
          className={classes.rootTable}
          style={{ backgroundColor: "transparent" }}
        >
          {/* <TableContainer> */}
          <EnhancedTableToolbar
            classes={classes}
            numSelected={selected.length}
            dataLocationList={dataLocationList}
            setDataLocationList={setDataLocationList}
            getLocationList={getLocationList}
          />
          {loader ? <LinearProgress /> : null}

          <Table
            className={classes.table}
            aria-label="sticky table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              rowCount={dataLocationList.list.length}
            />

            {dataLocationList &&
              dataLocationList.list &&
              dataLocationList.list.length > 0 && (
                <TableBody>
                  {dataLocationList.list.map((item, i) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                  inputProps={{ "aria-label": "select all desserts" }}
                                /> */}
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {dataLocationList.options.paging.page *
                              dataLocationList.options.paging.limit +
                              i +
                              1}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {item.location_name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography
                            variant="subtitle2"
                            className={classes.title333}
                          >
                            {moment(item.created_at).format("DD MMMM YYYY")}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <IconButton
                            edge="end"
                            onClick={(e) => handleDropdownOpen(e, i)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl && anchorIn === i)}
                            onClose={(e) => handleDropdownClose(e, i)}
                          >
                            <StyledMenuItem
                              onClick={(e) => handleShowModalEdit(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Edit</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                            <StyledMenuItem
                              onClick={(e) => handleShowModalDelete(e, item)}
                            >
                              <ListItemText
                                primary={
                                  <Typography
                                    variant="subtitle2"
                                    className={classes.title}
                                  >
                                    <b>Hapus</b>
                                  </Typography>
                                }
                              />
                            </StyledMenuItem>
                          </StyledMenu>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              )}

            {dataLocationList &&
              dataLocationList.list &&
              dataLocationList.list.length <= 0 &&
              !isLoaderEmpty && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          className={classes.title333}
                          style={{
                            marginTop: 16,
                            marginBottom: 16,
                          }}
                        >
                          <b>Lokasi tidak tersedia</b>
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

            <TableFooter>
              <TableRow>
                <TablePagination
                  className={classes.paginationTimeManagement}
                  rowsPerPageOptions={[1, 5, 10, 25]}
                  colSpan={5}
                  count={dataLocationList.options.paging.count}
                  rowsPerPage={dataLocationList.options.paging.limit}
                  page={dataLocationList.options.paging.page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  labelRowsPerPage="Baris setiap halaman"
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
          {/* </TableContainer> */}
        </Paper>
      </Box>
      <DialogCreate
        classes={classes}
        isModalCreate={context.isModalCreate}
        setModalCreate={context.setModalCreate}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataLocation={masterDataLocation}
        setMasterDataLocation={setMasterDataLocation}
        dataLocation={dataLocation}
        setDataLocation={setDataLocation}
        listError={context.listError}
        createLocation={createLocation}
      />
      <DialogEdit
        classes={classes}
        isModalEdit={context.isModalEdit}
        setModalEdit={context.setModalEdit}
        // fotoQuery={context.fotoQuery}
        // setFotoQuery={context.setFotoQuery}
        userLoginName={context.userLoginName}
        masterDataLocation={masterDataLocation}
        setMasterDataLocation={setMasterDataLocation}
        dataLocation={dataLocation}
        setDataLocation={setDataLocation}
        listError={context.listError}
        handleUpdate={handleUpdate}
      />
      <DialogDelete
        classes={classes}
        isModalDelete={context.isModalDelete}
        setModalDelete={context.setModalDelete}
        dataLocation={dataLocation}
        setDataLocation={setDataLocation}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default ViewTabLocation;
