import React, { useState, forwardRef, useEffect } from "react";
import {
  Dialog,
  Typography,
  Box,
  Button,
  Slide,
  AppBar,
  Toolbar,
  Container,
  Grid,
  IconButton,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";

// import useSWR from "swr";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
} from "../../../MembershipManagement/constant-data";

import DialogPackageBisnis from "./DialogPackageBisnis";
import DialogPackagePersonal from "./DialogPackagePersonal";
import DialogError from "../../../../../components/DialogError";

// Transtition Cmponent
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogSubscribeMembership = ({
  classes,
  openDialogSubscribe,
  setOpenDialogSubscribe,
}) => {
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const accTypeId = user.membership_account_type_id;
  const pckgId = user.membership_package_id;

  // ===========================================================================
  // STATE
  // ===========================================================================
  const [openDialogPackagePersonal, setOpenDialogPackagePersonal] =
    useState(false);
  const [openDialogPackageBisnis, setOpenDialogPackageBisnis] = useState(false);
  const [listBusinessPackage, setListBusinessPackage] = useState([]);
  const [listBusinessPrice, setListBusinessPrice] = useState([]);
  const [listPersonalPackage, setListPersonalPackage] = useState([]);
  const [listPersonalPrice, setListPersonalPrice] = useState([]);
  const [listPaymentMethods, setListPaymentMethods] = useState([]);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogSubscribe) {
      Axios.get(`${URL_API}/account-management/membership:subscribe`)
        .then((res) => {
          const result = res.data.data;
          if (res.status === 200) {
            const pckgColl = result.membershipPackageCollections;
            const priceColl = result.membershipItemPriceCollections;
            const payColl = result.membershipPaymentMethodCollections;

            const bisnisPckg = pckgColl.filter(
              (pckg) => pckg.accountType.id === ACCOUNT_TYPE_BISNIS
            );
            const bisnisPrice = priceColl.filter(
              (pckg) => pckg.accountType.id === ACCOUNT_TYPE_BISNIS
            );
            const personalPckg = pckgColl.filter(
              (pckg) => pckg.accountType.id === ACCOUNT_TYPE_PERSONAL
            );
            const personalPrice = priceColl.filter(
              (pckg) => pckg.accountType.id === ACCOUNT_TYPE_PERSONAL
            );

            setListBusinessPackage(bisnisPckg);
            setListBusinessPrice(bisnisPrice);
            setListPersonalPackage(personalPckg);
            setListPersonalPrice(personalPrice);

            setListPaymentMethods(payColl);
          }
        })
        .catch((error) => {
          // console.log("Error >", error.response);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors[0]);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorMessage("Whoops, something went wrong !");
          }
        });
    }
  }, [openDialogSubscribe]);

  return (
    <Dialog
      fullScreen
      open={openDialogSubscribe}
      TransitionComponent={Transition}
    >
      <AppBar
        style={{ position: "relative", backgroundColor: "#fff" }}
        elevation={0}
      >
        <Toolbar>
          <IconButton onClick={() => setOpenDialogSubscribe(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* <Paper className={classes.root} elevation={0}> */}
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "center", marginTop: 30 }}>
          <Typography variant="h5" className={classes.title}>
            <b>Mulai Berlangganan Performate.id</b>
          </Typography>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ marginTop: 15 }}
          >
            Pilih jenis akun untuk mulai berlangganan dan dapatkan layanan
            terlengkap dengan harga terbaik untuk anda.
          </Typography>
        </Grid>
      </Grid>
      {/* </Paper> */}

      <Container maxWidth="md" style={{ marginTop: 25, width: 850 }}>
        <Grid container>
          <Grid item md={6}>
            <Box margin={2}>
              <Paper
                variant="outlined"
                style={{ backgroundColor: "#f7ffff", borderRadius: 15 }}
              >
                <Box margin={2}>
                  <Typography
                    variant="h4"
                    className={classes.title333}
                    style={{ marginTop: 30 }}
                  >
                    <b>Personal</b>
                  </Typography>

                  <Box marginTop={3} height={60}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "#555" }}
                    >
                      Pilihan terbaik untuj anda atau keluarga yang ingin
                      meningkatkan produktivitas
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#555", marginTop: 25 }}
                  >
                    Dapatkan semua fitur termasuk :
                  </Typography>

                  <Box marginTop={1} marginBottom={4} height={70}>
                    {personalBens.map((item, i) => (
                      <Box key={i} display="flex" alignItems="center">
                        <CheckIcon
                          style={{
                            color: "#da5464",
                            marginRight: 7,
                            fontSize: 14,
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#999", lineHeight: 2 }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.button4}
                    disabled={listPersonalPackage.length === 0}
                    onClick={() => setOpenDialogPackagePersonal(true)}
                    style={{ marginBottom: 10 }}
                  >
                    <Typography variant="subtitle1">
                      Berlangganan Paket Personal
                    </Typography>
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box margin={2}>
              <Paper
                variant="outlined"
                style={{ backgroundColor: "#f7ffff", borderRadius: 15 }}
              >
                <Box margin={2}>
                  <Typography
                    variant="h4"
                    className={classes.title333}
                    style={{ marginTop: 30 }}
                  >
                    <b>Bisnis</b>
                  </Typography>
                  <Box marginTop={3} height={60}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "#555" }}
                    >
                      Sesuai untuk perusahaan kelas menengah, UKM yang sedang
                      berkembang dan dalam proses meningkatkan omset
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#555", marginTop: 25 }}
                  >
                    Membantu bisnis anda lebih produktif dengan :
                  </Typography>

                  <Box marginTop={1} marginBottom={4} height={70}>
                    {bisnisBens.map((item, i) => (
                      <Box key={i} display="flex" alignItems="center">
                        <CheckIcon
                          style={{
                            color: "#da5464",
                            marginRight: 7,
                            fontSize: 14,
                          }}
                        />
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "#999", lineHeight: 2 }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.button4}
                    disabled={listBusinessPackage.length === 0}
                    onClick={() => setOpenDialogPackageBisnis(true)}
                    style={{ marginBottom: 10 }}
                  >
                    <Typography variant="subtitle1">
                      Berlangganan Paket Bisnis
                    </Typography>
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={5}
      >
        <Typography variant="subtitle1" className={classes.title333}>
          Anda punya perntanyaan seputar membership ?
        </Typography>
        <Button
          variant="outlined"
          size="large"
          className={classes.buttonDisabled}
          style={{ marginLeft: 15 }}
        >
          Hubungi Kami
        </Button>
      </Box>

      <DialogPackageBisnis
        classes={classes}
        openDialogPackageBisnis={openDialogPackageBisnis}
        setOpenDialogPackageBisnis={setOpenDialogPackageBisnis}
        listBusinessPackage={listBusinessPackage}
        listBusinessPrice={listBusinessPrice}
        listPaymentMethods={listPaymentMethods}
      />
      <DialogPackagePersonal
        classes={classes}
        accTypeId={accTypeId}
        pckgId={pckgId}
        openDialogPackagePersonal={openDialogPackagePersonal}
        setOpenDialogPackagePersonal={setOpenDialogPackagePersonal}
        listPersonalPackage={listPersonalPackage}
        listPersonalPrice={listPersonalPrice}
        listPaymentMethods={listPaymentMethods}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogSubscribeMembership;

const personalBens = ["Lisensi User", "Optional Service"];
const bisnisBens = [
  "Deployment (Workshop, Training & KPI Setting)",
  "Consultant Visit",
  "Lisensi User",
];
