import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  CircularProgress,
  InputBase,
} from "@material-ui/core";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import ContextReports from "../../../../context/ContextReports";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import DialogError from "../../../../../../components/DialogError";
import handleError from "../../../global/handleError";
import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  // selected summary
  HIT_PERIOD,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  SET_SUMMARY_TAB,
  GET_DATA_DETAIL,
} from "../../../../context/types";
import ListItemPeriod from "./sub-components/ListItemPeriod";
import { Typography } from "@material-ui/core";
import mapTable from "../../../global/mapTable";
import { navigate } from "hookrouter";

const ConfigPeriod = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    reportDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  const [anchorPeriod, setAnchorPeriod] = useState(null);
  const [period, setPeriod] = useState(summaryState.selectedPeriod);
  const [search, setSearch] = useState("");
  const [periodList, setPeriodList] = useState([]);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (anchorPeriod !== null) {
      const filterPeriod =
        summaryState.periods.length > 0 &&
        summaryState.periods.filter((item) => {
          const periodName = `${item.name}`;
          const thePeriod =
            periodName.toLowerCase().indexOf(search.toLowerCase()) !== -1;

          return thePeriod;
        });

      setPeriodList(filterPeriod);
    }
  }, [anchorPeriod, search]);

  // Show Dialog Period Range
  const handleShowAnchorPeriod = (event) => {
    setAnchorPeriod(event.currentTarget);
  };

  // Hide Dialog Period Range
  const handleCloseAnchorPeriod = () => {
    setAnchorPeriod(null);
  };

  const handlePeriod = (data) => {
    setPeriod(data);
    summaryDispatch({
      type: HIT_PERIOD,
      payload: { selectedPeriod: data },
    });
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleShowResult = () => {
    setLoading(true);
    // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
    const pagination = tableSmrState.paginating;
    const currentPage = pagination.currentPage;
    const perPage = pagination.perPage;

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    let newAchievement = [];

    if (
      summaryState.selectedEntities.includes("goal") &&
      summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal, ...refMa];
    }

    if (
      summaryState.selectedEntities.includes("goal") &&
      !summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal];
    }

    if (
      summaryState.selectedEntities.includes("ma") &&
      !summaryState.selectedEntities.includes("goal")
    ) {
      newAchievement = [...refMa];
    }

    // ~~~~~~~~~~~ Data POST Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: summaryState.dateRange.code,
        user: summaryState.selectedUsers,
        unit: summaryState.selectedUnits,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
        period_id: period.id,
      },
      entities: summaryState.selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [summaryState.selectedOrder],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA TABLE SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY TABLE", result);
        const DATATABLE = mapTable(result.results.data);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Ovaerge Company =============
    const dataAvg = {
      ...dataSummary,
      report_format: "summary",
    };

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataAvg
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          summaryDispatch({
            type: GET_AVG_COMPANY,
            payload: {
              averages: result.results,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });

    handleCloseAnchorPeriod();
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classes.configBtn}
        size="small"
        startIcon={<EventAvailableIcon style={{ fontSize: 15 }} />}
        disabled={tableSmrState.loading}
        onClick={handleShowAnchorPeriod}
      >
        {summaryState.selectedPeriod.name}
      </Button>
      {/* ====== Menu DropDown Period range ====== */}
      <Menu
        id="simple-menu"
        anchorEl={anchorPeriod}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorPeriod)}
      >
        <Box mb={1.5} mt={1.5} ml={2} mr={2}>
          <InputBase
            placeholder="Type period name"
            variant="outlined"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            style={{ width: 250 }}
            onChange={handleSearch}
          />
        </Box>

        {/* Render List Period Item */}
        {periodList.length > 0 &&
          periodList.slice(0, 10).map((item, i) => (
            <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
              <ListItemPeriod
                classes={classes}
                item={item}
                period={period}
                onPeriod={handlePeriod}
              />
            </MenuItem>
          ))}

        {summaryState.periods.length === 0 && (
          <Box m={2} justifyContent="center" alignItems="center">
            <Typography className={classes.title888} variant="caption">
              <Typography variant="subtitle2" className={classes.fieldPreset}>
                Tidak ada Period,{" "}
                <span
                  className={classes.toPeriod}
                  onClick={() => navigate("/7wd-period")}
                >
                  buat period
                </span>
              </Typography>
            </Typography>
          </Box>
        )}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box mx={2} mb={1} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorPeriod}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResult}
          >
            {loading ? "Loading..." : "Show results"}
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ConfigPeriod;
