import React, { useEffect, useState, useContext } from "react";

import {
  Paper,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Chip,
  Button,
  Tooltip,
} from "@material-ui/core";

import ContextGoal from "./Context/ContextGoal";

import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import ContextGlobalGoalDetail from "./Context/ContextGlobalGoalDetail";
import DotLineChartGoalDetailComplete from "./Components/DotLineChartGoalDetailComplete";

import NewIconGoal from "./Components/NewIconGoal";
import DialogSetAsComplete from "./Components/DialogSetAsCompleteDetail";
import DialogRemoveDirectLink from "./ComponentGoalAddDirectLink/DialogRemoveDirectLink";

import { A } from "hookrouter";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";

import TextOverDotLineChart from "./Components/TextOverDotLineChart";
import TextOverDotLineChartSecond from "./Components/TextOverDotLineChartSecond";
import DialogBiggerPictInDetail from "./Components/DialogBiggerPictInDetail";

// import Uploader from './ComponentsGoalResult/Uploader';
import ListCollectionGoalResult from "./ComponentsGoalResult/ListCollectionGoalResult";
import ListCollectionSubGoal from "./ComponentSubGoal/ListCollectionSubGoal";

import ImagePhoto from "./ComponentsMoreDetailOverviewGOAL/ImagePhoto";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";
import {
  STATUS_COMPLETED,
  STATUS_APPROVED,
  STATUS_OVERDUE,
} from "../../../constants/config-status-goal";

import "../../../components/Bar.css";
import MemberName from "./ComponentsMoreDetailOverviewGOAL/MemberName";
import PositionName from "./ComponentsMoreDetailOverviewGOAL/PositionName";
import CircleProgressORA from "./ComponentsMoreDetailOverviewGOAL/CircleProgressORA";
import GoalOwnerName from "./ComponentsMoreDetailOverviewGOAL/GoalOwnerName";
import GoalDescriptionName from "./ComponentsMoreDetailOverviewGOAL/GoalDescriptionName";
import ParentMainGoalName from "./ComponentsMoreDetailOverviewGOAL/ParentMainGoalName";
import Bars from "./ComponentsMoreDetailOverviewGOAL/Bars";
import IconStructureName from "./ComponentsMoreDetailOverviewGOAL/IconStructureName";
import IconStatusGoal from "./ComponentsMoreDetailOverviewGOAL/IconStatusGoal";
import IconStartValue from "./ComponentsMoreDetailOverviewGOAL/IconStartValue";
import IconTargetValue from "./ComponentsMoreDetailOverviewGOAL/IconTargetValue";
import IconActualValue from "./ComponentsMoreDetailOverviewGOAL/IconActualValue";
import IconTargetRangeValue from "./ComponentsMoreDetailOverviewGOAL/IconTargetRangeValue";
import IconResultValue from "./ComponentsMoreDetailOverviewGOAL/IconResultValue";
import IconGapValue from "./ComponentsMoreDetailOverviewGOAL/IconGapValue";
import IconExpectedValue from "./ComponentsMoreDetailOverviewGOAL/IconExpectedValue";
import IconSisaWaktu from "./ComponentsMoreDetailOverviewGOAL/IconSisaWaktu";
import IconTargetType from "./ComponentsMoreDetailOverviewGOAL/IconTargetType";
import TextOverDotLineChartThird from "./Components/TextOverDotLineChartThird";
import DialogWeightMA from "./Components/DialogWeightMA";
import DialogUpdateFrequency from "./Components/DialogUpdateFrequency";
import DialogEditGoal from "./Components/DialogEditGoal";

import icon_project_team from "../../../assets/images/icon_project_team.png";
import icon_project_personal from "../../../assets/images/icon_project_personal.png";

import "./Style/custom.css";
import MyLang from "../../../utilities/MyLang";
import { bahasa, english } from "../../../constants/config-lang";

const numeral = require("numeral");

const ViewGoalMoreDetailOverviewGOAL = (props) => {
  // const context = useContext(ContextGoalDetail);

  const {
    classes,
    goalDetailState,
    userToken,
    userLoginName,
    memberPositionList,
    handleDropdownOpen,
    handleDropdownClose,
    anchorEl,
    handleDialogEditGoalMaster,
    handleDialogDeleteGoalMaster,
    collectionGoalResultList,
    goalResultLength,
    handleCollapse,
    openCollapse,
    handleDropdownCloseGoalResult,
    handleDropdownOpenGoalResult,
    anchorElListGoalResult,
    handleDialogEdit,
    growPaper,
    isModalCreate,
    isModalEdit,
    isModalDelete,

    handleDialogModalGoalResult,

    isModalConfirmBeforePilihUser,
    setModalConfirmBeforePilihUser,

    setFireGoalDetail,
    // loaderEvidence
    // isSuccessUploadFile
    onChangePage,
    onChangeRowsPerPage,
    loader,
    page,
    rowsPerPage,
    maCount,
  } = props;

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  const context = useContext(ContextGoal);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse?.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse?.member_position !== null
      ? statusUserLoginAfterParse?.member_position.is_superior
      : false;

  const [fotoQueryContent, setFotoQueryContent] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [resultValueAchievement, setResultValueAchievement] = useState(null);
  const [expectedValueAchievement, setExpectedValueAchievement] =
    useState(null);

  const [positionName, setPositionName] = useState("");

  const [isStatusActive, setStatusActive] = useState(false); //*Di gunakan untuk component based on status of Goal !
  const [isStatusApproved, setStatusApproved] = useState(false); //*Di gunakan untuk component based on status of Goal !

  // const [dataExponent, setDataExponent] = useState("");
  const [isExponentOra, setExponentOra] = useState(false);
  const [isExponentRva, setExponentRva] = useState(false);
  const [isExponentAva, setExponentAva] = useState(false);

  const [openDialogWeightMA, setOpenDialogWeightMA] = useState(false);
  // Dialog Update Freq
  const [openDialogUpdateFreq, setOpenDialogUpdateFreq] = useState(false);

  const [mainGoalName, setMainGoalName] = useState(null);
  const [mainGoalId, setMainGoalId] = useState(null);

  // edit goal new
  const [openEditGoal, setOpenEditGoal] = useState(false);

  // Set Policy (Hak Akses) State
  const [policy, setPolicy] = useState([]);

  useEffect(() => {
    if (statusUserLoginAfterParse !== null) {
      if (
        statusUserLoginAfterParse.userRole !== null &&
        statusUserLoginAfterParse.userRole !== undefined
      ) {
        if (statusUserLoginAfterParse.userRole.length > 0) {
          if (statusUserLoginAfterParse.userRole[0].name) {
            if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
              setPositionName(
                goalDetailState.owner.structure_position_title_name
              );
            }

            if (statusUserLoginAfterParse.userRole[0].name === "USER") {
              setPositionName(
                goalDetailState.owner.structure_position_title_name
              );
            }
          }
        }
      }
    }

    if (goalDetailState.id !== null) {
      /*
                `````````````````````````````````````````
                HANDLE EXKSPONENT IN RESULT VALUE PERCENT

                `````````````````````````````````````````            
            */
      if (
        goalDetailState.calculatedValue.resultOriented
          .overall_result_achievement !== null
      ) {
        const oraString =
          goalDetailState.calculatedValue.resultOriented.overall_result_achievement.toString();

        if (oraString.indexOf("e") !== -1) {
          // // console.log(" oraString : ", oraString);
          setExponentOra(true);
        }
      }

      if (
        goalDetailState.calculatedValue.resultOriented
          .result_value_achievement !== null
      ) {
        const rvaString =
          goalDetailState.calculatedValue.resultOriented.result_value_achievement.toString();

        if (rvaString.indexOf("e") !== -1) {
          // // console.log(" rvaString : ", rvaString);
          setExponentRva(true);
        }
      }

      if (
        goalDetailState.calculatedValue.actualOriented
          .actual_value_achievement !== null
      ) {
        const avaString =
          goalDetailState.calculatedValue.actualOriented.actual_value_achievement.toString();

        if (avaString.indexOf("e") !== -1) {
          // // console.log(" avaString : ", avaString);
          setExponentAva(true);
        }
      }

      if (goalDetailState.member !== null) {
        setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
        setFirstName(goalDetailState.member.first_name);
        setLastName(goalDetailState.member.last_name);
      }

      if (
        goalDetailState.calculatedValue.resultOriented
          .result_value_achievement !== null
      ) {
        setResultValueAchievement(
          goalDetailState.calculatedValue.resultOriented
            .result_value_achievement * 100
        );
      }

      if (
        goalDetailState.calculatedValue.resultOriented
          .expected_result_value_achievement !== null
      ) {
        setExpectedValueAchievement(
          goalDetailState.calculatedValue.resultOriented
            .expected_result_value_achievement * 100
        );
      }

      if (goalDetailState.direct_link_main_goal_id === null) {
        localStorage.setItem("main_goal_name", goalDetailState.name);
      }

      if (goalDetailState.direct_link_main_goal_id !== null) {
        // let mainGoalNameFromLocalStorage = localStorage.getItem("main_goal_name");

        setMainGoalName(goalDetailState.directLinkMainGoal.name);

        if (goalDetailState.directLinkMainGoal !== null) {
          if (goalDetailState.directLinkMainGoal.id !== null) {
            setMainGoalId(goalDetailState.directLinkMainGoal.id);
          }
        }
      }

      if (fotoQueryContent === "" && goalDetailState.member !== null) {
        if (userToken !== undefined) {
          axiosConfig
            .get(
              URL_API +
                `/${
                  goalDetailState.id !== null
                    ? goalDetailState.member.self.rel.photo_url
                    : undefined
                }&token=${userToken}`
            )
            .then(function (response) {
              // // console.log("Response Original FOTO : ", response);
              setFotoQueryContent(goalDetailState.member.self.rel.photo_url);
            })
            .catch(function (error) {
              // console.log("Error : ", error.response);
            });
        } else {
          // console.log("No Access Token available!");
        }
      }
    }

    // SET POLICY (Hak Akses)
    if (goalDetailState.policy !== null) {
      setPolicy(goalDetailState.policy);
    }

    /*
            ````````````````````````````````````````
            HANDLE COMPONENT BASED ON STATUS OF GOAL

            ````````````````````````````````````````
        */

    if (goalDetailState.status.code === STATUS_COMPLETED) {
      setStatusActive(true);
    }

    if (goalDetailState.status.code === STATUS_APPROVED) {
      setStatusApproved(true);
    }
  }, [goalDetailState, context.isOpenSnackbeer]);

  /*
        ``````````````````````````````````
        HANDLE DIALOG SET AS COMPLETE GOAL

        ``````````````````````````````````
    */
  const [isOpenDialogSetAsComplete, setOpenDialogSetAsComplete] =
    useState(false);

  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    setOpenDialogSetAsComplete(true);
  };

  /*
        ``````````````````````````````
        HANDLE REMOVE FROM DIRECT LINK

        ``````````````````````````````
    */
  const [isOpenDialogRemoveDirectLink, setOpenDialogRemoveDirectLink] =
    useState(false);
  const handleDialogConfirmRemoveDirectLink = () => {
    handleDropdownClose();
    setOpenDialogRemoveDirectLink(true);
  };

  /*
        ````````````````````````````
        HANDLE BIGGER PICT IN DETAIL
        
        ````````````````````````````
    */
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [listPictState, setListPictState] = useState([]);

  const handleBiggerPict = () => {
    setOpenDialog(true);
    let dataFoto = [];
    dataFoto.push(fotoQueryContent);

    // console.log("fotoQueryContent : ", fotoQueryContent);
    setListPictState(dataFoto);
  };

  const handleWeightOpen = () => {
    handleDropdownClose();
    setOpenDialogWeightMA(true);
  };

  const handleDialogEditGoal = () => {
    setOpenEditGoal(true);
    handleDropdownClose();
  };

  // Tags For Goal Detail (Achievement, Method, Calculation, Target)
  const RenderTags = ({ label }) => (
    <Chip
      size="small"
      label={
        <Typography
          variant="subtitle2"
          style={{ fontSize: 11.5, color: "#fff200" }}
        >
          {label}
        </Typography>
      }
      style={{
        backgroundColor: "#786fa6",
        margin: "0 1.5px",
      }}
    />
  );

  // Ini adalah untuk Tombol2 yang dipperbolhkan di click ata tidak
  let editAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;
  let directLinkCreateAllowed = false;
  let directLinkRemoveAllowed = false;

  policy.forEach((item) => {
    if (item.actionName === "update") {
      editAllowed = item.result;
    } else if (item.actionName === "delete") {
      deleteAllowed = item.result;
    } else if (item.actionName === "completed") {
      setCompleteAllowed = item.result;
    } else if (item.actionName === "directLinkCreate") {
      directLinkCreateAllowed = item.result;
    } else if ((item.actionName = "directLinkRemove")) {
      directLinkRemoveAllowed = item.result;
    }
  });

  return (
    <ContextGlobalGoalDetail>
      <Grid container>
        <Grid item xs={6} sm={6} md={4} lg={3}>
          <Paper
            elevation={1}
            className={classes.paperNewColorOfGoal}
            style={{
              float:
                goalDetailState?.goal_category === "PROJECT" ? "left" : "none",
              position: "relative",
            }}
          >
            {goalDetailState?.goal_category === "PROJECT" &&
              goalDetailState?.project_type === "TEAM" && (
                <Tooltip title="Team Project">
                  <Box className="ribbon3">
                    <img alt="icon team" src={icon_project_team} width={25} />
                  </Box>
                </Tooltip>
              )}

            {goalDetailState?.goal_category === "PROJECT" &&
              goalDetailState?.project_type === "PERSONAL" && (
                <Tooltip title="Personal Project">
                  <Box className="ribbon2">
                    <img
                      alt="icon personal"
                      src={icon_project_personal}
                      width={25}
                    />
                  </Box>
                </Tooltip>
              )}
            <List>
              <Grid container style={{ display: "flex" }}>
                <Box style={{ flexGrow: 1 }}>
                  {goalDetailState?.goal_category === "OPERATIONAL" && (
                    <NewIconGoal />
                  )}
                </Box>
                {role === "superadmin" && (
                  <Box style={{ alignItems: "flex-end" }}>
                    {goalDetailState.goal_category === "OPERATIONAL" && (
                      <IconButton onClick={handleDropdownOpen}>
                        <MoreHorizIcon color="#fff" />
                      </IconButton>
                    )}
                  </Box>
                )}

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleDropdownClose}
                >
                  {/* {superior && (
                    <StyledMenuItem
                      onClick={handleDialogEditGoalMaster}
                      disabled={
                        goalDetailState.status_id === "6"
                          ? true
                          : goalDetailState.status_id === "4"
                          ? true
                          : !editAllowed
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>Edit</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )} */}
                  {superior && (
                    <StyledMenuItem
                      onClick={handleDialogEditGoal}
                      disabled={
                        goalDetailState.status_id === "6"
                          ? true
                          : goalDetailState.status_id === "4"
                          ? true
                          : !editAllowed
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{MyLang() ? bahasa.edit : english.edit}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}
                  {superior && maCount > 0 && (
                    <StyledMenuItem onClick={handleWeightOpen}>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>
                              {MyLang() ? bahasa.setWeight : english.setWeight}{" "}
                              MA
                            </b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}
                  {superior && (
                    <StyledMenuItem
                      onClick={handleDialogDeleteGoalMaster}
                      disabled={!deleteAllowed}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{MyLang() ? bahasa.delete : english.delete}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}

                  {mainGoalName !== null && (
                    <StyledMenuItem
                      onClick={handleDialogConfirmRemoveDirectLink}
                      disabled={
                        goalDetailState.status_id === "6"
                          ? true
                          : !directLinkRemoveAllowed
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>
                              {MyLang()
                                ? bahasa.removeFrom
                                : english.removeFrom}{" "}
                              <i>
                                {MyLang()
                                  ? bahasa.directLink
                                  : english.directLink}
                              </i>
                            </b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}

                  <StyledMenuItem
                    onClick={() => {
                      setOpenDialogUpdateFreq(true);
                      handleDropdownClose();
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>
                            {MyLang() ? bahasa.updateFreq : english.updateFreq}
                          </b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>

                  {goalDetailState.status_id !== "4" &&
                    goalDetailState.status_id !== "2" && (
                      <StyledMenuItem
                        onClick={handleDialogSetAsComplete}
                        disabled={!setCompleteAllowed}
                      >
                        <ListItemText
                          primary={
                            <Chip
                              variant="outlined"
                              label={
                                <Typography
                                  variant="subtitle2"
                                  className={classes.title}
                                >
                                  <b>
                                    {MyLang()
                                      ? bahasa.setAsComplete
                                      : english.setAsComplete}
                                  </b>
                                </Typography>
                              }
                            />
                          }
                        />
                      </StyledMenuItem>
                    )}
                </StyledMenu>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <ImagePhoto
                  classes={classes}
                  fotoQueryContent={fotoQueryContent}
                  handleBiggerPict={handleBiggerPict}
                  userToken={userToken}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <MemberName
                  classes={classes}
                  firstName={firstName}
                  lastName={lastName}
                />
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <PositionName
                  positionName={positionName}
                  memberPositionList={memberPositionList}
                />
              </Grid>
              <br />

              <ListItem>
                <CircleProgressORA
                  classes={classes}
                  isExponentOra={isExponentOra}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <GoalOwnerName
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              {/* <ListItem> */}
              <Box
                display="flex"
                justifyContent="center"
                flexWrap="wrap"
                marginBottom={2}
                padding={0.75}
              >
                <RenderTags
                  label={
                    goalDetailState.achievement_type_id === "1"
                      ? MyLang()
                        ? bahasa.more
                        : english.more
                      : MyLang()
                      ? bahasa.less
                      : english.less
                  }
                />
                <RenderTags
                  label={
                    goalDetailState.calculation_method_id === "1"
                      ? MyLang()
                        ? bahasa.sum
                        : english.sum
                      : goalDetailState.calculation_method_id === "2"
                      ? MyLang()
                        ? bahasa.average
                        : english.average
                      : MyLang()
                      ? bahasa.lastVal
                      : english.lastVal
                  }
                />
                <RenderTags
                  label={
                    goalDetailState.input_method_id === "1"
                      ? MyLang()
                        ? bahasa.lastVal
                        : english.lastVal
                      : MyLang()
                      ? bahasa.increment
                      : english.increment
                  }
                />
                <RenderTags
                  label={
                    goalDetailState.value_type_id === "1"
                      ? MyLang()
                        ? bahasa.numeric
                        : english.numeric
                      : MyLang()
                      ? bahasa.percentage
                      : english.percentage
                  }
                />
                <RenderTags
                  label={
                    goalDetailState.target_type_id === "1"
                      ? "Interval"
                      : MyLang()
                      ? bahasa.final
                      : english.final
                  }
                />
              </Box>
              {/* </ListItem> */}

              <ListItem style={{ paddingTop: 0 }}>
                <GoalDescriptionName
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              {goalDetailState.targetValueSyncStatus.name !== "sync" ||
              goalDetailState.startValueSyncStatus.name !== "sync" ? (
                <ListItem style={{ paddingTop: 0 }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Typography
                      variant="caption"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton
                        size="small"
                        style={{ backgroundColor: "red", padding: "0px" }}
                      >
                        <PriorityHighOutlinedIcon
                          fontSize="small"
                          style={{ color: "white", padding: "2px" }}
                        />
                      </IconButton>
                      &nbsp;
                      <i>
                        {MyLang()
                          ? bahasa.missMatchSubGoal
                          : english.missMatchSubGoal}
                      </i>
                    </Typography>
                  </Grid>
                </ListItem>
              ) : null}

              {mainGoalName !== null && (
                <ListItem style={{ paddingTop: 0 }}>
                  <ParentMainGoalName
                    classes={classes}
                    mainGoalName={mainGoalName}
                    mainGoalId={mainGoalId}
                  />
                </ListItem>
              )}

              <ListItem>
                <Bars
                  classes={classes}
                  resultValueAchievement={resultValueAchievement}
                  expectedValueAchievement={expectedValueAchievement}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconStructureName
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconStatusGoal
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconTargetType
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconStartValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconTargetValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconActualValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                  isExponentAva={isExponentAva}
                />
              </ListItem>

              {goalDetailState.target_range_value !== null && (
                <ListItem>
                  <IconTargetRangeValue
                    classes={classes}
                    goalDetailState={goalDetailState}
                  />
                </ListItem>
              )}

              <ListItem>
                <IconResultValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                  isExponentRva={isExponentRva}
                />
              </ListItem>

              <ListItem>
                <IconGapValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconExpectedValue
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>

              <ListItem>
                <IconSisaWaktu
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={8} lg={9}>
          <Paper elevation={1} className={classes.paperColumnDuaRowFirst}>
            <Box pr={3} display="flex" justifyContent="space-between">
              <Typography
                variant="subtitle1"
                className={classes.titleInLineChart}
              >
                <b>
                  {MyLang() ? bahasa.overviewHistory : english.overviewHistory}
                </b>
              </Typography>

              {goalDetailState?.goal_category === "PROJECT" ? (
                <Typography
                  variant="subtitle1"
                  className={classes.titleInLineChart}
                >
                  <Button className={classes.timeFrameIcontDateRangeText}>
                    <A
                      href={`/project/${goalDetailState.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <b>{MyLang() ? bahasa.toProject : english.toProject}</b>
                    </A>
                  </Button>
                </Typography>
              ) : (
                <Box />
              )}
            </Box>

            <Grid container>
              <Grid item sm={4}>
                <TextOverDotLineChart
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </Grid>

              <Grid item sm={4}>
                <TextOverDotLineChartSecond
                  classes={classes}
                  goalDetailState={goalDetailState}
                  isExponent={isExponentRva}
                />
              </Grid>

              <Grid item sm={4}>
                <TextOverDotLineChartThird
                  classes={classes}
                  goalDetailState={goalDetailState}
                />
              </Grid>
            </Grid>
            <br />

            {/* 
                ``````````````
                DOT LINE CHART

                ``````````````                   
            */}

            <DotLineChartGoalDetailComplete
              classes={classes}
              goalDetailState={goalDetailState}
              isModalCreate={isModalCreate}
              isModalEdit={isModalEdit}
              isModalDelete={isModalDelete}
            />
          </Paper>

          {/* 
              ```````````````````````````
              LIST COLLECTION GOAL RESULT

              ```````````````````````````
          */}
          <br />

          <ListCollectionGoalResult
            classes={classes}
            collectionGoalResultList={collectionGoalResultList}
            goalResultLength={goalResultLength}
            goalDetailState={goalDetailState}
            userToken={userToken}
            handleDropdownOpenGoalResult={handleDropdownOpenGoalResult}
            anchorElListGoalResult={anchorElListGoalResult}
            handleDropdownCloseGoalResult={handleDropdownCloseGoalResult}
            handleDialogEdit={handleDialogEdit}
            handleDialogModalGoalResult={handleDialogModalGoalResult}
            setFireGoalDetail={setFireGoalDetail}
            // loaderEvidence = { loaderEvidence}
            // isSuccessUploadFile = { isSuccessUploadFile  }
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            loader={loader}
            page={page}
            rowsPerPage={rowsPerPage}
          />

          <ListCollectionSubGoal
            classes={classes}
            setFireGoalDetail={setFireGoalDetail}
            isModalConfirmBeforePilihUser={isModalConfirmBeforePilihUser}
            setModalConfirmBeforePilihUser={setModalConfirmBeforePilihUser}
            collectionGoalResultList={collectionGoalResultList}
            goalDetailState={goalDetailState}
          />
        </Grid>
        {/* </Box> */}

        <DialogSetAsComplete
          classes={classes}
          isOpenDialogSetAsComplete={isOpenDialogSetAsComplete}
          setOpenDialogSetAsComplete={setOpenDialogSetAsComplete}
          goalDetailState={goalDetailState}
          setFireGoalDetail={setFireGoalDetail}
        />

        <DialogBiggerPictInDetail
          isOpenDialog={isOpenDialog}
          setOpenDialog={setOpenDialog}
          listPictState={listPictState}
          userToken={userToken}
        />

        <DialogRemoveDirectLink
          classes={classes}
          isOpenDialogRemoveDirectLink={isOpenDialogRemoveDirectLink}
          setOpenDialogRemoveDirectLink={setOpenDialogRemoveDirectLink}
          goalId={goalDetailState.direct_link_main_goal_id}
          subGoalId={goalDetailState.id}
        />

        <DialogWeightMA
          classes={classes}
          openDialogWeightMA={openDialogWeightMA}
          setOpenDialogWeightMA={setOpenDialogWeightMA}
        />

        <DialogUpdateFrequency
          classes={classes}
          openDialogUpdateFreq={openDialogUpdateFreq}
          setOpenDialogUpdateFreq={setOpenDialogUpdateFreq}
          goalDetailState={goalDetailState}
          setFireGoalDetail={setFireGoalDetail}
        />

        <DialogEditGoal
          classes={classes}
          goalData={goalDetailState}
          openEditGoal={openEditGoal}
          setOpenEditGoal={setOpenEditGoal}
          setFireGoalDetail={setFireGoalDetail}
        />
      </Grid>
    </ContextGlobalGoalDetail>
  );
};

export default ViewGoalMoreDetailOverviewGOAL;
