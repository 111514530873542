import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Button,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { title } from "../../Style/styleKIt";

const styles = (theme) => ({
  title333: {
    ...title,
    color: "#333",
    textDecoration: "none",
  },
  title555: {
    ...title,
    color: "#555",
    textDecoration: "none",
  },
  title888: {
    ...title,
    color: "#888",
    textDecoration: "none",
  },
  titlePrimary: {
    ...title,
    color: "#d64253",
    textDecoration: "none",
  },
  bestSeller: {
    width: "100%",
    color: "#fff",
    backgroundColor: "#d64253",
    padding: "3px auto",
    borderRadius: "5px 5px 0 0",
  },
  btnBuy: {
    zIndex: "20",
    color: "#555",
    padding: "10px 20px",
    border: "1.5px solid #555",
    fontWeight: "600",
    fontSize: "12px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "15px",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  },
  btnBuy2: {
    zIndex: "20",
    color: "#d64253",
    padding: "10px 20px",
    border: "1.5px solid #d64253",
    fontWeight: "600",
    fontSize: "12px",
    textTransform: "capitalize",
    borderRadius: "5px",
    lineHeight: "15px",
    backgroundColor: "transparent",
    "&:hover,&:focus": {
      background: "rgba(0, 0, 0, 0.1)",
    },
  },
  borderSeller: {
    borderLeft: "2px solid #d64253",
    borderRight: "2px solid #d64253",
  },
  borderSellerLast: {
    borderLeft: "2px solid #d64253",
    borderRight: "2px solid #d64253",
    borderBottom: "2px solid #d64253",
  },
});

const useStyle = makeStyles(styles);

const ListPricing = () => {
  const classes = useStyle();

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);

  const labels = [
    {
      title: "Disertakan",
      icon: <CheckCircleIcon style={{ color: "#d64253" }} />,
    },
    {
      title: "Disertakan Sebagian",
      icon: <CheckCircleOutlineIcon style={{ color: "#d64253" }} />,
    },
    { title: "Expand/Collapse", icon: <KeyboardArrowDownIcon /> },
  ];

  const OptionPrice = ({ name, price, detail, benefits, action }) => {
    const handleContactUs = () => {
      window.open(
        action,
        "_blank" // <- This is what makes it open in a new window.
      );
    };

    return (
      <Box display="flex" flexDirection="column" maxWidth={200}>
        <Typography variant="subtitile2" className={classes.title555}>
          {name}
        </Typography>
        <Typography
          variant="h5"
          className={
            name === "BUSINESS" ? classes.titlePrimary : classes.title333
          }>
          {price}
        </Typography>
        <Box height={55} maxWidth={200}>
          <Typography variant="caption" className={classes.title888}>
            {detail}
          </Typography>
        </Box>
        <Box mb={1}>
          {benefits.map((benefit, i) => (
            <Box key={i} display="flex" alignItems="center">
              <CheckIcon
                style={{ color: "#888", fontSize: 11, marginRight: 5 }}
              />
              <Typography variant="caption" className={classes.title888}>
                {benefit}
              </Typography>
            </Box>
          ))}
        </Box>
        <Button
          size="small"
          onClick={(name === "PRO" || name === "ADVANCED") && handleContactUs}
          className={name === "BUSINESS" ? classes.btnBuy2 : classes.btnBuy}>
          Buy
        </Button>
      </Box>
    );
  };

  return (
    <Box py={15}>
      <Container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ borderBottom: "none" }} />
                <TableCell style={{ borderBottom: "none" }} />
                <TableCell style={{ borderBottom: "none" }} />
                <TableCell
                  align="center"
                  style={{
                    borderBottom: "none",
                    backgroundColor: "#d64253",
                    borderRadius: "15px 15px 0 0",
                  }}>
                  <Typography variant="subtitile2" style={{ color: "#fff" }}>
                    Best Seller
                  </Typography>
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} />
                <TableCell style={{ borderBottom: "none" }} />
                <TableCell style={{ borderBottom: "none" }} />
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography variant="h6" className={classes.title333}>
                      <b>Pilih paket sesuai kebutuhan bisnis Anda !</b>
                    </Typography>

                    <Box mt={4}>
                      {labels.map((label, i) => (
                        <Box key={i} mb={1} display="flex" alignItems="center">
                          {label.icon}
                          <Box mx={1} />
                          <Typography
                            variant="subtitile2"
                            className={classes.title555}>
                            {label.title}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <OptionPrice
                    name="BASIC"
                    price="Rp. 1.900.000"
                    detail="1 sesi pendampingan / bulan"
                    benefits={[
                      "Timespan Monthly",
                      "User Unlimited",
                      "Pendampingan Konsultan",
                      "Konsultasi 1x /bulan",
                      "CS Support 24 Hours",
                    ]}
                    action="#"
                  />
                </TableCell>
                <TableCell className={classes.borderSeller}>
                  <OptionPrice
                    name="BUSINESS"
                    price="Rp. 3.900.000"
                    detail="4 sesi pendampingan / bulan"
                    benefits={[
                      "Timespan Monthly",
                      "User Unlimited",
                      "Pendampingan Konsultan",
                      "Konsultasi 4x /bulan",
                      "CS Support 24 Hours",
                    ]}
                    action="#"
                  />
                </TableCell>
                <TableCell>
                  <OptionPrice
                    name="PRO"
                    price="Contact Us"
                    detail="Improvement dan 8 sesi pendampingan / bulan"
                    benefits={[
                      "Timespan Monthly",
                      "User Unlimited",
                      "Pendampingan Konsultan",
                      "Konsultasi 4x /bulan",
                      "CS Support 24 Hours",
                    ]}
                    action="https://api.whatsapp.com/send?phone=6281212030314&text=Saya%20tertarik%20berlangganan%20Performate.Id"
                  />
                </TableCell>
                <TableCell>
                  <OptionPrice
                    name="ADVANCED"
                    price="Contact Us"
                    detail="Expert Support Sesuai Kebutuhan"
                    benefits={[
                      "Timespan Monthly",
                      "User Unlimited",
                      "Pendampingan Konsultan",
                      "Konsultasi - Contact us",
                      "CS Support 24 Hours",
                    ]}
                    action="https://api.whatsapp.com/send?phone=6281212030314&text=Saya%20tertarik%20berlangganan%20Performate.Id"
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>

              <TableRow style={{ borderBottom: "2px solid #333" }}>
                <TableCell align="left">
                  <Typography variant="h6" className={classes.title333}>
                    <b>Performance Strategic Planning</b>
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center" className={classes.borderSeller}>
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen1(!open1)}>
                    {open1 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {open1 && (
                <>
                  <TableRow>
                    <TableCell>Pelatihan dan workshop 7WD</TableCell>
                    <TableCell align="center">
                      <InfoIcon style={{ color: "#888" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Pendampingan Perumusan Strategi (Goal & Aktivitas)
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon style={{ color: "#888" }} />
                    </TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      Dengan Konsultan
                    </TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Performance Audit and Activity Monitoring</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center" className={classes.borderSeller}>
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen2(!open2)}>
                    {open2 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open2 && (
                <>
                  <TableRow>
                    <TableCell>Pemeriksaan konsistensi Implementasi</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Pemeriksaan kesesuaian Implementasi</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      Pemeriksaan disiplin input hasil di Sistem
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Performance Control and Improvement</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <CheckCircleOutlineIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center" className={classes.borderSeller}>
                  <CheckCircleOutlineIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen3(!open3)}>
                    {open3 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open3 && (
                <>
                  <TableRow>
                    <TableCell>Meeting evaluasi kerja</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">1x /bulan</TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      4x /bulan
                    </TableCell>
                    <TableCell align="center">4x /bulan</TableCell>
                    <TableCell align="center">Contact Us</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Durasi</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">3 Jam</TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      3 Jam
                    </TableCell>
                    <TableCell align="center">7 Jam</TableCell>
                    <TableCell align="center">Contact Us</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Coaching & Counceling (Cnc)</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      Dengan Konsultan
                    </TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell align="center">Dengan Konsultan</TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Pengawasan Kedisiplinan</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Penanganan Masalah</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Performance Report Management</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <CheckCircleOutlineIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center" className={classes.borderSeller}>
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen4(!open4)}>
                    {open4 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open4 && (
                <>
                  <TableRow>
                    <TableCell>
                      Laporan kinerja dan aktivitas Tim secara Otomatis
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Analisa laporan kinerja goal dan aktifitas
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" className={classes.borderSeller}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Data Management</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell
                  align="center"
                  className={classes.borderSeller}></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen5(!open5)}>
                    {open5 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open5 && (
                <>
                  <TableRow>
                    <TableCell>Desain Data Arsitektur</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Manajemen Kualitas Data</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Analisa Data Spesifik Bisnis</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Business Improvement & Problem Solving</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell
                  align="center"
                  className={classes.borderSeller}></TableCell>
                <TableCell align="center">
                  <CheckCircleOutlineIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen6(!open6)}>
                    {open6 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open6 && (
                <>
                  <TableRow>
                    <TableCell>Business Checkup</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>General Business Improvement</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Spesific Business Improvement</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Expert Support</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSeller}></TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}

              {/* <Box py={6} /> */}

              <TableRow
                style={{ borderBottom: "2px solid #333", paddingTop: 40 }}>
                <TableCell>
                  <Typography variant="h6" className={classes.title333}>
                    <b>Update Information</b>
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell
                  align="center"
                  className={
                    open7 ? classes.borderSeller : classes.borderSellerLast
                  }>
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell align="center">
                  <CheckCircleIcon style={{ color: "#d64253" }} />
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen7(!open7)}>
                    {open7 ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>

              {open7 && (
                <>
                  <TableRow>
                    <TableCell>Koneksi bisnis dan informasi</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.borderSellerLast}>
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell align="center">
                      <CheckCircleIcon style={{ color: "#d64253" }} />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

export default ListPricing;
