import React from "react";
import whatsapp from "../../../../assets/images/whatsapp.png";

const FloatingWhatsapp = () => {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=6281212030314&text=Saya%20tertarik%20berlangganan%20Performate.Id"
      target="_blank">
      <img
        src={whatsapp}
        style={{
          height: 50,
          width: 50,
          position: "fixed",
          zIndex: 100,
          bottom: 30,
          right: 30,
        }}
      />
    </a>
  );
};

export default FloatingWhatsapp;
