import moment from "moment";
import { SELECT_DATE_RANGE, GET_FILTER_DATA, GET_COMPARISONS } from "../types";

// ~ Initial State for Filtering ~
export const initialFilter = {
  dateRange: {
    title: "Last 7 Days",
    code: "last_seven_days",
    startDate: moment()
      .subtract(6, "days")
      .startOf("days")
      .format("DD MMM YYYY"),
    endDate: moment().endOf("days").format("DD MMM YYYY"),
    category: "weekly",
  },
  entities: [],
  filterUsers: [],
  filterUnits: [],
  filterPresets: [],
  filterRanges: [],
  status: [],
  orderBy: [],
  comparisonList: [],
  comparisonFields: [],
  loadFilter: false,
};

// =======================================================================
// ~ REDUCER | Managing GLobal State for Filtering ~
export const filterReducer = (state, { type, payload }) => {
  switch (type) {
    case SELECT_DATE_RANGE:
      return {
        ...state,
        dateRange: payload.date_range,
      };
    case GET_FILTER_DATA:
      return {
        ...state,
        filterPresets: payload.tablePresets,
        entities: payload.entities,
        filterUsers: payload.users,
        filterUnits: payload.units,
        filterRanges: payload.filterRanges,
        status: payload.status,
        orderBy: payload.orderBy,
        loadFilter: payload.loadFilter,
      };
    case GET_COMPARISONS:
      return {
        ...state,
        comparisonList: payload.comparisonList,
        comparisonFields: payload.comparisonFields,
      };
    default:
      return state;
  }
};
