import React, { useLayoutEffect, useRef } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";
// Chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

// set chart animated
am4core.useTheme(am4themes_animated);

const BarChartDashboard = ({ classes, data, is_all_access }) => {
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // Get Data Chart
    chart.data = data;

    // const catY = category_y && barState.barPreset[0].category_y;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.dataFields.category = is_all_access
      ? "structure_unit_name"
      : "full_name";
    categoryAxis.renderer.minGridDistance = 60;

    chart.yAxes.push(new am4charts.ValueAxis());
    chart.min = 0;
    chart.max = 100;

    // Configure axis label
    var label = categoryAxis.renderer.labels.template;
    label.truncate = true;
    label.maxWidth = 105;

    let seriesGoal = chart.series.push(new am4charts.ColumnSeries());

    seriesGoal.dataFields.categoryX = is_all_access
      ? "structure_unit_name"
      : "full_name";
    seriesGoal.dataFields.valueY = "goal";
    seriesGoal.dataFields.valueX = "goal_status";
    seriesGoal.name = "Goal";
    seriesGoal.tooltipText = "{name}: {valueY}";

    const valueLabel = seriesGoal.bullets.push(new am4charts.LabelBullet());
    valueLabel.label.text = "Goal";
    valueLabel.label.fontSize = 14;
    valueLabel.label.dy = -8;
    // seriesGoal.fill = am4core.color("#555");

    const colTempGoal = seriesGoal.columns.template;
    colTempGoal.focusable = true;
    colTempGoal.hoverOnFocus = true;
    colTempGoal.tooltipText = "xx {valueY}";
    colTempGoal.fill = am4core.color("#34cc80");

    colTempGoal.adapter.add("fill", (fill, target) => {
      // // console.log("Val Y", target.dataItem && target.dataItem.valueY);

      if (target.dataItem && target.dataItem.valueY < 85) {
        return am4core.color("#e15367");
      } else if (
        target.dataItem &&
        target.dataItem.valueY >= 85 &&
        target.dataItem.valueY <= 100
      ) {
        return am4core.color("#fab445");
      } else return fill;
    });

    let seriesMa = chart.series.push(new am4charts.ColumnSeries());

    seriesMa.dataFields.categoryX = is_all_access
      ? "structure_unit_name"
      : "full_name";
    seriesMa.dataFields.valueY = "ma";
    seriesMa.name = "MA";
    seriesMa.tooltipText = "{name}: {valueY}";

    const valueLabelMa = seriesMa.bullets.push(new am4charts.LabelBullet());
    valueLabelMa.label.text = "MA";
    valueLabelMa.label.fontSize = 14;
    valueLabelMa.label.dy = -8;

    const colTempMa = seriesMa.columns.template;
    colTempMa.focusable = true;
    colTempMa.hoverOnFocus = true;
    colTempMa.tooltipText = "xx {valueY}";
    colTempMa.fill = am4core.color("#34cc80");

    colTempMa.adapter.add("fill", (fill, target) => {
      // // console.log("Val Y", target.dataItem && target.dataItem.valueY);
      if (target.dataItem && target.dataItem.valueY < 85) {
        return am4core.color("#e15367");
      } else if (
        target.dataItem &&
        target.dataItem.valueY >= 85 &&
        target.dataItem.valueY <= 100
      ) {
        return am4core.color("#fab445");
      } else return fill;
    });

    chart.scrollbarX = new am4core.Scrollbar();
    chart.cursor = new am4charts.XYCursor();
    // chart.legend = new am4charts.Legend();

    /**
     * ========================================================
     * Enabling responsive features
     * ========================================================
     */
    chart.responsive.enabled = true;
    chart.responsive.rules.push({
      relevant: function (target) {
        return false;
      },
      state: function (target, stateId) {
        return;
      },
    });

    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [data]);

  return (
    <Box>
      {data !== undefined && data !== null && data.length > 0 && (
        <div id="chartdiv" style={{ width: "100%", height: "325px" }} />
      )}
      {data.length === 0 && (
        <Box display="flex" mt={2} justifyContent="center">
          <Typography
            variant="subtitle2"
            className={classes.title555}
            style={{ marginRight: 10 }}
          >
            {MyLang() ? bahasa.noData : english.noData}
          </Typography>
        </Box>
      )}

      <Box display="flex" flexWrap="wrap" mt={2} justifyContent="center">
        {achieveStats.map((el, i) => (
          <Box key={i} display="flex" alignItems="center">
            <div
              style={{
                backgroundColor: el.color,
                height: 20,
                width: 20,
                borderRadius: 3,
                marginRight: 5,
              }}
            />
            <Typography
              variant="subtitle2"
              className={classes.title555}
              style={{ marginRight: 10 }}
            >
              {el.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BarChartDashboard;

const achieved = MyLang() ? bahasa.achieved : english.achieved;
const warning = MyLang() ? bahasa.warning : english.warning;
const notAchieved = MyLang() ? bahasa.notAchieved : english.notAchieved;

const achieveStats = [
  { color: "#34cc80", label: achieved },
  { color: "#fab445", label: warning },
  { color: "#e15367", label: notAchieved },
];
