import React from "react";
import { LinearProgress, Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
import numeral from "numeral";

import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";

const ProgressBarItem = ({ item }) => {
  const countValue = item;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 10,
      width: "100%",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor:
        countValue <= 85
          ? "#e15367"
          : countValue > 85 && countValue <= 95
          ? "#fab445"
          : "#34cc80",
    },
  }))(LinearProgress);

  return (
    <>
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={countValue}
        duration={3}
        easingFunction={easeQuadInOut}
      >
        {(value) => {
          const newVal = numeral(value).format("00");

          return (
            <BorderLinearProgress
              variant="determinate"
              value={newVal > 100 ? 100 : newVal}
            />
          );
        }}
      </AnimatedProgressProvider>
    </>
  );
};

export default ProgressBarItem;
