import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  Divider,
  TextField,
  DialogActions,
  Radio,
  CircularProgress,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";

import useSWR, { mutate } from "swr";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
  PACKAGE_BISNIS_A,
  PACKAGE_PERSONAL,
  DEPLOYMENT,
  USER_LICENSE,
  PMO_VISIT,
  STATUS_BANK_AVAILABLE,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PENDING,
} from "../../../MembershipManagement/constant-data";
import CardChoosenPackage from "../../../MembershipManagement/ComponentsDialogModal/CardChoosenPackage";

import bank_bca from "../../../../../assets/images/bank-bca.png";
import bank_bni from "../../../../../assets/images/bank-bni.png";
import bank_bri from "../../../../../assets/images/bank-bri.png";
import bank_permata from "../../../../../assets/images/bank-permata.png";
import bank_mandiri from "../../../../../assets/images/bank_mandiri.png";

import DialogError from "../../../../../components/DialogError";
import DialogPayFromTrial from "./DialogPayFromTrial";
import DialogPaidTrial from "./DialogPaidTrial";
import { FormatThousand } from "../../../../../utilities/FormatDecimal";

const DialogCheckoutMember = ({
  classes,
  openDialogCheckoutMember,
  setOpenDialogCheckoutMember,
  setOpenDialogPackage,
  checkoutFee,
  listPaymentMethods,
  duration,
  membership,
  paket,
  onVoucher,
  voucherErrorsList,
  statusRes,
}) => {
  // ====================================================
  // STATE COLLECTIONS
  // ====================================================
  const [loading, setLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState({});
  const [checkoutData, setCheckoutData] = useState({});

  // benefits state
  const [totalUser, setTotalUser] = useState("0");
  const [totalDeployment, setTotalDeployment] = useState("0");
  const [totalPmo, setTotalPmo] = useState("0");
  const [totalDuration, setTotalDuration] = useState("0");

  const [priceDeployment, setPriceDeployment] = useState("");
  const [pricePmo, setPricePmo] = useState("");
  const [priceUser, setPriceUser] = useState("");
  const [priceUserLapsed, setPriceUserLapsed] = useState("");
  const [lapsedPrice, setLapsedPrice] = useState("");
  const [codeVoucher, setCodeVoucher] = useState("");

  const [openDialogPayFromTrial, setOpenDialogPayFromTrial] = useState(false);
  const [openDialogPaidTrial, setOpenDialogPaidTrial] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ====================================================
  // Side Effect
  // ====================================================
  useEffect(() => {
    if (openDialogCheckoutMember) {
      setTotalDuration(duration);

      // console.log("checkoutFee.pricePerItem", checkoutFee.pricePerItem);

      checkoutFee.pricePerItem.forEach((item) => {
        if (item.id === DEPLOYMENT) {
          setTotalDeployment(Number(item.quantity));
          setPriceDeployment(Number(item.total_price_after_duration));
        } else if (item.id === PMO_VISIT && item.name === "Consultant Visit") {
          setTotalPmo(Number(item.quantity));
          setPricePmo(Number(item.total_price_after_duration));
        } else if (item.id === USER_LICENSE) {
          if (!item.name.includes("charged lapsed")) {
            setTotalUser(Number(item.quantity));
            setPriceUser(Number(item.total_price_after_duration));
          } else if (item.name.includes("charged lapsed")) {
            // setTotalUser(Number(item.quantity));
            setPriceUserLapsed(Number(item.total_price_after_duration));
          }
        }
      });
    }
  }, [openDialogCheckoutMember]);

  // // console.log("totalUser", totalUser);
  // // console.log("totalDeployment", totalDeployment);
  // // console.log("totalPmo", totalPmo);
  // // console.log("checkoutFee", checkoutFee);

  // ====================================================
  // FUNCTIONS || METHODS
  // ====================================================
  const handleChangePayment = (data) => {
    // // console.log("Selected Payment", data);
    setSelectedPayment(data);
  };

  const handleSetVoucher = () => {
    onVoucher(codeVoucher);
  };

  const handleClearVoucher = () => {
    setCodeVoucher("");
    onVoucher("");
  };

  const handleCheckoutMember = () => {
    setLoading(true);

    const data = new URLSearchParams();

    // // console.log("DATASSS", {
    //   userL: totalUser,
    //   pmoS: totalPmo,
    //   depDEP: totalDeployment,
    // });

    if (paket === "Bisnis") {
      data.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_BISNIS
      );
      data.append("Membership[membership_package_id]", PACKAGE_BISNIS_A);
      data.append("Membership[membership_duration_value]", totalDuration);
      data.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      data.append("Membership[membership_item][0][id]", USER_LICENSE);
      data.append("Membership[membership_item][0][quantity]", totalUser);
      data.append("Membership[membership_item][1][id]", PMO_VISIT);
      data.append("Membership[membership_item][1][quantity]", totalPmo);
      data.append("Membership[membership_item][2][id]", DEPLOYMENT);
      data.append("Membership[membership_item][2][quantity]", totalDeployment);
      data.append("Membership[membership_voucher_code]", codeVoucher);
    } else if (paket === "Personal") {
      data.append(
        "Membership[membership_account_type_id]",
        ACCOUNT_TYPE_PERSONAL
      );
      data.append("Membership[membership_voucher_code]", codeVoucher);
      data.append("Membership[membership_package_id]", PACKAGE_PERSONAL);
      data.append("Membership[membership_duration_value]", totalDuration);
      data.append(
        "Membership[membership_payment_method_id]",
        selectedPayment.id
      );
      data.append("Membership[membership_item][0][id]", USER_LICENSE);
      data.append("Membership[membership_item][0][quantity]", totalUser);
      if (totalPmo >= 1) {
        data.append("Membership[membership_item][1][id]", PMO_VISIT);
        data.append("Membership[membership_item][1][quantity]", totalPmo);
      }
      if (totalDeployment >= 1) {
        data.append("Membership[membership_item][2][id]", DEPLOYMENT);
        data.append(
          "Membership[membership_item][2][quantity]",
          totalDeployment
        );
      }
    }

    // // console.log("SUBSCRIBE", data[0]);

    Axios.post(`${URL_API}/account-management/membership:subscribe`, data)
      .then((res) => {
        // console.log("Response Checkout Bisnis", res);
        const result = res.data.data;

        if (res.status === 200) {
          Axios.get(
            `${URL_API}/account-management/membership-payment/check-status`
          ).then((response) => {
            // // console.log("Res Status Payment", response);
            const result2 = response.data.data;

            if (response.status === 200) {
              if (result2.status_id === PAYMENT_STATUS_PENDING) {
                setCheckoutData(result2);
                setOpenDialogPayFromTrial(true);
              }

              if (result2.status_id === PAYMENT_STATUS_PAID) {
                setCheckoutData(result2);
                setOpenDialogPaidTrial(true);
              }
            }
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        // console.log("Error : ", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  const renderPayment = listPaymentMethods
    .filter((bank) => bank.status_id === STATUS_BANK_AVAILABLE)
    .map((payment) => {
      const isCurrent = selectedPayment.name === payment.name;
      let img_url;

      if (payment.name === "BCA") {
        img_url = bank_bca;
      } else if (payment.name === "BNI") {
        img_url = bank_bni;
      } else if (payment.name === "BRI") {
        img_url = bank_bri;
      } else if (payment.name === "Permata") {
        img_url = bank_permata;
      } else if (payment.name === "Mandiri") {
        img_url = bank_mandiri;
      }

      return (
        <label key={payment.id}>
          <Paper
            variant={isCurrent ? "elevation" : "outlined"}
            elevation={3}
            style={{
              border: isCurrent && "1px solid #d1354a",
              backgroundColor: isCurrent && "#f9e5e6",
            }}
            className={classes.paymentCard}
          >
            <img src={img_url} height="20" />
          </Paper>
          <Radio
            onChange={() => handleChangePayment(payment)}
            checked={selectedPayment.name === payment.name}
            style={{
              visibility: "hidden",
              height: 0,
              width: 0,
              opacity: 0,
            }}
          />
        </label>
      );
    });

  // // console.log("TOTAL: PMO", totalPmo);

  return (
    <Dialog
      open={openDialogCheckoutMember}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpenDialogCheckoutMember(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={8}>
            <Box marginBottom={4}>
              <Typography variant="h6" className={classes.title}>
                <b>Periksa pesanan anda</b>
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ width: 600, color: "#888" }}
              >
                Harap periksa kembali paket pesanan anda, pastikan anda memilih
                paket keanggotaan yang sesuai dengan kebutuhan anda
              </Typography>
            </Box>

            <Box marginBottom={5} width={600}>
              <CardChoosenPackage
                classes={classes}
                paket={paket}
                membership={membership}
                duration={duration}
                totalUser={totalUser}
                totalDeployment={totalDeployment}
                totalPmo={totalPmo}
              />
            </Box>

            <Box marginBottom={8}>
              <Typography variant="subtitle1" className={classes.title}>
                <b>Metode Pembayaran</b>
              </Typography>

              <Paper variant="outlined" style={{ width: 100 }}>
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "#555", padding: 10 }}
                >
                  Kartu Kredit / Debit Card
                </Typography>
              </Paper>
            </Box>

            <Typography variant="subtitle2" className={classes.title}>
              Virtual Account (Di cek otomatis)
            </Typography>

            <Box
              width={300}
              marginTop={1.5}
              display="flex"
              justifyContent="space-between"
            >
              {renderPayment}
            </Box>
          </Grid>
          <Grid md={4}>
            <Paper variant="outlined">
              <Box padding={2}>
                <Typography
                  variant="subtitle1"
                  className={classes.title}
                  style={{ marginBottom: 5 }}
                >
                  <b>Summary</b>
                </Typography>

                <Grid container>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Total harga lisensi
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Biaya consultant visit
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Biaya Deployment
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      Biaya Tertunda
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp {new Intl.NumberFormat("id-ID").format(priceUser)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp {new Intl.NumberFormat("id-ID").format(pricePmo)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp{" "}
                      {new Intl.NumberFormat("id-ID").format(priceDeployment)}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      className={classes.txtSummary}
                    >
                      : Rp{" "}
                      {new Intl.NumberFormat("id-ID").format(
                        checkoutFee?.total_lapsed_price
                      )}
                    </Typography>
                  </Grid>
                </Grid>

                <Box marginTop={3} marginBottom={3.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Voucher</b>
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <TextField
                      variant="outlined"
                      size="small"
                      style={{ width: 215 }}
                      value={codeVoucher}
                      onChange={(e) => setCodeVoucher(e.target.value)}
                      disabled={checkoutFee.total_item_price_discount !== 0}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              onClick={handleClearVoucher}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button
                      size="small"
                      className={classes.btnVoucher}
                      disabled={checkoutFee.total_item_price_discount !== 0}
                      onClick={handleSetVoucher}
                    >
                      Pakai
                    </Button>
                  </Box>
                  {checkoutFee !== false &&
                  checkoutFee.total_item_price_discount !== 0 ? (
                    <span
                      style={{ color: "green", fontSize: 13, marginTop: 5 }}
                      className={classes.title}
                    >
                      Code Voucher Valid!
                    </span>
                  ) : statusRes === 400 ? (
                    <ul className={classes.voucherErr}>
                      {voucherErrorsList !== undefined &&
                        voucherErrorsList.length > 0 &&
                        voucherErrorsList.map((err, i) => (
                          <li key={i} className={classes.voucherErrItem}>
                            {err.description}
                          </li>
                        ))}
                    </ul>
                  ) : null}
                </Box>

                <Box marginBottom={1.5}>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ marginBottom: 5 }}
                  >
                    <b>Pembayaran</b>
                  </Typography>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Total Harga
                      </Typography>

                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        Discount (%)
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp{" "}
                        {FormatThousand(
                          checkoutFee !== null || checkoutFee !== undefined
                            ? checkoutFee.total_item_price
                            : "-"
                        )}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        : Rp{" "}
                        {FormatThousand(checkoutFee.total_item_price_discount)}{" "}
                        (
                        {checkoutFee.voucher !== undefined &&
                          checkoutFee.voucher !== null &&
                          checkoutFee.voucher.value}
                        %)
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Divider variant="fullWidth" />

                <Box marginTop={1.5}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>Total Pembayaran</b>
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      <Typography
                        variant="subtitle2"
                        className={classes.txtSummary}
                      >
                        <b>
                          : Rp{" "}
                          {new Intl.NumberFormat("id-ID").format(
                            checkoutFee.total_item_price_after_discount
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>

                <Button
                  className={classes.button2}
                  fullWidth
                  variant="contained"
                  style={{ marginTop: 20 }}
                  disabled={
                    selectedPayment.name === undefined || statusRes === 400
                  }
                  onClick={handleCheckoutMember}
                >
                  {loading ? (
                    <CircularProgress color="#fff" size={15} />
                  ) : (
                    "Bayar"
                  )}
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginTop: 50 }} />

      <DialogPayFromTrial
        classes={classes}
        openDialogPayFromTrial={openDialogPayFromTrial}
        setOpenDialogPayFromTrial={setOpenDialogPayFromTrial}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutMember}
        setOpenDialogPackage={setOpenDialogPackage}
        checkoutData={checkoutData}
        paket={paket}
        membership={membership}
        duration={duration}
        totalUser={totalUser}
        totalDeployment={totalDeployment}
        totalPmo={totalPmo}
      />

      <DialogPaidTrial
        classes={classes}
        openDialogPaidTrial={openDialogPaidTrial}
        setOpenDialogPaidTrial={setOpenDialogPaidTrial}
        setOpenDialogPayment={setOpenDialogPayFromTrial}
        setOpenDialogCheckoutReg={setOpenDialogCheckoutMember}
        setOpenDialogPackage={setOpenDialogPackage}
        dataPay={checkoutData}
        paket={paket}
        membership={membership}
        duration={duration}
        totalUser={totalUser}
        totalDeployment={totalDeployment}
        totalPmo={totalPmo}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogCheckoutMember;
