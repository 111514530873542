import React, { useContext, useMemo, useState } from "react";
import {
  Table as Tables,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useTable, useExpanded } from "react-table";
import { FormatDecimal } from "../../../../../../utilities/FormatDecimal";
import { SET_SUMMARY_TAB, GET_DATA_DETAIL } from "../../../../context/types";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";

import ContextReports from "../../../../context/ContextReports";
import Pagination from "./Pagination";
import TotalRecords from "./TotalRecords";
import handleError from "../../../global/handleError";
import DialogError from "../../../../../../components/DialogError";

const Table = ({ classes, rate }) => {
  const { tableSmrState, tableSmrDispatch } = useContext(ContextReports);
  const { goal, ma } = rate;
  let THE_PRESETS = [];

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const TitleCell = ({ value }) => {
    const handleToDetail = () => {
      tableSmrDispatch({
        type: SET_SUMMARY_TAB,
        payload: { summaryTab: 1 },
      });

      tableSmrDispatch({
        type: GET_DATA_DETAIL,
        payload: { loadDetail: true, dataDetail: [] },
      });

      const data = {
        goal_report_ids: value.goal_ids,
        ma_report_ids: value.ma_ids,
      };

      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary/detail`,
        data
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Detail", result);

          if (res.status === 200) {
            tableSmrDispatch({
              type: GET_DATA_DETAIL,
              payload: { loadDetail: false, dataDetail: result },
            });
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableSmrDispatch({
            type: GET_DATA_DETAIL,
            payload: { loadDetail: false, dataDetail: [] },
          });
        });
    };

    return (
      <Typography
        variant="subtitle2"
        className={classes.txtTitleGoal}
        onClick={handleToDetail}
      >
        {value.content}
      </Typography>
    );
  };

  const GoalCell = ({ value }) => {
    const handleToDetail = () => {
      tableSmrDispatch({
        type: SET_SUMMARY_TAB,
        payload: { summaryTab: 1 },
      });

      tableSmrDispatch({
        type: GET_DATA_DETAIL,
        payload: { loadDetail: true, dataDetail: [] },
      });

      const data = {
        goal_report_ids: value.goal_ids,
        ma_report_ids: null,
      };

      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary/detail`,
        data
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Detail", result);

          if (res.status === 200) {
            tableSmrDispatch({
              type: GET_DATA_DETAIL,
              payload: { loadDetail: false, dataDetail: result },
            });
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableSmrDispatch({
            type: GET_DATA_DETAIL,
            payload: { loadDetail: false, dataDetail: [] },
          });
        });
    };
    return (
      <Typography
        variant="subtitle2"
        className={classes.txtTitleGoal}
        onClick={handleToDetail}
      >
        {value.content === "N/A"
          ? value.content
          : `${FormatDecimal(value.content)}%`}
      </Typography>
    );
  };

  const MaCell = ({ value }) => {
    const handleToDetail = () => {
      tableSmrDispatch({
        type: SET_SUMMARY_TAB,
        payload: { summaryTab: 1 },
      });

      tableSmrDispatch({
        type: GET_DATA_DETAIL,
        payload: { loadDetail: true, dataDetail: [] },
      });

      const data = {
        goal_report_ids: null,
        ma_report_ids: value.ma_ids,
      };

      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary/detail`,
        data
      )
        .then((res) => {
          const result = res.data.data;

          // console.log("RES Detail", result);

          if (res.status === 200) {
            tableSmrDispatch({
              type: GET_DATA_DETAIL,
              payload: { loadDetail: false, dataDetail: result },
            });
          }
        })
        .catch((error) => {
          // console.log("ERROR", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableSmrDispatch({
            type: GET_DATA_DETAIL,
            payload: { loadDetail: false, dataDetail: [] },
          });
        });
    };

    return (
      <Typography
        variant="subtitle2"
        className={classes.txtTitleGoal}
        onClick={handleToDetail}
      >
        {value.content === "N/A"
          ? value.content
          : `${FormatDecimal(value.content)}%`}
      </Typography>
    );
  };

  const NumCell = ({ value }) => (
    <Typography variant="subtitle2">
      {value === "N/A" ? value : `${FormatDecimal(value)}%`}
    </Typography>
  );

  const RegCell = ({ value }) => (
    <Typography variant="subtitle2">{value}</Typography>
  );

  const RateCell = ({ value }) => (
    <Typography variant="subtitle2">
      {value !== null ? `${FormatDecimal(value)} %` : "0 %"}
    </Typography>
  );

  // // console.log("TABLE SUMMARY", tableSmrState);

  // ====================== | Map Table Header, and add some prop | ======================
  const PRESETS =
    tableSmrState.fieldPreset !== undefined &&
    tableSmrState.fieldPreset.length > 0 &&
    tableSmrState.fieldPreset.map((item) => {
      return {
        Header: item.caption,
        accessor: item.data_field_name,
        Cell:
          item.data_field_name === "full_name"
            ? TitleCell
            : item.data_field_name === "structure_unit_name"
            ? TitleCell
            : item.data_field_name === "position_title"
            ? TitleCell
            : item.data_field_name === "goal"
            ? GoalCell
            : item.data_field_name === "ma"
            ? MaCell
            : item.data_field_name === "goal_input_rate"
            ? NumCell
            : item.data_field_name === "ma_input_rate"
            ? NumCell
            : RegCell,
      };
    });

  const RATE_GOAL =
    PRESETS.length > 0 &&
    PRESETS.filter((el) => el.accessor !== "goal_input_rate");
  const RATE_MA =
    PRESETS.length > 0 &&
    PRESETS.filter((el) => el.accessor !== "ma_input_rate");

  const NO_RATE =
    PRESETS.length > 0 &&
    PRESETS.filter(
      (el) =>
        el.accessor !== "goal_input_rate" && el.accessor !== "ma_input_rate"
    );

  if (goal && ma) {
    THE_PRESETS = PRESETS;
  } else if (goal && !ma) {
    THE_PRESETS = RATE_MA;
  } else if (!goal && ma) {
    THE_PRESETS = RATE_GOAL;
  } else if (!goal && !ma) {
    THE_PRESETS = NO_RATE;
  }

  // // console.log("GOALZ", goal);
  // // console.log("MAZ", ma);
  // // console.log("THE_PRESETS", THE_PRESETS);
  // // console.log("TABLE_DATS", tableSmrState.dataTable);

  const COLUMN = [
    {
      id: "expander",
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
        <span {...getToggleAllRowsExpandedProps()}>
          {isAllRowsExpanded ? (
            <IconButton size="small">
              <KeyboardArrowDownIcon style={{ color: "#888" }} />
            </IconButton>
          ) : (
            <IconButton size="small">
              <KeyboardArrowRightIcon style={{ color: "#888" }} />
            </IconButton>
          )}
        </span>
      ),
      Cell: ({ row }) =>
        row.canExpand ? (
          <span
            style={{ width: 50 }}
            {...row.getToggleRowExpandedProps({
              style: {
                paddingLeft: `${row.depth * 0.5}rem`,
              },
            })}
          >
            {row.isExpanded ? (
              <IconButton size="small">
                <KeyboardArrowDownIcon />
              </IconButton>
            ) : (
              <IconButton size="small">
                <KeyboardArrowRightIcon />
              </IconButton>
            )}
          </span>
        ) : null,

      style: {
        borderTopLeftRadius: 8.5,
        borderBottomLeftRadius: 8.5,
        width: 1,
        borderBottom: "none",
      },
    },
  ].concat(THE_PRESETS.length > 0 ? THE_PRESETS : []);
  // .concat(goal ? RATE_GOAL : [])
  // .concat(ma ? RATE_MA : []);

  const columns = useMemo(() => COLUMN, [tableSmrState, goal, ma]);
  const data = useMemo(
    () => tableSmrState.dataTable !== undefined && tableSmrState.dataTable,
    [tableSmrState.dataTable, goal, ma]
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable({ columns, data }, useExpanded);

  return (
    <>
      <TableContainer>
        <Tables size="small" aria-label="a dense table" {...getTableProps}>
          <TableHead className={classes.tableHeader}>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    {...column.getHeaderProps({
                      style: column.style,
                    })}
                    className={classes.txtHeader}
                  >
                    <b>{column.render("Header")}</b>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()} {...row.subRows}>
                  {row.cells.map((cell) => (
                    <TableCell
                      {...cell.getCellProps({
                        style: { width: cell.width },
                      })}
                      className={classes.txtBody}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Tables>
      </TableContainer>

      {/* LOADIER */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {tableSmrState.loading && (
          <>
            <CircularProgress
              size={16}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Loading...
            </Typography>
          </>
        )}

        {tableSmrState.dataTable.length === 0 && !tableSmrState.loading && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>

      {/* PAGINATION */}
      <Box display="flex" justifyContent="space-between" mt={1} pb={2}>
        <TotalRecords classes={classes} />
        <Pagination classes={classes} />
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default Table;
