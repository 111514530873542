import React, { useContext, useEffect, useState, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
  Radio,
  List,
  FormControlLabel,
} from "@material-ui/core";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import axios from "axios";
import moment from "moment";
import clsx from "clsx";

// import IconWarningYellow from '../assets/images/icon_warning_yellow.png';

import ContextGoal from "../Context/ContextGoal";

import DialogError from "../../../../components/DialogError";
import IconWarningYellow from "../../../../assets/images/icon_warning_yellow.png";
import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";

import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

import { styles } from "../Style/StyleGoal";

const fileDownload = require("js-file-download");

const useStyle = makeStyles(styles);

const delimeters = [
  { name: MyLang() ? bahasa.comma : english.comma, code: "comma" },
  { name: MyLang() ? bahasa.semicolon : english.semicolon, code: "semicolon" },
];

const floatings = [
  { name: MyLang() ? bahasa.dot : english.dot, code: "dot" },
  { name: MyLang() ? bahasa.comma : english.comma, code: "comma" },
];

const DialogExportGoal = (props) => {
  const {
    isModalExportGoal,
    setModalExportGoal,
    // statusGoalId
  } = props;

  const context = useContext(ContextGoal);
  const classes = useStyle();
  let disableFloatRadio = false;

  const [profileGroupName, setProfileGroupName] = useState("");
  const [delimeter, setDelimeter] = useState("comma");
  const [floating, setFloating] = useState("dot");

  useEffect(() => {
    if (isModalExportGoal === true) {
      const profileName = localStorage.getItem("status_user_login");
      const profileNameAfterParse = JSON.parse(profileName);

      if (
        profileNameAfterParse !== null &&
        profileNameAfterParse !== undefined
      ) {
        // // console.log("profileNameAfterParse", profileNameAfterParse);
        if (
          profileNameAfterParse.group_name !== null &&
          profileNameAfterParse.group_name !== "" &&
          profileNameAfterParse.group_name !== undefined
        ) {
          setProfileGroupName(profileNameAfterParse.group_name);
        }
      }
    }
  }, [isModalExportGoal]);

  // Handle Choose Delimeters
  const handleChooseDelimeters = (e, data) => {
    if (data.code === "comma") {
      setDelimeter(e.target.value);
      setFloating("dot");
    }
    setDelimeter(e.target.value);
  };

  // Handle Choose Floating
  const handleChooseFloating = (e, data) => {
    setFloating(e.target.value);
  };

  if (delimeter === "comma") {
    disableFloatRadio = true;
  }

  /*
        `````````````````````````
        HANDLE BUTTON SUBMIT GOAL

        `````````````````````````
    */
  const [loader, setLoader] = useState(false);

  const handleSubmitExportGoal = () => {
    setLoader(true);

    const userTokenState = localStorage.getItem("userToken");

    if (userTokenState !== "") {
      const header = {
        //    'Accept': "application/json",
        "Content-Type": "text/csv; charset=UTF-8",
        Authorization: "bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .get(
          `${URL_API}/swd/goal:export?options[filter][search]=${
            context.valueSearch === undefined || context.valueSearch === null
              ? ""
              : context.valueSearch
          }&options[filter][status_id]=${
            context.statusGoalId
          }&options[paging][offset]=${
            context.offsetGoalCount
          }&options[paging][limit]=${
            context.limitGoalCount
          }&options[filter][period_id]=${context.period?.id}${
            context.goalCategory
          }${context.projectType}&delimiter=${delimeter}&floating=${floating}`
        )
        //    .get(`${URL_API}/swd/goal:export`)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);
          //    fileDownload(response.data, `${moment(new Date()).format('DD MMMM YYYY hh:mm')}.csv`);
          fileDownload(
            response.data,
            `PF_${profileGroupName}_GOAL_${moment(new Date()).format(
              "YYYY-MM-DD hh:mm:ss"
            )}.csv`
          );
          //    console.log(moment(new Date()).format('DD MMMM YYYY hh:mm'))
          setModalExportGoal(false);
        })
        .catch(function (error) {
          setLoader(false);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);

              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformation(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  return (
    <Fragment>
      <Dialog
        open={isModalExportGoal}
        onClose={() => setModalExportGoal(false)}
        aria-labelledby="alert-dialog-title-422"
        aria-describedby="alert-dialog-description-422"
        // fullWidth
      >
        <DialogTitle
          id="alert-dialog-title-422"
          style={{ textAlign: "center" }}
        >
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-start"
          >
            <IconButton size="small" onClick={() => setModalExportGoal(false)}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent style={{ width: 300, marginRight: 15, marginLeft: 15 }}>
          <DialogContentText id="alert-dialog-description-422">
            <Box marginBottom={5} style={{ textAlign: "center" }}>
              <WarningRoundedIcon
                style={{ color: "#f9ca24" }}
                fontSize="large"
              />
              <Typography variant="subtitle1" className={classes.title}>
                <b>
                  Export semua <i>Goal</i> ini ?
                </b>
              </Typography>
            </Box>

            <Typography
              variant="subtitle2"
              style={{ color: "#555" }}
              className={classes.title}
            >
              {MyLang() ? bahasa.delimiter : english.delimiter}
            </Typography>

            <List dense>
              {delimeters.map((item, i) => (
                <Fragment key={i}>
                  <FormControlLabel
                    label={
                      <p
                        style={{ color: "#aaa", fontSize: 14 }}
                        className={classes.title}
                      >
                        {item.name}
                      </p>
                    }
                    control={
                      <Radio
                        className={classes.rootRadio}
                        disableRipple
                        color="default"
                        name={item.name}
                        value={item.code}
                        checked={item.code === delimeter}
                        onChange={(e) => handleChooseDelimeters(e, item)}
                        checkedIcon={
                          <span
                            className={clsx(
                              classes.iconRadio,
                              classes.checkedIconRadio
                            )}
                          />
                        }
                        icon={<span className={classes.iconRadio} />}
                      />
                    }
                  />
                </Fragment>
              ))}
            </List>

            <Typography
              variant="subtitle2"
              style={{ color: "#555" }}
              className={classes.title}
            >
              {MyLang() ? bahasa.floating : english.floating}
            </Typography>

            <List dense>
              {floatings.map((item, i) => (
                <Fragment key={i}>
                  <FormControlLabel
                    label={
                      <p
                        style={{ color: "#aaa", fontSize: 14 }}
                        className={classes.title}
                      >
                        {item.name}
                      </p>
                    }
                    control={
                      <Radio
                        className={classes.rootRadio}
                        disableRipple
                        color="default"
                        disabled={disableFloatRadio && item.code === "comma"}
                        name={item.name}
                        value={item.code}
                        checked={item.code === floating}
                        onChange={(e) => handleChooseFloating(e, item)}
                        checkedIcon={
                          <span
                            className={clsx(
                              classes.iconRadio,
                              classes.checkedIconRadio
                            )}
                          />
                        }
                        icon={<span className={classes.iconRadio} />}
                      />
                    }
                  />
                </Fragment>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            variant="outlined"
            onClick={() => setModalExportGoal(false)}
            color="primary"
            size="small"
            className={classes.button0}
          >
            {MyLang() ? bahasa.btnCancel : english.btnCancel}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sizeDialogError="small"
            className={classes.button1}
            onClick={handleSubmitExportGoal}
          >
            {loader !== true ? (
              <span style={{ fontSize: 12 }}>{MyLang() ? "Ya" : "Yes"}</span>
            ) : (
              <CircularProgress size={16} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
        <br />
      </Dialog>
      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogExportGoal;
