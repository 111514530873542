import React, { useState } from "react";
import {
  TableRow,
  Box,
  Typography,
  IconButton,
  TableCell,
  Paper,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import FitnessCenterRoundedIcon from "@material-ui/icons/FitnessCenterRounded";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";

import classNames from "classnames";

import ProjectBar from "../global/ProjectBar";
import DialogDeleteTaskGroup from "../dialogs/DialogDeleteTaskGroup";
import DialogRenameTaskGroup from "../dialogs/DialogRenameTaskGroup";
import DialogDuplicateTaskGroup from "../dialogs/DialogDuplicateTaskGroup";

const RowTaskGroup = ({ classes, row, onClose, open, project }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteTaskGroup, setOpenDeleteTaskGroup] = useState(false);
  const [openRenameTaskGroup, setOpenRenameTaskGroup] = useState(false);
  const [openDuplicateGroup, setOpenDuplicateGroup] = useState(false);

  const handleShow = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAway = (event) => {
    if (anchorEl.contains(event.target)) {
      return;
    }
    setAnchorEl(null);
  };

  const handleDeleteGroup = () => {
    handleClose();
    setOpenDeleteTaskGroup(true);
  };

  const handleRenameGroup = () => {
    handleClose();
    setOpenRenameTaskGroup(true);
  };

  const handleDuplicateGroup = () => {
    handleClose();
    setOpenDuplicateGroup(true);
  };

  // ==================================================
  // handle progress bar
  // ==================================================
  const method = project?.achievement_calculation_method;
  const _scope_res =
    row.project_scope_results !== null ? Number(row.project_scope_results) : 0;
  const _schedule_res =
    row.project_schedule_results !== null
      ? Number(row.project_schedule_results)
      : 0;
  // const _gap_scope =
  //   row.project_scope_gap_results !== null
  //     ? Number(row.project_scope_gap_results)
  //     : 0;
  // const _gap_schedule =
  //   row.project_schedule_gap_results !== null
  //     ? Number(row.project_schedule_gap_results)
  //     : 0;

  // const _scope_expected_res =
  //   row.project_scope_expected_results !== null
  //     ? Number(row.project_scope_expected_results)
  //     : 0;
  // const _schedule_expected_res =
  //   row.project_schedule_expected_results !== null
  //     ? Number(row.project_schedule_expected_results)
  //     : 0;

  let PROGRESS_SCOPE = _scope_res;
  let PROGRESS_SCHEDULE = _schedule_res;
  let GAP_SCOPE = 1;
  let GAP_SCHEDULE = 1;
  let COLOR_SCOPE_BOLD = "#3498db";
  let COLOR_SCOPE_LIGHT = "#d7dbdd";
  let BG_SCOPE_BOLD = "#74CB80";
  let BG_SCOPE_LIGHT = "#d7dbdd";
  let COLOR_SCHEDULE_BOLD = "#3498db";
  let COLOR_SCHEDULE_LIGHT = "#d7dbdd";
  let BG_SCHEDULE_BOLD = "#74CB80";
  let BG_SCHEDULE_LIGHT = "#d7dbdd";
  let TEXT_SCOPE = _scope_res;
  let TEXT_SCHEDULE = _schedule_res;

  // if (_gap_scope >= 0) {
  //   COLOR_SCOPE_BOLD = "#74CB80";
  //   COLOR_SCOPE_LIGHT = "#d5efd8";
  //   BG_SCOPE_BOLD = "#fff";
  //   BG_SCOPE_LIGHT = "#fff";
  //   PROGRESS_SCOPE = _scope_res;
  //   GAP_SCOPE = _scope_expected_res;
  //   TEXT_SCOPE = _scope_res;
  // }

  // if (_gap_scope < 0) {
  //   COLOR_SCOPE_BOLD = "#F16C7D";
  //   COLOR_SCOPE_LIGHT = "#f8b5be";
  //   BG_SCOPE_BOLD = "#fff";
  //   BG_SCOPE_LIGHT = "#fff";
  //   PROGRESS_SCOPE = _scope_res;
  //   GAP_SCOPE = _scope_expected_res;
  //   TEXT_SCOPE = _scope_res;
  // }

  // if (_gap_schedule < 0) {
  //   COLOR_SCHEDULE_BOLD = "#F16C7D";
  //   COLOR_SCHEDULE_LIGHT = "#f8b5be";
  //   BG_SCHEDULE_BOLD = "#fff";
  //   BG_SCHEDULE_LIGHT = "#fff";
  //   PROGRESS_SCHEDULE = _schedule_res;
  //   GAP_SCHEDULE = _schedule_expected_res;
  //   TEXT_SCHEDULE = _schedule_res;
  // }

  // if (_gap_schedule >= 0) {
  //   COLOR_SCHEDULE_BOLD = "#74CB80";
  //   COLOR_SCHEDULE_LIGHT = "#d5efd8";
  //   BG_SCHEDULE_BOLD = "#fff";
  //   BG_SCHEDULE_LIGHT = "#fff";
  //   PROGRESS_SCHEDULE = _schedule_res;
  //   GAP_SCHEDULE = _schedule_expected_res;
  //   TEXT_SCHEDULE = _schedule_res;
  // }

  // // console.log("ROWx", row);

  return (
    <TableRow style={{ borderBottom: "1px solid #D9D9D9" }}>
      <TableCell>
        <Box
          mt={2.5}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <IconButton size="small" onClick={onClose}>
              {!open ? (
                <ArrowDropDownIcon style={{ color: "#555" }} />
              ) : (
                <ArrowDropUpIcon style={{ color: "#555" }} />
              )}
            </IconButton>
            <Box mx={0.5} />
            <Typography
              variant="subtitle1"
              className={classes.title555}
              style={{ fontWeight: "600" }}
            >
              {row.name} ({row.todolists?.length})
            </Typography>
            <Box mx={0.5} />
            {project?.project_type === "TEAM" && (
              <IconButton size="small" onClick={handleShow}>
                <MoreHorizIcon style={{ color: "#555", fontSize: 14 }} />
              </IconButton>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" className={classes.title555}>
              {row.weighting !== null ? `${row.weighting}%` : "not set"}
            </Typography>
            <FitnessCenterRoundedIcon
              style={{ color: "#555", fontSize: 17, marginLeft: 7 }}
            />
          </Box>
        </Box>
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      {method !== "SCHEDULE" && (
        <TableCell>
          <ProjectBar
            percentage={PROGRESS_SCOPE}
            gap={GAP_SCOPE}
            text={TEXT_SCOPE}
            bold={COLOR_SCOPE_BOLD}
            light={COLOR_SCOPE_LIGHT}
            bg={BG_SCOPE_BOLD}
            dash={BG_SCOPE_LIGHT}
          />
        </TableCell>
      )}
      {method !== "SCOPE" && (
        <TableCell>
          <ProjectBar
            percentage={PROGRESS_SCHEDULE}
            gap={GAP_SCHEDULE}
            text={TEXT_SCHEDULE}
            bold={COLOR_SCHEDULE_BOLD}
            light={COLOR_SCHEDULE_LIGHT}
            bg={BG_SCHEDULE_BOLD}
            dash={BG_SCHEDULE_LIGHT}
          />
        </TableCell>
      )}
      <TableCell />

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement="bottom-start"
        className={classNames({
          [classes.popperClose]: !anchorEl,
          [classes.popperResponsive]: true,
        })}
      >
        {() => (
          <Grow
            in={Boolean(anchorEl)}
            id="menu-list"
            style={{ transformOrigin: "0 0 0" }}
          >
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleCloseAway}>
                <MenuList role="menu" className={classes.menuList}>
                  <MenuItem
                    className={classes.dropdownItem}
                    disabled={row.name.includes("Default Task Group")}
                    onClick={handleRenameGroup}
                  >
                    <Box display="flex" alignItems="center">
                      <EditRoundedIcon
                        style={{
                          color: "#555555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555555" }}>Rename</span>
                    </Box>
                  </MenuItem>
                  <MenuItem
                    className={classes.dropdownItem}
                    disabled={row.name.includes("Default Task Group")}
                    onClick={handleDuplicateGroup}
                  >
                    <Box display="flex" alignItems="center">
                      <FileCopyRoundedIcon
                        style={{
                          color: "#555555",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#555555" }}>Duplicate</span>
                    </Box>
                  </MenuItem>
                  <Divider className={classes.dropdownDividerItem} />
                  <MenuItem
                    className={classes.dropdownItem}
                    disabled={row.name.includes("Default Task Group")}
                    onClick={handleDeleteGroup}
                  >
                    <Box display="flex" alignItems="center">
                      <DeleteRoundedIcon
                        style={{
                          color: "#C8203C",
                          fontSize: 14,
                          marginRight: 7,
                        }}
                      />
                      <span style={{ color: "#C8203C" }}>Delete</span>
                    </Box>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <DialogRenameTaskGroup
        classes={classes}
        row={row}
        openRenameTaskGroup={openRenameTaskGroup}
        setOpenRenameTaskGroup={setOpenRenameTaskGroup}
      />

      <DialogDuplicateTaskGroup
        classes={classes}
        group={row}
        openDuplicateGroup={openDuplicateGroup}
        setOpenDuplicateGroup={setOpenDuplicateGroup}
      />

      <DialogDeleteTaskGroup
        classes={classes}
        row={row}
        openDeleteTaskGroup={openDeleteTaskGroup}
        setOpenDeleteTaskGroup={setOpenDeleteTaskGroup}
      />
    </TableRow>
  );
};

export default RowTaskGroup;
