import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from "react";
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Radio,
  InputAdornment,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { usePath } from "hookrouter";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import LightTooltip from "../../../../components/LightTooltip";

import DialogError from "../../../../components/DialogError";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import Capitalize from "../../../../utilities/Capitalize";
import DialogDateRange from "../Components/DialogDateRange";

import {
  ToSOTable,
  ToGoalDetail,
  ToGoalDetailALL,
} from "../../../../constants/config-redirect-url";
import { RouteToOverviewGoal } from "../../../../constants/config-redirect-url-with-router";

import Redirect from "../../../../utilities/Redirect";

import "moment/locale/id";
import ContextGoalDetail from "../Context/ContextGoalDetail";
import DialogInfoSchedule from "../../../../components/DialogInfoSchedule";

const numeral = require("numeral");

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogEditGoalActiveUnUsed = (props) => {
  const {
    classes,
    isModalEditGoalUnUSED,
    setModalEditGoalUnUSED,
    goalDetailState,
    fotoQuery,
    setFotoQuery,
  } = props;

  const context = useContext(ContextGoalDetail);

  const SWD_GOAL_INPUT_METHOD_INCREMENT = "swd_goal_input_method_increment";

  const SWD_GOAL_CALCULATION_METHOD_SUM = "swd_goal_calculation_method_sum";
  const SWD_GOAL_CALCULATION_METHOD_LAST_VALUE =
    "swd_goal_calculation_method_last_value";
  const SWD_GOAL_CALCULATION_METHOD_AVERAGE =
    "swd_goal_calculation_method_average";

  /*
        ````````
        USE REFF

        ````````
    */
  let textInputReff = useRef(null);
  const userToken = localStorage.getItem("userToken");

  const [userTokenState, setUserTokenState] = useState("");
  const [idGoal, setIdGoal] = useState("");
  const [updatedAt, setUpdatedAt] = useState("");
  const [evidance, setEvidance] = useState(false);

  const [isDisabledFeature, setDisabledFeature] = useState(false);

  const [jumlahGoalResult, setJumlahGoalResult] = useState("");

  // const [ inputMethodData, setInputMethodData ] = useState('');
  // const [ achievementTypeData, setAchievementTypeData ] = useState('');
  //    const [ valueTypeData, setValueTypeData ] = useState('');
  const [calcMethodData, setCalcMethodData] = useState("");
  const [calcMethodDataID, setCalcMethodDataID] = useState("");

  const [isShowOnlyInputMethodTOTAL, setShowOnlyInputMethodTOTAL] =
    useState(false);

  // Dialog Info Schedule
  const [openDialogInfoSchedule, setOpenDialogInfoSchedule] = useState(false);

  useEffect(() => {
    if (isModalEditGoalUnUSED === true) {
      if (goalDetailState.member.self !== undefined) {
        if (goalDetailState.member.self.rel !== null) {
          if (goalDetailState.member.self.rel.photo_url !== null) {
            setFotoQuery(goalDetailState.member.self.rel.photo_url);
          } else {
            setFotoQuery(null);
          }
        }
      }

      const userToken = localStorage.getItem("userToken");
      setUserTokenState(userToken);

      setBigGoal(goalDetailState.name);
      setDescription(goalDetailState.description);

      if (
        goalDetailState.owner.structure_position_title_name !== undefined &&
        goalDetailState.owner.structure_position_title_name !== null
      ) {
        setTextValuePosition(
          goalDetailState.owner.structure_position_title_name
        );
        setOwnerId(goalDetailState.owner.id);
      }

      setJumlahGoalResult(goalDetailState.calculatedValue.result_value);

      setStartValue(goalDetailState.calculatedValue.start_value);
      setTargetValue(goalDetailState.calculatedValue.target_value);
      setIdGoal(goalDetailState.id);
      setEvidance(goalDetailState.is_evidence_required === 1 ? true : false);

      if (goalDetailState.target_range_value !== null) {
        setTargetRangeValue(goalDetailState.target_range_value);

        setShowTextFieldTargetRangeValue(true);
      }

      if (goalDetailState.inputMethod.code === "total") {
        setShowOnlyInputMethodTOTAL(true);
      }

      setSelectedValueInputMethod(goalDetailState.inputMethod);
      setSelectedValueAchievementType(goalDetailState.achievementType);
      setSelectedValueCalcMethod(goalDetailState.calculationMethod);
      setTargetType(goalDetailState.targetType);

      //    setValueTypeData(goalDetailState.valueType);
      setSelectedValueType(goalDetailState.valueType);
      setCalcMethodData(goalDetailState.calculationMethod);

      setCalcMethodDataID(goalDetailState.calculationMethod.id);

      setLoader(false);
      setDisabledButton(false);

      //*
      if (
        goalDetailState.valueType.id === "2" &&
        goalDetailState.valueType.code === "percentage"
      ) {
        setShowPercent(true);
      }

      /*
               ``````````````````````````
               TO KNOW WHAT TIME FRAME IS

               ``````````````````````````
           */

      if (goalDetailState.time_frame_id === "3") {
        setShowDueDateComponent(true);
        setSelectedDueDate(goalDetailState.time.end_date);

        setShowTextDateRange(false);
        setShowTextPeriod(false);
      }

      if (goalDetailState.time_frame_id === "1") {
        setPeriodId(goalDetailState.period.id);
        setPeriodName(goalDetailState.period.name);
        setEndDateRangeState(goalDetailState.time.end_date);
        setStartDateRangeState(goalDetailState.time.start_date);

        setShowTextPeriod(true);
        setShowTextDateRange(false);
      }

      if (goalDetailState.time_frame_id === "2") {
        setShowTextPeriod(false);
        setShowTextDateRange(true);

        setEndDateRangeState(goalDetailState.time.end_date);
        setStartDateRangeState(goalDetailState.time.start_date);
      }

      /*
               ````````````````````````````````
               TO KNOW STATUS COMPLETE OR NOT : 

                   - active

                   - approved

                   - draft

                   - completed

                   - overdue

               ````````````````````````````````
           */
      if (goalDetailState.status.code === "overdue") {
        setDisabledFeature(true);
      }

      if (goalDetailState.status_id === "6") {
        setDisabledFeature(true);
      }

      if (goalDetailState.status.code === "completed") {
        setDisabledFeature(true);
      }

      if (goalDetailState.status.code === "approved") {
        setDisabledFeature(false);
        // console.log("Status Goal approved !");
      }

      if (goalDetailState.status.code === "draft") {
        setDisabledFeature(false);
      }

      /*
               ````````````````````````````````````````
               GET UPDATED AT, UNTUK VIEW DATA PAKE INI 

               ````````````````````````````````````````
           */
      if (userToken !== undefined) {
        axiosConfig
          .get(`${URL_API}/swd/goal/${goalDetailState.id}/update`)
          .then(function (response) {
            // console.log("Response goal unused : ", response);
            const result = response.data.data;

            if (response.status === 200) {
              switch (result.fields.time_frame_id.value) {
                case "1":
                  setTimeFrameId("1");
                  break;
                case "2":
                  setTimeFrameId("2");
                  break;
                case "3":
                  setTimeFrameId("3");
                  break;
                default:
                  setTimeFrameId(goalDetailState.time_frame_id);
              }

              if (goalDetailState.input_method_id === "2") {
                setCalculationMethodList([
                  {
                    code: "swd_goal_calculation_method_sum",
                    id: "1",
                    name: "sum",
                  },
                  {
                    code: "swd_goal_calculation_method_average",
                    id: "2",
                    name: "average",
                  },
                ]);
              } else {
                setCalculationMethodList([
                  {
                    code: "swd_goal_calculation_method_last_value",
                    id: "3",
                    name: "last value",
                  },
                ]);
              }

              if (
                response.data.data.inputMethodCollections !== null &&
                response.data.data.inputMethodCollections !== undefined
              ) {
                setInputMethodList(response.data.data.inputMethodCollections);
              }

              if (
                response.data.data.achievementTypeCollections !== null &&
                response.data.data.achievementTypeCollections !== undefined
              ) {
                setInputAchievementTypeList(
                  response.data.data.achievementTypeCollections
                );
              }

              if (
                response.data.data.valueTypeCollections !== null &&
                response.data.data.valueTypeCollections !== undefined
              ) {
                setInputValueTypeList(response.data.data.valueTypeCollections);
              }

              if (
                response.data.data.targetTypeCollections !== null &&
                response.data.data.targetTypeCollections !== undefined
              ) {
                setTargetTypeList(response.data.data.targetTypeCollections);
              }

              if (
                response.data.data.periodCollections !== null &&
                response.data.data.periodCollections !== undefined
              ) {
                setPeriodCollections(response.data.data.periodCollections);
              }

              if (
                response.data.data.ownerCollections !== null &&
                response.data.data.ownerCollections !== undefined
              ) {
                setMemberPositionList(response.data.data.ownerCollections);
              }

              setUpdatedAt(response.data.data.updated_at);
            }
          })
          .catch(function (error) {
            if (error.response !== undefined) {
              if (error.response.status === 422) {
                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    if (
                      error.response.data.info.errors[0].code ===
                      "INVALID_STATUS"
                    ) {
                      setOpenDialogError(true);
                      setTextErrorInformationValue(
                        "Error 422 :" +
                          Capitalize(
                            error.response.data.info.errors[0].description
                          )
                      );
                    }
                  }
                }
              }
            }
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [isModalEditGoalUnUSED]);

  /*
       ```````````````````
       COMPONENT DID MOUNT 

       ```````````````````
   */
  //    const userToken = localStorage.getItem('userToken');

  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");

  //    const [ userTokenState, setUserTokenState ] = useState('');
  const [positionList, setPositionList] = useState([]);

  const [calculationMethodList, setCalculationMethodList] = useState([]);
  const [inputAchievementTypeList, setInputAchievementTypeList] = useState([]);
  const [inputMethodList, setInputMethodList] = useState([]);
  const [inputValueTypeList, setInputValueTypeList] = useState([]);

  const [periodCollections, setPeriodCollections] = useState([]);
  const [memberPositionList, setMemberPositionList] = useState([]);

  useEffect(() => {
    // console.log("startDateRangeState : ", startDateRange);
    // console.log("endDateRangeState : ", endDateRange);
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /*
      ``````````````````````
      HANDLE CHANGE BIG GOAL

      ``````````````````````
  */
  const [bigGoal, setBigGoal] = useState("");
  const handleChangeBIGGoal = (e) => {
    setBigGoal(e.target.value);
  };

  /*
      `````````````````````````
      HANDLE CHANGE DESCRIPTION

      `````````````````````````
  */
  const [description, setDescription] = useState("");
  const handleChangeDescription = (e) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  /*
      ``````````````````````````
      HANDLE CHANGE NILAI TARGET

      ``````````````````````````
  */
  const [targetValue, setTargetValue] = useState("");
  const handleChangeTargetValue = (e) => {
    e.preventDefault();
    setTargetValue(e.target.value);
  };

  /*
       `````````````````````````
       HANDLE CHANGE START VALUE

       `````````````````````````
   */
  const [startValue, setStartValue] = useState("");
  const handleChangeStartValue = (e) => {
    e.preventDefault();
    setStartValue(numeral(e.target.value).format("0"));
  };

  /*
       ````````````````````````````````
       HANDLE CHANGE TARGET VALUE RANGE

       ````````````````````````````````
   */
  // const [ targetValueRange, setTargetValueRange ] = useState('');

  const [targetRangeValue, setTargetRangeValue] = useState(null);

  const handleChangeTargetValueRange = (e) => {
    e.preventDefault();
    setTargetRangeValue(e.target.value);
  };

  /*
        `````````````````
        DROPDOWN POSITION

        `````````````````
    */
  const [anchorElTimePosition, setAnchorElTimePosition] = useState(null);

  const [textValuePosition, setTextValuePosition] = useState("");
  const [ownerId, setOwnerId] = useState("");

  const [choosedMemberOwner, setChoosedMemberOwner] = useState("");

  function handleClickDropdownPosition(event) {
    setAnchorElTimePosition(event.currentTarget);
  }

  function handleCloseDropdownPosition() {
    setAnchorElTimePosition(null);
  }

  const handleChoosePosition = (e, data) => {
    // console.log("Pilih Goal Owner : ", data);
    setTextValuePosition(data.structure_position_title_name);
    setOwnerId(data.id);

    setChoosedMemberOwner(data.member_first_name + " " + data.member_last_name);
    handleCloseDropdownPosition();

    if (data.member.self !== undefined) {
      if (data.member.self.rel !== null) {
        if (data.member.self.rel.photo_url !== null) {
          setFotoQuery(data.member.self.rel.photo_url);
        } else {
          setFotoQuery(null);
        }
      }
    }
  };

  /*
      ```````````````````
      DROPDOWN TIME FRAME
      ```````````````````
  */
  const [locale, setLocale] = useState("id");
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);

  const [textValueTimeFrame, setTextValueTimeFrame] = useState("Time Frame");

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  /*
        `````````````````````
        HANDLE TIME FRAME ID

        - idDueDate: "3",
        - idDateRange: "2",
        - idPeriod: "1"

        `````````````````````
    */
  const [timeFrameId, setTimeFrameId] = useState(null);

  /*
      ```````````````
      DUE DATE OPTION

      ```````````````
  */
  const [selectedDueDate, setSelectedDueDate] = useState("");

  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  const handleChooseDueDate = () => {
    setTimeFrameId("3");

    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Due Date");

    setEndDateRangeState(null);

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  /*
      `````````````````
      DATE RANGE OPTION

      `````````````````
  */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  const handleChooseRange = () => {
    setTimeFrameId("2");

    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    // setShowDateRangeComponent(true);
    setOpenDialogInfoSchedule(true);
    setShowTextDateRange(true);
    setTextValueTimeFrame("Date Range");
    setShowTextPeriod(false);
  };

  /*
       ``````````````
       PERIODE OPTION

       ``````````````
   */
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);

  const handleChoosePeriod = (event) => {
    setAnchorElPeriod(event.currentTarget);
    setTimeFrameId("1");

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Period");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");

    setShowPeriodComponent(true);
    setSelectedDueDate(null);
  };

  /*
       ````````````````````````````
       PERIODE OPTION LIST DROPDOWN

       ````````````````````````````
   */
  //*
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);

  function handleClickPeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'

    setAnchorElPeriod(event.currentTarget);
  }

  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  const [periodId, setPeriodId] = useState("");
  const [periodName, setPeriodName] = useState("");

  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();

    // console.log("Item : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  /*
      ```````````````````
      HANDLE DIALOG ERROR

      ```````````````````
   */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformationValue, setTextErrorInformationValue] =
    useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
       `````````````````````````
       HANDLE BUTTON SUBMIT GOAL

       `````````````````````````
   */

  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSUBMITGoal = () => {
    setLoader(true);
    setDisabledButton(true);

    let data = {
      Goal: {
        updated_at: updatedAt,
        name: bigGoal,
        //    owner_id: memberPositionList.length > 0 && ownerId == '' ?  memberPositionList[0].id : ownerId,
        owner_id: ownerId,
        input_method_id:
          selectedValueInputMethod !== "" ? selectedValueInputMethod.id : "",
        //    value_type_id: valueTypeData !== '' ? valueTypeData.id : selectedValueType.id ,
        value_type_id: selectedValueType.id,
        achievement_type_id:
          selectedValueAchievementType !== ""
            ? selectedValueAchievementType.id
            : null,
        calculation_method_id: calcMethodDataID, //selectedValueCalcMethod.id,
        period_id: periodId !== "" ? periodId : null,
        start_date: startDateRangeState !== null ? startDateRangeState : null,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : moment(selectedDueDate).format("YYYY-MM-DD"),
        //    end_date: endDateRangeState !== null ? endDateRangeState :  null,
        start_value: startValue !== "0" ? startValue : 0,
        target_value: targetValue,
        target_range_value: targetRangeValue !== null ? targetRangeValue : null,
        description: description,
        time_frame_id: timeFrameId !== null ? timeFrameId : null,
        target_type_id: targetType.id !== null ? targetType.id : null,
        is_evidence_required: evidance === true ? 1 : 0, //*0 === Tidak butuh evidence, 1 === Butuh evidence
        goal_category: "OPERATIONAL",
      },
    };

    if (data.Goal.period_id == null) {
      delete data.Goal.period_id;
    }

    if (
      data.Goal.period_id !== null &&
      data.Goal.start_date == null &&
      data.Goal.end_date == "Invalid date"
    ) {
      delete data.Goal.start_date;
      delete data.Goal.end_date;
    }

    if (data.Goal.start_date == null) {
      delete data.Goal.start_date;
    }

    if (data.Goal.target_range_value == null) {
      delete data.Goal.target_range_value;
    }

    // console.log("Data SUBMIT : ", data);

    if (userTokenState !== "") {
      axiosConfig
        .put(URL_API + `/swd/goal/${idGoal}`, data)
        .then(function (response) {
          const currentLocation = window.location.pathname;
          setLoader(false);
          setDisabledButton(false);
          // // console.log("Response Edit GOAL unused : ", response);
          if (response.status === 200) {
            setModalEditGoalUnUSED(false);
            localStorage.setItem(
              "goal_detail",
              JSON.stringify(response.data.data)
            );
            if (response.data.data !== null) {
              context.setFireGoalDetail(true);
            }
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  // if(error.response.data.info.errors[0].code === 'VALIDATION_ERROR'){
                  //     setOpenDialogError(true)
                  //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description ))
                  // }
                }
              }
            }
            if (error.response.status == 409) {
              setErrorStatus(409);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }
            if (error.response.status == 403) {
              setErrorStatus(403);
              if (error.response.data.info !== null) {
                if (error.response.data.info.message !== null) {
                  setOpenDialogError(true);
                  setTextErrorInformationValue(
                    "Error 409 :" + Capitalize(error.response.data.info.message)
                  );
                }
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
      ``````````````````````````````````
      HANDLE SELECTED CALCULATION METHOD 

      `````````````````````````````````
   */
  const [selectedValueCalcMethod, setSelectedValueCalcMethod] = useState("");

  const handleChangeRadioButton = (e, data) => {
    e.preventDefault();
    setSelectedValueCalcMethod(data);

    setCalcMethodData(data.code); //*Ini sebenernya ga ke pake !
    setCalcMethodDataID(data.id);

    if (data.code === "swd_goal_calculation_method_average") {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_final",
        id: "2",
        name: "Final",
      });
    } else {
      setTargetTypeList([
        {
          code: "swd_goal_target_type_daily",
          id: "1",
          name: "Daily",
        },
        {
          code: "swd_goal_target_type_final",
          id: "2",
          name: "Final",
        },
      ]);

      setTargetType({
        code: "swd_goal_target_type_daily",
        id: "1",
        name: "Daily",
      });
    }
  };

  /*
        =====================================
        HANDLE SELECTED  TARGET TYPE
        =====================================
    */
  const [targetTypeList, setTargetTypeList] = useState([]);
  const [targetType, setTargetType] = useState({
    code: "swd_goal_target_type_daily",
    id: "1",
    name: "Daily",
  });

  const handleSelectTargetType = (e, data) => {
    e.preventDefault();
    setTargetType(data);
  };

  /*
       ``````````````````````````````````
       HANDLE SELECTED INPUT METHOD 

       `````````````````````````````````
   */
  const [selectedValueInputMethod, setSelectedValueInputMethod] = useState({
    id: "1",
    code: "swd_goal_input_method_last_value",
    name: "last value",
  });

  const handleChangeRadioButtonInputMethod = (e, data) => {
    e.preventDefault();
    setSelectedValueInputMethod(data);

    if (data.code === SWD_GOAL_INPUT_METHOD_INCREMENT) {
      setCalcMethodDataID("1");
      setSelectedValueCalcMethod({
        id: "1",
        code: SWD_GOAL_CALCULATION_METHOD_SUM,
        name: "sum",
      });

      setCalculationMethodList([
        {
          id: "1",
          code: SWD_GOAL_CALCULATION_METHOD_SUM,
          name: "sum",
        },
        {
          id: "2",
          code: SWD_GOAL_CALCULATION_METHOD_AVERAGE,
          name: "average",
        },
      ]);
    } else {
      setCalcMethodDataID("3");
      setSelectedValueCalcMethod({
        id: "3", //"1",
        code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
        name: "last value",
      });

      setCalculationMethodList([
        {
          id: "3", //"1",
          code: SWD_GOAL_CALCULATION_METHOD_LAST_VALUE,
          name: "last value",
        },
      ]);
    }
  };

  /*
       ``````````````````````````````````
       HANDLE SELECTED ACHIEVEMENT METHOD 

       `````````````````````````````````
   */
  const [selectedValueAchievementType, setSelectedValueAchievementType] =
    useState({
      id: "1",
      code: "swd_goal_achievement_type_more_oriented",
      name: "more oriented",
    });

  const handleChangeRadioButtonAchievementType = (e, data) => {
    e.preventDefault();
    setSelectedValueAchievementType(data);
  };

  /*
       ``````````````````````````````````
       HANDLE SELECTED  VALUE METHOD 

       `````````````````````````````````
   */

  const [selectedValueType, setSelectedValueType] = useState({
    id: "1",
    code: "numeric",
    name: "numeric",
  });

  const [isShowPercent, setShowPercent] = useState(false);
  const handleChangeRadioButtonValueType = (e, data) => {
    e.preventDefault();
    setSelectedValueType(data);

    if (data.code === "percentage") {
      setShowPercent(true);
    } else {
      setShowPercent(false);
    }
  };

  /*
        ``````````````````````````````````
        HANDLE SHOW TEXT FIELD RANGE VALUE

        ``````````````````````````````````
    */
  const [isShowTextFieldTargetRangeValue, setShowTextFieldTargetRangeValue] =
    useState(false);

  useEffect(() => {
    //*
    if (
      startValue === targetValue &&
      (selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_SUM ||
        selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_LAST_VALUE)
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else if (
      targetValue === "0" &&
      selectedValueCalcMethod.code === SWD_GOAL_CALCULATION_METHOD_AVERAGE
    ) {
      setShowTextFieldTargetRangeValue(true);
    } else {
      setShowTextFieldTargetRangeValue(false);
    }
  }, [startValue, targetValue, selectedValueCalcMethod]);

  const handleDateRange = () => {
    if (isDisabledFeature === false) {
      setOpenDialogInfoSchedule(true);
    }
  };

  return (
    <Fragment>
      <Dialog
        open={isModalEditGoalUnUSED}
        onClose={() => setModalEditGoalUnUSED(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            <b>Edit Goal </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>What is your BIG ambitious Goal ?</b>
            </Typography>
            <TextField
              // inputRef={ textInputReff }
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeBIGGoal}
              value={bigGoal}
              variant="outlined"
              fullWidth
              // color='primary'
              // onKeyDown={handleEnterPress}
              // placeholder = {goalDetailState.name}
              // value = {goalDetailState.name}
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Grid container>
            <Grid item xs={6}>
              <List>
                <ListItem style={{ paddingLeft: 0 }}>
                  <ListItemIcon>
                    {fotoQuery !== "" && fotoQuery !== null ? (
                      <img
                        src={
                          URL_API + "/" + fotoQuery + "&token=" + userTokenState
                        }
                        className={classes.userRealFoto}
                        alt="User"
                      />
                    ) : (
                      // <img src={URL_API + '/my-profile/photo' + "?token=" + userToken}  className={classes.userRealFoto} />

                      // <img src={AvatarDummy} alt="Gear Picture" className={classes.imageAvatar} />
                      <IconButton size="medium">
                        <AccountCircleIcon fontSize="large" />
                      </IconButton>
                    )}
                  </ListItemIcon>

                  <ListItemText
                    className={classes.listItemtext}
                    primary={
                      // <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                      //     Goal owner
                      // </Typography>
                      <Button
                        onClick={handleClickDropdownPosition}
                        variant="outlined"
                        size="small"
                        className={classes.timeFrameIconInModal}
                      >
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                          style={{ color: "grey" }}
                        >
                          Goal owner :
                        </Typography>
                      </Button>
                    }
                    secondary={
                      <Typography variant="subtitle2" className={classes.title}>
                        {choosedMemberOwner === "" ? (
                          <b>&nbsp;{goalDetailState.owner.member_first_name}</b>
                        ) : (
                          <b>&nbsp; {choosedMemberOwner}</b>
                        )}
                      </Typography>
                    }
                  />
                </ListItem>

                {/* 

                                    ````````````````````````
                                    USER PILIH OWNER DI SINI

                                    ````````````````````````
                                */}

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorElTimePosition}
                  keepMounted
                  open={Boolean(anchorElTimePosition)}
                  onClose={handleCloseDropdownPosition}
                >
                  {memberPositionList.length > 0 &&
                    memberPositionList.map((item, i) => {
                      return (
                        <StyledMenuItem
                          key={i}
                          onClick={(e) => handleChoosePosition(e, item)}
                        >
                          <ListItemText
                            primary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                              >
                                <b>
                                  {item.member_first_name + " "}{" "}
                                  {item.member_last_name}{" "}
                                </b>
                              </Typography>
                            }
                          />
                        </StyledMenuItem>
                      );
                    })}
                </StyledMenu>
              </List>
            </Grid>

            <Grid item xs={6}>
              {/* 

                            `````````````````
                            TIME FRAME BUTTON

                            `````````````````                        
                        */}
              <Button
                onClick={handleClickTimeFrame}
                variant="outlined"
                size="small"
                className={classes.timeFrameIconInModal}
                style={{ marginTop: 24 }}
                // disabled={goalDetailState.status === "overdue"}
                // disabled={isDisabledFeature === true ? true : false}
              >
                <IconButton style={{ background: "#edcfd8" }}>
                  <i
                    className="material-icons"
                    style={{ color: "white", fontSize: 14 }}
                  >
                    calendar_today
                  </i>
                </IconButton>
                &nbsp;
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}
              >
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChoosePeriod}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* 
                            ````````````````
                            MENU LIST PERIOD

                            ````````````````
                        */}

              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: "-160px" }}
              >
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDueDateComponent === true && ( //https://stackoverflow.com/questions/17493309/how-do-i-change-the-language-of-moment-js
                <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                  <ThemeProvider theme={theme}>
                    <Fragment>
                      <DatePicker
                        value={selectedDueDate}
                        onChange={setSelectedDueDate}
                        animateYearScrolling
                        open={isShowDueDateComponentDirectly}
                        onOpen={() => setShowDueDateComponentDirectly(true)}
                        onClose={() => setShowDueDateComponentDirectly(false)}
                        variant="dialog" // dialog, static, inline
                        disableToolbar={false}
                        // orientation="landscape"
                        format="DD MMMM YYYY"
                        // TextFieldComponent =
                        // ToolbarComponent
                        label=""
                        style={{ marginLeft: 16 }}
                        disabled={isDisabledFeature === true ? true : false}
                      />
                    </Fragment>
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
              )}

              {isShowDateRangeComponent === true && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                    onStartDate={setStartDateRangeState}
                    onEndDate={setEndDateRangeState}
                  />
                </Fragment>
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {isShowTextDateRange === true && (
                <LightTooltip
                  title="Time frame tidak bisa di ubah lagi untuk Goal yang sudah AKTIF"
                  placement="right"
                >
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.timeFrameIcontDateRangeText}
                    style={{ marginRight: 8, borderWidth: 0 }}
                    onClick={handleDateRange}
                  >
                    <b>
                      {moment(startDateRangeState).format("DD MMMM YYYY")} -{" "}
                      {moment(endDateRangeState).format("DD MMMM YYYY")}
                    </b>
                  </Button>
                </LightTooltip>
              )}

              {isShowTextPeriod === true && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIcontDateRangeText}
                  style={{ marginRight: 8, borderWidth: 0 }}
                  onClick={(e) => handleClickPeriod(e)}
                >
                  <b>{periodName}</b>
                </Button>
              )}
            </Grid>
          </Grid>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}
          >
            <b>Deskripsi (Optional)</b>
          </Typography>

          <TextField
            onChange={handleChangeDescription}
            style={{ marginTop: 0 }}
            id="outlined-multiline-static-description"
            // label="Multiline"
            multiline
            rows="4"
            // defaultValue="Default Value"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            fullWidth
            value={description !== null ? description : ""}
            inputProps={{
              className: classes.title,
            }}
          />

          <br />
          <Grid container>
            <Grid item xs={6}>
              {memberPositionList.length === 0 && ownerId === "" && (
                <Button
                  onClick={() => Redirect(ToSOTable)}
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                >
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}
                    >
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>Belum ada posisi yang di tentukan untuk membuat Goal ;(</b>
                </Button>
              )}

              {memberPositionList.length === 0 && textValuePosition !== "" && (
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                >
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}
                    >
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>{textValuePosition}</b>
                </Button>
              )}

              {memberPositionList.length > 0 && (
                <Button
                  onClick={handleClickDropdownPosition}
                  variant="outlined"
                  size="small"
                  className={classes.timeFrameIconInModal}
                  style={{ marginTop: 24 }}
                  disabled={isDisabledFeature === true ? true : false}
                >
                  <IconButton style={{ background: "#edcfd8" }}>
                    <i
                      className="material-icons"
                      style={{ color: "white", fontSize: 14 }}
                    >
                      person
                    </i>
                  </IconButton>
                  &nbsp;
                  <b>
                    {memberPositionList.length > 0 && textValuePosition == ""
                      ? memberPositionList[0].structure_position_title_name
                      : textValuePosition}
                  </b>
                </Button>
              )}

              {/* <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorElTimePosition}
                            keepMounted
                            open={Boolean(anchorElTimePosition)}
                            onClose={handleCloseDropdownPosition}
                        >

                            {
                                memberPositionList.length > 0 && memberPositionList.map((item, i) => {
                                    
                                    return (
                                        <StyledMenuItem key={i} onClick={(e) => handleChoosePosition(e, item)}>                         
                                            <ListItemText 
                                                
                                                primary = {

                                                    <Typography variant='subtitle2' className={classes.title}>
                                                        <b>{item.structure_position_title_name}</b>
                                                    </Typography>
                                                }  
                                            />
                                        </StyledMenuItem>
                                    )
                                })
                            }            
                        </StyledMenu> */}
            </Grid>

            {/* 
                        ``````````````````
                        TARGET RANGE VALUE

                        ``````````````````
                    */}
            <Grid item xs={6}>
              {isShowTextFieldTargetRangeValue === true && (
                <Fragment>
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "grey" }}
                  >
                    <b>Target Range value : </b>
                  </Typography>
                  <Box>
                    <TextField
                      placeholder={
                        targetRangeValue !== null ? targetRangeValue : 0
                      }
                      type="number"
                      id="outlined-bare"
                      onChange={handleChangeTargetValueRange}
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        className: classes.title,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography
                              variant="subtitle1"
                              className={classes.title}
                              style={{ color: "grey" }}
                            >
                              {isShowPercent === true ? <b>%</b> : null}
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Fragment>
              )}
            </Grid>
          </Grid>

          <br />
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Start value : </b>
              </Typography>
              {/* <Typography variant='subtitle1' className={classes.title}>
                            <b>0</b>
                        </Typography> */}
              <Box marginRight={theme.spacing(0.2)}>
                <TextField
                  placeholder={startValue}
                  type="number"
                  id="outlined-bare"
                  onChange={handleChangeStartValue}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    className: classes.title,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          variant="subtitle1"
                          className={classes.title}
                          style={{ color: "grey" }}
                        >
                          {isShowPercent === true ? <b>%</b> : null}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Nilai Target : </b>
              </Typography>
              {/* <TextField
                            id="outlined-bare"
                            onChange= {handleChangeTargetValue}
                            value = { numeral(targetValue).format('0,0') }
                            variant="outlined"
                            fullWidth
                        /> */}
              <TextField
                placeholder={targetValue}
                type="number"
                id="outlined-bare"
                onChange={handleChangeTargetValue}
                variant="outlined"
                fullWidth
                inputProps={{
                  className: classes.title,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        variant="subtitle1"
                        className={classes.title}
                        style={{ color: "grey" }}
                      >
                        {isShowPercent === true ? <b>%</b> : null}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <br />
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                size="small"
                checked={evidance}
                onChange={(e) => setEvidance(e.target.checked)}
              />
            }
            label={
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "grey" }}
              >
                Wajib lampirkan bukti
              </Typography>
            }
            labelPlacement="end"
          />
          <br />
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}
          >
            <b>Input Method :</b>
          </Typography>

          <List>
            {inputMethodList.length > 0 &&
              inputMethodList.map((item, i) => (
                <Fragment key={i}>
                  <Radio
                    checked={selectedValueInputMethod.code === item.code}
                    onChange={(e) =>
                      handleChangeRadioButtonInputMethod(e, item)
                    }
                    value={item.id}
                    name={item.name}
                  />
                  <span style={{ fontFamily: "Roboto", color: "grey" }}>
                    {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                  </span>
                </Fragment>
              ))}
          </List>

          {/* <List>

                    <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                        {Capitalize(inputMethodData !== '' ? inputMethodData.code : '')} : &nbsp;&nbsp;&nbsp;&nbsp; 
                    </span>

                    <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                        <b>{ jumlahGoalResult }</b>
                    </span>                    
                </List> */}

          <br />
          {isShowOnlyInputMethodTOTAL !== true && (
            <Fragment>
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Achievement Type : &nbsp; </b>
                {/* <br />
                                        <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                                            {Capitalize(achievementTypeData !== '' ? achievementTypeData.name : '' )} 

                                            &nbsp;
                                            {
                                                targetRangeValue !== null ? ": " + targetRangeValue : ''
                                            }
                                        </span> */}
              </Typography>
              <List>
                {inputAchievementTypeList.length > 0 &&
                  inputAchievementTypeList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={selectedValueAchievementType.id === item.id}
                        onChange={(e) =>
                          handleChangeRadioButtonAchievementType(e, item)
                        }
                        value={item.id}
                        handleChangeRadioButtonAchievementType
                        name={item.name}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                  ))}
              </List>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Value Type : &nbsp; </b>
                {/* <span style={{fontFamily: 'Roboto', color: 'grey'}}>
                                            {Capitalize(valueTypeData !== '' ? valueTypeData.code : '')}
                                        </span> */}
              </Typography>
              <List>
                {inputValueTypeList.length > 0 &&
                  inputValueTypeList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={selectedValueType.id === item.id}
                        onChange={(e) =>
                          handleChangeRadioButtonValueType(e, item)
                        }
                        value={item.id}
                        name={item.name}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                    // </ListItem>
                  ))}
              </List>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Calculation Method : &nbsp; </b>
              </Typography>

              <List>
                {calculationMethodList.length > 0 &&
                  calculationMethodList.map((item, i) => (
                    <Fragment key={i}>
                      <Radio
                        checked={selectedValueCalcMethod.id === item.id}
                        onChange={(e) => handleChangeRadioButton(e, item)}
                        value={item.id}
                        name={item.name}
                        disabled={item.code === "last_value" ? true : false}
                      />
                      <span style={{ fontFamily: "Roboto", color: "grey" }}>
                        {Capitalize(item.name)} : &nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                    </Fragment>
                  ))}
              </List>

              <br />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "grey" }}
              >
                <b>Target Type : </b>
              </Typography>

              <List>
                {targetTypeList.length > 0 &&
                  targetTypeList.map((item, i) => {
                    return (
                      <Fragment key={i}>
                        <Radio
                          checked={targetType.code === item.code}
                          onChange={(e) => handleSelectTargetType(e, item)}
                          value={item.id}
                          name={item.name}
                        />
                        <span style={{ fontFamily: "Roboto", color: "grey" }}>
                          {Capitalize(item.name)} &nbsp;&nbsp;&nbsp;
                        </span>
                      </Fragment>
                    );
                  })}
              </List>
            </Fragment>
          )}
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSUBMITGoal}
            variant="contained"
            className={classes.button}
            fullWidth
            // disableRipple={disabledButton == true ? true : false}
            disabled={loader}
          >
            {loader === true ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "  Simpan Perubahan !"
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <DialogInfoSchedule
        openDialogInfoSchedule={openDialogInfoSchedule}
        setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
        onOpenDialog={setShowDateRangeComponent}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformationValue}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
};

export default DialogEditGoalActiveUnUsed;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});
