import React, { useContext } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";

import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";

import ContextGoalDetail from "../Context/ContextGoalDetail";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";

import numeral from "numeral";
import {
  FormatDecimal,
  FormatThousand,
} from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const TextOverDotLineChart = (props) => {
  const { classes, goalDetailState } = props;

  const context = useContext(ContextGoalDetail);

  return (
    <List>
      <ListItem>
        <ListItemIcon
          style={{
            marginLeft: 8,
          }}
        >
          <IconButton style={{ backgroundColor: "#aed9ff", padding: "6px" }}>
            <RadioButtonCheckedIcon style={{ color: "#4aa9fb" }} />
          </IconButton>
        </ListItemIcon>

        <ListItemText
          className={classes.listItemtext}
          primary={
            <Typography variant="subtitle2" className={classes.title}>
              {goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE ? (
                <b>{numeral(goalDetailState.target_value).format("0.00")} %</b>
              ) : (
                <b>
                  {context.modeRupiahOn === true
                    ? `Rp ${FormatDecimal(goalDetailState.target_value)}`
                    : FormatDecimal(goalDetailState.target_value)}
                </b>
              )}
            </Typography>
          }
          secondary={
            <Typography
              variant="subtitle2"
              className={classes.title}
              style={{ color: "grey" }}
            >
              {MyLang() ? bahasa.targetVal : english.targetVal}
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
};

export default TextOverDotLineChart;
