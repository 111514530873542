import React from "react";
import { Typography, Box, Checkbox } from "@material-ui/core";

import clsx from "clsx";

const ListItemStatus = ({
  classes,
  item,
  selected,
  setSelected,
  isSelected,
}) => {
  const isItemSelected = isSelected(Number(item.id));

  const handleChangeCheckbox = (contetnt) => {
    const selectedIndex = selected.indexOf(Number(contetnt.id));

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, Number(contetnt.id));
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    // // console.log("selected ALL MA", newSelected);

    setSelected(newSelected);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginLeft={1}
      marginRight={2}
      onClick={() => handleChangeCheckbox(item)}
    >
      <Checkbox
        className={classes.checkboxRoot}
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        checked={isItemSelected}
        disableRipple
        color="default"
      />
      <Typography variant="subtitle2" className={classes.title333}>
        {item.name}
      </Typography>
    </Box>
  );
};

export default ListItemStatus;
