import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  Divider,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import clsx from "clsx";

import ContextReports from "../../../../../context/ContextReports";
import {
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
  HIT_UNIT_SELECT,
} from "../../../../../context/types";
import Axios from "../../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../../constants/config-api";
import extractTable from "../../../../global/extractTable";
import handleError from "../../../../global/handleError";
import DialogError from "../../../../../../../components/DialogError";

import ListItemUnit from "./ListItemUnit";
import ListItemType from "./ListItemType";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const UnitFilter = ({ classes }) => {
  const { filterState, tableState, tableDispatch, reportDispatch } =
    useContext(ContextReports);

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ STATE ~~~~~~~~~~~~~~~~~
  // ==============================================
  const [selected, setSelected] = useState([]);
  const [anchorUnit, setAnchorUnit] = useState(null);
  const [anchorType, setAnchorType] = useState(null);
  const [anchorStructure, setAnchorStructure] = useState(null);
  const [unitType, setUnitType] = useState({ id: "", name: "Pilih unit type" });
  const [unitTypeCollections, setUnitTypeCollections] = useState([]);
  const [structureCollections, setStructureCollections] = useState([]);
  const [loadUnit, setLoadUnit] = useState(false);
  const [loadStructure, setLoadStructure] = useState(false);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const itemCount = structureCollections.length;
  const isSelected = (id) => selected.indexOf(id) !== -1;

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ SIDE EFFECT ~~~~~~~~~~~~~~~~~
  // ==============================================
  useEffect(() => {
    if (anchorUnit !== null) {
      setLoadUnit(true);

      Axios.get(
        `${URL_API}/human-resource/master-structure-unit/list/from-so/create`
      )
        .then((res) => {
          // // console.log("RES SO", res);
          const result = res.data.data;
          const unitTypeId = result.fields.structure_unit_type_id.default_value;

          if (res.status === 200) {
            setUnitTypeCollections(unitTypeId);
          }
          setLoadUnit(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadUnit(false);
        });
    }
  }, [anchorUnit]);

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ METHODS ~~~~~~~~~~~~~~~~~
  // ==============================================
  const handleShowAnchorUnit = (event) => {
    setAnchorUnit(event.currentTarget);
  };

  const handleCloseAnchorUnit = () => {
    setAnchorUnit(null);
  };

  const handleShowAnchorType = (event) => {
    setAnchorType(event.currentTarget);
  };

  const handleCloseAnchorType = () => {
    setAnchorType(null);
  };

  const handleShowAnchorStructure = (event) => {
    setAnchorStructure(event.currentTarget);
  };

  const handleCloseAnchorStructure = () => {
    setAnchorStructure(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = structureCollections.map((n) => n.id);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleType = (data) => {
    setUnitType(data);
    handleCloseAnchorType();
    setLoadStructure(true);

    Axios.post(
      `${URL_API}/human-resource/master-structure-unit/list/from-so/list`,
      {
        structure_unit_type_id: data.id,
      }
    )
      .then((res) => {
        // // console.log("RES Structure", res);
        const result = res.data.data;

        if (res.status === 200) {
          setStructureCollections(result);
        }
        setLoadStructure(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoadStructure(false);
      });
  };

  const handleShowResult = () => {
    setLoading(true);

    tableDispatch({
      type: HIT_UNIT_SELECT,
      payload: { selected },
    });

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: [],
        unit: selected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    const dataUnit = {
      structure_unit_ids: selected,
    };

    Axios.post(
      `${URL_API}/human-resource/structure-position/descendant/based-on-unit`,
      dataUnit
    )
      .then((res) => {
        const result = res.data.data;

        if (res.status === 200) {
          localStorage.setItem("filter_user", JSON.stringify(result));
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });

    Axios.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data;

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        if (res.status === 200) {
          handleCloseAnchorUnit();
          setLoading(false);

          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<AccountTreeIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            disabled={tableState.loadTable || filterState.loadFilter}
            onClick={handleShowAnchorUnit}
          >
            Unit
          </Button>
        }
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorUnit}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorUnit)}
        onClose={handleCloseAnchorUnit}
      >
        <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
          <Typography variant="subtitle2" className={classes.title555}>
            <b>Unit Type</b>
          </Typography>

          <Button
            variant="outlined"
            className={classes.actionBtn}
            fullWidth
            endIcon={<ArrowDropDownIcon />}
            style={{ marginTop: 5 }}
            onClick={handleShowAnchorType}
          >
            {unitType.name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorType}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorType)}
            onClose={handleCloseAnchorType}
          >
            {loadUnit && (
              <Box m={2}>
                <Typography
                  variant="caption"
                  className={classes.title555}
                  style={{ textAlign: "center" }}
                >
                  Loading...
                </Typography>
              </Box>
            )}
            {unitTypeCollections.length > 0 &&
              unitTypeCollections.map((item, i) => (
                <MenuItem key={i} style={{ padding: 0 }} dense>
                  <ListItemType
                    classes={classes}
                    item={item}
                    type={unitType}
                    onType={handleType}
                  />
                </MenuItem>
              ))}
          </Menu>
        </Box>
        {unitType.id !== "" && (
          <Box mx={2} mb={2}>
            <Typography variant="subtitle2" className={classes.title555}>
              <b>
                Structure Unit {selected.length > 0 && `(${selected.length})`}
              </b>
            </Typography>

            <Button
              variant="outlined"
              className={classes.actionBtn}
              fullWidth
              endIcon={<ArrowDropDownIcon />}
              style={{ marginTop: 5 }}
              onClick={handleShowAnchorStructure}
            >
              Structure Unit
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorStructure}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorStructure)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={1}
                mr={2}
                mb={1}
              >
                <Box display="flex" alignItems="center">
                  <Checkbox
                    disableRipple
                    indeterminate={
                      selected.length > 0 && selected.length < itemCount
                    }
                    checked={itemCount > 0 && selected.length === itemCount}
                    onChange={handleSelectAllClick}
                    className={classes.checkboxRoot}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    color="default"
                    inputProps={{ "aria-label": "decorative checkbox" }}
                  />
                  <Typography variant="subtitle2" className={classes.title555}>
                    All
                  </Typography>
                </Box>
                <Typography
                  className={classes.btnTxt}
                  onClick={() => setSelected([])}
                >
                  None
                </Typography>
              </Box>
              <Box>
                {loadStructure && (
                  <Box m={2}>
                    <Typography
                      variant="caption"
                      className={classes.title555}
                      style={{ textAlign: "center" }}
                    >
                      Loading...
                    </Typography>
                  </Box>
                )}
                {structureCollections.length > 0 &&
                  structureCollections.map((item, i) => (
                    <MenuItem key={i} style={{ padding: 0 }} dense>
                      <ListItemUnit
                        classes={classes}
                        index={i}
                        unit={item}
                        selected={selected}
                        setSelected={setSelected}
                        isSelected={isSelected}
                      />
                    </MenuItem>
                  ))}
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                my={1.5}
                mx={2}
                style={{ minWidth: 200 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button0}
                  onClick={() => {
                    handleCloseAnchorStructure();
                    setSelected([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 7.5 }}
                  size="small"
                  className={classes.button1}
                  onClick={handleCloseAnchorStructure}
                >
                  Select ({selected.length})
                </Button>
              </Box>
            </Menu>
          </Box>
        )}
        {selected.length > 0 && (
          <>
            <Box m={1.5} mt={1}>
              <Divider />
            </Box>

            <Box
              marginRight={2}
              marginBottom={1}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                size="small"
                variant="outlined"
                className={classes.button0}
                onClick={handleCloseAnchorUnit}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.button1}
                style={{ marginLeft: 7.5 }}
                disabled={selected.length === 0}
                onClick={handleShowResult}
              >
                {loading ? "Showing..." : "Show results"}
              </Button>
            </Box>
          </>
        )}
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default UnitFilter;
