import React, { useContext } from "react";
import { Paper, Box, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import numeral from "numeral";

import CircularProgress from "./CircularProgress";
import BarProgress from "./BarProgress";
import ContextReports from "../../../../context/ContextReports";

const Businessprofile = ({ classes }) => {
  const { summaryState } = useContext(ContextReports);

  const average =
    summaryState.averages !== null &&
    summaryState.averages.length > 0 &&
    summaryState.averages.reduce((acc, curr) => {
      return acc + curr.value;
    }, 0) / summaryState.averages.length;

  /**
   *
   * Kalo ada setting dari general seting
   * (overall goal * gneral setting goal / 100) + (overall ma * gneral setting ma / 100)
   *
   * kalo ngga ada tetep pake average
   */

  // ===============================================================
  // ~~ LABEL Performance ~~
  // ===============================================================
  let labelPerformance = "";

  if (summaryState.selectedUsers.length === 1) {
    const users =
      summaryState.users.length > 0 &&
      summaryState.users.filter(
        (user) => user.structure_position.id === summaryState.selectedUsers[0]
      );
    labelPerformance = `${users[0].member.first_name} ${users[0].member.last_name}`;
  } else if (
    summaryState.selectedUnits.length === 1 &&
    summaryState.selectedUnit.name !== ""
  ) {
    const structures =
      summaryState.structureCollections.length > 0 &&
      summaryState.structureCollections.filter(
        (structure) => structure.id === summaryState.selectedUnits[0]
      );

    // // console.log("structures", structures);
    // // console.log("selectedUnit.name", summaryState.selectedUnit.name);

    labelPerformance =
      structures.length > 0
        ? structures[0].structure_unit_name
        : summaryState.selectedUnit.name;
  } else if (summaryState.selectedUnit.name !== "") {
    labelPerformance = summaryState.selectedUnit.name;
  } else {
    labelPerformance = "Overall";
  }

  return (
    <Paper className={classes.shadowSection}>
      <Box padding={3}>
        {/* ====== Title & Icon More ====== */}
        <Typography className={classes.title333} variant="subtitle1">
          <b>{labelPerformance} Performance</b>
        </Typography>
        <Box
          mt={4}
          mb={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ width: 200, height: 200 }}>
            <CircularProgress average={average} />
          </div>
        </Box>
        <Box marginBottom={3}>
          <Typography className={classes.title333} variant="subtitle2">
            {labelPerformance === "Overall"
              ? "Perusahaan anda"
              : labelPerformance}{" "}
            telah mencapai {numeral(average).format("0.00")}% dari target yang
            diharapkan
          </Typography>
        </Box>

        {/* ====== PROGRESS BAR ====== */}
        {summaryState.averages !== null &&
          summaryState.averages.length > 0 &&
          summaryState.averages.map((item) => (
            <>
              <Box display="flex" alignItems="center">
                <FiberManualRecordIcon
                  style={{
                    color:
                      item.value <= 85
                        ? "#CF364A"
                        : item.value > 85 && item.value <= 95
                        ? "#E8A700"
                        : "#35B970",
                    fontSize: 13,
                  }}
                />
                <Typography className={classes.title888} variant="caption">
                  <b>Average {item.type}</b>
                </Typography>
              </Box>
              <BarProgress classes={classes} countValue={item.value} />
            </>
          ))}
      </Box>
    </Paper>
  );
};

export default Businessprofile;
