import React, { useContext, useEffect, useState } from "react";
import { styles } from "./Style/StyleRequestAttendance";
import { navigate } from "hookrouter";

import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  Grid,
  Divider,
  Card,
  CardContent,
  Box,
  Button,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DateRange } from "@material-ui/icons";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty, now } from "lodash";

import ContextRequestAttendance from "./Context/ContextRequestAttendance";

import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";

import { isAuthenticated } from "../MyAttendance/Hook/isAuthenticated";
import RequestAttendanceDialog from "./Components/RequestAttendanceDialog";
import ErrorDialog from "../MyAttendance/Components/ErrorDialog";
import SuccessDialog from "../Account/Components/SuccessDialog";
import DialogProgress from "../Account/Components/DialogProgress";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ViewRequestAttendance = () => {
  const classes = useStyles();
  const context = useContext(ContextRequestAttendance);

  const [value, setValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [dataRaw, setDataRaw] = useState([]);
  const [dataPending, setDataPending] = useState([]);
  const [dataApproved, setDataApproved] = useState([]);

  const [requestAttendanceDialog, setRequestAttendanceDialog] = useState(false);
  const [requestAttendanceValue, setRequestAttendanceValue] = useState({
    workday: new Date(now()),
    description: "",
    clock_in: null,
    clock_out: null,
  });

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [successDialog, setSuccessDialog] = useState(false);
  const [successStatus, setSuccessStatus] = useState("");

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialog(false);
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/");
    }
    fetchRequestAttendance();
  }, []);

  const fetchRequestAttendance = async () => {
    try {
      setLoader(true);
      const response = await axiosConfig.get(
        `${URL_API}/human-resource/request-attendance/list-by-user`
      );

      setDataRaw(response.data.data);
      setDataPending(
        response.data.data.filter((item) => {
          return item.status_approve === "1";
        })
      );
      setDataApproved(
        response.data.data.filter((item) => {
          return item.status_approve !== "1";
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      const data = dataRaw.filter((item) => {
        return item.status_approve !== "1";
      });
      setDataApproved(data);
      console.log(data);
    } else if (newValue === 1) {
      const data = dataRaw.filter((item) => {
        return item.status_approve === "1";
      });
      setDataPending(data);
      console.log(data);
    }
  };

  const handleRequestDialogClose = () => {
    setRequestAttendanceDialog(false);
    setRequestAttendanceValue({
      workday: new Date(now()),
      description: "",
      clock_in: null,
      clock_out: null,
    });
  };

  const verificationRequest = async () => {
    try {
      setLoader(true);
      const data = {
        RequestAttendance: {
          date: requestAttendanceValue.workday.toISOString().split("T")[0],
          clock_in:
            moment(requestAttendanceValue.clock_in).format("HH:mm") !==
            "Invalid date"
              ? moment(requestAttendanceValue.clock_in).format("HH:mm")
              : null,
          clock_out:
            moment(requestAttendanceValue.clock_out).format("HH:mm") !==
            "Invalid date"
              ? moment(requestAttendanceValue.clock_out).format("HH:mm")
              : null,
          deskripsi: requestAttendanceValue.description,
        },
      };

      const response = await axiosConfig.post(
        `${URL_API}/human-resource/request-attendance`,
        data
      );

      if (
        response.status === 200 &&
        response.data.info.message !== "Schedule Not Found"
      ) {
        setDataRaw([response.data.data, ...dataRaw]);
        setDataPending([response.data.data, ...dataPending]);
        setRequestAttendanceDialog(false);
        setSuccessStatus("Berhasil melakukan request attendance");
        setSuccessDialog(true);
      } else {
        setErrorDialog(true);
        setErrorMessage(response.data.info.message);
        setErrorStatus("Gagal melakukan request attendance");
      }
    } catch (error) {
      setErrorDialog(true);
      setErrorMessage(error.response.data.info.message);
      setErrorStatus("Gagal melakukan request attendance");
    } finally {
      setLoader(false);
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div
          style={{
            marginLeft: 100,
            marginRight: 100,
            marginTop: 100,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <div
                style={{
                  position: "fixed",
                  width: "37%",
                  maxWidth: "inherit",
                }}
              >
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: "#262261",
                    color: "#ffffff",
                    marginTop: "1rem",
                    height: "5rem",
                  }}
                  onClick={() => {
                    setRequestAttendanceDialog(true);
                  }}
                >
                  <b>AJUKAN ATTENDANCE</b>
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={6}>
              <Typography
                component={"span"}
                variant="h5"
                style={{ fontWeight: "bold", color: "#262261" }}
              >
                History Request Attendance
              </Typography>

              <Box sx={{ width: "100%" }}>
                <Box>
                  <Tabs
                    style={{ textColor: "grey" }}
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    aria-label="report-tab"
                    variant="fullWidth"
                    TabIndicatorProps={{
                      style: {
                        borderBottom: `3px solid purple`,
                        borderRadius: "20px",
                      },
                    }}
                  >
                    <Tab label="Disetujui" />
                    <Tab label="Perlu Persetujuan" />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 15,
                      margin: "20px 0px",
                    }}
                  >
                    {dataApproved?.map((item, index) => (
                      <Card
                        style={{
                          padding: "10px",
                          color: "#221E5B",
                          borderRadius: "10px",
                        }}
                        key={index}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <DateRange />
                            <Typography component={"span"} variant="h6">
                              <Moment
                                format="dddd, D MMMM YYYY"
                                locale="id"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </Typography>
                          </div>
                          <Typography variant="subtitle1">
                            Pukul:{" "}
                            <b>{`${
                              item.clock_in !== null
                                ? moment(item.clock_in).format("hh:mm A")
                                : ""
                            } ${
                              item.clock_in === null || item.clock_out === null
                                ? ""
                                : "-"
                            } ${
                              item.clock_out !== null
                                ? moment(item.clock_out).format("hh:mm A")
                                : ""
                            }`}</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color:
                                item.status_approve !== "99"
                                  ? "#CE354A"
                                  : "#221E5B",
                            }}
                          >
                            <b>Status: {item.status_approve_name}</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 15,
                      margin: "20px 0px",
                    }}
                  >
                    {dataPending?.map((item, index) => (
                      <Card
                        style={{
                          padding: "10px",
                          color: "#221E5B",
                          borderRadius: "10px",
                        }}
                        key={index}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              gap: "10px",
                              marginBottom: "10px",
                              fontWeight: "bold",
                            }}
                          >
                            <DateRange />
                            <Typography component={"span"} variant="h6">
                              <Moment
                                format="dddd, D MMMM YYYY"
                                locale="id"
                                interval={1}
                                unit="seconds"
                              >
                                {item.workday}
                              </Moment>
                            </Typography>
                          </div>
                          <Typography variant="subtitle1">
                            Pukul:{" "}
                            <b>{`${
                              item.clock_in !== null
                                ? moment(item.clock_in).format("hh:mm A")
                                : ""
                            } ${
                              item.clock_in === null || item.clock_out === null
                                ? ""
                                : "-"
                            } ${
                              item.clock_out !== null
                                ? moment(item.clock_out).format("hh:mm A")
                                : ""
                            }`}</b>
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            style={{
                              color: "#CE354A",
                            }}
                          >
                            <b>Status: {item.status_approve_name}</b>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                  </div>
                </TabPanel>
              </Box>
            </Grid>
          </Grid>

          <RequestAttendanceDialog
            handleClose={handleRequestDialogClose}
            open={requestAttendanceDialog}
            verification={verificationRequest}
            value={requestAttendanceValue}
            setValue={setRequestAttendanceValue}
          />

          <ErrorDialog
            open={errorDialog}
            handleClose={handleErrorDialogClose}
            status={errorStatus}
            message={errorMessage}
          />

          <SuccessDialog
            open={successDialog}
            handleClose={handleSuccessDialogClose}
            status={successStatus}
          />

          <DialogProgress
            classes={classes}
            isModalProgress={loader}
            setModalProgress={setLoader}
          />
        </div>
      </div>
    </MuiThemeProvider>
  );
};

export default ViewRequestAttendance;
