import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  ListItemText,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styles } from "./Style/StyleAttendanceListRequest";
import { navigate } from "hookrouter";
import moment from "moment";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";

import ContextAttendanceListRequest from "./Context/ContextAttendanceListRequest";
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core";

import UpdateIcon from "@material-ui/icons/Update";
import CategoryIcon from "@material-ui/icons/Category";
import DialogProgress from "./Components/DialogProgress";
import { URL_API } from "../../../constants/config-api";
import axiosConfig from "../../../constants/config-axios";
import SnackbarTM from "./Components/SnackbarTM";
import DialogErrorTM from "./Components/DialogErrorTM";
import EnhancedTableToolbar from "./Components/EnhancedTableToolbar";
import { Button, Table } from "react-bootstrap";
import EnhancedTableHead from "./Components/EnhancedTableHead";
import TableFilter from "./Components/TableFilter";
import PaginationAttendanceRequest from "./Components/PaginationAttendanceRequest";
import ListCardItemAttendanceRequest from "./Components/ListCardItemAttendanceRequest";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewTimeOffCategoryAllowance = (props) => {
  const classes = useStyles();
  const context = useContext(ContextAttendanceListRequest);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const [roleName, setRoleName] = useState([]);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (
      statusUserLoginAfterParse !== null &&
      statusUserLoginAfterParse !== undefined
    ) {
      // setFotoQueryUser(statusUserLoginAfterParse.member_photo_url);

      if (statusUserLoginAfterParse.member_position && statusUserLoginAfterParse.member_position.is_superior) {
        setRoleName('manage');
      }

      if (statusUserLoginAfterParse.userRole.length > 0) {
        if (statusUserLoginAfterParse.userRole[0].name === "HR") {
          setRoleName('hr');
        }
        if (statusUserLoginAfterParse.userRole[0].name === "superadmin") {
          setRoleName(statusUserLoginAfterParse.userRole[0].name);
        }
        if (statusUserLoginAfterParse.userRole.length > 1) {
          if (statusUserLoginAfterParse.userRole[1].name === "admin") {
            setRoleName(statusUserLoginAfterParse.userRole[1].name);
          }
        }
      } else {
        // console.log("Anda tidak memiliki ROLE sama sekali, hubungi Mas Tri !");
      }
    }
    getRequestAttendance();
  }, []);

  const [masterCategory, setMasterCategory] = useState({
    masterStatusCollections: [
      {
        id: "0",
        name: "Rejected",
      },
      {
        id: "1",
        name: "Waiting Approve by SPV",
      },
      {
        id: "2",
        name: "Waiting Approve by HR",
      },
      {
        id: "99",
        name: "Approved",
      },
    ],
  });

  const [dataRequestList, setDataRequestList] = useState({
    options: {
      filter: {
        search: "",
        status: "",
        status_obj: null,
      },
      paging: {
        limit: 5,
        offset: 0,
        count: 0,
        page: 1,
      },
    },
    list: [],
  });

  const getRequestAttendance = () => {
    context.setLoader(true);
    axiosConfig
      .get(
        `${URL_API}/human-resource/request-attendance/list` +
          `?options[filter][search]=${dataRequestList.options.filter.search}` +
          `&options[filter][status]=${dataRequestList.options.filter.status}` +
          `&options[paging][limit]=${dataRequestList.options.paging.limit}` +
          `&options[paging][offset]=${dataRequestList.options.paging.offset}`
      )
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        let dataRequestListTemporary = dataRequestList;
        dataRequestListTemporary.list = response.data.data;
        dataRequestListTemporary.options.paging.count =
          response.data.info.count;
        setDataRequestList({ ...dataRequestListTemporary });
        context.setLoader(false);
      })
      .catch(function (error) {
        context.setLoader(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleApprove = (item) => {
    context.setModalProgress(true);
    axiosConfig
      .post(`${URL_API}/human-resource/request-attendance/approve/${item.id}`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Approve berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const handleReject = (item) => {
    context.setModalProgress(true);
    axiosConfig
      .post(`${URL_API}/human-resource/request-attendance/reject/${item.id}`)
      .then(function (response) {
        // console.log("Response Original : ", response.data);
        setAnchorEl(null);
        setAnchorIn(null);
        context.setModalProgress(false);
        context.setMessages("Reject berhasil");
        context.setSnackbarResponse200(true);
        refreshList(null, 0, 1, null);
      })
      .catch(function (error) {
        context.setModalProgress(false);
        // console.log("Error : ", error.response);
        if (error.response !== undefined) {
          if (error.response.status === 429) {
            context.setErrorStatus(429);
            context.setTextErrorInformation("Too Many Request !");
            context.setOpenDialogError(true);
          }
          if (
            error.response.data &&
            error.response.data.info &&
            error.response.data.info.message
          ) {
            context.setErrorStatus(error.response.data.info.status);
            context.setTextErrorInformation(error.response.data.info.message);
            context.setOpenDialogError(true);
          }
        }
      });
  };

  const [anchorEl, setAnchorEl] = useState();
  const [anchorIn, setAnchorIn] = useState();

  // Function Show Dropdown List
  function handleDropdownOpen(event, index) {
    setAnchorEl(event.currentTarget);
    setAnchorIn(index);
  }

  // Function Close Dropdown List
  function handleDropdownClose() {
    setAnchorEl(null);
    setAnchorIn(null);
  }

  const handleChangePage = (event, newPage) => {
    refreshList(
      null,
      dataRequestList.options.paging.limit * (newPage - 1),
      newPage,
      null
    );
  };

  const handleChangeRowsPerPage = (event) => {
    refreshList(null, 0, 1, parseInt(event.target.value));
  };

  const refreshList = (search, offset, page, limit) => {
    let dataRequestListTemporary = dataRequestList;
    dataRequestListTemporary.options.filter.search =
      search !== null ? search : dataRequestListTemporary.options.filter.search;
    dataRequestListTemporary.options.paging.offset =
      offset !== null ? offset : dataRequestListTemporary.options.paging.offset;
    dataRequestListTemporary.options.paging.page =
      page !== null ? page : dataRequestListTemporary.options.paging.page;
    dataRequestListTemporary.options.paging.limit =
      limit !== null ? limit : dataRequestListTemporary.options.paging.limit;
    setDataRequestList({ ...dataRequestListTemporary });
    getRequestAttendance();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Box marginLeft={theme.spacing(1.2)} marginTop={theme.spacing(1.5)}>
        <TableFilter
          classes={classes}
          masterCategory={masterCategory}
          dataRequestList={dataRequestList}
          setDataRequestList={setDataRequestList}
          getRequestAttendance={getRequestAttendance}
        />
        <EnhancedTableHead classes={classes} />

        {context.loader ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={15}
          >
            <CircularProgress
              size={20}
              style={{ marginRight: 10, color: "#d64253" }}
            />
            <Typography className={classes.title555} variant="subtitle1">
              Loading...
            </Typography>
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="enhanced table"
            style={{ marginTop: "16px", paddingRight: "24px" }}
          >
            <TableBody mt={2}>
              {dataRequestList &&
                dataRequestList.list &&
                dataRequestList.list.length > 0 &&
                dataRequestList.list.map((item, i) => {
                  return (
                    <ListCardItemAttendanceRequest
                      keyItem={i}
                      key={i}
                      classes={classes}
                      item={item}
                      dataRequestList={dataRequestList}
                      handleApprove={handleApprove}
                      handleReject={handleReject}
                      userTokenState={context.userTokenStateGlobal}
                      roleName={roleName}
                    />
                  );
                })}
            </TableBody>
          </Table>
        )}

        {dataRequestList.list.length === 0 && context.loader !== true && (
          <Box width="100%" style={{ paddingRight: 24 }}>
            <Paper
              elevation={1}
              className={classes.paperList}
              style={{ paddingTop: "8px", paddingBottom: "8px" }}
            >
              <Typography
                variant="subtitle2"
                className={classes.title555}
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  textAlign: "center",
                }}
              >
                <IconButton disabled>
                  <UpdateIcon style={{ color: "#d64253" }} />
                </IconButton>
                <b>
                  Tidak ada Request Attendance yang dapat di tampilkan saat ini
                  ;(
                </b>
              </Typography>
            </Paper>
          </Box>
        )}

        <Box ml={1} mr={2}>
          <PaginationAttendanceRequest
            classes={classes}
            dataRequestList={dataRequestList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
      <DialogProgress
        classes={classes}
        isModalProgress={context.isModalProgress}
        setModalProgress={context.setModalProgress}
      />
      <SnackbarTM
        isModalResponse200={context.isSnackbarResponse200}
        setModalResponse200={context.setSnackbarResponse200}
        messages={context.messages}
      />
      <DialogErrorTM
        classes={classes}
        isOpenDialogError={context.isOpenDialogError}
        setOpenDialogError={context.setOpenDialogError}
        textErrorInformation={context.textErrorInformation}
        errorStatus={context.errorStatus}
        listError={context.listError}
      />
    </MuiThemeProvider>
  );
};

export default ViewTimeOffCategoryAllowance;
