import React, { useState } from "react";
import { Typography, Box, Slider, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { bahasa, english } from "../../../../../constants/config-lang";
import MyLang from "../../../../../utilities/MyLang";

const SimpleSlider = withStyles({
  root: {
    width: 300,
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    border: "1px solid #888",
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  track: {
    height: 5,
    borderRadius: 4,
    color: "#d1354a",
  },
  rail: {
    height: 5,
    borderRadius: 4,
    color: "#10266F",
  },
})(Slider);

const PerformanceSettings = ({
  classes,
  goalFactor,
  maFactor,
  onGoalFactor,
  onMaFactor,
  onReadyData,
  lockSetting,
}) => {
  const handleFactor = (e, value) => {
    const valueMa = 100 - value;
    onGoalFactor(value);
    onMaFactor(valueMa);

    onReadyData("open");
  };

  const handleGoalFactor = (e) => {
    const value = e.target.value;
    const valueMa = 100 - value;
    onGoalFactor(value);
    onMaFactor(valueMa);

    onReadyData("open");
  };

  const handleMaFactor = (e) => {
    const value = e.target.value;
    const valueGoal = 100 - value;
    onMaFactor(value);
    onGoalFactor(valueGoal);

    onReadyData("open");
  };

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title333}>
        <b>{MyLang() ? bahasa.overallPerform : english.overallPerform}</b>
      </Typography>
      <Box my={1} />
      <Typography variant="caption" className={classes.title888}>
        {MyLang() ? bahasa.descOverallPerform : english.descOverallPerform}
      </Typography>

      <Box mt={4} display="flex" alignItems="center">
        <Typography variant="subtitle2" className={classes.title888}>
          <b>Goal {MyLang() ? bahasa.factor : english.factor}</b>
        </Typography>

        <Box mx={1} />
        <TextField
          size="small"
          type="number"
          variant="outlined"
          style={{ width: 75 }}
          value={goalFactor}
          onChange={handleGoalFactor}
          disabled={lockSetting}
        />
        <Box mx={1} />

        <SimpleSlider
          value={goalFactor}
          onChange={handleFactor}
          disabled={lockSetting}
        />

        <Box mx={1} />
        <TextField
          size="small"
          type="number"
          variant="outlined"
          style={{ width: 75 }}
          value={maFactor}
          onChange={handleMaFactor}
          disabled={lockSetting}
        />
        <Box mx={1} />

        <Typography variant="subtitle2" className={classes.title888}>
          <b> MA {MyLang() ? bahasa.factor : english.factor}</b>
        </Typography>
      </Box>
    </div>
  );
};

export default PerformanceSettings;
