export const PROJECT_TEAM_COLLECTIONS = "PROJECT_TEAM_COLLECTIONS";
export const PROJECT_TEAM_DETAILS = "PROJECT_TEAM_DETAILS";
export const TASK_TEAM_COLLECTIONS = "TASK_TEAM_COLLECTIONS";
export const DRAWER_OPEN = "DRAWER_OPEN";
export const SET_TASK_DETAILS = "SET_TASK_DETAILS";
export const SEARCH_PROJECT = "SEARCH_PROJECT";
export const SEARCH_TASK = "SEARCH_TASK";
export const SET_PAGINATE_PROJECT = "SET_PAGINATE_PROJECT";
export const SET_PAGINATE_TASK = "SET_PAGINATE_TASK";
export const TOGGLE_PROJECT = "TOGGLE_PROJECT";
export const GANTT_MAP_FORM = "GANTT_MAP_FORM";
export const HIDE_TASK_GROUP = "HIDE_TASK_GROUP";
export const FILTER_TASK = "FILTER_TASK";
export const SORT_TASK = "SORT_TASK";
export const ASSIGNEE_CONTENT_FILTER = "ASSIGNEE_CONTENT_FILTER";
export const FILTER_PROJECT = "FILTER_PROJECT";
export const THE_OWNER_CONTENT = "THE_OWNER_CONTENT";
export const SORT_NAME_PROJECT = "SORT_NAME_PROJECT";
export const GROUP_BY_IN_LIST = "GROUP_BY_IN_LIST";

// ==== KANBAN ====
export const GET_KANBAN = "GET_KANBAN";
export const SEARCH_CARDS = "SEARCH_CARDS";
export const DRAWER_KANBAN = "DRAWER_KANBAN";
export const SET_GROUP_BY = "SET_GROUP_BY";
export const OFFLINE_MODE = "OFFLINE_MODE";
