import React, { useState } from "react";
import { Box, MenuItem, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import axiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import handleError from "../../screen/Report/components/global/handleError";
import DialogError from "../../components/DialogError";
import moment from "moment";
import { navigate } from "hookrouter";

const ListNotification = ({ classes, item, setTrigerNotif }) => {
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );
  // ==========================================================
  // VARS
  let TITLE_NOTIF = "";
  let ENTYTY_NAME = "";
  let ENTITIES = [];

  if (item?.data?.hasOwnProperty("entityType")) {
    TITLE_NOTIF = item?.data?.entityType;
    ENTYTY_NAME = "";
    ENTITIES = item?.data?.entities;
  }

  if (item?.data?.hasOwnProperty("message_type_name")) {
    TITLE_NOTIF = item?.data?.message_type_name;
    ENTYTY_NAME = item?.data?.entity_name;
    ENTITIES = [];
  }

  const handleRead = async () => {
    if (item.read_at === null) {
      try {
        const response = await axiosConfig.put(
          `${URL_API}/account-management/user/notifications/read/${item.id}`
        );

        if (response.status === 200) {
          setTrigerNotif(Math.floor(Math.random() * 1000 + 1));

          if (item?.data?.hasOwnProperty("entity_id")) {
            if (item?.data?.message_type_code?.includes("goal")) {
              navigate(`/goal-detail/${item?.data?.entity_id}`);
            }
            if (item?.data?.message_type_code?.includes("ma")) {
              navigate(`/allma-detail/${item?.data?.entity_id}`);
            }
          }
        }
      } catch (error) {
        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      }
    } else {
      if (item?.data?.hasOwnProperty("entity_id")) {
        if (item?.data?.message_type_code?.includes("goal")) {
          navigate(`/goal-detail/${item?.data?.entity_id}`);
        }
        if (item?.data?.message_type_code?.includes("ma")) {
          navigate(`/allma-detail/${item?.data?.entity_id}`);
        }
      }
    }
  };

  return (
    <MenuItem
      onClick={handleRead}
      style={{
        cursor: "pointer",
        backgroundColor: item.read_at === null && "#f8fbfd",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 2,
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="subtitle2" className={classes.title333}>
          {TITLE_NOTIF}
        </Typography>
        <Typography variant="caption" className={classes.title555}>
          {ENTYTY_NAME}
        </Typography>
        {ENTITIES.length > 0 &&
          ENTITIES.map((item) => (
            <Typography
              key={item.id}
              variant="caption"
              className={classes.title555}
            >
              - {item.name}
            </Typography>
          ))}

        <Typography
          variant="caption"
          className={classes.title888}
          style={{ color: item.read_at === null && "#6faee7" }}
        >
          {moment(item?.updated_at).fromNow()}
        </Typography>
      </Box>

      <div>
        {item.read_at === null && (
          <FiberManualRecordIcon style={{ color: "#6faee7", fontSize: 10 }} />
        )}
      </div>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MenuItem>
  );
};

export default ListNotification;
