import axios from "axios";

const userToken = localStorage.getItem("userToken");
const instance = axios.create({});
// baseURL: 'https://api-dev.performate.id/api/v1'

instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.common["Content-Type"] = "application/json";
instance.defaults.headers.common["Authorization"] = "bearer " + userToken;
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
instance.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Origin, X-Requested-With, Content-Type, Accept";
instance.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET,PUT,POST,DELETE,PATCH,OPTIONS";

export default instance;
