const mapTable = (arr) => {
  // // console.log("ARR DATS", arr);

  const data =
    arr !== undefined &&
    arr.length > 0 &&
    arr.map((item) => {
      return {
        full_name: {
          content: item.full_name,
          goal_ids: item.summary_goal_ids,
          ma_ids: item.summary_ma_ids,
        },
        structure_unit_name: {
          content: item.structure_unit_name,
          goal_ids: item.summary_goal_ids,
          ma_ids: item.summary_ma_ids,
        },
        position_title: {
          content: item.position_title,
          goal_ids: item.summary_goal_ids,
          ma_ids: item.summary_ma_ids,
        },
        goal: {
          content: item.goal,
          goal_ids: item.summary_goal_ids,
        },
        ma: {
          content: item.ma,
          ma_ids: item.summary_ma_ids,
        },
        goal_input_rate: item.goal_input_rate,
        ma_input_rate: item.ma_input_rate,
      };
    });

  return data.length > 0 ? data : [];
};

export default mapTable;
