import React, { useState, useEffect } from "react";

import ContextGoal from "./ContextGoal";

const ContextGlobalGoal = (props) => {
  /*
        ````````````````
        USER TOKEN STATE

        ````````````````
    */
  const [userTokenStateGlobal, setUserTokenStateGlobal] = useState("");

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");
    setUserTokenStateGlobal(userToken);

    // statusGoalId
    const statusGoalIdInLocalStorage = localStorage.getItem("status_goal_id");

    const statusTab = localStorage.getItem("location_tab");

    if (
      statusGoalIdInLocalStorage !== null &&
      statusTab !== null &&
      statusTab !== "You"
    ) {
      setDataTabActive({
        id: 1,
        label: "All",
      });

      // if (statusGoalIdInLocalStorage === "1") {
      //   setStatusGoalId(1); //*Draft
      //   // setStatusGoalIdinYou(1) //*Draft

      //   setValueTab(0);
      //   // setValueTabStatusGoalInYou(0)
      // }

      if (statusGoalIdInLocalStorage === "2") {
        setStatusGoalId(2); //*Approved
        // setStatusGoalIdinYou(2) //*Approved

        setValueTab(0);
        // setValueTabStatusGoalInYou(1)
      }

      if (statusGoalIdInLocalStorage === "3") {
        setStatusGoalId(3); //*Active
        // setStatusGoalIdinYou(3) //*Active

        setValueTab(1);
        // setValueTabStatusGoalInYou(2)
      }

      if (statusGoalIdInLocalStorage === "6") {
        setStatusGoalId(6); //*Overdue
        // setStatusGoalIdinYou(6) //*Overdue

        setValueTab(2);
        // setValueTabStatusGoalInYou(3)
      }

      if (statusGoalIdInLocalStorage === "4") {
        setStatusGoalId(4); //*Completed
        // setStatusGoalIdinYou(4) //*Completed

        setValueTab(3);
        // setValueTabStatusGoalInYou(4)
      }
    } else {
      setDataTabActive({
        id: 2, //*self
        label: "You",
      });

      // if (statusGoalIdInLocalStorage === "1") {
      //   // setStatusGoalId(1) //*Draft
      //   setStatusGoalIdinYou(1); //*Draft

      //   // setValueTab(0)
      //   setValueTabStatusGoalInYou(0);
      // }

      if (statusGoalIdInLocalStorage === "2") {
        // setStatusGoalId(2) //*Approved
        setStatusGoalIdinYou(2); //*Approved

        // setValueTab(0)
        setValueTabStatusGoalInYou(0);
      }

      if (statusGoalIdInLocalStorage === "3") {
        // setStatusGoalId(3) //*Active
        setStatusGoalIdinYou(3); //*Active

        // setValueTab(1)
        setValueTabStatusGoalInYou(1);
      }

      if (statusGoalIdInLocalStorage === "6") {
        // setStatusGoalId(6) //*Overdue
        setStatusGoalIdinYou(6); //*Overdue

        // setValueTab(2)
        setValueTabStatusGoalInYou(2);
      }

      if (statusGoalIdInLocalStorage === "4") {
        // setStatusGoalId(4) //*Completed
        setStatusGoalIdinYou(4); //*Completed
        // setValueTab(3)
        setValueTabStatusGoalInYou(3);
      }
    }
  }, []);

  const [paramsFilterGoal, setParamsFilteGoal] = useState(3); // Active = 3, Overdue = 6

  const [collectionGoalList, setCollectionGoalList] =
    useState(listCollectionGoal);
  /*
        ``````````````````
        HANDLE PAGINATION 

        ``````````````````
    */
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const [offset, setOffset] = useState(0);
  const [loaderForPaging, setLoaderForPaging] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  // PRIOD
  const [period, setPeriod] = useState({ id: "", name: "" });

  const [goalCategory, setGoalCategory] = useState(
    "&options[filter][goal_category][1]=OPERATIONAL"
  );
  const [projectType, setProjectType] = useState("");

  /*
        ```````````````````````
        HANDLE NEW REFACTOR-MAN

        ```````````````````````
    */
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [loader, setLoader] = useState(false);

  const [dataTabActive, setDataTabActive] = useState(
    {
      id: 1, //*self
      label: "All",
    }
    // {
    //   id: 1,
    //   label: 'All'
    // }
  );

  const [paramsUrlGoal, setParamsUrlGoal] = useState(
    "options[filter][scope]=self"
  );
  const [lengthGoalCount, setLengthGoalCount] = useState(0);
  const [limitGoalCount, setLimitGoalCount] = useState(10);
  const [offsetGoalCount, setOffsetGoalCount] = useState(0);

  /*
        ````````````````````````````
        HANDLE NEW IN VIEW ALL & YOU

        ````````````````````````````
    */
  const [isModalCreate, setModalCreate] = useState(false);
  const [fotoQuery, setFotoQuery] = useState("");
  const [userLoginName, setUserLoginName] = useState("");
  const [memberPositionList, setMemberPositionList] = useState([]);
  const [isSearchRun, setSearchRun] = useState(false);
  const [statusGoalId, setStatusGoalId] = useState(3); //Default Aktif = 3
  const [statusGoalIdInYou, setStatusGoalIdinYou] = useState(3); //Default Aktif = 3

  const [clearSearchResult, setClearSearchResult] = useState(false);

  const [valueTab, setValueTab] = useState(1);
  const [valueTabStatusGoalInYou, setValueTabStatusGoalInYou] = useState(1);

  const [successCreateGoal, setSuccessCreateGoal] = useState(null);

  const [successDeleteGoal, setSuccessDeleteGoal] = useState(false);

  const [weightValue, setWeightValue] = useState({ min: 0, max: 100 });
  const [weightValueAll, setWeightValueAll] = useState({ min: 0, max: 100 });

  /*
      ```````````````````````````````````
      HANDLE EXPORT NEW IN VIEW ALL & YOU

      ```````````````````````````````````
  */
  const [isModalExportGoal, setModalExportGoal] = useState(false);
  /*
        ````````````````````
        HANDLE SNACKBEER 

        ````````````````````
    */
  const [isOpenSnackbeer, setOpenSnackbeer] = useState(false);
  const [messages, setMessages] = useState("");
  const [successComplete, setSuccessComplete] = useState(null);

  // LIST GOAL STATE MULTIPLE
  const [listGoal, setListGoal] = useState([]);

  // ======================================================================
  // Trigger GOAL COLLECTION
  const [triggerGoals, setTriggerGoals] = useState(null);

  return (
    <ContextGoal.Provider
      value={{
        userTokenStateGlobal: userTokenStateGlobal,

        page: page,
        setPage: setPage,

        rowsPerPage: rowsPerPage,
        setRowsPerPage: setRowsPerPage,

        offset: offset,
        setOffset: setOffset,

        loaderForPaging: loaderForPaging,
        setLoaderForPaging: setLoaderForPaging,

        valueSearch: valueSearch,
        setValueSearch: setValueSearch,

        paramsFilterGoal: paramsFilterGoal,
        setParamsFilteGoal: setParamsFilteGoal,

        //*
        isShowDateRangeComponent: isShowDateRangeComponent,
        setShowDateRangeComponent: setShowDateRangeComponent,

        collectionGoalList: collectionGoalList,
        setCollectionGoalList: setCollectionGoalList,

        loader: loader,
        setLoader: setLoader,

        dataTabActive: dataTabActive,
        setDataTabActive: setDataTabActive,

        paramsUrlGoal: paramsUrlGoal,
        setParamsUrlGoal: setParamsUrlGoal,

        lengthGoalCount: lengthGoalCount,
        setLengthGoalCount: setLengthGoalCount,

        //*
        isModalCreate: isModalCreate,
        setModalCreate: setModalCreate,

        fotoQuery: fotoQuery,
        setFotoQuery: setFotoQuery,

        userLoginName: userLoginName,
        setUserLoginName: setUserLoginName,

        memberPositionList: memberPositionList,
        setMemberPositionList: setMemberPositionList,

        isSearchRun: isSearchRun,
        setSearchRun: setSearchRun,

        statusGoalId: statusGoalId,
        setStatusGoalId: setStatusGoalId,

        statusGoalIdInYou: statusGoalIdInYou,
        setStatusGoalIdinYou: setStatusGoalIdinYou,

        clearSearchResult: clearSearchResult,
        setClearSearchResult: setClearSearchResult,

        valueTab: valueTab,
        setValueTab: setValueTab,

        valueTabStatusGoalInYou: valueTabStatusGoalInYou,
        setValueTabStatusGoalInYou: setValueTabStatusGoalInYou,

        isModalExportGoal: isModalExportGoal,
        setModalExportGoal: setModalExportGoal,

        successCreateGoal: successCreateGoal,
        setSuccessCreateGoal: setSuccessCreateGoal,

        successDeleteGoal,
        setSuccessDeleteGoal,

        isOpenSnackbeer,
        setOpenSnackbeer,

        messages,
        setMessages,

        successComplete,
        setSuccessComplete,

        listGoal,
        setListGoal,

        weightValue,
        setWeightValue,

        weightValueAll,
        setWeightValueAll,

        period,
        setPeriod,

        goalCategory,
        setGoalCategory,

        projectType,
        setProjectType,

        limitGoalCount,
        setLimitGoalCount,

        offsetGoalCount,
        setOffsetGoalCount,

        triggerGoals,
        setTriggerGoals,
      }}
    >
      {props.children}
    </ContextGoal.Provider>
  );
};

export default ContextGlobalGoal;

//*
let listCollectionGoal = [
  {
    id: null,
    name: "",
    goal_type_id: "",
    owner_id: "",
    achievement_type_id: "",
    value_type_id: "",
    start_value: "",
    target_value: "",
    target_range_value: null,
    tolerance_value: "",
    weight_value: "",
    input_method_id: "",
    calculation_method_id: "",
    direct_link_main_goal_id: "",
    direct_link_node_level: null,
    update_interval_id: "",
    update_interval_trigger_value: null,
    period_id: "-",
    start_date: "-",
    end_date: "-",
    completed_timestamp: null,
    time_frame_id: "",
    goal_mode_id: "",
    visibility_type_id: "",
    last_goal_result_date: "",
    status_id: "",
    description: null,
    created_by: "",
    updated_by: null,
    deleted_by: null,
    created_at: "",
    updated_at: "",
    deleted_at: null,
    is_used: true,
    period: {
      id: "",
      name: "-",
      period_group_id: null,
      sequence: null,
      prev_id: null,
      next_id: null,
      interval_id: "",
      start_date: "",
      end_date: "",
      status_id: "",
      created_by: "",
      updated_by: null,
      deleted_by: null,
      created_at: "",
      updated_at: "",
      deleted_at: null,
      self: {
        rel: {
          prev: null,
          next: null,
          periodGroup: null,
        },
      },
    },
    achievementType: {
      id: "",
      code: "",
      name: "",
    },
    inputMethod: {
      id: "",
      code: "",
      name: "",
    },
    calculationMethod: {
      id: "",
      code: "",
      name: "",
    },
    goalMode: {
      id: "",
      code: "",
      name: "",
    },
    goalType: {
      id: "",
      code: "",
      name: "",
    },
    owner: {
      id: "",
      parent_id: "",
      prev_sibling_id: null,
      next_sibling_id: null,
      code: "",
      member_id: "",
      member_first_name: "-",
      member_last_name: "-",
      user_id: "",
      user_email: "",
      structure_unit_type_id: null,
      structure_unit_type_name: null,
      structure_unit_id: null,
      structure_unit_name: null,
      structure_position_title_id: "",
      structure_position_title_name: "-",
      classification_id: null,
      classification_name: "",
      node_level: null,
      created_by: "",
      updated_by: "",
      deleted_by: null,
      created_at: "",
      updated_at: "",
      deleted_at: null,
      self: {
        rel: null,
      },
    },
    member: {
      id: "",
      first_name: "",
      last_name: "",
      nickname: null,
      photo_url: null,
      self: {
        rel: {
          photo_url: null,
        },
      },
    },
    directLinkMainGoal: {
      id: "",
      name: "",
    },
    startValueSyncStatus: {
      id: "",
      code: "",
      name: "",
    },
    targetValueSyncStatus: {
      id: "",
      code: "",
      name: "",
    },
    status: {
      id: "",
      code: "",
      name: "",
      sort_order: null,
    },
    updateInterval: {
      id: "",
      name: "",
      code: "",
      datetime_unit: null,
      datetime_value: null,
      sort_order: null,
    },
    valueType: {
      id: "",
      code: "",
      name: "",
    },
    visibilityType: {
      id: "",
      code: "",
      name: "",
    },
    calculatedValue: {
      start_value: "",
      target_value: "",
      input_value: "",
      target_value_achievement: "",
      target_range_value: "",
      resultOriented: {
        result_value: "",
        result_value_achievement: "",
        expected_result_value: "",
        expected_result_value_achievement: "",
        gap_result_value: "",
        gap_result_value_achievement: "",
        overall_result_achievement: "",
      },
      actualOriented: {
        actual_value: "",
        actual_value_achievement: "",
        expected_actual_value: "",
        expected_actual_value_achievement: "",
        gap_actual_value: "",
        gap_actual_value_achievement: "",
        overall_actual_achievement: "",
      },
      day_offset: null,
      date_offset: "",
      start_date: "",
      end_date: "",
    },
    time: {
      elapsed_days: null,
      remaining_days: null,
      total_days: null,
      start_date: "",
      end_date: "",
    },
    timeFrame: {
      id: "",
      code: "",
      name: "",
    },
    self: {
      rel: null,
    },
  },
];
