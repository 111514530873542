import React, { useContext, useState } from "react";
import { Button, Menu, MenuItem, Box, Divider } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";

import moment from "moment";
// import "moment/locale/en-SG";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { URL_API } from "../../../../../../constants/config-api";
import AxiosConfig from "../../../../../../constants/config-axios";
import ContextReports from "../../../../context/ContextReports";

import { defaultStaticRanges } from "../../detailed-type/grouping/date-range/customRanges";
import ListItemDateRange from "./ListItemDateRange";
import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_DATE_RANGE,
  SET_PAGINATING,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  SET_SUMMARY_TAB,
  GET_DATA_DETAIL,
} from "../../../../context/types";
import DialogError from "../../../../../../components/DialogError";
import handleError from "../../../global/handleError";
import mapTable from "../../../global/mapTable";

const DateRangeFilter = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    barDispatch,
    pieDispatch,
    filterState,
  } = useContext(ContextReports);

  const [dateRange, setDateRange] = useState({
    title: "",
    code: "",
    length: null,
    startDate: moment(summaryState.dateRange.startDate),
    endDate: moment(summaryState.dateRange.endDate),
  });
  const [anchorDate, setAnchorDate] = useState(null);
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleSelectDateRange = (data) => {
    summaryDispatch({
      type: SET_DATE_RANGE,
      payload: {
        dateRange: {
          title: data.name,
          code: data.code,
          startDate: moment(data.date_option.start_date).format("DD MMM YYYY"),
          endDate: moment(data.date_option.end_date).format("DD MMM YYYY"),
        },
      },
    });
    setDateRange({
      ...dateRange,
      title: data.name,
      code: data.code,
      startDate: data.date_option.start_date,
      endDate: data.date_option.end_date,
    });
  };

  // Show Dialog Date Range
  const handleShowAnchorDate = (event) => {
    setAnchorDate(event.currentTarget);
  };

  // Hide Dialog Date Range
  const handleCloseAnchorDate = () => {
    setAnchorDate(null);

    const get_range = localStorage.getItem("summary_range");
    const date_range = JSON.parse(get_range);

    // Date Range
    summaryDispatch({
      type: SET_DATE_RANGE,
      payload: {
        dateRange: {
          title: date_range.title,
          code: date_range.code,
          startDate: moment(date_range.startDate).format("DD MMM YYYY"),
          endDate: moment(date_range.endDate).format("DD MMM YYYY"),
        },
      },
    });
  };

  const handleShowResult = () => {
    localStorage.setItem("summary_range", JSON.stringify(dateRange));

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    let newAchievement = [];

    if (
      summaryState.selectedEntities.includes("goal") &&
      summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal, ...refMa];
    }

    if (
      summaryState.selectedEntities.includes("goal") &&
      !summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal];
    }

    if (
      summaryState.selectedEntities.includes("ma") &&
      !summaryState.selectedEntities.includes("goal")
    ) {
      newAchievement = [...refMa];
    }

    // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: dateRange.code,
        user: summaryState.selectedUsers,
        unit: summaryState.selectedUnits,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
        period_id: summaryState.selectedPeriod.id,
      },
      entities: summaryState.selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [summaryState.selectedOrder],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    setLoading(true);
    setAnchorDate(null);

    // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
    const pagination = tableSmrState.paginating;
    const currentPage = pagination.currentPage;
    const perPage = pagination.perPage;

    // ================= GET INITIAL DATA TABLE SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        //   // console.log("RES SUMMARY TABLE", result);

        const DATATABLE = mapTable(result.results.data);

        // // console.log("RES DATATABLE", DATATABLE);
        // // console.log("RES result.results.data", result.results.data);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                // dataTable: result.results.data,
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Ovaerge Company =============
    const dataAvg = {
      ...dataSummary,
      report_format: "summary",
    };

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataAvg
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          summaryDispatch({
            type: GET_AVG_COMPANY,
            payload: {
              averages: result.results,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });

          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        className={classes.configBtn}
        size="small"
        startIcon={<DateRangeIcon style={{ fontSize: 15 }} />}
        disabled={tableSmrState.loading}
        onClick={handleShowAnchorDate}
      >
        {summaryState.dateRange.title}
      </Button>

      {/* ====== Menu DropDown Date range ====== */}
      <Menu
        id="simple-menu"
        anchorEl={anchorDate}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorDate)}
        onClose={handleCloseAnchorDate}
      >
        {/* Render List Date range Item */}
        {filterState.filterRanges.length > 0 &&
          filterState.filterRanges.map((item, i) => (
            <MenuItem key={i} style={{ margin: 0, padding: "0 10px" }} dense>
              <ListItemDateRange
                classes={classes}
                item={item}
                range={summaryState.dateRange}
                onHandleRange={handleSelectDateRange}
              />
            </MenuItem>
          ))}
        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorDate}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            onClick={handleShowResult}
            disabled={dateRange.code === ""}
          >
            {loading ? "Loading..." : "Show results"}
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default DateRangeFilter;
