import React, { useEffect, useState } from "react";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Link,
} from "@material-ui/core";
import preval from "preval.macro";

import { version } from "../../package.json";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CloseIcon from "@material-ui/icons/Close";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";

import PictInfoWarningRed from "../assets/images/icon-info-24px.svg";
import Pict400 from "../assets/images/400.png";
import Pict500 from "../assets/images/500_wow.png";
import IconWarningYellow from "../assets/images/icon_warning_yellow.png";

import { styles } from "./Style/StyleDialogHelp";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const useStyle = makeStyles(styles);

const DialogHelp = (props) => {
  const { isOpenDialogHelp, setOpenDialogHelp } = props;
  const [buildTime, setbuildTime] = useState();
  const classes = useStyle();

  useEffect(() => {
    setbuildTime(preval`module.exports = new Date().toLocaleString();`);
  }, []);

  return (
    <Dialog
      open={isOpenDialogHelp}
      onClose={() => setOpenDialogHelp(false)}
      aria-labelledby="alert-dialog-title-422"
      aria-describedby="alert-dialog-description-422"
      // fullWidth
    >
      <DialogTitle id="alert-dialog-title-422" style={{ textAlign: "center" }}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start">
          <IconButton size="small" onClick={() => setOpenDialogHelp(false)}>
            <HighlightOffIcon style={{ color: "grey", fontSize: "17px" }} />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent style={{ textAlign: "center" }}>
        <DialogContentText id="text">
          <Typography variant="h6" className={classes.title}>
            <b>Kami senang jika bisa membantu Anda :)</b>
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogContent style={{ textAlign: "left" }}>
        <Paper
          elevation={1}
          style={{ backgroundColor: "transparant", padding: 16 }}>
          <Box display="flex" flexDirection="row">
            <Typography
              variant="subtitle1"
              className={classes.title}
              style={{ color: "grey" }}>
              Kirim pertanyaan, keluhan, atau usulan Anda ke email support kami
              ke
              <b>
                <i style={{ color: "red" }}>
                  <a
                    href="mailto:cs@performate.id"
                    style={{ color: "red", textDecoration: "none" }}>
                    &nbsp;cs@performate.id
                  </a>
                </i>
              </b>
              , atau kirim peasan via{" "}
              <Link
                href="https://api.whatsapp.com/send?phone=6281212030314&text=Halo%20Performate"
                component="a"
                target="__blank"
                style={{ fontSize: 15, color: "green" }}>
                WhatsAapp
              </Link>{" "}
              . Pesan Anda akan kami balas maksimal dalam waktu 24 jam{" "}
              <i>(Office Hour)</i>
            </Typography>
          </Box>
        </Paper>
        <Typography variant="subtitle2" align="right" style={{ marginTop: 10 }}>
          Anda sedang menggunakan performate (TEST)
          <div>
            Versi: <strong>{version}</strong>
          </div>
          <div>
            Build time: <strong>{buildTime}</strong>
          </div>
        </Typography>
      </DialogContent>
      <br />
      <br />
    </Dialog>
  );
};

export default DialogHelp;
