import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Lato", "Lato"].join(","),
  },
  button: {
    fontFamily: ["Lato", "Lato"].join(","),
  },
});

export const styles = () => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
  },
  rootLinearProgress: {
    width: "100%",
  },
  layoutMarginLeftAndTop: {
    width: "100%",
    marginTop: 56,
    paddingLeft: 72,
    maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  fixedLayout: {
    width: "100%",
    maxWidth: 2192,
    marginLeft: "auto",
    marginRight: "auto",
  },
  layoutMarginTop: {
    // marginLeft: 72,
    marginTop: 56,
  },
  layoutMarginTopNew: {
    marginTop: theme.spacing(10),
  },
  layoutMarginLeftList: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  layoutMarginLeftListChooseTab: {
    // marginLeft: theme.spacing(5),
    marginTop: theme.spacing(4),
  },
  title: {
    fontFamily: "Lato",
    color: "#111",
  },
  title333: {
    fontFamily: "Lato",
    fontWeight: "normal",
    color: "#333",
  },
  title555: {
    fontFamily: "Lato",
    fontWeight: "normal",
    color: "#555",
  },
  title888: {
    fontFamily: "Lato",
    fontWeight: "normal",
    color: "#888",
  },
  titleInLineChart: {
    fontFamily: "Lato",
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
  },

  button: {
    // width: '503px',
    // height: '42px',
    borderRadius: 5,
    background: "#d1354a",
    border: 0,
    fontFamily: "Lato",
    padding: "2px 12px",
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  button0: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#d64253",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    "&:hover": {
      backgroundColor: "#f9e3e6",
      border: "1px solid #d64253",
    },
  },
  button1: {
    textTransform: "capitalize",
    fontSize: 11,
    fontWeight: "700",
    padding: `3px 6px`,
    color: "#fff",
    backgroundColor: "#d64253",
    "&:hover": {
      backgroundColor: "#DE6775",
    },
  },
  button2: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "#d64253",
    padding: "10px 20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
  buttonFab: {
    // width: '503px',
    // height: '42px',
    background: "#d1354a",
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  btnWhite: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #B1B1B1",
    color: "#5B5B5B",
    textTransform: "capitalize",
    // padding: "2px 12px",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
  },
  btnGrey: {
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #DFDFDF",
    color: "#333",
    textTransform: "capitalize",
    padding: "2px 12px",
    backgroundColor: "#DFDFDF",
    "&:hover": {
      backgroundColor: "#B1B1B1",
    },
  },
  buttonDisabled: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "grey",
    fontWeight: "bold",
  },
  uploadBtn: {
    fontFamily: "'Lato', sans-serif",
    color: "#00ACE6",
    backgroundColor: "rgba(0, 172, 230, 0.1)",
    borderRadius: 5,
    padding: "10px, 3px",
    border: "none",
    fontSize: 13,
    fontWeight: "500",
    textTransform: "capitalize",
    "&:hover": {
      opacity: 0.9,
    },
  },
  btnFilter: {
    fontFamily: "'Lato', sans-serif",
    borderRadius: 10,
    border: "1px solid #8F8F8F",
    padding: "2px 8px",
    textTransform: "capitalize",
    color: "#8F8F8F",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
  },
  timeFrameIcontDateRangeText: {
    fontFamily: "Lato",
    color: "#4aa9fb",
    textTransform: "capitalize",
    backgroundColor: "#edf6ff",
  },

  /*
        ```````````
        DIALOG EDIT

        ```````````
    */
  titleListChoose: {
    fontFamily: "Lato",
    cursor: "pointer",
  },
  titleListChooseAktif: {
    fontFamily: "Lato",
    color: "#d6d3d3",
    cursor: "pointer",
  },

  bottomBorderActive: {
    borderBottom: "0.2em solid #282560",
    borderRadius: 2,
  },

  timeFrameIcon: {
    fontFamily: "Lato",
    color: "grey", //#4aa9fb
    textTransform: "capitalize",
    // marginRight: theme.spacing(4),
    // marginTop: theme.spacing(9),
    // backgroundColor: '#cfe8fd'
  },

  timeFrameIconInModal: {
    fontFamily: "Lato",
    color: "grey",
    textTransform: "capitalize",
    "&:hover": {
      color: "#edcfd8", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  /*
   ******************************
   */

  paperColumPertamaRowFirst: {
    marginLeft: theme.spacing(11),
    padding: theme.spacing(0.2),
  },
  paperColumPertamaRowFirstKebakaran: {
    marginLeft: theme.spacing(11),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderLeft: "0.5em solid red",
  },
  paperColumnDuaRowFirst: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
  },

  paperNewColorOfGoal: {
    marginLeft: theme.spacing(11),
    // padding: theme.spacing(0.2),
    padding: theme.spacing(1),
    background: "linear-gradient(1deg, #262261, #6B688B)",
    // background: '#262261', // #6B688B
    // borderLeft: '0.5em solid red'
  },

  tableHeader: {
    backgroundColor: "#fff",
    borderRadius: 8,
    fontFamily: "'Lato', sans-serif",
  },
  txtHeader: {
    fontWeight: "600",
    color: "#333",
    fontFamily: "'Lato', sans-serif",
  },
  txtBody: {
    color: "#4D4D4D",
    fontFamily: "'Lato', sans-serif",
  },

  /*
        `````````````````````````````
        FOTO REAL USER NAME, USERNAME

        `````````````````````````````
    */
  userRealFoto: {
    // margin: 10,
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  imageAvatar: {
    width: 50,
    height: 50,
  },

  /*
        ````````
        CIRCULAR

        ````````
    */
  circularProgressBarInMoreDetail: {
    // width: 60,
    // height: 60,
    width: 100,
    height: 100,
  },
  circularProgressBarInPaperDetail: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  buttonModalDelete: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    fontFamily: "Lato",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    marginRight: theme.spacing(4),
    color: "white",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },

  /*
        ``````````
        MODAL EDIT 

        ``````````
    */
  textField: {
    minWidth: 425,
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 5,
    border: `2px dashed #DFDFDF`,
    color: "#808080",

    marginBottom: 5,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  /*
        ```````
        SEARCH

        ```````
    */
  search: {
    borderRadius: 12,
    backgroundColor: "#fff",
    border: "1px solid #C4C4C4",
    "&:hover": {
      backgroundColor: "#f9f9f9",
    },
    fontSize: 15,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: 250,
    },
  },

  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "'Lato', sans-serif",
    fontSize: 15,
    width: 300,
  },
  inputInput: {
    fontSize: 15,
    width: 300,
    padding: "4px 8px",
    transition: theme.transitions.create("width"),
    color: "grey",
  },
  popperClose: {
    pointerEvents: "none",
  },
  menuList: {
    padding: "0",
  },
  dropdown: {
    borderRadius: "3px",
    border: "0",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.26)",
    top: "100%",
    zIndex: "1000",
    minWidth: "160px",
    padding: "5px 0",
    margin: "2px 0 0",
    fontSize: "14px",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
  },
  dropdownItem: {
    fontWeight: "300",
    lineHeight: "1.5em",
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    position: "relative",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    height: "fit-content",
    color: "#333",
    whiteSpace: "nowrap",
    minHeight: "unset",
  },
  dropdownDividerItem: {
    margin: "5px 0",
    backgroundColor: "rgba(0, 0, 0, 0.12)",
    height: "1px",
    overflow: "hidden",
  },
  popperResponsive: {
    zIndex: "1200",
    [theme.breakpoints.down("sm")]: {
      zIndex: "1640",
      position: "static",
      float: "none",
      width: "auto",
      marginTop: "0",
      backgroundColor: "transparent",
      border: "0",
      boxShadow: "none",
      color: "black",
    },
  },

  /*
        ````````````````````````````
        PAPER, CIRCULAR PROGRESS BAR

        ````````````````````````````        
    */
  paperList: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
  },
  paperListKebakaran: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    padding: theme.spacing(0.2),
    background: "#edcfd8",
    borderBottom: "0.5em solid red",
    // borderLeft: '0.1em solid #c3c0c0de',
  },

  chipFotoMA: {
    backgroundColor: "#edcfd8",
    fontFamily: "Lato",
    color: "grey",
  },
  connectMAToGoal: {
    fontFamily: "Lato",
    color: "#d85466",
    textTransform: "capitalize",
    "&:hover": {
      color: "grey", //#edcfd8, #cc0707
    },
    borderWidth: 0,
  },

  /* 
        ``````````````````````
        GRID VIEW - PICT BUKTI
        
        ``````````````````````
    */
  rootGridView: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  text: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },

  /*
        ``````````````````
        STYLE CSS SUB GOAL

        ``````````````````
    */

  rootTable: {
    width: "100%",
    overflowX: "auto",
  },

  table: {
    minWidth: 650,
  },
  topBotomZero: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  /*
        ````````````
        YOU - ACTIVE

        ````````````
    */
  moreDetail: {
    fontFamily: "Lato",
    color: "black", //#4aa9fb
    textTransform: "capitalize",
    fontSize: 11,
    cursor: "pointer",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 3,
    paddingLeft: 3,
    backgroundColor: "yellow",
  },
  buttonOutlinedDirectLink: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 1,
    "&:hover": {
      borderWidth: 1,
    },
  },
  buttonOutlined: {
    fontFamily: "Lato",
    textTransform: "capitalize",
    color: "#d64253",
    borderWidth: 0,
    "&:hover": {
      borderWidth: 0,
    },
  },

  cardRootGoalResult: {
    // maxWidth: 745,
    // width: '100%'
    width: 745,
  },

  /*
        `````
        VIDEO

        `````
    */

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  // ==================== GR ====================
  titleGRList: {
    fontSize: 11,
    fontFamily: "Lato",
    color: "#bbb",
  },

  // ========================================
  // Set Permission Tabs
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#282560",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#282560",
    },
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 10,
    border: `3px dashed #eeeeee`,
    backgroundColor: "#fafafa",
    color: "#bdbdbd",

    marginBottom: 10,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  textBreadcrum: {
    "&:hover": { cursor: "pointer", color: "#333" },
    fontFamily: "Lato",
    fontSize: 11,
  },

  // ========================================
  // Styled Checkbox
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a",
    },
  },

  // ========================================
  // List Evidence Root
  // ========================================
  listEvidenceRoot: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },

  //
  expandGRBtn: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Lato",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  // FREQ
  checkDaily: {
    visibility: "hidden",
    display: "none",
    height: 0,
    width: 0,
    opacity: 0,
  },
});
