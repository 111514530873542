import React, { Fragment } from "react";
import {
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
} from "@material-ui/core";
import LinearScaleIcon from "@material-ui/icons/LinearScale";

import Capitalize from "../../../../utilities/Capitalize";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const IconStatusGoal = (props) => {
  const { classes, goalDetailState } = props;

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <IconButton
          style={{ backgroundColor: "#aed9ff", padding: "7px", opacity: 0.5 }}
        >
          <LinearScaleIcon style={{ color: "magenta" }} />
        </IconButton>
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            {MyLang() ? bahasa.status : english.status} <i>Goal</i>
          </Typography>
        }
        secondary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            <b>
              <i>{Capitalize(goalDetailState.status.code)} </i>
            </b>
          </Typography>
        }
      />
    </Fragment>
  );
};

export default IconStatusGoal;
