import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Menu,
  MenuItem,
  Badge,
  Box,
  Divider,
  InputBase,
  Checkbox,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import ContextReports from "../../../../context/ContextReports";
import {
  GET_DATA_TABLE,
  GET_FIELD_PRESET,
  SET_PAGINATING,
  TOGGLE_DRAWER_CHART,
  // selected summary
  HIT_UNITS,
  GET_BAR_CHART,
  GET_PIE_CHART,
  GET_AVG_COMPANY,
  GET_SUMMARY_CONFIG,
  SET_SUMMARY_TAB,
  GET_DATA_DETAIL,
  GET_STRUCTURES,
} from "../../../../context/types";

import clsx from "clsx";

import handleError from "../../../global/handleError";
import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import ListItemType from "./sub-components/ListItemType";
import DialogError from "../../../../../../components/DialogError";
import ListItemUnit from "../../detailed-type/grouping/unit/ListItemUnit";
import mapTable from "../../../global/mapTable";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const ConfigUnit = ({ classes }) => {
  const {
    summaryState,
    tableSmrState,
    summaryDispatch,
    tableSmrDispatch,
    reportDispatch,
    barDispatch,
    pieDispatch,
  } = useContext(ContextReports);

  // =================== State ===================
  const [anchorUnit, setAnchorUnit] = useState(null);
  const [anchorType, setAnchorType] = useState(null);
  const [anchorStructure, setAnchorStructure] = useState(null);
  const [selected, setSelected] = useState([]);
  const [unitType, setUnitType] = useState({ id: "", name: "Pilih unit type" });
  const [unitTypeCollections, setUnitTypeCollections] = useState([]);
  const [structureCollections, setStructureCollections] = useState([]);

  const [loadUnit, setLoadUnit] = useState(false);
  const [loadStructure, setLoadStructure] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableUnit, setDisableUnit] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const itemCount = summaryState.users.length;

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ SIDE EFFECT ~~~~~~~~~~~~~~~~~
  // ==============================================
  useEffect(() => {
    if (anchorUnit !== null) {
      setLoadUnit(true);

      AxiosConfig.get(
        `${URL_API}/human-resource/master-structure-unit/list/from-so/create`
      )
        .then((res) => {
          // // console.log("RES SO", res);
          const result = res.data.data;
          const unitTypeId = result.fields.structure_unit_type_id.default_value;

          if (res.status === 200) {
            setUnitTypeCollections(unitTypeId);
          }
          setLoadUnit(false);
        })
        .catch((error) => {
          // console.log("Error", error);
          setLoadUnit(false);
        });
    }
  }, [anchorUnit]);

  useEffect(() => {
    summaryDispatch({
      type: HIT_UNITS,
      payload: { selectedUnits: selected },
    });
  }, [selected]);

  useEffect(() => {
    setUnitType(summaryState.selectedUnit);
    handleCloseAnchorType();
    setLoadStructure(true);

    if (
      summaryState.selectedUnit.id === "" &&
      summaryState.selectedGroup.field === "full_name"
    ) {
      setDisableUnit(false);
    }
    if (
      summaryState.selectedUnit.id !== "" &&
      summaryState.selectedGroup.field === "structure_unit_name"
    ) {
      setDisableUnit(true);
    }

    // // console.log("ASSEW", summaryState.selectedUnit);

    AxiosConfig.post(
      `${URL_API}/human-resource/master-structure-unit/list/from-so/list`,
      {
        structure_unit_type_id:
          summaryState.selectedUnit !== undefined &&
          summaryState.selectedUnit.id,
      }
    )
      .then((res) => {
        // // console.log("RES Structure", res);
        const result = res.data.data;

        if (res.status === 200) {
          setStructureCollections(result);

          summaryDispatch({
            type: GET_STRUCTURES,
            payload: {
              structureCollections: result,
            },
          });
        }
        setLoadStructure(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoadStructure(false);
      });
  }, [summaryState.selectedUnit]);

  // ==============================================
  // ~~~~~~~~~~~~~~~~~ METHODS ~~~~~~~~~~~~~~~~~
  // ==============================================
  const handleShowAnchorUnit = (event) => {
    setAnchorUnit(event.currentTarget);
  };

  const handleCloseAnchorUnit = () => {
    setAnchorUnit(null);
  };

  const handleShowAnchorType = (event) => {
    setAnchorType(event.currentTarget);
  };

  const handleCloseAnchorType = () => {
    setAnchorType(null);
  };

  const handleShowAnchorStructure = (event) => {
    setAnchorStructure(event.currentTarget);
  };

  const handleCloseAnchorStructure = () => {
    setAnchorStructure(null);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = structureCollections.map((n) => n.id);
      setSelected(newSelecteds);
      // // console.log("selAll", newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleType = (data) => {
    setUnitType(data);
    // // console.log("UnType", data);
    handleCloseAnchorType();
    setLoadStructure(true);

    AxiosConfig.post(
      `${URL_API}/human-resource/master-structure-unit/list/from-so/list`,
      {
        structure_unit_type_id: data.id,
      }
    )
      .then((res) => {
        // // console.log("RES Structure", res);
        const result = res.data.data;

        if (res.status === 200) {
          setStructureCollections(result);

          summaryDispatch({
            type: GET_STRUCTURES,
            payload: {
              structureCollections: result,
            },
          });
        }
        setLoadStructure(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoadStructure(false);
      });
  };

  const handleShowResult = () => {
    setLoading(true);

    // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
    const pagination = tableSmrState.paginating;
    const currentPage = pagination.currentPage;
    const perPage = pagination.perPage;

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    let newAchievement = [];

    if (
      summaryState.selectedEntities.includes("goal") &&
      summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal, ...refMa];
    }

    if (
      summaryState.selectedEntities.includes("goal") &&
      !summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal];
    }

    if (
      summaryState.selectedEntities.includes("ma") &&
      !summaryState.selectedEntities.includes("goal")
    ) {
      newAchievement = [...refMa];
    }

    // ~~~~~~~~~~~ Data POST Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: summaryState.dateRange.code,
        user: summaryState.selectedUsers,
        unit: selected,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
        period_id: summaryState.selectedPeriod.id,
      },
      entities: summaryState.selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [summaryState.selectedOrder],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    tableSmrDispatch({
      type: GET_DATA_TABLE,
      payload: {
        dataTable: [],
        loading: true,
      },
    });

    const dataUnit = {
      structure_unit_ids: selected,
    };

    AxiosConfig.post(
      `${URL_API}/human-resource/structure-position/descendant/based-on-unit`,
      dataUnit
    )
      .then((res) => {
        const result = res.data.data;

        // Set The user List to Local Storage
        localStorage.setItem("summary_user", JSON.stringify(result));

        if (res.status === 200) {
          summaryDispatch({
            type: GET_SUMMARY_CONFIG,
            payload: {
              primaryFields: summaryState.primaryFields,
              groupBy: summaryState.groupBy,
              calcMethods: summaryState.calcMethods,
              orderBy: summaryState.orderBy,
              entities: summaryState.entities,
              users: result,
              units: summaryState.units,
              achievements: summaryState.achievements,
              status: summaryState.status,
              periods: summaryState.periods,
              // modes,
              // filters,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        // setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    // ================= GET INITIAL DATA TABLE SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataSummary
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY TABLE", result);
        const DATATABLE = mapTable(result.results.data);

        const paginating = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
          totalRecords: result.results.total,
        };

        if (res.status === 200) {
          tableSmrDispatch({
            type: GET_FIELD_PRESET,
            payload: { fieldPreset: result.settings.field_preset },
          });

          setTimeout(() => {
            tableSmrDispatch({
              type: GET_DATA_TABLE,
              payload: {
                dataTable: DATATABLE,
                loading: false,
              },
            });
          }, 1000);

          tableSmrDispatch({
            type: SET_PAGINATING,
            payload: { paginating },
          });

          setLoading(false);
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableSmrDispatch({
          type: GET_DATA_TABLE,
          payload: {
            dataTable: [],
            loading: false,
          },
        });
      });

    //============= Datat For Ovaerge Company =============
    const dataAvg = {
      ...dataSummary,
      report_format: "summary",
    };

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataAvg
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          summaryDispatch({
            type: GET_AVG_COMPANY,
            payload: {
              averages: result.results,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    //============= Datat For Bar Chart =============
    const dataBar = { ...dataSummary, report_format: "bar_chart" };

    barDispatch({
      type: GET_BAR_CHART,
      payload: {
        barData: [],
        barPreset: [],
        loading: true,
      },
    });

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataBar
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);

        if (res.status === 200) {
          barDispatch({
            type: GET_BAR_CHART,
            payload: {
              barData: result.results.data,
              barPreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        barDispatch({
          type: GET_BAR_CHART,
          payload: {
            barData: [],
            barPreset: [],
            loading: true,
          },
        });
      });

    //============= Datat For Pie Chart =============
    const dataPie = {
      ...dataSummary,
      report_format: "pie_chart",
      order_by: [],
    };

    pieDispatch({
      type: GET_PIE_CHART,
      payload: {
        pieDataGoal: [],
        pieDataMa: [],
        piePreset: [],
        loading: true,
      },
    });

    // // console.log("dataPie", dataPie);

    // ================= GET INITIAL DATA BAR CHART SUMMARY =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail/summary?page=${currentPage}&per_page=${perPage}`,
      dataPie
    )
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES SUMMARY BAR", result);
        const pieGoal = result.results.filter((el) => el.type === "goal");
        const pieMa = result.results.filter((el) => el.type === "ma");

        if (res.status === 200) {
          reportDispatch({ type: TOGGLE_DRAWER_CHART, payload: false });
          pieDispatch({
            type: GET_PIE_CHART,
            payload: {
              pieDataGoal: pieGoal,
              pieDataMa: pieMa,
              piePreset: result.settings.field_preset,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        pieDispatch({
          type: GET_PIE_CHART,
          payload: {
            pieDataGoal: [],
            pieDataMa: [],
            piePreset: [],
            loading: false,
          },
        });
      });

    tableSmrDispatch({
      type: SET_SUMMARY_TAB,
      payload: { summaryTab: 0 },
    });

    tableSmrDispatch({
      type: GET_DATA_DETAIL,
      payload: {
        loadDetail: false,
        dataDetail: {
          goals: [],
          mas: [],
        },
      },
    });

    handleCloseAnchorUnit();
  };

  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<AccountTreeIcon style={{ fontSize: 15 }} />}
            className={classes.configBtn}
            disabled={tableSmrState.loading}
            onClick={handleShowAnchorUnit}
          >
            Unit
          </Button>
        }
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorUnit}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorUnit)}
        style={{ marginBottom: 8, width: 320 }}
        onClose={handleCloseAnchorUnit}
      >
        <Box mx={2} mt={2} mb={3} style={{ width: 200 }}>
          <Typography variant="subtitle2" className={classes.title555}>
            <b>Unit Type</b>
          </Typography>

          <Button
            variant="outlined"
            className={classes.configBtn}
            fullWidth
            disabled={disableUnit}
            endIcon={<ArrowDropDownIcon />}
            style={{ marginTop: 5 }}
            onClick={handleShowAnchorType}
          >
            {unitType.name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorType}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorType)}
            onClose={handleCloseAnchorType}
          >
            {loadUnit && (
              <Box m={2}>
                <Typography
                  variant="caption"
                  className={classes.title555}
                  style={{ textAlign: "center" }}
                >
                  Loading...
                </Typography>
              </Box>
            )}
            {unitTypeCollections.length > 0 &&
              unitTypeCollections.map((item, i) => (
                <MenuItem key={i} style={{ padding: 0 }} dense>
                  <ListItemType
                    classes={classes}
                    item={item}
                    type={unitType}
                    onType={handleType}
                  />
                </MenuItem>
              ))}

            {!loadUnit && unitTypeCollections.length === 0 && (
              <Box m={2}>
                <Typography
                  variant="caption"
                  className={classes.title555}
                  style={{ textAlign: "center" }}
                >
                  There is no Unit Type
                </Typography>
              </Box>
            )}
          </Menu>
        </Box>
        {unitType.id !== "" && (
          <Box mx={2} mb={2}>
            <Typography variant="subtitle2" className={classes.title555}>
              <b>
                Structure Unit {selected.length > 0 && `(${selected.length})`}
              </b>
            </Typography>

            <Button
              variant="outlined"
              className={classes.configBtn}
              fullWidth
              endIcon={<ArrowDropDownIcon />}
              style={{ marginTop: 5 }}
              onClick={handleShowAnchorStructure}
            >
              Structure Unit
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorStructure}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorStructure)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                ml={1}
                mr={2}
                mb={1}
              >
                <Box display="flex" alignItems="center">
                  <Checkbox
                    disableRipple
                    indeterminate={
                      selected.length > 0 && selected.length < itemCount
                    }
                    checked={itemCount > 0 && selected.length === itemCount}
                    onChange={handleSelectAllClick}
                    className={classes.checkboxRoot}
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    color="default"
                    inputProps={{ "aria-label": "decorative checkbox" }}
                  />
                  <Typography variant="subtitle2" className={classes.title555}>
                    All
                  </Typography>
                </Box>
                <Typography
                  className={classes.btnTxt}
                  onClick={() => setSelected([])}
                >
                  None
                </Typography>
              </Box>
              <Box>
                {loadStructure && (
                  <Box m={2}>
                    <Typography
                      variant="caption"
                      className={classes.title555}
                      style={{ textAlign: "center" }}
                    >
                      Loading...
                    </Typography>
                  </Box>
                )}
                {structureCollections.length > 0 &&
                  structureCollections.map((item, i) => (
                    <MenuItem key={i} style={{ padding: 0 }} dense>
                      <ListItemUnit
                        classes={classes}
                        index={i}
                        unit={item}
                        selected={selected}
                        setSelected={setSelected}
                        isSelected={isSelected}
                      />
                    </MenuItem>
                  ))}

                {!loadStructure && structureCollections.length === 0 && (
                  <Box m={2}>
                    <Typography
                      variant="caption"
                      className={classes.title555}
                      style={{ textAlign: "center" }}
                    >
                      There is no Structure Unit
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                my={1.5}
                mx={2}
                style={{ minWidth: 200 }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button0}
                  onClick={() => {
                    setSelected([]);
                    handleCloseAnchorStructure();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button1}
                  style={{ marginLeft: 7.5 }}
                  onClick={handleCloseAnchorStructure}
                >
                  Select ({selected.length})
                </Button>
              </Box>
            </Menu>
          </Box>
        )}
        {selected.length > 0 && (
          <>
            <Box m={1.5} mt={1}>
              <Divider />
            </Box>

            <Box
              marginRight={2}
              marginBottom={1}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                size="small"
                variant="outlined"
                className={classes.button0}
                onClick={handleCloseAnchorUnit}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                className={classes.button1}
                style={{ marginLeft: 7.5 }}
                disabled={selected.length === 0}
                onClick={handleShowResult}
              >
                {loading ? "Showing..." : "Show results"}
              </Button>
            </Box>
          </>
        )}
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default ConfigUnit;
