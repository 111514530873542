import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Grid,
  DialogActions,
  Button,
  Chip,
  IconButton,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import numeral from "numeral";
import moment from "moment";
import {
  DateRange as IconDateRange,
  HighlightOff as HighlightOffIcon,
  EditOutlined as EditOutlinedIcon,
} from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import "moment/locale/id";

import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";
import ContextGoal from "../Context/ContextGoal";

import LightTooltip from "../../../../components/LightTooltip";
import DialogDuplicateGoalv2 from "./DialogDuplicateGoalv2";
import DialogDateRange from "../Components/DialogDateRange";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../../components/StyledMenuDropdown";
import DialogError from "../../../../components/DialogError";
import handleError from "../../../Report/components/global/handleError";
import DialogRemarksLog from "../../../../components/DialogRemarksLog";

// CLASS Local Time Utilities
class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

function DialogDuplicate({
  classes,
  item,
  isModalDuplicate,
  setModalDuplicate,
}) {
  /* ===============================================
     IMPORTANT VARIABLE
    ================================================= */
  const context = useContext(ContextGoal);
  const userToken = localStorage.getItem("userToken");
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const startDateRange = localStorage.getItem("start_date");
  const endDateRange = localStorage.getItem("end_date");
  /* ===============================================
     STATE COMPONENT
    ================================================= */
  const [userTokenState, setUserTokenState] = useState("");
  const [goalData, setGoalData] = useState({
    id: "",
    name: "",
    owner_id: "",
    achievement_type_id: "",
    start_value: "",
    target_value: null,
    input_method_id: "",
    calculation_method_id: "",
    start_date: "",
    end_date: "",
    time_frame_id: null,
    description: "",
    value_type_id: "",
    target_type_id: "",
    is_evidence_required: 0,
    owner: {
      id: "",
      member_id: "",
      member_first_name: "",
      member_last_name: "",
      user_id: "",
      user_email: "",
    },
    achievementType: {
      id: "",
      code: "",
      name: "",
    },
    inputMethod: {
      id: "",
      code: "",
      name: "",
    },
    calculationMethod: {
      id: "",
      code: "",
      name: "",
    },
    valueType: {
      id: "",
      code: "",
      name: "",
    },
    targetType: {
      id: "",
      code: "",
      name: "",
    },
  });
  const [timeFrame, setTimeFrame] = useState([
    {
      id: "1",
      code: "period",
      name: "period",
    },
    {
      id: "2",
      code: "date_range",
      name: "date range",
    },
    {
      id: "3",
      code: "due_date",
      name: "due date",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [loadBtn, setLoadBtn] = useState(false);
  const [isModalEditDuplicate, setModalEditDuplicate] = useState(false);
  const [openDuplicateGoal, setOpenDuplicateGoal] = useState(false);

  //LOG
  const [dataRemarks, setDataRemarks] = useState(null);
  const [openRemarksLog, setOpenRemarksLog] = useState(false);

  const [locale, setLocale] = useState("id");
  /*
        `````````````````````
        HANDLE TIME FRAME ID

        - idDueDate: 3,
        - idDateRange: 2,
        - idPeriod: 1

        `````````````````````
    */
  const [timeFrameId, setTimeFrameId] = useState(null);

  // TIME FRAME STATE
  const [anchorElTimeFrame, setAnchorElTimeFrame] = useState(null);
  const [textValueTimeFrame, setTextValueTimeFrame] =
    useState("Pilih time frame");

  // SELEECT DUE DATE STATE
  const [selectedDueDate, setSelectedDueDate] = useState("");
  const [isShowDueDateComponent, setShowDueDateComponent] = useState(false);
  const [isShowDueDateComponentDirectly, setShowDueDateComponentDirectly] =
    useState(false);

  // START - END | DATE RANGE
  const [startDateRangeState, setStartDateRangeState] = useState(null);
  const [endDateRangeState, setEndDateRangeState] = useState(null);

  // DATE RANGE OPTION STATE
  const [isShowDateRangeComponent, setShowDateRangeComponent] = useState(false);
  const [isShowTextDateRange, setShowTextDateRange] = useState(false);

  // PERIOD OPTION STATE
  const [isShowPeriodComponent, setShowPeriodComponent] = useState(false);
  const [isShowTextPeriod, setShowTextPeriod] = useState(false);

  // CHOOSE PERIOD
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const [periodId, setPeriodId] = useState("");
  const [periodName, setPeriodName] = useState("");
  const [periodCollections, setPeriodCollections] = useState([]);

  // INTERVAL
  const [dataInterval, setDataInterval] = useState({
    goal_ma_id: "",
    category: "goal",
    interval: "",
    schedule_type: "",
    scheduled_at: "",
    input_start: "-",
    input_finish: "-",
    result_locked: true,
    reminder: "",
    auto_insert_result: "1",
  });

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (isModalDuplicate) {
      const frequency = item?.update_frequency;
      const selectedDays = frequency?.selected_days?.split(",");

      if (frequency?.interval === "hourly") {
        const times =
          frequency?.hourly_details.length > 0
            ? frequency?.hourly_details.map((item) => {
                const start = item.time_start.slice(0, 5);
                const finish = item.time_finish.slice(0, 5);

                return {
                  start,
                  finish,
                };
              })
            : [];

        const hourly_details = {
          selected_days: selectedDays,
          selected_times: times,
        };

        // // console.log("times", times);

        setDataInterval({
          ...dataInterval,
          goal_ma_id: item?.id,
          auto_insert_result: frequency?.auto_insert_result,
          category: frequency?.category,
          schedule_type: frequency?.schedule_type,
          scheduled_at: frequency?.scheduled_at,
          interval: frequency?.interval,
          input_start: frequency?.input_start,
          input_finish: frequency?.input_finish,
          reminder: frequency?.reminder_id,
          result_locked: frequency?.result_locked,
          hourly_details,
        });
      }

      if (frequency?.interval === "daily") {
        const selectedDays = frequency?.daily_details.map((item) => {
          return {
            day: item.day,
            scheduled_at: item.scheduled_at.slice(0, 5),
          };
        });

        const daily_details = {
          selected_days: selectedDays,
        };

        setDataInterval({
          ...dataInterval,
          goal_ma_id: item?.id,
          auto_insert_result: frequency?.auto_insert_result,
          category: frequency?.category,
          schedule_type: frequency?.schedule_type,
          scheduled_at: frequency?.scheduled_at,
          interval: frequency?.interval,
          input_start: frequency?.input_start,
          input_finish: frequency?.input_finish,
          reminder: frequency?.reminder_id,
          result_locked: frequency?.result_locked,
          daily_details,
        });
      }

      if (frequency?.interval === "weekly") {
        const selectedWeeks = frequency?.weekly_details.map((item) => {
          return {
            finish: item.date_finish,
            selected: true,
            start: item.date_start,
            week: item.week,
          };
        });

        const weekly_details = {
          selected_weeks: selectedWeeks,
        };

        setDataInterval({
          ...dataInterval,
          goal_ma_id: item?.id,
          auto_insert_result: frequency?.auto_insert_result,
          category: frequency?.category,
          schedule_type: frequency?.schedule_type,
          scheduled_at: frequency?.scheduled_at,
          interval: frequency?.interval,
          input_start: frequency?.input_start,
          input_finish: frequency?.input_finish,
          reminder: frequency?.reminder_id,
          result_locked: frequency?.result_locked,
          weekly_details,
        });
      }

      if (frequency?.interval === "monthly") {
        const selectedMonths = frequency?.monthly_details.map((item) => {
          return {
            finish: item.date_finish,
            selected: true,
            start: item.date_start,
            month: item.month,
          };
        });

        const monthly_details = {
          selected_months: selectedMonths,
        };

        setDataInterval({
          ...dataInterval,
          goal_ma_id: item?.id,
          auto_insert_result: frequency?.auto_insert_result,
          category: frequency?.category,
          schedule_type: frequency?.schedule_type,
          scheduled_at: frequency?.scheduled_at,
          interval: frequency?.interval,
          input_start: frequency?.input_start,
          input_finish: frequency?.input_finish,
          reminder: frequency?.reminder_id,
          result_locked: frequency?.result_locked,
          monthly_details,
        });
      }
    }
  }, [isModalDuplicate]);

  /* ===============================================
     API REQUEST
    ================================================= */

  useEffect(() => {
    if (isModalDuplicate === true) {
      setUserTokenState(userToken);
      setLoading(true);
      axiosConfig
        .get(`${URL_API}/swd/goal/${item.id}:duplicate`)
        .then((response) => {
          if (response.status === 200) {
            const result = response.data.data;

            // console.log("DUPDUP", result);

            setGoalData(result.goal);
            setTimeFrame(result.timeFrameCollections);

            if (result.goal.period !== null) {
              setShowTextPeriod(true);
              setShowTextDateRange(false);
              setPeriodName(result.goal.period.name);
              setPeriodId(result.goal.period_id);
            }
            setPeriodCollections(result.periodCollections);

            // console.log("RESPONSE GOAL DATA", result.goal);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // console.log("Error Geting Duplicate Goal", error);

          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorMessage(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          }
        });
    }
  }, [isModalDuplicate]);

  // // console.log("ITEM", item);

  const handleSubmitDuplicate = () => {
    setLoadBtn(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    let data = {
      Goal: {
        name: goalData.name,
        description: goalData.description,
        // owner_id: ownerList.length > 0 && ownerId === '' ?  ownerList[0].id : ownerId,
        owner_id: goalData.owner_id,

        input_method_id: goalData.input_method_id,
        achievement_type_id: goalData.achievement_type_id,
        calculation_method_id: goalData.calculation_method_id,
        value_type_id: goalData.value_type_id,
        target_type_id: goalData.target_type_id,
        period_id: periodId !== "" ? periodId : null,
        start_date:
          startDateRangeState !== null
            ? startDateRangeState
            : goalData.start_date,
        end_date:
          endDateRangeState !== null
            ? endDateRangeState
            : selectedDueDate !== ""
            ? moment(selectedDueDate).format("YYYY-MM-DD")
            : goalData.end_date,
        start_value: goalData.start_value !== "0" ? goalData.start_value : 0,
        target_value: goalData.target_value,
        // target_range_value: targetValueRange !== null ? targetValueRange : null,
        time_frame_id:
          goalData.time_frame_id !== null ? goalData.time_frame_id : null,
        is_evidence_required: goalData.is_evidence_required,
        goal_category: "OPERATIONAL",
        disabled_interval: goalData.disabled_interval,
      },
    };

    // console.log("Data SUBMIT : ", data);

    axiosConfig
      .post(`${URL_API}/swd/goal`, data)
      .then((response) => {
        const result = response.data.data;
        // // console.log("SUCCESS SUBMIT DUPLICATE : ", response);

        if (response.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "goal",
              event: "create",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: result?.id,
              entity_name: result?.name,
              remarks: `Goal (${result?.name}) 1`,
              comment: "",
            },
          };

          setDataRemarks(dataLog);

          const newData = {
            ...dataInterval,
            goal_ma_id: result?.id,
          };

          axiosConfig
            .post(`${URL_API}/swd/update-frequency/store`, newData)
            .then((res) => {
              // console.log("res freq", res);
              // setOpenCreateGoal(false);
            })
            .catch((error) => {
              // Handle Error
              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });

          // context.setOpenSnackbeer(true);
          // context.setTriggerGoals(Math.floor(Math.random() * 1000 + 1));
          // context.setMessages("Berhasil menyalin Goal");
          // setModalDuplicate(false);
          setOpenRemarksLog(true);

          // setTimeout(() => window.location.reload(), 1500);
        }
        setLoadBtn(false);
      })
      .catch((error) => {
        setLoadBtn(false);
        // console.log("EROR POST DUPLICATE", error);

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        }
      });
  };

  // GET START & END DATE RANGE
  useEffect(() => {
    setStartDateRangeState(startDateRange);
    setEndDateRangeState(endDateRange);
  }, [startDateRange, endDateRange]);

  /* ===============================================
     HANDLE FUNCTION
    ================================================= */

  // Function For Handling Dialog EDIT DUPLICATE is Show or Not

  function handleDuplicateGoalv2() {
    setModalDuplicate(false);
    setOpenDuplicateGoal(true);
  }

  function handleClickTimeFrame(event) {
    setAnchorElTimeFrame(event.currentTarget);
  }

  function handleCloseTimeFrame() {
    setAnchorElTimeFrame(null);
  }

  // Handle Choose Due Date
  const handleChooseDueDate = () => {
    // setEndDateRangeState(new Date()) //*Baris kode ini untuk validasi DISABLED Button saja !
    setTimeFrameId("3");

    handleCloseTimeFrame();
    setShowDueDateComponent(true);
    setShowDueDateComponentDirectly(true);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);
    setShowTextPeriod(false);
    setTextValueTimeFrame("Due Date");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");
  };

  // Handle Choose Date Range
  const handleChooseRange = () => {
    setTimeFrameId("2");
    // setSelectedDueDate(new Date());//*Baris kode ini untuk validasi DISABLED Button saja !
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);
    setShowTextPeriod(false);
    setShowDateRangeComponent(true);
    setShowTextDateRange(true);

    setTextValueTimeFrame("Date Range");
  };

  // Handle Choose Period
  const handleChoosePeriod = (event) => {
    setTimeFrameId("1");
    setAnchorElPeriod(event.currentTarget);

    //*
    handleCloseTimeFrame();
    setShowDueDateComponent(false);
    setShowDueDateComponentDirectly(false);

    setShowDateRangeComponent(false);
    setShowTextDateRange(false);

    setTextValueTimeFrame("Period");

    localStorage.removeItem("start_date");
    localStorage.removeItem("end_date");

    setShowPeriodComponent(true);
    setShowTextPeriod(true);
    setSelectedDueDate(null);
  };

  // Handle Click Period
  function handleClickPeriod(event) {
    //*Fungsi setAnchor di sini sudah di pakai langsung di 'handleChoosePeriod()'
    setAnchorElPeriod(event.currentTarget);
  }

  // Handle Close Period
  function handleClosePeriod() {
    setAnchorElPeriod(null);
  }

  // Handle Choose Period
  const handleChoosePeriodId = (e, item) => {
    e.preventDefault();
    // console.log("Item : ", item);

    setPeriodId(item.id);
    setPeriodName(item.name);

    setStartDateRangeState(item.start_date);
    setEndDateRangeState(item.end_date);

    handleClosePeriod();
    setShowTextPeriod(true);
  };

  return (
    <Fragment>
      <Dialog
        open={isModalDuplicate}
        onClose={() => setModalDuplicate(false)}
        aria-labelledby="max-width-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="max-width-dialog-title">
          <Grid container direction="row" justify="space-between">
            <Typography variant="h6">
              <b>Duplicate Goal</b>
            </Typography>

            <IconButton size="small" onClick={() => setModalDuplicate(false)}>
              <HighlightOffIcon style={{ color: "grey", fontSize: "24px" }} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box bgcolor="#eee">
            <Grid container>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Nama Goal</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? <CircularProgress size={14} /> : goalData.name}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Deskripsi Goal</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : goalData.description !== null ? (
                      goalData.description
                    ) : (
                      <span
                        style={{
                          fontStyle: "italic",
                          color: "#aaa",
                          fontWeight: "lighter",
                        }}
                      >
                        (no description)
                      </span>
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Achievement Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      goalData.achievementType.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Target Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      goalData.targetType.name
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Input Method</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      goalData.inputMethod.name
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Start Value</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      numeral(goalData.start_value).format("0.00")
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Target Value</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      numeral(goalData.target_value).format("0.00")
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Evidence</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : goalData.is_evidence_required === 0 ? (
                      "Not Required"
                    ) : (
                      "Required"
                    )}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box style={{ margin: 15 }}>
                  <Typography variant="subtitle1">
                    <b>Owner</b>
                  </Typography>
                  {loading ? (
                    <CircularProgress size={14} />
                  ) : goalData.owner_id === "-1" ? (
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#cc0707" }}
                    >
                      Owner ini bukan subordinate anda
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      {goalData.owner.member_first_name}{" "}
                      {goalData.owner.member_last_name}
                    </Typography>
                  )}
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Calculation Method</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : goalData.calculation_method_id === "1" ? (
                      "Total"
                    ) : goalData.calculation_method_id === "2" ? (
                      "Rata-rata"
                    ) : (
                      "Nilai Akhir"
                    )}
                  </Typography>
                </Box>

                <Box style={{ margin: 15, marginTop: 30 }}>
                  <Typography variant="subtitle1">
                    <b>Value Type</b>
                  </Typography>
                  <Typography variant="subtitle2">
                    {loading ? (
                      <CircularProgress size={14} />
                    ) : (
                      goalData.valueType.name
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box style={{ marginBottom: 15 }}>
            <Grid container justify="space-between" alignItems="flex-start">
              <Typography variant="subtitle1" style={{ marginTop: 15 }}>
                <b>Time Frame</b>
              </Typography>
              <Box>
                <Button
                  onClick={handleDuplicateGoalv2}
                  disabled={goalData.owner_id === "-1" ? true : false}
                  style={{ textTransform: "capitalize", fontWeight: "600" }}
                >
                  <EditOutlinedIcon style={{ fontSize: 17, marginRight: 8 }} />
                  Edit Details
                </Button>
              </Box>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ marginTop: 5 }}
            >
              {/* USER CHOOSE TIME FRAME */}
              <Button
                onClick={handleClickTimeFrame}
                disabled={goalData.owner_id === "-1" ? true : false}
                variant="outlined"
                size="small"
                className={classes.timeFrameButonDuplicate}
              >
                <b>{textValueTimeFrame}</b>
              </Button>

              <StyledMenu
                id="customized-menu"
                anchorEl={anchorElTimeFrame}
                keepMounted
                open={Boolean(anchorElTimeFrame)}
                onClose={handleCloseTimeFrame}
              >
                <StyledMenuItem onClick={handleChooseDueDate}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Due Date</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem onClick={handleChooseRange}>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Date Range</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>

                <StyledMenuItem
                  // onClick={handleClickPeriod}
                  onClick={handleChoosePeriod}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" className={classes.title}>
                        <b>Period</b>
                      </Typography>
                    }
                  />
                </StyledMenuItem>
              </StyledMenu>

              {/* MENU LIST PERIOD */}
              <StyledMenu
                id="customized-menu-period"
                anchorEl={anchorElPeriod}
                keepMounted
                open={Boolean(anchorElPeriod)}
                onClose={handleClosePeriod}
                style={{ marginTop: "-160px" }}
              >
                {isShowPeriodComponent === true &&
                periodCollections.length > 0 ? (
                  periodCollections.map((item, i) => (
                    <StyledMenuItem
                      key={i}
                      onClick={(e) => handleChoosePeriodId(e, item)}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>{item.name}</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  ))
                ) : (
                  <StyledMenuItem disabled>
                    <ListItemText
                      primary={
                        <Typography
                          variant="subtitle2"
                          className={classes.title}
                        >
                          <b>Anda belum memiliki periode ;(</b>
                        </Typography>
                      }
                    />
                  </StyledMenuItem>
                )}
              </StyledMenu>

              {isShowDateRangeComponent && (
                <Fragment>
                  <DialogDateRange
                    classes={classes}
                    userTokenState={userTokenState}
                    isShowDateRangeComponent={isShowDateRangeComponent}
                    setShowDateRangeComponent={setShowDateRangeComponent}
                    onStartDate={setStartDateRangeState}
                    onEndDate={setEndDateRangeState}
                  />
                </Fragment>
              )}

              <Typography>
                {isShowTextDateRange === true ? (
                  <Chip
                    icon={<IconDateRange />}
                    label={`${moment(startDateRangeState).format(
                      "DD MMMM YYYY"
                    )} - ${moment(endDateRangeState).format("DD MMMM YYYY")}`}
                    clickable
                    size="medium"
                  />
                ) : isShowDueDateComponent === true ? (
                  <MuiPickersUtilsProvider
                    utils={LocalizedUtils}
                    locale={locale}
                  >
                    <ThemeProvider theme={theme}>
                      <Fragment>
                        <DatePicker
                          value={selectedDueDate}
                          onChange={setSelectedDueDate}
                          animateYearScrolling
                          open={isShowDueDateComponentDirectly}
                          onOpen={() => setShowDueDateComponentDirectly(true)}
                          onClose={() => setShowDueDateComponentDirectly(false)}
                          variant="dialog" // dialog, static, inline
                          disableToolbar={false}
                          format="DD MMMM YYYY"
                          label=""
                          style={{ marginLeft: 16 }}
                        />
                      </Fragment>
                    </ThemeProvider>
                  </MuiPickersUtilsProvider>
                ) : isShowTextPeriod && !isShowTextDateRange ? (
                  <Chip
                    icon={<IconDateRange />}
                    label={periodName}
                    size="medium"
                  />
                ) : (
                  <Chip
                    icon={<IconDateRange />}
                    label={`${goalData.start_date} - ${goalData.end_date}`}
                    size="medium"
                  />
                )}
              </Typography>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          {goalData.owner_id === "-1" && (
            <Box>
              <Typography
                variant="subtitle2"
                style={{ color: "#cc0707", fontSize: 12 }}
              >
                * Tidak bisa Duplicate Goal
              </Typography>
            </Box>
          )}
          <Box style={{ margin: 15 }}>
            <Button
              className={classes.button3}
              variant="contained"
              disableElevation
              onClick={() => setModalDuplicate(false)}
            >
              Batal
            </Button>
            {loadBtn ? (
              <Button
                style={{ width: 95 }}
                className={classes.button2}
                variant="contained"
              >
                <CircularProgress color="#fff" size={24} />
              </Button>
            ) : goalData.owner_id === "-1" ? (
              <Button
                style={{ width: 95 }}
                className={classes.button2}
                variant="contained"
                disabled={true}
              >
                Duplicate
              </Button>
            ) : (
              <LightTooltip title="Salin langsung" placement="top">
                <Button
                  style={{ width: 95 }}
                  className={classes.button2}
                  variant="contained"
                  onClick={handleSubmitDuplicate}
                >
                  Duplicate
                </Button>
              </LightTooltip>
            )}
          </Box>
        </DialogActions>
      </Dialog>

      <DialogDuplicateGoalv2
        classes={classes}
        goalData={goalData}
        dataInterval={dataInterval}
        openDuplicateGoal={openDuplicateGoal}
        setOpenDuplicateGoal={setOpenDuplicateGoal}
      />

      <DialogRemarksLog
        classes={classes}
        openRemarksLog={openRemarksLog}
        setOpenRemarksLog={setOpenRemarksLog}
        defaultRemarks=""
        dataRemarks={dataRemarks}
        onCloseRemarks={setModalDuplicate}
        onTriggerRemarks={context.setTriggerGoals}
        editRemarks={true}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Fragment>
  );
}

export default DialogDuplicate;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});
