import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import ContextProject from "./context/ContextProject";
import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";

import "./components/kanban/style/kanban.css";
import Kanban from "./components/kanban";
import { GET_KANBAN } from "./context/types";
import { styles } from "./style/styleProject";

const useStyles = makeStyles(styles);

const ViewKanban = () => {
  const classes = useStyles();
  const {
    dataKanban,
    setDataKanban,
    projectTDispatch,
    triggerKanban,
    projectTState,
    setProjectKanban,
  } = useContext(ContextProject);
  const project_id = window.location.pathname.split("/")[2];

  const [kanbanReady, setKanbanReady] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    projectTDispatch({
      type: GET_KANBAN,
      payload: {
        loadKanban: true,
      },
    });

    setKanbanReady(false);
    setLoading(true);

    let BASE_URL = `${URL_API}/project/${project_id}/result/kanban?group_by=${projectTState.groupBy}&options[paging][limit]=${projectTState.limitTask}&options[paging][offset]=${projectTState.offsetTask}&options[filter][search]=${projectTState.searchCards}`;

    let PERIODS_URL = `&${projectTState.periods}`;
    let ASSIGNEE_URL = `&${projectTState.filterAssignee}`;
    let ACHIEVEMENT_URL = `&${projectTState.filterAchievement}`;
    let STATUS_URL = `&${projectTState.filterStatus}`;
    let PRIORITY_URL = `&${projectTState.filterPriority}`;
    let COMPLETE_URL = `&${projectTState.filterComplete}`;
    let PHASE_URL = `&${projectTState.filterPhase}`;
    let TASK_GROUP_URL = `&${projectTState.filterByGroup}`;
    let GROUPS_URL = `&${projectTState.filterGroup}`;

    const arrFilter = [
      PERIODS_URL,
      ASSIGNEE_URL,
      ACHIEVEMENT_URL,
      STATUS_URL,
      PRIORITY_URL,
      COMPLETE_URL,
      PHASE_URL,
      TASK_GROUP_URL,
      GROUPS_URL,
    ];

    // // console.log("arrFilter", arrFilter);
    // // console.log("GROUPS_URL", GROUPS_URL);

    const combinedURL = arrFilter.filter((el) => el !== "&null").join("");

    AxiosConfig.get(`${BASE_URL}${combinedURL}`)
      .then((res) => {
        // console.log("Kanban RES", res);

        const result = res.data.data;
        const lists = result?.list;
        let listIds = window.Object.values(lists).map((k) => k.id);

        const list = { lists, listIds };

        // ======= Process
        // arg 1 : data kanban
        // arg 2 : group by for kanban
        // configDefaultPhase(list, projectTState.groupBy);

        // // console.log("Kanban list", list);

        setDataKanban(list);
        setProjectKanban(result.project);

        setKanbanReady(true);
        setLoading(false);

        projectTDispatch({
          type: GET_KANBAN,
          payload: {
            loadKanban: false,
          },
        });
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        projectTDispatch({
          type: GET_KANBAN,
          payload: {
            loadKanban: false,
          },
        });
      });
  }, [triggerKanban]);

  return (
    <Box mr={1} mt={8} p={1.5}>
      {loading && (
        <Box display="flex" alignItems="center">
          <CircularProgress
            size={16}
            style={{ color: "#d64253", marginRight: 5 }}
          />
          <Typography variant="subtitle2" className={classes.title888}>
            Loading...
          </Typography>
        </Box>
      )}
      <Box mt={2} />
      {dataKanban !== null && <Kanban classes={classes} />}
    </Box>
  );
};

export default ViewKanban;
