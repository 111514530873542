import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  Box,
} from "@material-ui/core";

import clsx from "clsx";
import moment from "moment";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import ContextReports from "../../context/ContextReports";
import handleError from "../global/handleError";

const fileDownload = require("js-file-download");

const DialogExport = ({ classes, openDialogExport, setOpenDialogExport }) => {
  const userlogin = localStorage.getItem("status_user_login");
  const getUser = JSON.parse(userlogin);

  const { tableState, filterState } = useContext(ContextReports);

  const [loading, setLoading] = useState(false);
  const [exportVal, setExportVal] = useState({
    id: 1,
    code: "csv",
    label: ".csv",
  });

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleExportVal = (data) => {
    setExportVal(data);
  };

  const handleExport = () => {
    setLoading(true);
    // ~~~~~~~~~~~ BODY For Data Table ~~~~~~~~~~~
    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: tableState.valueType,
          cal_method: tableState.calMethod,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    AxiosConfig.post(
      `${URL_API}/reporting/export/detailed/${exportVal.code}`,
      dataPost
    )
      .then((res) => {
        // console.log("EXPORT CSV", res);

        if (res.status === 200) {
          fileDownload(
            res.data,
            `PF_Report_${getUser.group_name}_${moment(new Date()).format(
              "YYYY-MM-DD hh:mm:ss"
            )}.${exportVal.code}`
          );
          setOpenDialogExport(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogExport}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Export Report ini ke dalam file</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ textAlign: "left", width: 300 }}>
        <Typography variant="subtitle2" className={classes.title333}>
          Format file
        </Typography>

        <Box>
          {exportTypes.map((item) => {
            return (
              <FormControlLabel
                key={item.id}
                label={
                  <Typography variant="subtitle2" className={classes.title555}>
                    {item.label}
                  </Typography>
                }
                style={{ paddingLeft: 15 }}
                control={
                  <Radio
                    className={classes.rootRadio}
                    value={item.code}
                    checked={exportVal.code === item.code}
                    onChange={() => handleExportVal(item)}
                    checkedIcon={
                      <span
                        className={clsx(
                          classes.iconRadio,
                          classes.checkedIconRadio
                        )}
                      />
                    }
                    icon={<span className={classes.iconRadio} />}
                  />
                }
              />
            );
          })}
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogExport(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleExport}
        >
          {loading ? "downloading..." : "Export"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogExport;

const exportTypes = [
  {
    id: 1,
    code: "csv",
    label: ".csv",
  },
  // {
  //   id: 2,
  //   code: "xlsx",
  //   label: ".xlsx",
  // },
];
