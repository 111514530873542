import React, { useState, useContext, Fragment } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Radio,
  FormControlLabel,
  List,
  Box,
} from "@material-ui/core";

import moment from "moment";
import clsx from "clsx";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";

import DialogError from "../../../../components/DialogError";
import ContextReports from "../../context/ContextReports";
import handleError from "../global/handleError";

// const fileDownload = require("js-file-download");

const DialogExportSummary = ({
  classes,
  openDialogExportSummary,
  setOpenDialogExportSummary,
}) => {
  const userlogin = localStorage.getItem("status_user_login");
  const getUser = JSON.parse(userlogin);

  const { summaryState } = useContext(ContextReports);

  const [floating, setFloating] = useState("dot");
  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // Handle Choose Floating
  const handleChooseFloating = (e, data) => {
    setFloating(e.target.value);
  };

  const handleExportSummary = () => {
    setLoading(true);

    // Convet achievement
    const calMethodGoal =
      summaryState.selectedAchievements.length > 0
        ? summaryState.selectedAchievements.map((item) => {
            return {
              formula: item,
            };
          })
        : [];
    const calMethodMa =
      summaryState.selectedAchievementsMa.length > 0
        ? summaryState.selectedAchievementsMa.map((item) => {
            return {
              formula: item,
            };
          })
        : [];

    const achievementGoal = {
      field: "goal",
      cal_method: calMethodGoal,
    };
    const achievementMa = {
      field: "ma",
      cal_method: calMethodMa,
    };

    const refGoal = [].concat(
      achievementGoal.cal_method.length > 0 ? achievementGoal : []
    );
    const refMa = [].concat(
      achievementMa.cal_method.length > 0 ? achievementMa : []
    );

    let newAchievement = [];

    if (
      summaryState.selectedEntities.includes("goal") &&
      summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal, ...refMa];
    }

    if (
      summaryState.selectedEntities.includes("goal") &&
      !summaryState.selectedEntities.includes("ma")
    ) {
      newAchievement = [...refGoal];
    }

    if (
      summaryState.selectedEntities.includes("ma") &&
      !summaryState.selectedEntities.includes("goal")
    ) {
      newAchievement = [...refMa];
    }

    // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
    const dataSummary = {
      name: "Table",
      platform: "web",
      report_format: "table",
      filter_by: {
        entity: [],
        date_range: summaryState.dateRange.code,
        // date_range: "all_time",
        user: summaryState.selectedUsers,
        unit: summaryState.selectedUnits,
        status: summaryState.selectedStatus,
        achievement: newAchievement,
        period_id: summaryState.selectedPeriod.id,
      },
      entities: summaryState.selectedEntities,
      group_by: [summaryState.selectedGroup.field],
      group_by_calculation: summaryState.selectedCalc.code,
      order_by: [summaryState.selectedOrder],
      primary_column: summaryState.selectedFields.code,
      unit_type_id: summaryState.selectedUnit.id,
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
      export_options: {
        delimeter: "comma",
        floating,
      },
    };

    AxiosConfig.post(`${URL_API}/reporting/export/summary/xlsx`, dataSummary)
      .then((res) => {
        // // console.log("EXPORT Excl", res);

        if (res.status === 200) {
          window.open(res.data.url);
          // fileDownload(
          //   res.data,
          //   `PF_Report_${getUser.group_name}_${moment(new Date()).format(
          //     "YYYY-MM-DD hh:mm:ss"
          //   )}.xlsx`
          // );
          setOpenDialogExportSummary(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogExportSummary}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Export Summary</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 300 }}>
        <Typography
          variant="subtitle2"
          className={classes.title333}
          style={{ textAlign: "center" }}
        >
          Export Report Summary ke dalam file Excel
        </Typography>

        <Box mt={3}>
          <Typography
            variant="subtitle2"
            style={{ color: "#555" }}
            className={classes.title}
          >
            Floating
          </Typography>

          <List dense>
            {floatings.map((item, i) => (
              <Fragment key={i}>
                <FormControlLabel
                  label={
                    <p
                      style={{ color: "#aaa", fontSize: 14 }}
                      className={classes.title}
                    >
                      {item.name}
                    </p>
                  }
                  control={
                    <Radio
                      className={classes.rootRadio}
                      disableRipple
                      color="default"
                      // disabled={disableFloatRadio && item.code === "comma"}
                      name={item.name}
                      value={item.code}
                      checked={item.code === floating}
                      onChange={(e) => handleChooseFloating(e, item)}
                      checkedIcon={
                        <span
                          className={clsx(
                            classes.iconRadio,
                            classes.checkedIconRadio
                          )}
                        />
                      }
                      icon={<span className={classes.iconRadio} />}
                    />
                  }
                />
              </Fragment>
            ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          disableElevation
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogExportSummary(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleExportSummary}
        >
          {loading ? "downloading..." : "Export"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogExportSummary;

const floatings = [
  { name: "Dot", code: "dot" },
  { name: "Comma", code: "comma" },
];
