import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  Grid,
  TextField,
  InputAdornment,
  Button,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";

import useSWR from "swr";

import Axios from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import {
  DEPLOYMENT,
  USER_LICENSE,
  PMO_VISIT,
} from "../../../MembershipManagement/constant-data";

import HideExtendPersonal from "../../../MembershipManagement/ComponentsDialogModal/components/HideExtendPersonal";
import ShowExtendPersonal from "../../../MembershipManagement/ComponentsDialogModal/components/ShowExtendPersonal";
import DialogCheckoutMember from "./DialogCheckoutMember";
import handleError from "../../../../Report/components/global/handleError";
import DialogError from "../../../../../components/DialogError";

const DialogPackagePersonal = ({
  classes,
  // accTypeId,
  // pckgId,
  openDialogPackagePersonal,
  setOpenDialogPackagePersonal,
  listPersonalPackage,
  listPersonalPrice,
  listPaymentMethods,
}) => {
  const user = JSON.parse(localStorage.getItem("status_user_login"));
  const accTypeId = user.membership_account_type_id;
  const pckgId = user.membership_package_id;
  // ====================================================
  // STATE COLLECTIONS
  // ====================================================
  const [packagePersonal, setPackagePersonal] = useState({});
  const [packageId, setPackageId] = useState(pckgId);
  const [accountTypeId, setAccountTypeId] = useState(accTypeId);
  const [price, setPrice] = useState("");
  const [deploymentPrice, setDeploymentPrice] = useState("");
  const [pmoPrice, setPmoPrice] = useState("");
  const [userPrice, setUserPrice] = useState("");
  const [checkoutFee, setCheckoutFee] = useState({});
  const [voucherErrorsList, setVoucherErrorsList] = useState([]);
  const [triggerPricing, setTriggerPricing] = useState(null);

  const [loadPrice, setLoadPrice] = useState(false);
  const [toggleDeployment, setToggleDeployment] = useState(false);
  const [togglePmo, setTogglePmo] = useState(false);
  const [openDialogCheckoutMember, setOpenDialogCheckoutMember] =
    useState(false);
  const [statusRes, setStatusRes] = useState(null);
  const [extendPackage, setExtendPackage] = useState(false);

  // State Calculation Form
  const [userLicenseCount, setUserLicenseCount] = useState(0);
  const [licenseDurationCount, setUserDurationCount] = useState(0);
  const [deploymentTicketCount, setDeploymentTicketCount] = useState(1);
  const [pmoVisitCount, setPmoVisitCount] = useState(1);
  const [voucherCode, setVoucherCode] = useState("");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const fetcher = (url) =>
    Axios.get(url).then((r) => {
      if (r.status === 200) {
        setStatusRes(r.status);
      }

      return r.data.data;
    });

  // ====================================================
  // Side Effect
  // ====================================================
  useEffect(() => {
    if (openDialogPackagePersonal) {
      setPackagePersonal(listPersonalPackage[0]);

      // // console.log("PRSNL PCKG", listPersonalPackage);
      // // console.log("PCKG id", pckgId);
      // // console.log("acctype id", accTypeId);

      // setPackageId(pckgId);
      // setAccountTypeId(accTypeId);
      if (listPersonalPackage[0] !== undefined) {
        // setPackageId(listPersonalPackage[0].id);
        // setAccountTypeId(listPersonalPackage[0].accountType.id);
        setUserDurationCount(listPersonalPackage[0].trial_duration_value);

        // set counting
        listPersonalPackage[0].membershipPackageItem.forEach((item) => {
          if (item.membership_item_id === USER_LICENSE) {
            setUserLicenseCount(item.membership_item_minimum_quantity);
          }
        });
      }

      // Multiply All Benefit with its price
      listPersonalPrice.forEach((data) => {
        if (data.membership_item_id === USER_LICENSE) {
          const usr_licnse = parseInt(data.price);
          setPrice(usr_licnse);
        }
      });
    }
  }, [openDialogPackagePersonal]);

  useEffect(() => {
    if (openDialogPackagePersonal && accountTypeId !== "" && packageId !== "") {
      // console.log("PCKG id", pckgId);
      // console.log("acctype id", accTypeId);
      // console.log("VCHR", voucherCode);
      const root_url = `account-management/membership-payment:calculate`;
      const act_type = `MembershipPayment[membership_account_type_id]=${accountTypeId}`;
      const pckg_id = `MembershipPayment[membership_package_id]=${packageId}`;
      const dur_val = `MembershipPayment[membership_duration_value]=${licenseDurationCount}`;
      const user_license_id = `MembershipPayment[membership_item][0][id]=${USER_LICENSE}`;
      const user_license_count = `MembershipPayment[membership_item][0][quantity]=${userLicenseCount}`;
      // additional
      const deploy_id = `MembershipPayment[membership_item][1][id]=${DEPLOYMENT}`;
      const deploy_count = `MembershipPayment[membership_item][1][quantity]=${deploymentTicketCount}`;
      const pmo_id = `MembershipPayment[membership_item][2][id]=${PMO_VISIT}`;
      const pmo_count = `MembershipPayment[membership_item][2][quantity]=${pmoVisitCount}`;
      const voucher = `MembershipPayment[membership_voucher_code]=${voucherCode}`;

      let FINAL_URL;
      const C_URL1 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${voucher}`;
      const C_URL2 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${deploy_id}&${deploy_count}&${voucher}`;
      const C_URL3 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${pmo_id}&${pmo_count}&${voucher}`;
      const C_URL4 = `${URL_API}/${root_url}?${act_type}&${pckg_id}&${dur_val}&${user_license_id}&${user_license_count}&${deploy_id}&${deploy_count}&${pmo_id}&${pmo_count}&${voucher}`;

      if (!togglePmo && !toggleDeployment) {
        FINAL_URL = C_URL1;
      } else if (!togglePmo && toggleDeployment) {
        FINAL_URL = C_URL2;
      } else if (togglePmo && !toggleDeployment) {
        FINAL_URL = C_URL3;
      } else if (togglePmo && toggleDeployment) {
        FINAL_URL = C_URL4;
      }

      Axios.get(FINAL_URL)
        .then((res) => {
          const result = res.data.data;

          // console.log("CAlCulaTE", result);

          if (res.status === 200) {
            setPrice(result.total_item_price);
            setCheckoutFee(result);

            result.pricePerItem.forEach((item) => {
              if (item.id === DEPLOYMENT) {
                setDeploymentPrice(item.total_price_after_duration);
              } else if (item.id === PMO_VISIT) {
                setPmoPrice(item.total_price_after_duration);
              } else if (item.id === USER_LICENSE) {
                if (
                  userLicenseCount >= 25 &&
                  item.name.includes("charged lapsed")
                ) {
                  setUserPrice(item.total_price_after_duration);
                  // console.log("ITEM CHARG", item);
                } else if (
                  userLicenseCount < 25 &&
                  !item.name.includes("charged lapsed")
                ) {
                  // console.log("ITEM T CHARG", item);
                  setUserPrice(item.total_price_after_duration);
                }
              }
            });
          }
        })
        .catch((error) => {
          // // console.log("Ew Ew", error);
          setStatusRes(error.response.status);
          setVoucherErrorsList(error.response.data.info.errors);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [
    openDialogPackagePersonal,
    licenseDurationCount,
    userLicenseCount,
    deploymentTicketCount,
    pmoVisitCount,
    toggleDeployment,
    togglePmo,
    voucherCode,
  ]);

  // Handle For User License Input
  const handleUserLicense = (e) => {
    const value = parseInt(e.target.value);
    // setUserLicenseCount(e.target.value);

    if (value > 0 && value <= 500) {
      setUserLicenseCount(e.target.value);
    } else if (value > 500) {
      setUserLicenseCount(500);
    } else if (value < 1) {
      setTimeout(() => setUserLicenseCount(1), 1000);
    }
  };

  // Handle For License Duration Input
  const handleLicenseDuration = (e) => {
    const value = parseInt(e.target.value);
    // setUserDurationCount(e.target.value);

    // console.log("ini VAL", value);
    // console.log("ini TYP", typeof value);

    if (value < 1) {
      setUserDurationCount(1);
    } else if (isNaN(value)) {
      setUserDurationCount(1);
    } else {
      setUserDurationCount(e.target.value);
    }
  };

  // Handle For Deployment Ticket Input
  const handleDeplymentTicket = (e) => {
    const value = parseInt(e.target.value);
    // setDeploymentTicketCount(e.target.value);

    if (value >= 1 && value <= 1000) {
      setDeploymentTicketCount(e.target.value);
    } else if (value > 1000) {
      setDeploymentTicketCount(1000);
    } else if (value < 1) {
      setDeploymentTicketCount(1);
    }
  };

  // Handle For PMO Visit Ticket Input
  const handlePmoVisitTicket = (e) => {
    const value = parseInt(e.target.value);
    // setPmoVisitCount(e.target.value);
    if (value < 1) {
      setPmoVisitCount(1);
    } else if (value >= 1 && value <= 1000) {
      setPmoVisitCount(e.target.value);
    } else if (value > 1000) {
      setPmoVisitCount(1000);
    }
  };

  return (
    <Dialog
      open={openDialogPackagePersonal}
      aria-labelledby="customized-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl" //*Default "md"
      style={{ height: "auto", width: "100%" }}
    >
      <DialogTitle id="customized-dialog-title">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5" className={classes.title}>
            <b>Berlangganan Paket Personal</b>
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpenDialogPackagePersonal(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 1050 }}>
        <Box marginTop={2} display="flex" justifyContent="space-between">
          <Box className={classes.cardPackage}>
            <Typography variant="subtitle1" className={classes.txtTitle}>
              PERSONAL
            </Typography>
            <Typography variant="h5" className={classes.txtPrice}>
              {/* Rp {new Intl.NumberFormat("id-ID").format(price)} */}
              Rp {new Intl.NumberFormat("id-ID").format(userPrice)}
            </Typography>
            <Typography variant="subtitle2" className={classes.txtDetail}>
              Harga lisensi Rp 30.000 / User
            </Typography>
          </Box>

          <Box></Box>
          <Box></Box>
        </Box>

        {/* ========= Extend Package (START) ========= */}

        <Box
          marginTop={2}
          marginBottom={3}
          padding={1.2}
          className={classes.extendPckgBg}
        >
          {!extendPackage ? (
            <HideExtendPersonal
              classes={classes}
              onExtendPackage={setExtendPackage}
              userLicenseCount={userLicenseCount}
              licenseDurationCount={licenseDurationCount}
              pmoVisitCount={pmoVisitCount}
              togglePmo={togglePmo}
            />
          ) : (
            <ShowExtendPersonal
              classes={classes}
              userLicenseCount={userLicenseCount}
              licenseDurationCount={licenseDurationCount}
              pmoVisitCount={pmoVisitCount}
              onExtendPackage={setExtendPackage}
              onLicenseDuration={handleLicenseDuration}
              onUserLicense={handleUserLicense}
              onPmoVisitTicket={handlePmoVisitTicket}
              togglePmo={togglePmo}
              onTogglePmo={setTogglePmo}
              pmoPrice={pmoPrice}
              userPrice={userPrice}
            />
          )}
        </Box>

        {/* ========= Extend Package (END) ========= */}

        <>
          <Typography variant="h6" className={classes.title}>
            <b>Tiket Deployment</b>
          </Typography>

          <Box display="flex" justifyContent="space-between" marginTop={1}>
            <Box display="flex" alignItems="center">
              <ConfirmationNumberIcon
                style={{ color: "#262261", fontSize: 30, marginRight: 7 }}
              />
              <Typography
                variant="subtitle1"
                className={classes.title}
                style={{ color: "#d1354a", marginRight: 5 }}
              >
                <b>Rp 30.000/Tiket</b>
              </Typography>
              <Typography variant="subtitle2" className={classes.title}>
                ( min 1 )
              </Typography>
            </Box>
            {!toggleDeployment ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.dashedBtn}
                onClick={() => setToggleDeployment(true)}
              >
                <AddIcon style={{ fontSize: 15, marginRight: 5 }} />
                <Typography variant="subtitle2" className={classes.title}>
                  <b>Tiket Deployment</b>
                </Typography>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column">
                <TextField
                  variant="outlined"
                  size="small"
                  type="number"
                  label="Tiket Deployment"
                  value={deploymentTicketCount}
                  onChange={(e) => handleDeplymentTicket(e)}
                  style={{ width: 250 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Tiket</InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <p
                  className={classes.txtDashedBtn}
                  onClick={() => {
                    setToggleDeployment(false);
                    setDeploymentPrice("");
                  }}
                >
                  Batal
                </p>
              </Box>
            )}
            <Typography variant="h6" className={classes.title}>
              <b>Rp {new Intl.NumberFormat("id-ID").format(deploymentPrice)}</b>
            </Typography>
          </Box>
        </>
      </DialogContent>
      <DialogActions
        style={{
          marginTop: 20,
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#e9e9e9",
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "#333" }}
          >
            Total Harga
          </Typography>
          <Typography
            variant="h4"
            className={classes.title}
            style={{ color: "#262261" }}
          >
            {/* <b>Rp {new Intl.NumberFormat("id-ID").format(price)}</b> */}
            <b>Rp {new Intl.NumberFormat("id-ID").format(userPrice)}</b>
          </Typography>
        </Box>
        <Button
          className={classes.button2}
          variant="outlined"
          style={{ width: 100 }}
          onClick={() => setOpenDialogCheckoutMember(true)}
        >
          Lanjut
        </Button>
      </DialogActions>

      <DialogCheckoutMember
        classes={classes}
        openDialogCheckoutMember={openDialogCheckoutMember}
        setOpenDialogCheckoutMember={setOpenDialogCheckoutMember}
        setOpenDialogPackage={setOpenDialogPackagePersonal}
        checkoutFee={checkoutFee}
        listPaymentMethods={listPaymentMethods}
        duration={licenseDurationCount}
        membership="Personal"
        paket="Personal"
        onVoucher={setVoucherCode}
        voucherErrorsList={voucherErrorsList}
        statusRes={statusRes}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogPackagePersonal;
