import React, { useContext } from "react";
import { CircularProgress, Box, Typography } from "@material-ui/core";

import ContextReports from "../../../../context/ContextReports";

import TableGoal from "./TableGoal";
import TableMA from "./TableMA";

const TableDetail = ({ classes }) => {
  const { tableSmrState } = useContext(ContextReports);
  const goals = tableSmrState.dataDetail.goals;
  const mas = tableSmrState.dataDetail.mas;
  const loading = tableSmrState.loadDetail;

  // // console.log("DATAW DETS", tableSmrState.dataDetail);

  return (
    <Box>
      {goals !== undefined && goals.length > 0 && (
        <>
          <Typography variant="subtitle1" clasName={classes.title555}>
            Goal
          </Typography>

          <Box mt={2} mb={4}>
            <TableGoal classes={classes} goals={goals} loading={loading} />
          </Box>
        </>
      )}

      {mas !== undefined && mas.length > 0 && (
        <>
          <Typography variant="subtitle1" clasName={classes.title555}>
            Measured Activity
          </Typography>

          <Box mt={2}>
            <TableMA classes={classes} mas={mas} loading={loading} />
          </Box>
        </>
      )}

      {goals !== undefined &&
        goals.length === 0 &&
        mas !== undefined &&
        mas.length === 0 && (
          <Typography variant="subtitle1" clasName={classes.title555}>
            No Data
          </Typography>
        )}
    </Box>
  );
};

export default TableDetail;
