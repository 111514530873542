import React, { useEffect, useState } from "react";
import { styles } from "../Style/StyleAttendanceHome";
import { navigate } from "hookrouter";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  LinearProgress,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";
import { isEmpty } from "lodash";

import { URL_API } from "../../../../constants/config-api";
import axiosConfig from "../../../../constants/config-axios";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const TomorrowCard = ({ data }) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background:
            "linear-gradient(to left bottom, #CE354A 30%, #C62B40 90%)",
          borderRadius: 11,
          padding: "10px 20px",
          margin: "10px 10px",
        }}
      >
        <CardContent style={{ flex: "1 0 auto" }}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              {!isEmpty(data?.tomorrow_schedule) ? (
                <>
                  <Typography
                    variant="h5"
                    style={{
                      color: "white",
                      marginTop: 20,
                      fontWeight: "bold",
                    }}
                  >
                    {data.tomorrow_schedule?.shift?.schedule_in} -{" "}
                    {data.tomorrow_schedule?.shift?.schedule_out}
                  </Typography>
                  <Typography variant="subtitle1" style={{ color: "white" }}>
                    <Moment
                      add={{ days: 1 }}
                      format="dddd, D MMMM YYYY"
                      locale="id"
                      interval={1}
                      unit="seconds"
                    ></Moment>
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ color: "white", fontWeight: "bold" }}
                  >
                    Di {data.tomorrow_schedule?.location?.location_name}
                  </Typography>
                </>
              ) : (
                <Typography
                  variant="h5"
                  style={{
                    color: "white",
                    marginTop: 20,
                    fontWeight: "bold",
                  }}
                >
                  TIDAK ADA JADWAL UNTUK BESOK
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              <img
                src={require("../../../../assets/images/clock.png")}
                alt="schedule"
                width={100}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </MuiThemeProvider>
  );
};

export default TomorrowCard;
