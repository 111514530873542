import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LiveHelpRoundedIcon from "@material-ui/icons/HelpRounded";

import handleError from "../../../../Report/components/global/handleError";
import AxiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";

import DialogError from "../../../../../components/DialogError";
import MyLang from "../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../constants/config-lang";

const DialogConfirmApply = ({
  classes,
  openDialogConfirmApply,
  setOpenDialogConfirmApply,
  achievementGoal,
  achievementMa,
  goalFactor,
  maFactor,
  period,
  enabledGoal,
  enabledMa,
  setModalResponse200,
  // for compare ori value
  orilGoalAchieve,
  oriMaAchieve,
  oriGoalFactor,
  oriMaFactor,
  lockSetting,
}) => {
  const [loading, setLoading] = useState(false);
  const [successApply, setSuccessApply] = useState(false);
  const [checkedGoal, setCheckedGoal] = useState(false);
  const [checkedMa, setCheckedMa] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogConfirmApply) {
      AxiosConfig.get(`${URL_API}/account-management/general-settings/goal`)
        .then((res) => {
          const result = res.data.data;
          // console.log("RES GS Goal", res);
          const general_setting =
            result.fields.general_setting_id.default_value;
          if (res.status === 200) {
            const value = general_setting[0].value;
            if (value !== null) {
              if (value.status !== undefined) {
                setCheckedGoal(value.status === "enabled" ? true : false);
              }
            }
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
        });

      // -------- GET MA Settings --------
      AxiosConfig.get(`${URL_API}/account-management/general-settings/ma`)
        .then((res) => {
          const result = res.data.data;
          // console.log("RES GS MA", res);
          const general_setting =
            result.fields.general_setting_id.default_value;
          if (res.status === 200) {
            const value = general_setting[0].value;
            if (value !== null) {
              if (value.status !== undefined) {
                setCheckedMa(value.status === "enabled" ? true : false);
              }
            }
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
        });
    }
  }, [openDialogConfirmApply]);

  // ==========================================================
  // make condition | comparing value
  let SETTINGS = [];

  if (
    achievementGoal.min != orilGoalAchieve.min ||
    achievementGoal.max != orilGoalAchieve.max ||
    enabledGoal !== checkedGoal
  ) {
    SETTINGS.push({
      id: "1",
      slug: "goal_achievement",
      value: {
        min: achievementGoal.min,
        max: achievementGoal.max,
        status: enabledGoal ? "enabled" : "disabled",
      },
    });
  }

  if (
    achievementMa.min != oriMaAchieve.min ||
    achievementMa.max != oriMaAchieve.max ||
    enabledMa !== checkedMa
  ) {
    SETTINGS.push({
      id: "2",
      slug: "ma_achievement",
      value: {
        min: achievementMa.min,
        max: achievementMa.max,
        status: enabledMa ? "enabled" : "disabled",
      },
    });
  }

  if (goalFactor != oriGoalFactor || maFactor != oriMaFactor) {
    SETTINGS.push({
      id: "3",
      slug: "overall_performance",
      value: {
        goal: goalFactor,
        ma: maFactor,
      },
    });
  }

  if (period !== "") {
    SETTINGS.push({
      id: "4",
      slug: "default_period",
      value: {
        period_id: period,
      },
    });
  }

  const handleApplySettings = () => {
    setLoading(true);

    const data = {
      settings: SETTINGS,
    };

    // console.log("DATA", data);

    AxiosConfig.post(
      `${URL_API}/account-management/general-settings/general`,
      data
    )
      .then((res) => {
        // const result = res.data.data;
        // console.log("RES GS", res);

        if (res.status === 200) {
          setModalResponse200(true);
          setSuccessApply(true);
          // setOpenDialogConfirmApply(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error : ", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={openDialogConfirmApply}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {successApply ? (
          <CheckCircleOutlineIcon style={{ fontSize: 44, color: "#2ecc71" }} />
        ) : (
          <LiveHelpRoundedIcon style={{ fontSize: 44, color: "#d1354a" }} />
        )}
        <Typography variant="subtitle1" className={classes.title333}>
          <b>
            {MyLang()
              ? bahasa.titleConfirmSetting
              : english.titleConfirmSetting}
          </b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 400 }}>
        <Box textAlign="center">
          <Typography variant="subtitle2" className={classes.title888}>
            {MyLang() ? bahasa.descConfirmSetting : english.descConfirmSetting}
          </Typography>
        </Box>

        {successApply && (
          <Box mt={3}>
            <Alert severity="success">
              <AlertTitle>Success!</AlertTitle>
              {MyLang()
                ? bahasa.alertConfirmSetting
                : english.alertConfirmSetting}
            </Alert>
          </Box>
        )}
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          disabled={loading}
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogConfirmApply(false)}
        >
          {successApply
            ? MyLang()
              ? bahasa.btnClose
              : english.btnClose
            : MyLang()
            ? bahasa.btnCancel
            : english.btnCancel}
        </Button>

        {!successApply && (
          <Button
            variant="contained"
            size="medium"
            fullWidth
            disabled={loading || lockSetting}
            className={classes.button1}
            onClick={handleApplySettings}
          >
            {loading
              ? MyLang()
                ? bahasa.btnApply
                : english.btnApply
              : MyLang()
              ? bahasa.btnApplying
              : english.btnApplying}
          </Button>
        )}
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogConfirmApply;
