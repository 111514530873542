import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ListItemGoalResult from "./ListItemGoalResult";
import moment from "moment";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const ListItemTitleResult = ({
  classes,
  item,
  goalDetailState,
  setFireGoalDetail,
  userToken,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <TableBody>
      <TableRow>
        <TableCell
          align="left"
          //   className={classes.titleHead}
          // style={{
          //   width: 120,
          // }}
        >
          <Typography
            variant="h6"
            className={classes.title}
            style={{ color: item.current_cyle ? "#55dc87" : "#999" }}
          >
            <b>{FormatDecimal(item.result_total)}</b>
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            width: 200,
          }}
        >
          <Typography
            variant="subtitle2"
            className={classes.title555}
            style={{ opacity: item.current_cyle ? 1 : 0.7 }}
          >
            {item?.results?.length} results
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{ opacity: item.current_cyle ? 1 : 0.7 }}
        >
          <Typography variant="subtitle2" className={classes.title555}>
            {/* <i>{moment(item.latest_result).format("DD MMM YYYY HH:mm")}</i> */}
            {item.title}
          </Typography>
          <Typography
            variant="caption"
            className={classes.title555}
            style={{ opacity: item.current_cyle ? 1 : 0.7 }}
          >
            {/* <i>{moment(item.latest_result).format("DD MMM YYYY HH:mm")}</i> */}
            {item.time_left}
          </Typography>
        </TableCell>
        <TableCell
          align="left"
          className={classes.titleHead}
          style={{
            width: 70,
          }}
        >
          <Typography
            variant="subtitle1"
            className={classes.title555}
            style={{ opacity: item.current_cyle ? 1 : 0.7 }}
          >
            {item.evidence_total}
          </Typography>
        </TableCell>
        <TableCell align="right" className={classes.titleHead}>
          <IconButton onClick={() => setOpen((prev) => !prev)}>
            {open ? (
              <KeyboardArrowUpIcon
                style={{ opacity: item.current_cyle ? 1 : 0.7 }}
              />
            ) : (
              <KeyboardArrowDownIcon
                style={{ opacity: item.current_cyle ? 1 : 0.7 }}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* <Collapse in={open} unmountOnExit> */}
      {open &&
        item.hasOwnProperty("results") &&
        item?.results.length > 0 &&
        item?.results.map((result, idx) => {
          return (
            <ListItemGoalResult
              key={idx}
              item={result}
              classes={classes}
              currentCyle={item.current_cyle}
              goalDetailState={goalDetailState}
              setFireGoalDetail={setFireGoalDetail}
              userToken={userToken}
            />
          );
        })}
      {/* </Collapse> */}
    </TableBody>
  );
};

export default ListItemTitleResult;
