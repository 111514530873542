import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";

import { useDropzone } from "react-dropzone";
import moment from "moment";

import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextProject from "../../context/ContextProject";

import FormatBytes from "../../../../utilities/FormatBytes";
import DialogErrorImport from "../../../../components/DialogErrorImport";

const csv = require("csvtojson");

const DialogImportProject = ({
  classes,
  project,
  openImportProject,
  setOpenImportProject,
}) => {
  const { setTriggerTask, setOpenSuccessImportTasks } =
    useContext(ContextProject);

  const [files, setFiles] = useState([]);
  const [csvs, setCsvs] = useState([]);
  const [loading, setLoading] = useState(false);
  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState("");

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles(acceptedFiles);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = () => {
          const fileAsBinaryString = reader.result;

          csv({
            noheader: true,
            output: "json",
            delimiter: "auto",
          })
            .fromString(fileAsBinaryString)
            .then((csvRows) => {
              const toJson = [];
              csvRows.forEach((aCsvRow, i) => {
                if (i !== 0) {
                  const builtObject = {};

                  Object.keys(aCsvRow).forEach((aKey) => {
                    const valueToAddInBuiltObject = aCsvRow[aKey];
                    const keyToAddInBuiltObject = csvRows[0][aKey];
                    builtObject[keyToAddInBuiltObject] =
                      valueToAddInBuiltObject;
                  });

                  toJson.push(builtObject);
                }
              });

              if (toJson.length > 0) {
                setCsvs(toJson);
                // // console.log("TO JSON", toJson);
              }
            });
        };

        reader.onabort = () =>
          // console.log("file reading was aborted");
          (reader.onerror = () =>
            // console.log("file reading has failed");

            reader.readAsBinaryString(file));
      });
    },
    accept: ".csv, text/csv",
  });

  const handleDownloadSample = () => {
    window
      .open(
        "https://api-test.performate.id/template/CSV_Projects_Taks_Import.csv",
        "_blank"
      )
      .focus();
  };

  // Handle Remove File From List
  const handleRemoveFile = (file) => {
    setFiles((prev) => prev.filter((item) => item.name !== file.name));
  };

  const handleImportProject = () => {
    const csvList = csvs.map((c) => {
      const due = c?.due_date !== "" && c?.due_date.split(" ");
      const start = c?.start_date !== "" && c?.start_date.split(" ");
      const end = c?.end_date !== "" && c?.end_date.split(" ");
      const completion =
        c?.completion_date !== "" && c?.completion_date.split(" ");

      const newdue = due
        ? due.length > 1
          ? due[0].split("/").reverse().join("-").concat(` ${due[1]}`)
          : due[0].split("/").reverse().join("-")
        : "";
      const newstart = start
        ? start.length > 1
          ? start[0].split("/").reverse().join("-").concat(` ${start[1]}`)
          : start[0].split("/").reverse().join("-")
        : "";
      const newend = end
        ? end.length > 1
          ? end[0].split("/").reverse().join("-").concat(` ${end[1]}`)
          : end[0].split("/").reverse().join("-")
        : "";
      const newCompletion = completion
        ? completion.length > 1
          ? completion[0]
              .split("/")
              .reverse()
              .join("-")
              .concat(` ${completion[1]}`)
          : completion[0].split("/").reverse().join("-")
        : "";

      return {
        title: c?.title,
        owner_email: c?.owner_email,
        is_complete: c?.is_complete,
        priority: c?.priority,
        task_group_name: c?.task_group,
        start_date:
          newstart === "" ? "" : moment(newstart).format("YYYY-MM-DD"),
        due_date:
          newdue === "" ? "" : moment(newdue).format("YYYY-MM-DD HH:mm:ss"),
        end_date: newend === "" ? "" : moment(newend).format("YYYY-MM-DD"),
        completion_date:
          newCompletion === ""
            ? ""
            : moment(newCompletion).format("YYYY-MM-DD HH:mm:ss"),
        status: c?.status,
        tags: c?.tags,
        description: c?.description,
        target_result: c?.target_result !== "" ? Number(c?.target_result) : "",
        actual_result: c?.actual_result !== "" ? Number(c?.actual_result) : "",
        remarks: c?.notes,
        entity_type: c.entity_type,
        parent_id: c.parent_id,
        local_id: c.local_id,
      };
    });

    for (let i = 0; i < csvList.length; i++) {
      if (
        csvList[i].actual_result === "" &&
        (csvList[i].status !== "DONE" || csvList[i].status !== "DOING")
      ) {
        delete csvList[i].actual_result;
      }
      if (csvList[i].end_date === "" && csvList[i].status !== "DONE") {
        delete csvList[i].end_date;
      }
      if (csvList[i].completion_date === "" && csvList[i].status !== "DONE") {
        delete csvList[i].completion_date;
      }
      if (csvList[i].target_result === "") {
        delete csvList[i].target_result;
      }
      if (csvList[i].task_group_name === "") {
        delete csvList[i].task_group_name;
      }
      if (csvList[i].tags === "") {
        delete csvList[i].tags;
      }
      if (csvList[i].remarks === "") {
        delete csvList[i].remarks;
      }
      if (csvList[i].tags === "") {
        delete csvList[i].tags;
      }
      if (csvList[i].description === "") {
        delete csvList[i].description;
      }
      if (csvList[i].entity_type === "project_task") {
        delete csvList[i].parent_id;
      }
    }

    const data = {
      ProjectGoals: {
        id: project?.id,
        data: Object.assign({}, csvList),
      },
    };

    // // console.log("DATA", data);
    // // console.log("csvList", csvList);
    // // console.log("scvs", csvs);

    setLoading(true);

    axiosConfig
      .post(`${URL_API}/project/import`, data)
      .then((res) => {
        // console.log("Res CSV : ", res);

        if (res.status === 200) {
          setTriggerTask(Math.floor(Math.random() * 100));
          setOpenImportProject(false);
          setLoading(false);
          setOpenSuccessImportTasks(true);
        }
      })
      .catch((error) => {
        // console.log("ERROR : ", error);
        setLoading(false);

        if (error.response !== undefined) {
          let resStatus = error.response.status;

          setErrorStatus(resStatus);

          if ([422, 400].includes(resStatus)) {
            if (
              error?.response?.data?.info?.errors !== null &&
              error?.response?.data?.info?.errors !== undefined
            ) {
              if (error?.response?.data?.info?.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error?.response?.data?.info?.errors);
              }
            }
          }

          if ([502, 503, 500, 403, 429, 405, 404, 401].includes(resStatus)) {
            setOpenDialogError(true);

            if (error.response?.data?.hasOwnProperty("message")) {
              setTextErrorMessage(error.response.data.message);
            }
            if (error.response?.data?.info?.hasOwnProperty("message")) {
              setTextErrorMessage(error.response.data.info.message);
            }
          }
        }
      });
  };

  return (
    <Dialog
      open={openImportProject}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        <Box p={1}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Import to Project</b>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <div className={classes.uploadContainer}>
          <div
            {...getRootProps()}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadOutlinedIcon
              fontSize="large"
              style={{ color: "#E0E0E0" }}
            />
            <span className={classes.title}>Drag 'n' drop some files here</span>
            <span>or</span>
            <Button
              size="small"
              variant="outlined"
              className={classes.uploadBtn}
            >
              Choose File
            </Button>
          </div>
        </div>

        <Box>
          <List dense>
            {files.length > 0 &&
              files.map((file, i) => (
                <ListItem key={i}>
                  <ListItemIcon>
                    <DescriptionOutlinedIcon style={{ color: "#888" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2">{file?.name}</Typography>
                    }
                    secondary={
                      <Typography variant="caption" style={{ color: "#bbb" }}>
                        {FormatBytes(file?.size)}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      size="small"
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleRemoveFile(file)}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </Box>
      </DialogContent>

      <DialogActions
        style={{
          alignItems: "center",
          margin: 15,
          marginTop: 20,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            size="small"
            variant="outlined"
            className={classes.btnSmallTrans}
            style={{ fontSize: 13, fontWeight: "normal" }}
            onClick={handleDownloadSample}
          >
            Download Sample Import Project
          </Button>
        </Box>

        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            size="medium"
            className={classes.button}
            onClick={loading ? null : () => setOpenImportProject(false)}
          >
            Cancel
          </Button>
          <Box mx={1} />
          <Button
            variant={loading || files.length === 0 ? "contained" : "outlined"}
            size="medium"
            disabled={loading || files.length === 0}
            className={classes.button1}
            onClick={handleImportProject}
          >
            {loading ? "Importing..." : "Import"}
          </Button>
        </Box>
      </DialogActions>

      <DialogErrorImport
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogImportProject;
