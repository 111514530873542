import React from "react";
import { styles } from "../Style/StyleHistoryClockInOut";

import {
  makeStyles,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import DateRange from "@material-ui/icons/DateRange";

const FilterDate = ({ bulan, tahun, setBulan }) => {
  return (
    <div
      style={{
        marginLeft: 100,
        marginRight: 100,
        marginTop: 20,
        textAlign: "center",
      }}
    >
      <FormControl
        variant="outlined"
        style={{
          width: "40%",
        }}
      >
        <Select
          value={bulan}
          inputProps={{
            name: "month",
            id: "month-filter",
          }}
          style={{
            fontFamily: "Nunito",
            fontWeight: "bold",
            fontSize: "0.8rem",
            color: "#221E5B",
          }}
          startAdornment={
            <InputAdornment position="start">
              <DateRange />
            </InputAdornment>
          }
          onChange={(e) => setBulan(e.target.value)}
        >
          <MenuItem value={"01"}>Januari {tahun}</MenuItem>
          <MenuItem value={"02"}>Februari {tahun}</MenuItem>
          <MenuItem value={"03"}>Maret {tahun}</MenuItem>
          <MenuItem value={"04"}>April {tahun}</MenuItem>
          <MenuItem value={"05"}>Mei {tahun}</MenuItem>
          <MenuItem value={"06"}>Juni {tahun}</MenuItem>
          <MenuItem value={"07"}>Juli {tahun}</MenuItem>
          <MenuItem value={"08"}>Agustus {tahun}</MenuItem>
          <MenuItem value={"09"}>September {tahun}</MenuItem>
          <MenuItem value={"10"}>Oktober {tahun}</MenuItem>
          <MenuItem value={"11"}>November {tahun}</MenuItem>
          <MenuItem value={"12"}>Desember {tahun}</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterDate;
