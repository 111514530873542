import React, { useContext, useState } from "react";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  IconButton,
  Button,
  Collapse,
  TextField,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";

import {
  ASSIGNEE_CONTENT_FILTER,
  DRAWER_KANBAN,
  FILTER_TASK,
} from "../../../context/types";
import ContextProject from "../../../context/ContextProject";

import icon_high from "../../../../../assets/images/SVG/icon_high.svg";
import icon_medium from "../../../../../assets/images/SVG/icon_medium.svg";
import icon_low from "../../../../../assets/images/SVG/icon_low.svg";
import FilterAssignee from "./FilterAssignee";
import FilterListChecnbox from "./FilterListChecnbox";
import { Autocomplete } from "@material-ui/lab";

const ExpandFilterKanban = ({ name, handleOpen, isOpen }) => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      px={2}
    >
      <Typography variant="subtitle1" style={{ color: "#555" }}>
        {name}
      </Typography>

      <IconButton size="small" onClick={handleOpen}>
        {isOpen ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
      </IconButton>
    </Box>

    <Box my={1}>
      <Divider />
    </Box>
  </>
);

const DrawerKanban = ({ classes, ownerCollections, listGroup }) => {
  const { projectTState, projectTDispatch, setTriggerTask, setTriggerKanban } =
    useContext(ContextProject);

  const [openOwner, setOpenOwner] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openAchievement, setOpenAchievement] = useState(false);
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [openCompleted, setOpenCompleted] = useState(false);
  const [openTaskGroup, setOpenTaskGroup] = useState(false);

  const [periods, setPeriods] = useState([]);
  const [achievement, setAchievement] = useState([]);
  const [ownerId, setOwnerId] = useState([]);
  const [status, setStatus] = useState([]);
  const [priority, setPriority] = useState([]);
  const [complete, setComplete] = useState([]);
  const [groups, setGroups] = useState([]);

  const [selectTaskGroup, setSelectTaskGroup] = useState(null);

  const isSelectedPeriods = (id) => periods.indexOf(id) !== -1;
  const isSelectedAchievement = (id) => achievement.indexOf(id) !== -1;
  const isSelectedStatus = (id) => status.indexOf(id) !== -1;
  const isSelectedPriority = (id) => priority.indexOf(id) !== -1;
  const isSelectedCompleted = (id) => complete.indexOf(id) !== -1;
  const isSelectedGroups = (id) => groups.indexOf(id) !== -1;

  const handleOwner = () => setOpenOwner((s) => !s);
  const handleStatus = () => setOpenStatus((s) => !s);
  const handlePriority = () => setOpenPriority((s) => !s);
  const handleAchievement = () => setOpenAchievement((s) => !s);
  const handleStartDate = () => setOpenStartDate((s) => !s);
  const handleEndDate = () => setOpenEndDate((s) => !s);
  const handleCompleted = () => setOpenCompleted((s) => !s);
  const handleTaskGroup = () => setOpenTaskGroup((s) => !s);

  const handleChooseGroup = (e, data) => {
    if (data !== null) {
      // // console.log("Task group", data);
      setSelectTaskGroup(data);
    } else {
      setSelectTaskGroup(null);
    }
  };

  const handleClose = () => {
    projectTDispatch({
      type: DRAWER_KANBAN,
      payload: {
        drawerKanban: false,
      },
    });
  };

  const handleFilterKanban = () => {
    const owners =
      ownerId.length > 0
        ? ownerId
            .map((el, i) => {
              return `options[filter][fields][owner_id][${i}]=${el.id}`;
            })
            .join("&")
        : null;

    const newPeriods =
      periods.length > 0
        ? periods
            .map((el, i) => {
              return `options[filter][periods][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newAchievement =
      achievement.length > 0
        ? achievement
            .map((el, i) => {
              return `options[filter][achievements][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newStatus =
      status.length > 0
        ? status
            .map((el, i) => {
              return `options[filter][fields][status][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newPriority =
      priority.length > 0
        ? priority
            .map((el, i) => {
              return `options[filter][fields][priority][${i}]=${el}`;
            })
            .join("&")
        : null;

    const newComplete =
      complete.length > 0
        ? complete
            .map((el, i) => {
              return `options[filter][fields][is_complete]=${el}`;
            })
            .join("&")
        : null;

    const newGroups =
      groups.length > 0
        ? groups
            .map((el, i) => {
              return `options[filter][fields][task_group_id][${i}]=${el}`;
            })
            .join("&")
        : null;

    const asignees =
      ownerId.length > 0
        ? ownerId.map((el, i) => {
            return `${el?.member?.first_name} ${el?.member?.last_name}`;
          })
        : null;

    // // console.log("newGroups", newGroups);

    projectTDispatch({
      type: FILTER_TASK,
      payload: {
        periods: newPeriods,
        filterAssignee: owners,
        filterAchievement: newAchievement,
        filterStatus: newStatus,
        filterPriority: newPriority,
        filterComplete: newComplete,
        // filterGroup: selectTaskGroup !== null ? selectTaskGroup?.name : "",
        filterGroup: newGroups,
        filterPhase: projectTState.filterPhase,
        filterByGroup: projectTState.filterByGroup,
      },
    });

    projectTDispatch({
      type: ASSIGNEE_CONTENT_FILTER,
      payload: {
        assigneeFilterContent: asignees,
      },
    });

    setTriggerTask(Math.floor(Math.random() * 100));
    setTriggerKanban(Math.floor(Math.random() * 100));

    handleClose();
  };

  // // console.log("listGroup", listGroup);

  return (
    <Drawer
      anchor="right"
      open={projectTState.drawerKanban}
      onClose={handleClose}
    >
      <Box minWidth={350}>
        <Box p={2}>
          <Typography variant="h6" className={classes.title555}>
            <b>Filter Kanban</b>
          </Typography>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        <ExpandFilterKanban
          name="Asignee"
          handleOpen={handleOwner}
          isOpen={openOwner}
        />
        <Collapse in={openOwner} timeout="auto" unmountOnExit>
          <FilterAssignee
            classes={classes}
            ownerCollections={ownerCollections}
            onOwnerId={setOwnerId}
          />
        </Collapse>

        {/* <ExpandFilterKanban
          name="Status"
          handleOpen={handleStatus}
          isOpen={openStatus}
        />
        <Collapse in={openStatus} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={statusCollections}
            isSelected={isSelectedStatus}
            moduls={status}
            onModuls={setStatus}
          />
        </Collapse> */}

        <ExpandFilterKanban
          name="Priority"
          handleOpen={handlePriority}
          isOpen={openPriority}
        />
        <Collapse in={openPriority} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={priorityCollections}
            isSelected={isSelectedPriority}
            moduls={priority}
            onModuls={setPriority}
            origin="code"
          />
        </Collapse>

        <ExpandFilterKanban
          name="Achievement"
          handleOpen={handleAchievement}
          isOpen={openAchievement}
        />
        <Collapse in={openAchievement} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={achievementCollections}
            isSelected={isSelectedAchievement}
            moduls={achievement}
            onModuls={setAchievement}
            origin="code"
          />
        </Collapse>

        <ExpandFilterKanban
          name="Start Date"
          handleOpen={handleStartDate}
          isOpen={openStartDate}
        />
        <Collapse in={openStartDate} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={periodCollections.slice(0, 3)}
            isSelected={isSelectedPeriods}
            moduls={periods}
            onModuls={setPeriods}
            origin="code"
          />
        </Collapse>

        <ExpandFilterKanban
          name="End Date"
          handleOpen={handleEndDate}
          isOpen={openEndDate}
        />
        <Collapse in={openEndDate} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={periodCollections.slice(3, 8)}
            isSelected={isSelectedPeriods}
            moduls={periods}
            onModuls={setPeriods}
            origin="code"
          />
        </Collapse>

        <ExpandFilterKanban
          name="Completed"
          handleOpen={handleCompleted}
          isOpen={openCompleted}
        />
        <Collapse in={openCompleted} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={completedCollections}
            isSelected={isSelectedCompleted}
            moduls={complete}
            onModuls={setComplete}
            origin="code"
          />
        </Collapse>

        <ExpandFilterKanban
          name="Task Group"
          handleOpen={handleTaskGroup}
          isOpen={openTaskGroup}
        />
        <Collapse in={openTaskGroup} timeout="auto" unmountOnExit>
          <FilterListChecnbox
            classes={classes}
            collections={listGroup}
            isSelected={isSelectedGroups}
            moduls={groups}
            onModuls={setGroups}
            origin="id"
          />
          {/* <Box p={2}>
            <Autocomplete
              id="combo-box-demo"
              options={listGroup}
              getOptionLabel={(option) => `${option.name}`}
              noOptionsText="Tidak ada task group"
              onChange={(e, group) => handleChooseGroup(e, group)}
              popupIcon={<KeyboardArrowDownIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Search task group"
                  variant="standard"
                  size="small"
                  InputLabelProps={{ style: { fontSize: 13 } }}
                />
              )}
            />
          </Box> */}
        </Collapse>

        <Box p={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            size="small"
            varian="outlined"
            className={classes.button}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Box mx={0.5} />
          <Button
            size="small"
            variant="outlined"
            className={classes.button1}
            onClick={handleFilterKanban}
          >
            Show Result
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerKanban;

const statusCollections = [
  {
    id: "TO DO",
    code: "TO DO",
    name: "To Do",
    color: "#888888",
  },
  {
    id: "DOING",
    code: "DOING",
    name: "Doing",
    color: "#73CDEE",
  },
  {
    id: "DONE",
    code: "DONE",
    name: "Done",
    color: "#74CB80",
  },
  {
    id: "ON HOLD",
    code: "ON HOLD",
    name: "On Hold",
    color: "#EFCB0F",
  },
  {
    id: "WAITING",
    code: "WAITING",
    name: "Waiting",
    color: "#92739F",
  },
];

const priorityCollections = [
  {
    id: "HIGH",
    code: "HIGH",
    name: "High",
    sort_order: 0,
    icon: icon_high,
  },
  {
    id: "MEDIUM",
    code: "MEDIUM",
    name: "Medium",
    sort_order: 1,
    icon: icon_medium,
  },
  {
    id: "LOW",
    code: "LOW",
    name: "Low",
    sort_order: 2,
    icon: icon_low,
  },
];

const completedCollections = [
  {
    id: "1",
    code: "1",
    name: "incompleted",
    sort_order: 0,
  },
  {
    id: "2",
    code: "2",
    name: "completed",
    sort_order: 1,
  },
];

const periodCollections = [
  {
    id: "0",
    code: "not_started",
    name: "Not Started",
  },
  {
    id: "1",
    code: "about_to_start",
    name: "About to start",
  },
  {
    id: "2",
    code: "started",
    name: "Started",
  },
  {
    id: "3",
    code: "early",
    name: "Completed (Early)",
  },
  {
    id: "4",
    code: "late",
    name: "Completed (Late)",
  },
  {
    id: "5",
    code: "on_time",
    name: "Completed (On Time)",
  },
  {
    id: "6",
    code: "overdue",
    name: "Overdue",
  },
  {
    id: "7",
    code: "overdue_soon",
    name: "Overdue Soon",
  },
];

const achievementCollections = [
  {
    id: "1",
    code: "scope_on_target",
    name: "Scope On Target",
  },
  {
    id: "2",
    code: "scope_behind_target",
    name: "Scope Behind Target",
  },
  {
    id: "3",
    code: "schedule_on_target",
    name: "Schedule On Target",
  },
  {
    id: "4",
    code: "schedule_behind_target",
    name: "Schedule Behind Target",
  },
];
