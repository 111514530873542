import React, { useEffect, useContext } from "react";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import AxiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";
import ContextGoal from "./Context/ContextGoal";

import ViewAll from "./All/ViewAll";

import "moment/locale/id";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewGoal = () => {
  const context = useContext(ContextGoal);

  // =================================================
  // GET Goal Collections
  // =================================================
  const getGoals = async () => {
    context.setLoader(true);

    const valueSearch = localStorage.getItem("value_search");
    // const valueCategory = localStorage.getItem("goal_category");
    // const valueProject = localStorage.getItem("goal_category");
    const serachval =
      valueSearch === null ? "" : valueSearch === undefined ? "" : valueSearch;

    let URL = "";

    // // console.log("CAT CAT 1", context.goalCategory);
    // // console.log("weightValue 1", context.weightValueAll);
    // context.setGoalCategory(context.goalCategory);

    const targetURL = `${URL_API}/swd/goal?options[filter][search]=${serachval}&options[filter][status_id]=${context.statusGoalId}&options[paging][offset]=${context.offsetGoalCount}&options[paging][limit]=${context.limitGoalCount}&options[filter][period_id]=${context.period?.id}${context.goalCategory}${context.projectType}`;
    const minWeight = `&options[filter][weighting][from]=${context.weightValueAll.min}`;
    const maxWeight = `&options[filter][weighting][to]=${context.weightValueAll.max}`;

    if (
      context.weightValueAll.min === 0 &&
      context.weightValueAll.max === 100
    ) {
      URL = targetURL;
    } else {
      URL = `${targetURL}${minWeight}${maxWeight}`;
    }

    try {
      const res = await AxiosConfig.get(URL);
      // console.log("res COLLECTION GOAL : ", res);

      context.setCollectionGoalList(res.data.data);
      context.setLengthGoalCount(res.data.info.count);

      // // console.log("CAT CAT 2", context.goalCategory);

      context.setLoader(false);
    } catch (error) {
      context.setLoader(false);
      // console.log("Error : ", error.response);
    }
  };

  useEffect(() => {
    getGoals();
  }, [context.triggerGoals]);

  // // console.log("CAT CAT 3", context.goalCategory);

  return (
    <MuiThemeProvider theme={theme}>
      <Box
        bgcolor="#fafafa"
        position="fixed"
        zIndex={110}
        width="100%"
        pb={3}
      ></Box>

      <Box ml={7} marginTop={theme.spacing(0.1)}>
        <ViewAll />
      </Box>
    </MuiThemeProvider>
  );
};

// export default withStyles(styles)(ViewGoal);
export default ViewGoal;
