import React, { useEffect, useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemIcon,
  Chip,
  Fade,
  Button,
} from "@material-ui/core";
import { A } from "hookrouter";

import { green } from "@material-ui/core/colors";

import ContextGoalDetail from "../Goal/Context/ContextGoalDetail";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PeopleIcon from "@material-ui/icons/People";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";

import DialogCreateMAResult from "./ComponentResultMA/DialogCreateMAResult";
import DialogDelete from "./ComponentResultMA/DialogDelete";

import DialogEditMAv2 from "./Components/DialogEditMAv2";
import DialogDeleteMA from "./Components/DialogDeleteMA";
import DialogSetAsComplete from "./Components/DialogSetAsComplete";

import DotLineChartMeasureActivityInDetail from "./Components/DotLineChartMeasureActivityInDetail";

import PictLitleIconMA from "./Components/PictLitleIconMA";
// import CircleMoreDetailInPercentMA from './Components/CircleMoreDetailInPercentMA';
import CircleMoreDetailInPercentMATemporary from "./Components/CircleMoreDetailInPercentMATemporary";

import BarDominantGreenScenarioByZERO from "./Components/BarDominantGreenScenarioByZERO";
import BarDominantGreenScenarioFIRST from "./Components/BarDominantGreenScenarioFIRST";
import BarDominantGreenScenarioSECOND from "./Components/BarDominantGreenScenarioSECOND";
import BarDominantGreenScenarioTHIRD from "./Components/BarDominantGreenScenarioTHIRD";
import BarDominantRedScenarioFOURTH from "./Components/BarDominantRedScenarioFOURTH";
import BarDominantRedScenarioFOURTHPartB from "./Components/BarDominantRedScenarioFOURTH_partB";
import BarDominantRedScenarioFIFTH from "./Components/BarDominantRedScenarioFIFTH";
import BarDominantGreenScenarioSEVEN from "./Components/BarDominantGreenScenarioSEVEN";

import TextOverDotLineChart from "../Goal/Components/TextOverDotLineChart";
import TextOverDotLineChartSecond from "./Components/TextOverDotLineChartSecond";
import TextOverDotLineChartThird from "./Components/TextOverDotLineChartThird";

import {
  StyledMenu,
  StyledMenuItem,
} from "../../../components/StyledMenuDropdown";

import { styles } from "../MeasureActivities/Style/StyleMA";

import ListCollectionMaResult from "./ComponentResultMA/ListCollectionMaResult";

import axiosConfig from "../../../constants/config-axios";
import { URL_API } from "../../../constants/config-api";

import Capitalize from "../../../utilities/Capitalize";

import AvatarDummy from "../../../assets/images/Avatar_dummy.png";
import PictResultValue from "../../../assets/images/Group_2620.png";
import PictGAP from "../../../assets/images/Group_2619.png";
import PictDone from "../../../assets/images/Group_2614.png";

import { FormatDecimal } from "../../../utilities/FormatDecimal";
import DialogConfirmWeight from "./Components/DialogConfirmWeight";
import DialogInfoSchedule from "../../../components/DialogInfoSchedule";
import FormatTitleCase from "../../../utilities/FormatTitleCase";

const useStyles = makeStyles(styles);

const ViewMAMoreDetail = (props) => {
  const {
    goalDetailState,
    fotoQuery,
    userTokenState,
    memberPositionList,

    isMeasuredActivityDetailShow,
    setMeasuredActivityDetailShow,

    collectionMaResultList,
    setCollectionMaResultList,
    maResultLength,
    collectionMA,
    setColletionMA,

    isModalCreateMAResult,
    setModalCreateMAResult,

    loader,
    setLoader,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const context = useContext(ContextGoalDetail);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const role = statusUserLoginAfterParse.userRole[0].name;
  const superior =
    role === "superadmin"
      ? true
      : statusUserLoginAfterParse.member_position !== null
      ? statusUserLoginAfterParse.member_position.is_superior
      : false;

  const [growPaper, setGrowPaper] = useState(false);
  const [maDetailState, setMaDetailState] = useState(detailMa);
  const [openEditMA, setOpenEditMA] = useState(false);

  /*
        ````````````````
        HANDLE FOTO, etc

        ````````````````
    */
  const [fotoQueryContent, setFotoQueryContent] = useState("");
  const [positionName, setPositionName] = useState("");
  // const [ isValueTypePercent, setValueTypePercent ] = useState(false);

  useEffect(() => {
    if (isMeasuredActivityDetailShow == false) {
      //*
      const maDetail = localStorage.getItem("MA_detail");
      const maDetailAfterParse = JSON.parse(maDetail);

      if (maDetailAfterParse !== null) {
        setMaDetailState(maDetailAfterParse);
      }

      setGrowPaper(true);

      /*
                ```````````
                HANDLE FOTO

                ```````````
            */

      /*
                ``````````````````````````````````````````````````
                HANDLE POSITION TITLE NAME WHEN ROLE IS SUPERADMIN 

                ``````````````````````````````````````````````````                
            */

      if (statusUserLoginAfterParse !== null) {
        if (
          statusUserLoginAfterParse.userRole !== null ||
          statusUserLoginAfterParse.userRole !== undefined
        ) {
          if (statusUserLoginAfterParse.userRole.length > 0) {
            if (statusUserLoginAfterParse.userRole[0].name) {
              // // console.log("Role : ", statusUserLoginAfterParse.userRole[0].name );
              if (statusUserLoginAfterParse.userRole[0].name == "superadmin") {
                if (maDetailAfterParse !== null) {
                  if (
                    maDetailAfterParse.owner.structure_position_title_name !==
                    null
                  ) {
                    setPositionName(
                      maDetailAfterParse.owner.structure_position_title_name
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [isMeasuredActivityDetailShow]);

  /*
        ``````````````````````
        HANDLE MA DETAIL STATE

        ``````````````````````
    */
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nameOfMA, setNameOfMA] = useState("");
  const [description, setDescription] = useState("");

  const [structureUnitTypeName, setStructureUnitTypeName] = useState("");
  const [structureUnitName, setStructureUnitName] = useState("");

  const [statusMeasuredActivity, setStatusMeasuredActivity] = useState("");
  const [startValue, setStartValue] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [targetType, setTargetType] = useState("");
  const [actualValue, setActualValue] = useState("");
  const [actualValueAchievement, setActualValueAchievement] = useState("");

  const [resultValue, setResultValue] = useState("");
  // const [ resultValuePercent, setResultValuePercent ] = useState('');

  const [gapValue, setGapValue] = useState("");
  const [gapValuePercent, setGapValuePercent] = useState("");

  const [posisiAmanActual, setPosisiAmanActual] = useState("");
  const [posisiAmanAchievement, setPosisiAmanAchievement] = useState("");
  const [sisaWaktu, setSisaWaktu] = useState("");
  const [TriggerMaAll, setTriggerMaAll] = useState(null);

  /*
        ````````````
        BAR CHART MA

        ````````````
    */
  const [resultValueAchievement, setResultValueAchievement] = useState(null);
  const [expectedValueAchievement, setExpectedValueAchievement] =
    useState(null);

  // CONFIRM WEIGHT
  const [openDialogConfirmWeight, setOpenDialogConfirmWeight] = useState(false);
  // Dialog Info Schedule
  const [openDialogInfoSchedule, setOpenDialogInfoSchedule] = useState(false);
  const [originSchedule, setOriginSchedule] = useState("");

  /*
        `````````
        STATUS MA

        `````````
    */

  useEffect(() => {
    if (maDetailState.id !== null) {
      if (maDetailState.member !== null) {
        setFirstName(
          maDetailState.member.first_name !== null
            ? maDetailState.member.first_name
            : " "
        );
        setLastName(
          maDetailState.member.last_name !== null
            ? maDetailState.member.last_name
            : " "
        );
      }

      setNameOfMA(maDetailState.name !== null ? maDetailState.name : " ");
      setDescription(
        maDetailState.description !== null ? maDetailState.description : " "
      );

      setStructureUnitTypeName(
        maDetailState.owner.structure_unit_type_name !== null
          ? maDetailState.owner.structure_unit_type_name
          : "-"
      );
      setStructureUnitName(
        maDetailState.owner.structure_unit_name !== null
          ? maDetailState.owner.structure_unit_name
          : " "
      );

      setStatusMeasuredActivity(maDetailState.status.code);

      setStartValue(
        maDetailState.calculatedValue.start_value !== null
          ? maDetailState.calculatedValue.start_value
          : " "
      );
      setTargetValue(
        maDetailState.calculatedValue.target_value !== null
          ? maDetailState.calculatedValue.target_value
          : " "
      );

      setTargetType(
        maDetailState.targetType !== null ? maDetailState.targetType.name : ""
      );

      setActualValue(maDetailState.calculatedValue.actualOriented.actual_value);
      setActualValueAchievement(
        maDetailState.calculatedValue.actualOriented.actual_value_achievement
      );

      setGapValue(
        maDetailState.calculatedValue.resultOriented.gap_result_value !== null
          ? maDetailState.calculatedValue.resultOriented.gap_result_value
          : ""
      );
      setGapValuePercent(
        maDetailState.calculatedValue.resultOriented
          .gap_result_value_achievement !== null
          ? maDetailState.calculatedValue.resultOriented
              .gap_result_value_achievement
          : ""
      );

      setPosisiAmanActual(
        maDetailState.calculatedValue.resultOriented.expected_result_value !==
          null
          ? maDetailState.calculatedValue.resultOriented.expected_result_value
          : ""
      );
      setPosisiAmanAchievement(
        maDetailState.calculatedValue.resultOriented
          .expected_result_value_achievement !== null
          ? maDetailState.calculatedValue.resultOriented
              .expected_result_value_achievement
          : ""
      );

      setSisaWaktu(
        maDetailState.time.remaining_days !== null
          ? maDetailState.time.remaining_days
          : ""
      );

      // console.log('maDetailState : ', maDetailState);

      setResultValue(maDetailState.calculatedValue.resultOriented.result_value);

      if (
        maDetailState.calculatedValue.resultOriented
          .result_value_achievement !== null
      ) {
        // // console.log("maDetailState.calculatedValue.result_value_percent : ", maDetailState.calculatedValue.result_value_percent)
        setResultValueAchievement(
          maDetailState.calculatedValue.resultOriented
            .result_value_achievement * 100
        );
      }

      if (
        maDetailState.calculatedValue.resultOriented
          .expected_result_value_achievement !== null
      ) {
        // // console.log("maDetailState.calculatedValue.expected_value_percent : ", maDetailState.calculatedValue.expected_value_percent)
        setExpectedValueAchievement(
          maDetailState.calculatedValue.resultOriented
            .expected_result_value_achievement * 100
        );
      }
    }
  }, [maDetailState]);

  /*
        ``````````````````````````````````
        HANDLE DIALOG SET AS COMPLETE GOAL

        ``````````````````````````````````
    */
  const [isOpenDialogSetAsComplete, setOpenDialogSetAsComplete] =
    useState(false);

  const handleDialogSetAsComplete = () => {
    handleDropdownClose();
    setOpenDialogSetAsComplete(true);
    // setOpenDialogInfoSchedule(true);
  };

  /*
        `````````````````````````````````````````````````````````
        HANDLE SHOW DROPDOWN "EDIT & DELETE " MEASURED ACTIVITIES

        `````````````````````````````````````````````````````````
    */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL EDIT MA MASTER

        ``````````````````````````````````````
    */

  const handleDialogEditMAv2 = () => {
    handleDropdownClose();
    setOpenEditMA(true);
  };

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL EDIT MA MASTER

        ``````````````````````````````````````
    */
  const [isModalDeleteMA, setModalDeleteMA] = useState(false);

  /*
        ``````````````````````````````````````
        HANDLE DIALOG MODAL DELETE GOAL RESULT

        ``````````````````````````````````````
    */
  const [isModalDelete, setModalDelete] = useState(false);

  const handleDialogDelete = (e, data) => {
    e.preventDefault();

    handleDropdownCloseMaResult();
    setModalDelete(true);
  };

  /*
        ````````````````````````````````````
        HANDLE DIALOG MODAL EDIT GOAL RESULT

        ````````````````````````````````````
    */

  // Success Create MA
  const [successCreateMA, setSuccessCreateMA] = useState(null);

  /*
        ```````````````````````````
        HANDLE DETAIL MA FRESH DATA

        ````````````````````````````
    */
  useEffect(() => {
    if (userTokenState !== undefined) {
      setLoader(true);
      axiosConfig
        .get(URL_API + `/swd/measured-activity/${maDetailState.id}`)
        .then(function (response) {
          // console.log("Response Original MA DETAIL : ", response);
          if (response.status === 200) {
            setMaDetailState(response.data.data);
            // localStorage.setItem("MA_detail", response.data.data);
          }
          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  }, [
    isModalCreateMAResult,
    isModalDelete,
    successCreateMA,
    TriggerMaAll,
    context.triggerMA,
  ]);

  /*
        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````
        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````
        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````

        FEATURE MA RESULT LIST

        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````
        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````
        ```````````````````````````````````````````````````````````````````````````````````````````````````````````````
    */

  /*
        `````````````````````````
        DROPDOWN GOAL RESULT LIST
        `````````````````````````
    */
  const [maResultId, setMaResultId] = useState("");
  const [maResultNameDescription, setMaResultNameDescription] = useState("");
  const [maResultDataDetail, setMaResultDataDetail] = useState("");
  const [anchorElListMaResult, setAnchorElListMaResult] = useState(null);

  const handleDropdownOpenMaResult = (event, item) => {
    event.preventDefault();

    // console.log("item : ", item);

    setAnchorElListMaResult(event.currentTarget);
    // console.log('handleDropdownOpenMaResult : ', item);

    setMaResultId(item.id);
    setMaResultNameDescription(item.description);
    setMaResultDataDetail(item);
  };

  function handleDropdownCloseMaResult() {
    setAnchorElListMaResult(null);
  }

  /*
       ````````````````````````````````````````````````
       HANDLE FRESH DATA IN LIST COLLECTION GOAL RESULT

       ````````````````````````````````````````````````
   */

  useEffect(() => {
    if (isModalDelete === false) {
      setLoader(true);
      if (userTokenState !== undefined) {
        axiosConfig
          .get(URL_API + `/swd/measured-activity/${maDetailState.id}/result`)
          .then(function (response) {
            // console.log("Response Original MA RESULT COLLECTION : ", response);

            if (response.status == 200) {
              if (response.data.data !== null) {
                setCollectionMaResultList(response.data.data);
              }
              setLoader(false);
            }
          })
          .catch(function (error) {
            setLoader(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [isModalDelete, successCreateMA]);

  // Handle status code 403 from create MA Reesult For Disableing Crate Result
  useEffect(() => {
    if (userTokenState !== undefined) {
      axiosConfig
        .get(
          `${URL_API}/swd/measured-activity/${maDetailState.id}/result/create`
        )
        .then((response) => {
          // // console.log("Response create GR", response);
        })
        .catch((error) => {
          // console.log("Error : ", error);

          if (error.response !== undefined) {
            if (error.response.status === 403) {
              context.setDisableCreateMAResult(true);
            }
          }
        });
    } else {
      // console.log("Usertoken not exist");
    }
  }, []);

  //  ALLOW EDIT/DE:ETE/SETASCOMPLETE
  let editAllowed = false;
  let deleteAllowed = false;
  let setCompleteAllowed = false;

  maDetailState.policy &&
    maDetailState.policy.forEach((item) => {
      if (item.actionName === "update") {
        editAllowed = item.result;
      } else if (item.actionName === "delete") {
        deleteAllowed = item.result;
      } else if (item.actionName === "completed") {
        setCompleteAllowed = item.result;
      }
    });

  return (
    // <Box display="flex" flexDirection="row" className={classes.fixedLayout}>
    <Grid container>
      <Grid item xs={3} md={3} style={{ textAlign: "left" }}>
        <Fade in={growPaper}>
          {/* style={{ width: 380 }} */}
          <Paper elevation={1} className={classes.paperNewColorOfGoal}>
            <List>
              <Grid container style={{ display: "flex" }}>
                <Box style={{ flexGrow: 1 }}>
                  <PictLitleIconMA />
                </Box>
                <Box style={{ alignItems: "flex-end" }}>
                  <IconButton onClick={handleDropdownOpen}>
                    <MoreHorizIcon color="#fff" />
                  </IconButton>
                </Box>

                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleDropdownClose}
                >
                  {superior && (
                    <StyledMenuItem
                      onClick={handleDialogEditMAv2}
                      disabled={
                        maDetailState.status_id === "6"
                          ? true
                          : maDetailState.status_id === "4"
                          ? true
                          : !editAllowed
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>Edit</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}
                  {superior && (
                    <StyledMenuItem
                      disabled={!deleteAllowed}
                      onClick={() => {
                        setOpenDialogConfirmWeight(true);
                        handleDropdownClose();
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                          >
                            <b>Delete</b>
                          </Typography>
                        }
                      />
                    </StyledMenuItem>
                  )}

                  {superior && maDetailState.status_id !== "4" && (
                    <StyledMenuItem
                      disabled={!setCompleteAllowed}
                      onClick={handleDialogSetAsComplete}
                    >
                      <ListItemText
                        primary={
                          <Chip
                            variant="outlined"
                            label={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                              >
                                <b>Set as complete</b>
                              </Typography>
                            }
                          />
                        }
                      />
                    </StyledMenuItem>
                  )}
                </StyledMenu>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {fotoQueryContent !== "" && fotoQueryContent !== null ? (
                  <IconButton aria-haspopup="true" color="inherit">
                    <img
                      src={
                        URL_API +
                        "/" +
                        fotoQueryContent +
                        "&token=" +
                        userTokenState +
                        "&mode=thumbnail"
                      }
                      className={classes.userRealFoto}
                      alt="."
                    />
                  </IconButton>
                ) : (
                  <IconButton aria-haspopup="true" color="inherit">
                    <AccountCircleIcon
                      className={classes.imageAvatar}
                      style={{ color: "#dcdcdc" }}
                    />
                  </IconButton>
                )}
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Typography
                  variant="subtitle2"
                  className={classes.title}
                  style={{ color: "white" }}
                >
                  <b>
                    {firstName + " "} {lastName}
                  </b>
                </Typography>
              </Grid>

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                {positionName !== "" && (
                  <Chip
                    label={positionName}
                    style={{
                      color: "white",
                      fontFamily: "Roboto",
                      marginTop: 8,
                      marginRight: 3,
                    }}
                    size="small"
                    variant="outlined"
                  />
                )}

                {memberPositionList.length > 0 &&
                  positionName == "" &&
                  memberPositionList.map((item, i) => (
                    <Chip
                      key={i}
                      label={item.structure_position_title_name}
                      style={{
                        color: "white",
                        fontFamily: "Roboto",
                        marginTop: 8,
                        marginRight: 3,
                      }}
                      size="small"
                      variant="outlined"
                    />
                  ))}
              </Grid>
              <br />

              <ListItem>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <div className={classes.circularProgressBarInMoreDetail}>
                    <CircleMoreDetailInPercentMATemporary
                      classes={classes}
                      goalDetailState={maDetailState}
                    />
                  </div>
                </Grid>
              </ListItem>
              <ListItem>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <Typography
                    variant="subtitle1"
                    className={classes.title}
                    style={{ color: "white" }}
                  >
                    {/* <b>{FormatTitleCase(nameOfMA)}</b> */}
                    <b>{nameOfMA}</b>
                  </Typography>
                </Grid>
              </ListItem>

              <ListItem style={{ paddingTop: 0 }}>
                <Box textAlign="justify">
                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#eeeeee" }}
                  >
                    <i>{description}</i>
                  </Typography>
                </Box>
              </ListItem>

              <ListItem>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  {/* 
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                            #SCENARIO-01 :

                                            - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                                            - "RESULT VALUE ACHIEVEMENT" KURANG DARI 100
                                                                                         
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                        */}

                  {resultValueAchievement > expectedValueAchievement &&
                    resultValueAchievement < 100 && (
                      <BarDominantGreenScenarioFIRST
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}

                  {/* 
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                            #SCENARIO-02 :

                                            - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                                            - "result_value_achievement" ===  100
                                                                                         
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                        */}
                  {resultValueAchievement > expectedValueAchievement &&
                    resultValueAchievement === 100 && (
                      <BarDominantGreenScenarioSECOND
                        classes={classes}
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}

                  {/* 
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                            #SCENARIO-03 :

                                            - KALAU "result_value_achievement" LEBIH BESAR "expected_result_value_achievement" &

                                            - "result_value_achievement" > 100
                                                                                         
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                        */}

                  {resultValueAchievement > expectedValueAchievement &&
                    resultValueAchievement > 100 && (
                      <BarDominantGreenScenarioTHIRD
                        classes={classes}
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}

                  {/* 
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                            #SCENARIO-04 :

                                            - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                                            - "result_value_achievement" KURANG DARI 100

                                            - "result_value_achievement" LEBIH DARI 0
                                                                                         
                                            ``````````````````````````````````````````````````````````````````````````````````````````````````
                                        */}

                  {expectedValueAchievement > resultValueAchievement &&
                    resultValueAchievement < 100 &&
                    resultValueAchievement > 0 && (
                      <BarDominantRedScenarioFOURTH
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}

                  {/* 
                                        ``````````````````````````````````````````````````````````````````````````````````````````````````
                                        #SCENARIO-04 PART-B :

                                        - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                                        - "result_value_achievement" LEBIH DARI 100

                                        - "expected_result_value_achievement" LEBIH DARI 100
                                                                                        
                                        ``````````````````````````````````````````````````````````````````````````````````````````````````
                                    */}

                  {expectedValueAchievement > resultValueAchievement &&
                    resultValueAchievement > 100 &&
                    resultValueAchievement > 100 && (
                      // <Box
                      //     marginTop={2}
                      //     marginBottom={4}
                      // >
                      <BarDominantRedScenarioFOURTHPartB
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                      // </Box>
                    )}

                  {/* 
                                        ```````````````````````````````````````````````````````````````````````````````````
                                        #SCENARIO-05 :

                                        - KALAU "expected_result_value_achievement" LEBIH BESAR DARI "result_value_achievement" &

                                        - "result_value_achievement" KURANG DARI 0

                                        - "result_value_achievement" bernilai MINUS
                                                                                        
                                        ``````````````````````````````````````````````````````````````````````````````````````````````````
                                    */}

                  {expectedValueAchievement > resultValueAchievement &&
                    resultValueAchievement < 0 && (
                      <BarDominantRedScenarioFIFTH
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}

                  {/* 
                                        ``````````````````````````````````
                                        #SCENARIO-06 :

                                        - "result_value_achievement" === 0
                                                                                        
                                        ``````````````````````````````````
                                    */}

                  {resultValueAchievement === 0 && (
                    //  expectedValueAchievement < resultValueAchievement &&
                    <BarDominantGreenScenarioByZERO
                      goalDetailState={maDetailState}
                      resultValueAchievement={resultValueAchievement}
                      expectedValueAchievement={expectedValueAchievement}
                    />
                  )}

                  {/* 
                                        `````````````````````````````````````````````````````````````
                                        #SCENARIO-07 :

                                        - "result_value_achievement" === "expected_result_value_achievement"
                                                                                        
                                        `````````````````````````````````````````````````````````````
                                    */}

                  {resultValueAchievement === expectedValueAchievement &&
                    resultValueAchievement !== 0 && (
                      <BarDominantGreenScenarioSEVEN
                        goalDetailState={maDetailState}
                        resultValueAchievement={resultValueAchievement}
                        expectedValueAchievement={expectedValueAchievement}
                      />
                    )}
                </Grid>
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{ backgroundColor: "#aed9ff", padding: "6px" }}
                  >
                    <PeopleIcon color="#4aa9fb" />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Structure Unit
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>
                        {structureUnitTypeName + " "} {structureUnitName}
                      </b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{
                      backgroundColor: "#aed9ff",
                      padding: "7px",
                      opacity: 0.5,
                    }}
                  >
                    <LinearScaleIcon style={{ color: "magenta" }} />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Status <i>Measured Activity</i>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>
                        <i>{Capitalize(statusMeasuredActivity)} </i>
                      </b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{
                      backgroundColor: "#fed9ff",
                      padding: "7px",
                      opacity: 0.5,
                    }}
                  >
                    <LinearScaleIcon style={{ color: "orangered" }} />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Target Type
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>{targetType}</b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <IconButton
                    style={{ backgroundColor: "#bdffde", padding: "6px" }}
                  >
                    <PlayArrowIcon color="#36d686" />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Start Value
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>{FormatDecimal(startValue)}</b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{ backgroundColor: "#aed9ff", padding: "6px" }}
                  >
                    <RadioButtonCheckedIcon color="#4aa9fb" />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Target value
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>{FormatDecimal(maDetailState.target_value)}</b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{ backgroundColor: green[900], padding: "6px" }}
                  >
                    <RadioButtonCheckedIcon style={{ color: green["A400"] }} />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Actual
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>
                        {FormatDecimal(actualValue)}
                        &nbsp; &nbsp;
                        {`(${FormatDecimal(actualValueAchievement * 100)})%`}
                      </b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <img
                    src={PictResultValue}
                    style={{ width: 40, height: 40 }}
                  />
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <i>MA Result</i>
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>
                        {FormatDecimal(resultValue)}
                        &nbsp; ({FormatDecimal(resultValueAchievement)}
                        %)
                      </b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <img src={PictGAP} style={{ width: 40, height: 40 }} />
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Gap
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>{FormatDecimal(gapValue)}</b>
                      &nbsp;
                      <b>({FormatDecimal(gapValuePercent * 100)}%)</b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <img src={PictDone} style={{ width: 40, height: 40 }} />
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Expected Value
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      <b>{FormatDecimal(posisiAmanActual)}</b>
                      &nbsp;
                      <b>({FormatDecimal(posisiAmanAchievement * 100)}%)</b>
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemIcon style={{ marginLeft: 8 }}>
                  <IconButton
                    style={{
                      backgroundColor: "#aed9ff",
                      padding: "7px",
                      opacity: 0.5,
                    }}
                  >
                    <CalendarTodayIcon color="orange" />
                  </IconButton>
                </ListItemIcon>

                <ListItemText
                  className={classes.listItemtext}
                  primary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      Sisa waktu
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "white" }}
                    >
                      {sisaWaktu} hari
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          </Paper>
        </Fade>
        <br />
        <br />
      </Grid>

      <Grid item xs={9} style={{ textAlign: "left" }}>
        {/* style={{ width: 1342 }} */}
        <Paper elevation={1} className={classes.paperColumnDuaRowFirst}>
          <Grid container justify="space-between">
            <Typography
              variant="subtitle1"
              className={classes.titleInLineChart}
            >
              <b>Overview History</b>
            </Typography>

            <Typography
              variant="subtitle1"
              className={classes.titleInLineChart}
            >
              <b>Goal : </b>
              <Button
                className={classes.timeFrameIcontDateRangeText}
                style={{ marginRight: 30 }}
                // onClick={(e) => handleLinkToGoal(e)}
              >
                <A
                  href={`/goal-detail/${maDetailState.goal.id}`}
                  style={{ textDecoration: "none" }}
                >
                  {/* <b>{FormatTitleCase(maDetailState.goal.name)}</b> */}
                  <b>{maDetailState.goal.name}</b>
                </A>
              </Button>
            </Typography>
          </Grid>

          <Grid container>
            <Grid item sm={4}>
              <TextOverDotLineChart
                classes={classes}
                goalDetailState={maDetailState}
              />
            </Grid>

            <Grid item sm={4}>
              <TextOverDotLineChartSecond
                classes={classes}
                goalDetailState={maDetailState}
              />
            </Grid>

            <Grid item sm={4}>
              <TextOverDotLineChartThird
                classes={classes}
                maDetailState={maDetailState}
              />
            </Grid>
          </Grid>
          <br />

          {/* 
              ``````````````
              DOT LINE CHART

              ``````````````                   
          */}

          <DotLineChartMeasureActivityInDetail
            classes={classes}
            isModalCreateMAResult={isModalCreateMAResult}
            isModalDelete={isModalDelete}
            successCreateMA={successCreateMA}
            loader={loader}
          />
        </Paper>
        {/* 
          ```````````````````````````
          LIST COLLECTION GOAL RESULT

          ```````````````````````````
        */}
        <br />

        <ListCollectionMaResult
          classes={classes}
          maDetailState={maDetailState}
          userTokenState={userTokenState}
          handleDropdownOpenMaResult={handleDropdownOpenMaResult}
          anchorElListMaResult={anchorElListMaResult}
          handleDropdownCloseMaResult={handleDropdownCloseMaResult}
          handleDialogDelete={handleDialogDelete}
          collectionMaResultList={collectionMaResultList}
          maResultLength={maResultLength}
          loader={loader}
          isMeasuredActivityDetailShow={isMeasuredActivityDetailShow}
          setSuccessCreateMA={setSuccessCreateMA}
        />
        <br />
        <br />
        <br />
      </Grid>

      <DialogSetAsComplete
        classes={classes}
        isOpenDialogSetAsComplete={isOpenDialogSetAsComplete}
        setOpenDialogSetAsComplete={setOpenDialogSetAsComplete}
        maDetailState={maDetailState}
        setMeasuredActivityDetailShow={setMeasuredActivityDetailShow}
      />

      <DialogDelete
        classes={classes}
        isModalDelete={isModalDelete}
        setModalDelete={setModalDelete}
        maResultId={maResultId}
        maDetailState={maDetailState}
        // collectionGoalResultList = { collectionGoalResultList }
        // setCollectionGoalResultList = { setCollectionGoalResultList }
        // maResultNameDescription = { maResultNameDescription }
      />

      <DialogCreateMAResult
        classes={classes}
        // setFireMaDetail = { setFireMaDetail }
        isModalCreateMAResult={isModalCreateMAResult}
        setModalCreateMAResult={setModalCreateMAResult}
        collectionMaResultList={collectionMaResultList}
        setCollectionMaResultList={setCollectionMaResultList}
        maDetailState={maDetailState}
        // measuredActivityId = { measuredActivityId }
      />

      <DialogEditMAv2
        classes={classes}
        openEditMA={openEditMA}
        setOpenEditMA={setOpenEditMA}
        maData={maDetailState}
        setTriggerMaAll={setTriggerMaAll}
      />

      <DialogDeleteMA
        classes={classes}
        isModalDeleteMA={isModalDeleteMA}
        setModalDeleteMA={setModalDeleteMA}
        maDetailState={maDetailState}
        setMeasuredActivityDetailShow={setMeasuredActivityDetailShow}
      />

      <DialogConfirmWeight
        classes={classes}
        openDialogConfirmWeight={openDialogConfirmWeight}
        setOpenDialogConfirmWeight={setOpenDialogConfirmWeight}
        setModalContinue={setModalDeleteMA}
      />

      {originSchedule === "complete" && (
        <DialogInfoSchedule
          openDialogInfoSchedule={openDialogInfoSchedule}
          setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
          onOpenDialog={setOpenDialogSetAsComplete}
        />
      )}

      {originSchedule === "delete" && (
        <DialogInfoSchedule
          openDialogInfoSchedule={openDialogInfoSchedule}
          setOpenDialogInfoSchedule={setOpenDialogInfoSchedule}
          onOpenDialog={setModalDeleteMA}
        />
      )}

      {/* <DialogSetAsComplete 
          classes = { classes }
          isOpenDialogSetAsComplete = { isOpenDialogSetAsComplete } 
          setOpenDialogSetAsComplete = { setOpenDialogSetAsComplete }
          goalDetailState = { goalDetailState }
      /> */}
    </Grid>
    // </Box>
  );
};

export default ViewMAMoreDetail;

let detailMa = {
  id: null,
  name: "",
  measured_activity_type_id: "",
  goal_id: "",
  owner_id: "",
  achievement_type_id: "",
  value_type_id: "",
  start_value: "",
  target_value: "",
  target_range_value: null,
  tolerance_value: "",
  weight_value: "",
  input_value: "",
  input_method_id: "",
  calculation_method_id: "",
  direct_link_main_measured_activity_id: null,
  direct_link_node_path: "",
  direct_link_node_level: null,
  update_interval_id: "",
  update_interval_trigger_value: null,
  period_id: null,
  is_evidence_required: 0,
  start_date: "",
  end_date: "",
  completed_timestamp: null,
  time_frame_id: "",
  measured_activity_mode_id: "",
  visibility_type_id: "",
  last_measured_activity_result_date: null,
  status_id: "",
  description: null,
  created_by: "",
  updated_by: null,
  deleted_by: null,
  created_at: "",
  updated_at: "",
  deleted_at: null,
  is_used: false,
  period: null,
  goal: {
    id: "",
    name: "",
  },
  achievementType: {
    id: "",
    code: "",
    name: "",
  },
  inputMethod: {
    id: "",
    code: "",
    name: "",
  },
  calculationMethod: {
    id: "",
    code: "",
    name: "",
  },
  measuredActivityMode: {
    id: "",
    code: "",
    name: "",
  },
  measuredActivityType: {
    id: "",
    code: "",
    name: "",
  },
  owner: {
    id: "",
    parent_id: "",
    prev_sibling_id: null,
    next_sibling_id: null,
    code: "",
    member_id: "",
    member_first_name: "",
    member_last_name: "",
    user_id: "",
    user_email: "",
    structure_unit_type_id: "",
    structure_unit_type_name: "",
    structure_unit_id: "",
    structure_unit_name: "",
    structure_position_title_id: "",
    structure_position_title_name: "",
    classification_id: 1,
    classification_name: "",
    node_level: null,
    created_by: "",
    updated_by: "",
    deleted_by: null,
    created_at: "",
    updated_at: "",
    deleted_at: null,
    self: {
      rel: null,
    },
  },
  member: {
    id: "",
    first_name: "",
    last_name: "",
    nickname: "",
    photo_url: "",
  },
  directLinkMainMeasuredActivity: null,
  startValueSyncStatus: {
    id: "1",
    code: "swd_ma_start_value_sync_status_sync",
    name: "sync",
  },
  targetValueSyncStatus: {
    id: "",
    code: "",
    name: "",
  },
  status: {
    id: "",
    code: "",
    name: "",
    sort_order: null,
  },
  updateInterval: {
    id: "",
    name: "",
    code: "",
    datetime_unit: null,
    datetime_value: null,
    sort_order: 0,
    created_by: null,
    updated_by: null,
    deleted_by: null,
    created_at: "",
    updated_at: null,
    deleted_at: null,
    self: {
      rel: null,
    },
  },
  valueType: {
    id: "",
    code: "",
    name: "",
  },
  visibilityType: {
    id: "",
    code: "",
    name: "",
  },
  calculatedValue: {
    start_value: "",
    target_value: "",
    input_value: "",
    target_value_achievement: "",
    target_range_value: "",
    resultOriented: {
      result_value: "",
      result_value_achievement: "",
      expected_result_value: "",
      expected_result_value_achievement: "",
      gap_result_value: "",
      gap_result_value_achievement: "",
      overall_result_achievement: "",
    },
    actualOriented: {
      actual_value: "",
      actual_value_achievement: "",
      expected_actual_value: "",
      expected_actual_value_achievement: "",
      gap_actual_value: "",
      gap_actual_value_achievement: "",
      overall_actual_achievement: "",
    },
    day_offset: null,
    date_offset: "",
    start_date: "",
    end_date: "",
  },
  time: {
    elapsed_days: "",
    remaining_days: "",
    total_days: "",
    start_date: "",
    end_date: "",
  },
  timeFrame: {
    id: "",
    code: "",
    name: "",
  },
  self: {
    rel: null,
  },
};
