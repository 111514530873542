import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";

import { styles } from "./Style/StyleCompanySetting";
import ViewAccountSetting from "./ViewAccountSetting";
import ViewGeneralSetting from "./ViewGeneralSetting";
import ViewNotificationSetting from "./ViewNotificationSetting";
import MyLang from "../../../utilities/MyLang";
import { bahasa, english } from "../../../constants/config-lang";

const useStyles = makeStyles(styles);

const ViewCompanySetting = (props) => {
  const classes = useStyles();
  const getStatusLogin = localStorage.getItem("status_user_login");
  const user = getStatusLogin !== null ? JSON.parse(getStatusLogin) : undefined;
  const userRole = user?.userRole[0]?.name;

  const [selectSetting, setSelectSetting] = useState({
    code: "account_setting",
  });

  useEffect(() => {
    const tabset = localStorage.getItem("setting_tab");

    if (tabset !== null) {
      setSelectSetting({ code: tabset });
    }
  }, []);

  const handleSetting = (data) => {
    setSelectSetting({ code: data.code });
    localStorage.setItem("setting_tab", data.code);
  };

  // // console.log("selectSetting", selectSetting);

  return (
    <Box ml={8} mt={8}>
      <Grid container style={{ minHeight: "100vh" }}>
        <Grid item md={2} style={{ backgroundColor: "#fff" }}>
          <Box>
            {userRole === "superadmin" &&
              listSettings.map((setting, i) => {
                const isCurrent = selectSetting.code === setting.code;

                return (
                  <Box
                    key={i}
                    display="flex"
                    p={2.2}
                    className={classes.listSettings}
                    bgcolor={isCurrent ? "#E1F5FE" : "#FFF"}
                    onClick={() => handleSetting(setting)}
                  >
                    <Box>{setting.icon}</Box>
                    <Box ml={1.5}>
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        {setting.name}
                      </Typography>
                      <Box my={0.8} />
                      <Typography
                        variant="caption"
                        className={classes.title888}
                      >
                        {setting.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}

            {userRole !== "superadmin" &&
              listSettings1.map((setting, i) => {
                const isCurrent = selectSetting.code === setting.code;

                return (
                  <Box
                    key={i}
                    display="flex"
                    p={2.2}
                    className={classes.listSettings}
                    bgcolor={isCurrent ? "#E1F5FE" : "#FFF"}
                    onClick={() => handleSetting(setting)}
                  >
                    <Box>{setting.icon}</Box>
                    <Box ml={1.5}>
                      <Typography
                        variant="subtitle2"
                        className={classes.title333}
                      >
                        {setting.name}
                      </Typography>
                      <Box my={0.8} />
                      <Typography
                        variant="caption"
                        className={classes.title888}
                      >
                        {setting.description}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Grid>
        <Grid item md={10} style={{ backgroundColor: "#FDFDFD" }}>
          {selectSetting.code === "general_setting" && <ViewGeneralSetting />}
          {selectSetting.code === "account_setting" && <ViewAccountSetting />}
          {selectSetting.code === "notification_setting" && (
            <ViewNotificationSetting />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ViewCompanySetting;

const listSettings = [
  {
    icon: <SettingsIcon style={{ color: "#888" }} />,
    name: MyLang() ? bahasa.generalSetting : english.generalSetting,
    code: "general_setting",
    description: MyLang()
      ? bahasa.descGeneralSetting
      : english.descGeneralSetting,
  },
  {
    icon: <AccountCircleIcon style={{ color: "#888" }} />,
    name: MyLang() ? bahasa.accSetting : english.accSetting,
    code: "account_setting",
    description: MyLang() ? bahasa.descAccSetting : english.descAccSetting,
  },
  {
    icon: <NotificationsActiveOutlinedIcon style={{ color: "#888" }} />,
    name: MyLang() ? bahasa.notifSetting : english.notifSetting,
    code: "notification_setting",
    description: MyLang() ? bahasa.descNotif : english.descNotif,
  },
];

const listSettings1 = [
  {
    icon: <AccountCircleIcon style={{ color: "#888" }} />,
    name: MyLang() ? bahasa.accSetting : english.accSetting,
    code: "account_setting",
    description: MyLang() ? bahasa.descAccSetting : english.descAccSetting,
  },
];
