import React, { useState } from "react";
import { Rnd } from "react-rnd";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { style, parentBoundary } from "./Style/style";
import { data } from "./data";
import { Box } from "@material-ui/core";

const widgets = [
  {
    id: 0,
    name: "Chart",
  },
  {
    id: 1,
    name: "Score",
  },
  {
    id: 2,
    name: "Table",
  },
].map((item, i) => {
  return {
    ...item,
    width: 200,
    height: 200,
    x: i * 100,
    y: i * 100,
  };
});

const ViewPlayground = () => {
  const [rnds, setRnds] = useState(widgets);

  // console.log("widgets", widgets);
  // console.log("RND", rnds);

  return (
    <Box mt={8.3} ml={7}>
      {widgets.map((item, i) => (
        <Rnd
          style={style}
          bounds="window"
          size={{
            width: rnds[i].width,
            height: rnds[i].height,
          }}
          position={{
            x: rnds[i].x,
            y: rnds[i].y,
          }}
          onDragStop={(e, d) => {
            const rnd = [...rnds];
            rnd[i] = { ...rnds[i], x: d.x, y: d.y };
            setRnds({ rnd });
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            const rnd = [...rnds];
            rnd[i] = {
              ...rnds[i],
              width: ref.offsetWidth,
              height: ref.offsetHeight,
              ...position,
            };
            setRnds({ rnd });
          }}
        >
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={rnds[i].width}
              height={rnds[i].height}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Rnd>
      ))}
    </Box>
  );
};

export default ViewPlayground;
