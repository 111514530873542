import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

import { navigate } from "hookrouter";

import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import ContextAllMA from "../context/AllMaContext";
import handleError from "../../../Report/components/global/handleError";

import DialogError from "../../../../components/DialogError";

const DialogArchive = ({
  classes,
  isOpenDialogArchive,
  setOpenDialogArchive,
  item,
}) => {
  const context = useContext(ContextAllMA);
  const status_login = localStorage.getItem("status_user_login");
  const user = JSON.parse(status_login);

  const [loading, setLoading] = useState(false);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const handleArchive = () => {
    setLoading(true);

    let device_os = "Not known";
    if (navigator.appVersion.indexOf("Win") !== -1) device_os = "Windows OS";
    if (navigator.appVersion.indexOf("Mac") !== -1) device_os = "Mac OS";
    if (navigator.appVersion.indexOf("X11") !== -1) device_os = "UNIX OS";
    if (navigator.appVersion.indexOf("Linux") !== -1) device_os = "Linux OS";

    let OSName = "Unknown";
    if (window.navigator.userAgent.indexOf("Windows NT 10.0") != -1)
      OSName = "Windows 10";
    if (window.navigator.userAgent.indexOf("Windows NT 6.3") != -1)
      OSName = "Windows 8.1";
    if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1)
      OSName = "Windows 8";
    if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1)
      OSName = "Windows 7";
    if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1)
      OSName = "Windows Vista";
    if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1)
      OSName = "Windows XP";
    if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1)
      OSName = "Windows 2000";
    if (window.navigator.userAgent.indexOf("Mac") != -1) OSName = "Mac/iOS";
    if (window.navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (window.navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";

    const data = {
      name: item.name,
      entity_type: "ma",
      entity_list: [
        {
          name: item.name,
          entity_id: item.id,
          owner_id: item.owner_id,
        },
      ],
    };

    // // console.log("Data Archive", data);

    AxiosConfig.post(`${URL_API}/swd/archived/store`, data)
      .then((res) => {
        // console.log("RES Archive", res);

        if (res.status === 200) {
          const dataLog = {
            user_activity_log: {
              module: "ma",
              event: "update",
              user_id: user?.user_id,
              user_email: user?.user_email,
              nama: `${user?.member_first_name} ${user?.member_last_name}`,
              position:
                user?.member_position?.structure_position_title_name ===
                undefined
                  ? ""
                  : user?.member_position?.structure_position_title_name,
              device_type: device_os,
              device_model: "",
              device_version: OSName,
              long: "",
              lat: "",
              entity_id: item.id,
              entity_name: item.name,
              remarks: "Archive Measured Activity 1",
              comment: "",
            },
          };

          AxiosConfig.post(
            `${URL_API}/account-management/user/activity-log`,
            dataLog
          )
            .then((resLog) => {
              // console.log("res resLog", resLog);
            })
            .catch((error) => {
              // console.log("Error", error);
              setLoading(false);

              const { listError, dialogErr, resStatus, errorMessage } =
                handleError(error);
              // Error State
              setOpenDialogError(dialogErr);
              setTextErrorMessage(errorMessage);
              setListError(listError);
              setErrorStatus(resStatus);
            });

          setOpenDialogArchive(false);
          context.setMessages("Berhasil mengarispkan MA");
          context.setModalResponse200(true);
          navigate("/archive");
        }
        setLoading(false);
      })
      .catch((error) => {
        // console.log("Error", error);
        setLoading(false);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  };

  return (
    <Dialog
      open={isOpenDialogArchive}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        <Typography variant="h6" className={classes.title333}>
          <b>Archive File</b>
        </Typography>
      </DialogTitle>

      <DialogContent style={{ width: 550, textAlign: "center" }}>
        <Typography variant="subtitle1" className={classes.title333}>
          Apakah Anda yakin ingin mengarsip File (<b>{item.name}</b>)?
        </Typography>
        <Typography variant="subtitle2" className={classes.title888}>
          File yang di archive hanya bisa dilihat pada menu archive
        </Typography>
      </DialogContent>

      <DialogActions
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: 15,
          marginTop: 20,
        }}
      >
        <Button
          variant="outlined"
          size="medium"
          className={classes.button0}
          onClick={() => setOpenDialogArchive(false)}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          size="medium"
          className={classes.button1}
          onClick={handleArchive}
        >
          {loading ? "Loading..." : "Archive"}
        </Button>
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
};

export default DialogArchive;
