/*
    `````````````````````````````````````````````
    NOW, 05 Desember 2019, 13.30. 
    
    Term "Employee" di ubah menjadi "User"
    
    `````````````````````````````````````````````
*/

import React, { useState, useEffect, useContext, Fragment } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  IconButton,
  Divider,
  CircularProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import axios from "axios";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import ContextNewSO from "../Context/ContextNewSO";

import DialogError from "../../../components/DialogError";
import Capitalize from "../../../utilities/Capitalize";

import { styles } from "../Style/StyleDialog";
import { VISIBILITY_DISABLED } from "../Constants/config-classification-so-master";
import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogTambahDeputyHead = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  const [listPositionTitle, setListPositionTitle] = useState([]);
  const [listEmployee, setListEmployee] = useState([]);
  const [isDisabledStructurePositionTitle, setDisabledStructurePositionTitle] =
    useState(false);
  const [isDisabledEmployee, setDisabledEmployee] = useState(false);

  /*
        ````````````
        PILIH ATASAN

        ````````````
    */

  /*
        ````````````````````````````
        DIALOG TAMBAH POSITION TITLE

        ````````````````````````````
    */
  // const [ isOpenDialogCreatePositionTitle, setOpenDialogCreatePositionTitle ] = useState(false); //*Moved to Global State

  const handleTambahJabatan = () => {
    context.setOpenDialogTambahDeputyHead(false);
    context.setOpenDialogCreatePositionTitleInDeputyHEAD(true);
  };

  const handleChoosePositionTitle = (e, data) => {
    e.preventDefault();

    // console.log("Wew Position Title : ", data);
    context.setPositionTitleId(data.id);
    context.setPositionTitleName(data.name);
    context.setPositionTitleChoose(true);

    //*Set state to next panel
    setExpanded("panel5");
    handleChange("panel5");
  };

  useEffect(() => {
    if (
      context.isOpenDialogTambahDeputyHead == true
      // kategoriPosisiId !== 0 ||
      // context.isSucessCreateEmployee == true
    ) {
      // setAtasanName('');
      // setAtasanChoosed(false);
      context.setFirstName("");
      context.setLastName("");

      context.setSuccessTambahDeputyHEAD(false);

      context.setTreeItems([
        {
          id: null,
          parent_id: null,
          structure_position_title_id: null,
          structure_position_title_name: null,

          structure_unit_id: null,
          structure_unit_name: null,

          structure_unit_type_id: null,
          structure_unit_type_name: null,

          member_first_name: null,
          member_last_name: null,
          self: null,

          classification: {
            id: null,
          },
          child: [],
        },
      ]);

      /*
                ```````````````````````````````````````````````
                if "STRUCTURE_POSITION_CLASSIFICATION_ID_STAFF"

                ```````````````````````````````````````````````
            */
      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(
            URL_API +
              `/human-resource/structure-position/create?classification=2`
          )
          .then((response) => {
            // console.log("Response Original : ", response);
            if (response.data.data !== null) {
              if (response.data.data.fields.hasOwnProperty("user_id")) {
                if (
                  response.data.data.fields.user_id.visibility ==
                  VISIBILITY_DISABLED
                ) {
                  setDisabledEmployee(true);
                }

                if (response.data.data.userCollections !== null) {
                  if (response.data.data.userCollections !== undefined) {
                    if (response.data.data.userCollections.length > 0) {
                      setListEmployee(response.data.data.userCollections);
                    }
                  }
                }
              }

              if (
                response.data.data.fields.hasOwnProperty(
                  "structure_position_title_id"
                )
              ) {
                if (
                  response.data.data.fields.structure_position_title_id
                    .visibility == VISIBILITY_DISABLED
                ) {
                  setDisabledStructurePositionTitle(true);
                }

                if (
                  response.data.data.structurePositionTitleCollections !== null
                ) {
                  if (
                    response.data.data.structurePositionTitleCollections !==
                    undefined
                  ) {
                    if (
                      response.data.data.structurePositionTitleCollections
                        .length > 0
                    ) {
                      setListPositionTitle(
                        response.data.data.structurePositionTitleCollections
                      );
                    }
                  }
                }
              }

              //********************************************************************/
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);
          });
      }
    }
  }, [
    context.isOpenDialogTambahDeputyHead,
    // kategoriPosisiId,
    // context.isSucessCreateEmployee
  ]);

  /*  
        `````````````````````````````````````````````````````````````````````````````````
        HANDLE DELETE EMPLOYEE MEMBER && HANDLE DROPDOWN EDIT & DELETE EMPLOYEE MEMBER

        `````````````````````````````````````````````````````````````````````````````````
    */

  const [anchorElMemberEmployee, setAnchorElMemberEmployee] = useState(null);

  const handleOpenDropdownEditAndDeleteMemberEmployee = (e, data) => {
    setAnchorElMemberEmployee(e.currentTarget);
    // console.log("Data from 'more_horizon' Member Employee : ", data);

    context.setDataDetail(data);

    //setSuccessDeleteMemberEmployee(false);
    // context.setSuccessEditNamaUnit(false);
  };

  const handleCloseDropdownEditAndDeleteMemberEmployee = () => {
    setAnchorElMemberEmployee(null);
  };

  /*
        ```````
        EXPAND

        ```````
    */
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : "");
  };

  /*
        ````````````````````
        DIALOG TAMBAH KARYAWAN

        ````````````````````
    */
  const handleChooseEmployee = (e, data) => {
    e.preventDefault();

    // console.log("Wew Employee : ", data);
    context.setEmployeeId(data.id);
    context.setFirstName(data.member?.first_name);
    context.setLastName(data.member?.last_name);

    //*Set state to next panel
    setExpanded("panelX");
    handleChange("panelX");
  };

  /*
        ````````````````````````````````
        HANDLE SUBMIT TAMBAH DEPUTY HEAD

        ````````````````````````````````
    */
  const [loader, setLoader] = useState(false);
  const handleSubmitTambahDeputyHead = () => {
    setLoader(true);

    let data = {
      StructurePosition: {
        parent_id: context.dataDetail.id,
        structure_position_title_id: context.positionTitleId,
        user_id: context.employeeId,
      },
    };

    // console.log("Data : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(
          URL_API + "/human-resource/structure-position?classification=2",
          data
        ) //*Classification 2 itu adalah ID DEPUTY HEAD
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            context.setSuccessTambahDeputyHEAD(true);

            context.setOpenDialogTambahDeputyHead(false);
            context.setSnackbarResponse200(true);
            context.setMessages("Berhasil menambahkan Deputy Head baru :)");
          }
        })
        .catch(function (error) {
          setLoader(false);
          context.setPositionTitleName("");

          if (error.response !== undefined) {
            if (error.response.status == 400) {
              setErrorStatus(error.response.status);
              if (
                error.response.data.info.errors !== undefined &&
                error.response.data.info.errors !== null
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setTextErrorInformationValue(".");
                  setOpenDialogError(true);
                  // if(error.response.data.info.errors[0].code == "VALIDATION_ERROR" ){

                  //     setTextErrorInformationValue('Error 400 : ' + Capitalize(error.response.data.info.errors[0].description))
                  //     setOpenDialogError(true);
                  // };
                }
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(error.response.status);
              setTextErrorInformationValue(
                "Error 500 : " + Capitalize(error.response.statusText)
              );
              setOpenDialogError(true);
            }
          } else {
            setTextErrorInformationValue("Whoops, something went wrong !");
            setOpenDialogError(true);
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*  
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState();
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogTambahDeputyHead}
        onClose={() => context.setOpenDialogTambahDeputyHead(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Tambah <i>Deputy Head</i>
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <div className={classes.root}>
            {/* 
                            ``````````````````
                            PILIH NAMA JABATAN

                            ``````````````````
                        */}
            <ExpansionPanel
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              disabled={isDisabledStructurePositionTitle == true ? true : false}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle2" className={classes.title}>
                  <IconButton style={{ padding: 4, marginBottom: 1 }}>
                    {expanded !== "panel4" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                  {context.isPositionTitleChoosed == true ? (
                    <Fragment>
                      <b>Terpilih {context.positionTitleName}</b>
                      <IconButton size="small">
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </IconButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      Tambah <i>Nama Jabatan</i>
                    </Fragment>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <div
                  className={classes.columnSeratusPersen}
                  style={{ textAlign: "left" }}
                >
                  {listPositionTitle.length == 0 && (
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ color: "red" }}
                    >
                      <IconButton size="small" style={{ marginBottom: 1 }}>
                        <ErrorIcon style={{ color: "red" }} />
                      </IconButton>
                      <b>
                        Belum ada <i>Nama Jabatan</i>
                      </b>
                    </Typography>
                  )}

                  {listPositionTitle.length > 0 &&
                    listPositionTitle.map((item, i) => (
                      <Fragment key={i}>
                        <Button
                          onClick={(e) => handleChoosePositionTitle(e, item)}
                          variant="outlined"
                          className={classes.buttonOutlined}
                        >
                          <b>{item.name}</b>
                        </Button>
                        <Divider />
                      </Fragment>
                    ))}
                </div>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={handleTambahJabatan}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp;<i>Jabatan</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>

            {/* 
                        ```````````````
                        TAMBAH USER

                        ```````````````
                    */}
            <ExpansionPanel
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              disabled={isDisabledEmployee == true ? true : false}
            >
              <ExpansionPanelSummary
                style={{ paddingBottom: 0, paddingLeft: 0 }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle2" className={classes.title}>
                  <IconButton style={{ padding: 4, marginBottom: 1 }}>
                    {expanded !== "panel5" ? (
                      <ExpandMoreIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                  {context.firstName !== "" ? (
                    <Fragment>
                      <b>
                        Terpilih {context.firstName + " "} {context.lastName}
                      </b>
                      <IconButton size="small">
                        <CheckCircleIcon
                          style={{ color: "green", fontSize: 14 }}
                        />
                      </IconButton>
                    </Fragment>
                  ) : (
                    <Fragment>
                      Tambah <i>User</i>
                    </Fragment>
                  )}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails
                style={{ paddingTop: 0, marginTop: 8 }}
                className={classes.details}
              >
                <List className={classes.rootList}>
                  {listEmployee.length > 0 &&
                    listEmployee.map((item, i) => (
                      <Fragment key={i}>
                        <ListItem
                          button
                          style={{ padding: 0 }}
                          onClick={(e) => handleChooseEmployee(e, item)}
                        >
                          <IconButton>
                            <AccountCircleIcon fontSize="large" />
                          </IconButton>

                          <ListItemText
                            id="label-employee"
                            primary={
                              // <Button
                              //     onClick={(e) => handleChooseEmployee(e, item)}
                              //     variant='outlined'
                              //     className={classes.buttonOutlined}
                              // >
                              //     <b>{item.member.first_name + " "}  {item.member.last_name}</b>
                              // </Button>
                              <Typography
                                variant="subtitle1"
                                className={classes.title}
                              >
                                <b>
                                  {item.member?.first_name + " "}{" "}
                                  {item.member?.last_name}
                                </b>
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="subtitle2"
                                className={classes.title}
                                style={{ color: "grey" }}
                              >
                                {item.email}
                              </Typography>
                            }
                          />

                          <ListItemSecondaryAction>
                            <Button
                              size="small"
                              variant="outlined"
                              className={classes.buttonOutlined}
                              style={
                                item.status.code ===
                                "user_status_id_enum_pending"
                                  ? {
                                      backgroundColor: "#ffd34f", //*yellow
                                      padding: 0,
                                    }
                                  : {
                                      backgroundColor: "#96ff96", //*green
                                      padding: 0,
                                    }
                              }
                            >
                              <Typography
                                variant="caption"
                                className={classes.title}
                                style={{ color: "white", marginLeft: 0 }}
                              >
                                {item.status.name}
                              </Typography>
                            </Button>
                          </ListItemSecondaryAction>

                          {/* <ListItemSecondaryAction>
                                                    <IconButton 
                                                        size='small'
                                                        onClick={(e) => handleOpenDropdownEditAndDeleteMemberEmployee(e, item)}
                                                    >
                                                        <MoreHorizIcon/>
                                                    </IconButton>

                                                <StyledMenu
                                                    id="customized-menu-goal-result"
                                                    anchorEl={anchorElMemberEmployee} //*Position Title
                                                    keepMounted
                                                    open={Boolean(anchorElMemberEmployee)}
                                                    onClose={handleCloseDropdownEditAndDeleteMemberEmployee}
                                                >
                                                        <StyledMenuItem 
                                                            onClick= {() => context.setOpenDialogEditMemberEmployee(true)}
                                                        >                         
                                                            <ListItemText 
                                                                
                                                                primary = {
                                                                    <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                        <IconButton  size='small'>
                                                                            <EditIcon style={{color: 'green'}} />
                                                                        </IconButton>
                                                                        <b>Edit</b>
                                                                    </Typography>
                                                                }  
                                                            />

                                                        </StyledMenuItem>
                                                        <StyledMenuItem
                                                            onClick= {() => context.setOpenDialogDeleteEmployeeMember(true)}
                                                        >                         
                                                            <ListItemText 
                                                                primary = {

                                                                    <Typography variant='subtitle2' className={classes.title} style={{color: 'grey'}}>
                                                                        <IconButton size='small'>
                                                                            <DeleteForeverIcon style={{color: 'red'}} />
                                                                        </IconButton>
                                                                        <b>Delete</b>
                                                                    </Typography>
                                                                }  
                                                            />
                                                        </StyledMenuItem>
                                                    </StyledMenu>

                                                </ListItemSecondaryAction> */}
                        </ListItem>
                        <Divider />
                      </Fragment>
                    ))}

                  {listEmployee.length == 0 && (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText
                        id="label-employee"
                        primary={
                          <Typography
                            variant="subtitle2"
                            className={classes.title}
                            style={{ color: "red" }}
                          >
                            <IconButton
                              size="small"
                              style={{ marginBottom: 1 }}
                            >
                              <ErrorIcon style={{ color: "red" }} />
                            </IconButton>
                            <b>
                              Belum ada <i>User</i>
                            </b>
                          </Typography>
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Button
                  onClick={() => {
                    handleChange("panelX");
                    setExpanded("panelX");

                    context.setFirstName("Vacant");
                    context.setLastName("");
                    context.setEmployeeId(null);
                  }}
                  variant="outlined"
                  size="small"
                  className={classes.buttonOutlined}
                >
                  <i style={{ color: "black" }}>Set as vacant</i>
                </Button>

                <Button
                  onClick={() => {
                    context.setOpenDialogTambahDeputyHead(false);
                    context.setOpenDialogCreateEmployeeInDeputyHEAD(true);
                  }}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className={classes.buttonOutlined}
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Tambah &nbsp; <i>User</i>
                </Button>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </div>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <Button
            onClick={handleSubmitTambahDeputyHead}
            variant="contained"
            className={classes.buttonDialog}
          >
            {loader !== true ? (
              <Fragment>
                Tambah &nbsp;<i> Deputy HEAD</i> &nbsp;sekarang
              </Fragment>
            ) : (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
          </Button>
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogTambahDeputyHead);
