import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, Button } from "@material-ui/core";
import CompareArrowIcon from "@material-ui/icons/CompareArrows";

import AxiosConfig from "../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../constants/config-api";
import handleError from "../../../../../Report/components/global/handleError";
import PieChartDashboard from "./PieChartDashboard";
import DialogError from "../../../../../../components/DialogError";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const PieChart = ({
  classes,
  period,
  structureUnitTypeId,
  structureUnitTypeName,
  unitType,
  is_admin,
  is_full_access,
  is_superior,
  divisionData,
  period200,
  unit200,
  divisi200,
}) => {
  const [loading, setLoading] = useState(false);
  const [pieGoal, setPieGoal] = useState([]);
  const [pieMa, setPieMa] = useState([]);
  const [pie, setPie] = useState("goal");

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  let unit_type_id;
  let unit_type_name;
  const units = divisionData.length > 0 ? divisionData.map((el) => el.id) : [];
  const unitName =
    divisionData.length > 0
      ? divisionData.map((el) => el.structure_unit_name)[0]
      : "";

  if (is_admin || is_full_access) {
    unit_type_id = unitType !== undefined ? unitType.id : "";
    unit_type_name = "";
  }

  if (is_admin && !is_superior) {
    unit_type_id = "";
    unit_type_name = "";
  }

  if (is_superior && !is_admin && !is_full_access) {
    unit_type_id = "";
    unit_type_name = unitName;
  }

  if (!is_superior && !is_admin && !is_full_access) {
    unit_type_id = structureUnitTypeId;
    unit_type_name = structureUnitTypeName;
  }

  // ~~~~~~~~~~~ BODY For Data Table Summary ~~~~~~~~~~~
  const dataPie = {
    name: "Bar Chart",
    platform: "web",
    report_format: "pie_chart",
    filter_by: {
      entity: [],
      date_range: "all_time",
      user: [],
      unit: is_admin || is_full_access ? [] : units,
      status: [3],
      period_id: period !== undefined ? period.id : "",
      achievement: [
        {
          field: "goal",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
        {
          field: "ma",
          cal_method: [
            { formula: "_value_ >= 100" },
            { formula: "_value_ >= 85 && _value_ <= 100" },
            { formula: "_value_ < 85" },
          ],
        },
      ],
    },
    entities: ["goal", "ma"],
    group_by:
      !is_admin && !is_full_access && !is_superior
        ? ["full_name"]
        : is_superior
        ? ["full_name"]
        : unit_type_id !== ""
        ? ["structure_unit_name"]
        : ["full_name"],
    group_by_calculation: "avg",
    order_by: [{ field: "full_name", method: "ASC", name: "User Name" }],
    primary_column: "overall_result_achievement",
    unit_type_id:
      !is_admin && !is_full_access && !is_superior
        ? ""
        : unit_type_id !== undefined || unit_type_id !== ""
        ? unit_type_id
        : "",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  useEffect(() => {
    setLoading(true);

    if (period200 === 200 && unit200 === 200 && divisi200 === 200) {
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail/summary?page=1&per_page=8`,
        dataPie
      )
        .then((res) => {
          const result = res.data.data;
          // // console.log("RES SUMMARY CHART", result);
          const pieGoalData = result.results.filter((el) => el.type === "goal");
          const pieMaData = result.results.filter((el) => el.type === "ma");

          if (res.status === 200) {
            setPieGoal(pieGoalData);
            setPieMa(pieMaData);
            setLoading(false);
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);
          setLoading(false);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);
        });
    }
  }, [unit_type_id, period]);

  const handlePie = () => {
    if (pie === "goal") setPie("ma");
    if (pie === "ma") setPie("goal");
  };

  return (
    <Paper className={classes.shadowSection}>
      <Box p={3} height={450}>
        {/* ====== Title & Icon More ====== */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.title333} variant="subtitle1">
            <b>
              {unit_type_name !== ""
                ? unit_type_name
                : MyLang()
                ? bahasa.overall
                : english.overall}{" "}
              {MyLang() ? bahasa.achievement : english.achievement}{" "}
              {pie === "goal" ? "Goal" : "MA"}
            </b>
          </Typography>

          <Button
            variant="outlined"
            size="small"
            className={classes.button0}
            endIcon={<CompareArrowIcon style={{ color: "#d64253" }} />}
            onClick={handlePie}
          >
            {pie === "goal" ? "MA" : "Goal"}
          </Button>
        </Box>
        {/* ====== Pie Chart ====== */}
        <Box mt={2}>
          {pie === "goal" && (
            <PieChartDashboard
              classes={classes}
              dataChart={pieGoal}
              loading={loading}
            />
          )}

          {pie === "ma" && (
            <PieChartDashboard
              classes={classes}
              dataChart={pieMa}
              loading={loading}
            />
          )}
        </Box>
      </Box>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Paper>
  );
};

export default PieChart;
