import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField, Typography } from "@material-ui/core";
import React, { useState, useContext, Fragment } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ContextAttendanceSetting from "../../Context/ContextAttendanceSetting";
import { DatePicker, KeyboardTimePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Autocomplete } from "@material-ui/lab";
import axiosConfig from "../../../../../constants/config-axios";
import { URL_API } from "../../../../../constants/config-api";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0F68D2",
    },
  },
});

const DialogEdit = (props) => {
  const {
    classes,
    isModalEdit,
    setModalEdit,
    // fotoQuery,
    // setFotoQuery,
    userLoginName,
    masterDataSchedule,
    setMasterDataSchedule,
    dataSchedule,
    setDataSchedule,
    listError,
    handleUpdate,
    handleChangeShift,
    masterMonthlyCollections,
    masterWeeklyCollections,
    masterCustomCollections,
    handleOnChangeTimePatternWeeklyOrMonthly,
    handleOnChangeTimePatternCustom,
    handleOnScheduleIn,
    handleOnScheduleOut,
    handleChangeWorkPattern,
  } = props;

  const context = useContext(ContextAttendanceSetting);
  const statusUserLogin = localStorage.getItem("status_user_login");
  const statusUserLoginAfterParse = JSON.parse(statusUserLogin);
  const userRole = statusUserLoginAfterParse.userRole.some(
    (el) => el.name === "superadmin" || el.name === "admin"
  );
  const [locale, setLocale] = useState("id");
  let endKonsekuensi = null;
  let startKonsekuensi = null;
  if (dataSchedule && dataSchedule.consequence_type && dataSchedule.consequence_type === "leave") {
    endKonsekuensi = <InputAdornment position="end">Hari</InputAdornment>;
  }
  if (dataSchedule && dataSchedule.consequence_type && dataSchedule.consequence_type === "financial") {
    startKonsekuensi = <InputAdornment position="start">Rp.</InputAdornment>;
  }
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isModalEdit}
        onClose={() => setModalEdit(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            color: "#fff",
            backgroundSize: "cover",
            backgroundColor: "#282560",
          }}
          disableTypography={true}
        >
          <Typography variant="h4">Edit Daftar Jadwal</Typography>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <Box p={1} my={1}>
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Pilh Work Pattern</b>
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataSchedule.masterWorkPatternCollections}
                getOptionLabel={(option) => {
                  if (option && option.name) {
                    return option.name;
                  } else {
                    return "";
                  }
                }}
                value={dataSchedule.work_pattern_obj}
                onChange={(event, newValue) => {
                  handleChangeWorkPattern(newValue);
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Work Pattern"
                    error={listError.some(item => 'work_pattern_id' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'work_pattern_id') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Pilih Shift</b>
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataSchedule.masterShiftCollections}
                getOptionLabel={(option) => {
                  if (option && option.title) {
                    return option.title;
                  } else {
                    return "";
                  }
                }}
                value={dataSchedule.shift}
                onChange={(event, newValue) => {
                  handleChangeShift(newValue);
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Shift"
                    error={listError.some(item => 'shift_id' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'shift_id') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Tulis Nama Schedule</b>
              </Typography>
              <Box my={0.5} />
              <TextField
                className={classes.textField}
                size="small"
                variant="outlined"
                fullWidth
                placeholder="e.g. Schedule Monday,Schedule Weekly..."
                value={dataSchedule.name}
                onChange={(event, newValue) => {
                  let dataScheduleTemporary = dataSchedule;
                  dataScheduleTemporary.name = event.target.value;
                  setDataSchedule({...dataScheduleTemporary});
                }}
                error={listError.some(item => 'name' === item.key)}
                helperText={listError.map((item) => {
                  if (item.key === 'name') {
                    return item.description;
                  }
                })}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Pilih Lokasi</b>
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataSchedule.masterLocationCollections}
                getOptionLabel={(option) => {
                  if (option && option.location_name) {
                    return option.location_name;
                  } else {
                    return "";
                  }
                }}
                value={dataSchedule.location}
                onChange={(event, newValue) => {
                  let dataScheduleTemporary = dataSchedule;
                  dataScheduleTemporary.location = newValue;
                  dataScheduleTemporary.location_id = newValue ? newValue.id : '';
                  setDataSchedule({...dataScheduleTemporary});
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Pilih Lokasi"
                    error={listError.some(item => 'location_id' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'location_id') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" className={classes.title333}>
                <b>Jenis Konsekuensi</b>
              </Typography>
              <Box my={0.5} />
              <Autocomplete
                options={masterDataSchedule.masterConsequenceTypeCollections}
                getOptionLabel={(option) => {
                  if (option && option.name) {
                    return option.name;
                  } else {
                    return "";
                  }
                }}
                value={dataSchedule.consequence_type_obj}
                onChange={(event, newValue) => {
                  let dataScheduleTemporary = dataSchedule;
                  dataScheduleTemporary.consequence_type_obj = newValue;
                  dataScheduleTemporary.consequence_type = newValue ? newValue.id : null;
                  setDataSchedule({...dataScheduleTemporary});
                }}
                noOptionsText="Tidak ada pilihan"
                popupIcon={<ArrowDropDownIcon />}
                renderInput={(params) => (
                  <TextField
                    style={{ fontSize: 13 }}
                    {...params}
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Jenis Konsekuensi"
                    error={listError.some(item => 'consequence_type' === item.key)}
                    helperText={listError.map((item) => {
                      if (item.key === 'consequence_type') {
                        return item.description;
                      }
                    })}
                  />
                )}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                <b>Nominal Konsekuensi</b>
              </Typography>
              <Box my={0.5} />
              <TextField
                className={classes.textField}
                size="small"
                variant="outlined"
                fullWidth
                placeholder="e.g. 1,2..."
                InputProps={{
                  endAdornment: endKonsekuensi,
                  startAdornment: startKonsekuensi
                }}
                type="number"
                disabled={!(dataSchedule.consequence_type === "leave" || dataSchedule.consequence_type === "financial")}
                value={dataSchedule.total_consequence}
                onChange={(event, newValue) => {
                  let dataScheduleTemporary = dataSchedule;
                  dataScheduleTemporary.total_consequence = event.target.value;
                  setDataSchedule({...dataScheduleTemporary});
                }}
                error={listError.some(item => 'total_consequence' === item.key)}
                helperText={listError.map((item) => {
                  if (item.key === 'total_consequence') {
                    return item.description;
                  }
                })}
              />
            </div>
            <Box my={2.5} />
            <div>
              <Typography variant="subtitle2" component={'span'} className={classes.title333}>
                <b>Allow Verification Failed</b>
              </Typography>
              <Switch
                checked={dataSchedule.allow_failed_verification === 1}
                onChange={(event) => {
                  let dataScheduleTemporary = dataSchedule;
                  dataScheduleTemporary.allow_failed_verification = event.target.checked ? 1 : 0;
                  setDataSchedule({...dataScheduleTemporary});
                }}
                name="allow_failed_verification"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </div>
            <Box my={2.5} />
            <div>
              <div>
                <Typography variant="subtitle2" className={classes.title333}>
                  <b>Verifikasi</b>
                </Typography>
              </div>
              <Box my={0.5} />
              <Grid container>
                {/* Schedule */}
                <Grid item xs={6} style={{marginBottom: "10px"}}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.schedule === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.schedule = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_schedule" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi Schedule</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.schedule === 0}
                        value={dataSchedule.verification.schedule_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.schedule_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.schedule_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.schedule_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Location */}
                <Grid item xs={6} style={{marginBottom: "10px"}} className={classes.gridLeft}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.location_coordinate === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.location_coordinate = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_location_coordinate" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi Lokasi</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.location_coordinate === 0}
                        value={dataSchedule.verification.location_coordinate_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.location_coordinate_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.location_coordinate_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.location_coordinate_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Member Pin */}
                <Grid item xs={6} style={{marginBottom: "10px"}}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.member_pin === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.member_pin = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_member_pin" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi PIN</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.member_pin === 0}
                        value={dataSchedule.verification.member_pin_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.member_pin_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.member_pin_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.member_pin_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Password */}
                <Grid item xs={6} style={{marginBottom: "10px"}} className={classes.gridLeft}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.member_password === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.member_password = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_member_password" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi Password</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.member_password === 0}
                        value={dataSchedule.verification.member_password_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.member_password_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.member_password_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.member_password_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Thumb Recognition */}
                <Grid item xs={6} style={{marginBottom: "10px"}}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.thumb_recognition === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.thumb_recognition = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_thumb_recognition" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi Sidik Jari</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.thumb_recognition === 0}
                        value={dataSchedule.verification.thumb_recognition_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.thumb_recognition_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.thumb_recognition_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.thumb_recognition_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* Face Recognition */}
                {/* <Grid item xs={6} style={{marginBottom: "10px"}} className={classes.gridLeft}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={dataSchedule.verification.face_recognition === 1}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataScheduleTemporary.verification.face_recognition = event.target.checked ? 1 : 0;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        name="verification_face_recognition" 
                        value={1}
                      />
                    }
                    label={<Typography variant="subtitle2" className={classes.title333}><b>Verifikasi Wajah</b></Typography>}
                  />
                  <Grid container spacing={2}>
                    <Grid item className={classes.girdMiddle}>
                      <Typography variant="subtitle2" className={classes.title333}>
                        Maksimal Percobaan :
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        className={classes.textField}
                        size="small"
                        variant="outlined"
                        fullWidth
                        placeholder="e.g. 1,2..."
                        type="number"
                        disabled={dataSchedule.verification.face_recognition === 0}
                        value={dataSchedule.verification.face_recognition_max_attempts}
                        onChange={(event, newValue) => {
                          let dataScheduleTemporary = dataSchedule;
                          dataSchedule.verification.face_recognition_max_attempts = event.target.value;
                          setDataSchedule({...dataScheduleTemporary});
                        }}
                        error={listError.some(item => 'verification.face_recognition_max_attempts' === item.key)}
                        helperText={listError.map((item) => {
                          if (item.key === 'verification.face_recognition_max_attempts') {
                            return item.description;
                          }
                        })}
                      />
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
            </div>
            <Box my={2.5} />
            {
              dataSchedule && dataSchedule.work_pattern && dataSchedule.work_pattern.interval === "Weekly" && 
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Hari Masuk</b>
                  </Typography>
                  <Box my={0.5} />
                  <div>
                    {
                      masterWeeklyCollections.map((item, i) => {
                        return (
                          <FormControlLabel
                            key={(i + 1)}
                            control={
                              <Checkbox 
                                checked={dataSchedule.day_in_arr.indexOf((i + 1)) > -1} 
                                onChange={(event, newValue) => {
                                  handleOnChangeTimePatternWeeklyOrMonthly(event);
                                }}
                                name="schedule_weekly" 
                                value={(i + 1)}
                              />
                            }
                            label={<Typography variant="subtitle2" className={classes.title333}>{item}</Typography>}
                          />
                        );
                      })
                    }
                  </div>
                </div>
            }
            {
              dataSchedule && dataSchedule.work_pattern && dataSchedule.work_pattern.interval === "Monthly" && 
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Tanggal Masuk</b>
                  </Typography>
                  <div>
                    {
                      masterMonthlyCollections.map((item, i) => {
                        return (
                          <FormControlLabel
                            style={{width: '60px'}}
                            key={item}
                            control={
                              <Checkbox 
                                checked={dataSchedule.day_in_arr.indexOf(item) > -1} 
                                onChange={(event, newValue) => {
                                  handleOnChangeTimePatternWeeklyOrMonthly(event);
                                }}
                                name="schedule_monthly" 
                                value={item}
                              />
                            }
                            label={<Typography variant="subtitle2" className={classes.title333}>{item}</Typography>}
                          />
                        );
                      })
                    }
                  </div>
                </div>
            }
            {
              dataSchedule && dataSchedule.work_pattern && dataSchedule.work_pattern.interval === "Custom" && 
                <Grid container spacing={2} style={{marginBottom: "20px"}}>
                  <Grid item md={12} style={{ textAlign: "left" }}>
                    <div>
                      <Typography variant="subtitle2" className={classes.title333}>
                        <b>Tangal Mulai</b>
                      </Typography>
                      <Box my={0.5} />
                      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                        <DatePicker
                          fullWidth
                          placeholder="Tangal Mulai"
                          value={dataSchedule.schedule_in}
                          format="DD-MM-YYYY"
                          onChange={(date) => {
                            handleOnScheduleIn(date)
                          }}
                          autoOk={true}
                          ampm={false}
                          inputVariant="outlined"
                          size="small"
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  size="small"
                                >
                                  <EventAvailableIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                  {/* <Grid item md={6} style={{ textAlign: "left" }}>
                    <div>
                      <Typography variant="subtitle2" className={classes.title333}>
                        <b>Tangal Berakhir</b>
                      </Typography>
                      <Box my={0.5} />
                      <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                        <DatePicker
                          placeholder="Tangal Berakhir"
                          value={dataSchedule.schedule_out}
                          format="DD-MM-YYYY"
                          onChange={(date) => {
                            handleOnScheduleOut(date);
                          }}
                          autoOk={true}
                          ampm={false}
                          inputVariant="outlined"
                          size="small"
                          className={classes.textField}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton
                                  size="small"
                                >
                                  <EventAvailableIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid> */}
                </Grid>
            }
            {
              dataSchedule && dataSchedule.work_pattern && dataSchedule.work_pattern.interval === "Custom" && 
                <div>
                  <Typography variant="subtitle2" className={classes.title333}>
                    <b>Pilih Tanggal Libur</b>
                  </Typography>
                  <Box my={0.5} />
                  <div>
                    {/* {
                      masterCustomCollections.map((item, i) => {
                        return (
                          <FormControlLabel
                            style={{width: '125px'}}
                            key={item.id}
                            control={
                              <Checkbox 
                                checked={dataSchedule.day_out_arr.indexOf(item.id) > -1} 
                                onChange={(event) => {
                                  handleOnChangeTimePatternCustom(event);
                                }}
                                name="schedule_custom" 
                                value={item.id}
                              />
                            }
                            label={<Typography variant="subtitle2" className={classes.title333}>{item.name}</Typography>}
                          />
                        );
                      })
                    } */}
                    {
                      masterCustomCollections.map((item, i) => {
                        return (
                          <FormControlLabel
                            style={{width: '125px'}}
                            key={item.id}
                            control={
                              <Checkbox 
                                checked={dataSchedule.day_out_arr.indexOf(item.id.toString()) > -1} 
                                onChange={(event) => {
                                  handleOnChangeTimePatternCustom(event);
                                }}
                                name="schedule_custom" 
                                value={item.id}
                              />
                            }
                            label={<Typography variant="subtitle2" className={classes.title333}>{item.name}</Typography>}
                          />
                        );
                      })
                    }
                  </div>
                </div>
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              varian="outlined"
              className={classes.button0}
              onClick={() => setModalEdit(false)}
            >
              Batalkan
            </Button>
            <Box mx={1} />
            <Button
              variant="outlined"
              className={classes.button1}
              onClick={handleUpdate}
            >
              Simpan !
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default DialogEdit;