import React from "react";
import { createMuiTheme, fade } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#d1354a", //#cc0707, #c62828
      light: "#fff",
      dark: "#fff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: ["Roboto", "Roboto"].join(","),
  },
});

export const styles = () => ({
  root: {
    width: "100%",
    marginTop: 1 * 3,
    background: "#fff",
    height: "200vh",
  },
  title: {
    fontFamily: "Roboto",
  },
  layoutMarginLeftAndTop: {
    marginLeft: 72,
    marginTop: 56,
  },
  backSection: {
    marginTop: 10,
    padding: `10px 20px`,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#ddd",
  },
  button: {
    borderRadius: 5,
    backgroundColor: "#d1354a",
    border: 0,
    color: "#fff",
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    padding: "10px 20px",
    "&:hover": {
      backgroundColor: "#f28181",
    },
  },
  button2: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
    color: "#d64253",
    padding: "10px 20px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
  btnCancelUpload: {
    borderRadius: 3,
    backgroundColor: "#f28181",
    border: 0,
    color: "#d1354a",
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: "700",
    padding: `1px 0`,
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
  btnCancelDownload: {
    borderRadius: 3,
    backgroundColor: "#f28181",
    border: 0,
    color: "#d1354a",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    fontSize: 14,
    fontWeight: "600",
    padding: `5px 0`,
    "&:hover": {
      backgroundColor: "#ffcccc",
    },
  },
  backButton: {
    backgroundColor: "transparent",
    color: "#888",
    fontFamily: "Roboto",
    border: 0,
    fontSize: 14,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonSimple: {
    borderRadius: 5,
    border: 0,
    fontFamily: "Roboto",
    marginLeft: theme.spacing(1),
    padding: 5,
    fontWeight: "600",
    fontSize: 14,
    textTransform: "capitalize",
  },
  driveContainer: {
    margin: `30px 50px`,
  },
  breadcrumbSection: {
    padding: `10px 20px 10px 0`,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#ddd",
  },
  breadcrumbText: {
    fontFamily: "Roboto",
    fontWeight: "600",
    fontSize: 14.5,
    color: "#aaa",
    textDecoration: "none",
    "&:hover": {
      color: "#888",
    },
  },
  textItemSort: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "600",
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: "#555",
    },
  },

  // ========================================
  // Search Input Drive
  // ========================================
  menuSection: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#ddd",
    padding: `20px 0`,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.05),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.1),
    },
    marginRight: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
    fontFamily: "Roboto",
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: 13,
    [theme.breakpoints.up("md")]: {
      width: 350,
    },
    color: "#333",
  },

  // ========================================
  // MENU Icon
  // ========================================
  menuContainer: {
    marginLeft: 25,
  },
  menuIcon: {
    padding: 10,
    marginLeft: 5,
  },
  menuIconColor: {
    fontSize: 25,
    color: "#aaa",
  },

  settingContainer: {
    marginLeft: 15,
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: "#ddd",
  },

  // ========================================
  // Folder & Sidebar
  // ========================================
  rootFolder: {
    marginTop: 15,
    marginRight: 50,
  },
  sideBarDivider: {
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: "#ddd",
    height: `100vh`,
  },
  dateStorageDivider: {
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: "#ddd",
  },
  titleSidebar: {
    fontFamily: "Roboto",
    fontSize: 13,
    fontWeight: "600",
    color: "#aaa",
  },
  textSidebar: {
    fontFamily: "Roboto",
    color: "#888",
    marginTop: 5,
  },
  textOwner: {
    fontFamily: "Roboto",
    fontSize: 13,
    marginLeft: 5,
    color: "#555",
  },
  titleAdmin: {
    fontFamily: "Roboto",
    fontSize: 16,
    color: "#333",
    marginBottom: 10,
  },
  textAdmin: {
    fontFamily: "Roboto",
    fontSize: 15,
    marginLeft: 10,
    color: "#333",
    fontWeight: "600",
  },
  rightMenuText: {
    fontFamily: "Roboto",
    fontSize: 13,
    marginLeft: -15,
    fontWeight: "600",
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: "#fff",
    },
  },
  hoverColor: {
    "&:hover": {
      color: "#fff",
    },
  },
  folderNameText: {
    fontFamily: "Roboto",
    fontWeight: "700",
    color: "#333",
    textDecoration: "none",
    "&:hover": {
      color: "#777",
      cursor: "pointer",
    },
  },

  // ========================================
  // Rounded Icon
  // ========================================
  checkboxRoot: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 20,
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(209, 53, 74,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#d1354a", // #137cbd
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#b1354a", // #106ba3
    },
  },

  // ========================================
  // Upload File
  // ========================================
  uploadContainer: {
    textAlign: "center",
    padding: 20,
    border: `3px dashed #eeeeee`,
    backgroundColor: "#fafafa",
    color: "#bdbdbd",

    marginBottom: 20,
    marginTop: 10,

    "&:focus": {
      color: "transparent",
      backgroundColor: "transparent",
    },
  },

  // ========================================
  // Tree View
  // ========================================
  rootTreeView: {
    height: "auto",
    flexGrow: 1,
    maxWidth: 400,
  },

  // ========================================
  // Set Permission Tabs
  // ========================================
  rootRadio: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconRadio: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
    padding: 0,
  },
  checkedIconRadio: {
    backgroundColor: "#d1354a",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#d1354a",
    },
  },
});
