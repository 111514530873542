import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FormatDecimal } from "../../../../../../utilities/FormatDecimal";
import TruncateTextCustom from "../../../../../../utilities/TruncateTextCustom";

const TableGoal = ({ classes, goals, loading }) => {
  const Row = ({ row }) => {
    const [open, setOpen] = React.useState(false);

    const handleToGoalDetail = (data) => {
      const url = `/goal-detail/${data.entity_id}`;
      window.open(url, "_blank");
    };

    // // console.log("ROW", row);

    return (
      <>
        <TableRow>
          <TableCell className={classes.txtBody}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.txtBody}>{row.full_name}</TableCell>
          <TableCell className={classes.txtBody}>
            {row.position_title}
          </TableCell>
          <TableCell className={classes.txtBody}>
            {FormatDecimal(row.weighted_ora)}%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box m={1}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>Details</b>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Achievement</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Weighted Achievement</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.details.map((detail) => {
                      const isNull = detail.weighted_ora === null;

                      return (
                        <TableRow key={detail.id}>
                          <TableCell>{row?.full_name}</TableCell>
                          <TableCell
                            className={classes.txtTitleGoal}
                            onClick={() => handleToGoalDetail(detail)}
                          >
                            {TruncateTextCustom(detail?.goal?.name, 42)}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : `${FormatDecimal(detail.ora)}%`}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : FormatDecimal(detail.weight)}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : `${detail.days} days`}
                          </TableCell>
                          <TableCell>
                            {isNull
                              ? "N/A"
                              : `${FormatDecimal(detail.weighted_ora)}%`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.txtHeader} />
              <TableCell className={classes.txtHeader}>Full Name</TableCell>
              <TableCell className={classes.txtHeader}>Position</TableCell>
              <TableCell className={classes.txtHeader}>
                Weighted Achievement
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goals !== undefined &&
              goals.length > 0 &&
              goals.map((row, i) => <Row key={i} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* LOADIER */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {loading && (
          <>
            <CircularProgress
              size={16}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Loading...
            </Typography>
          </>
        )}

        {goals !== undefined && goals.length === 0 && !loading && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>
    </>
  );
};

export default TableGoal;
