import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Menu,
  Checkbox,
  Button,
  Switch,
} from "@material-ui/core";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import WatchLaterIcon from "@material-ui/icons/WatchLater";

import moment from "moment";
import clsx from "clsx";
import DateFnsUtils from "@date-io/date-fns";
import ListItemDailyInWeek from "./ListItemDailyInWeek";
import ListItemWeeklyInWeek from "./ListItemWeeklyInWeek";
import ListItemRemindInWeek from "./ListItemRemindInWeek";

const FreqWeekly = ({
  classes,
  onWeeklyFreq,
  weeklyFreq,
  dailyList,
  weeklyList,
  remindList,
  goalDetailState,
  autoInsert,
  onTrigger,
}) => {
  const date = new Date();
  const is_locked =
    goalDetailState?.update_frequency?.result_locked === null
      ? false
      : goalDetailState?.update_frequency?.result_locked === 0
      ? false
      : true;

  const calcMethidId = goalDetailState.calculation_method_id;
  const auto_insert =
    calcMethidId === "3" ? true : autoInsert === "1" ? true : false;

  const scheduled =
    goalDetailState?.update_frequency === null
      ? "friday"
      : goalDetailState?.update_frequency?.interval !== "weekly"
      ? "friday"
      : goalDetailState?.update_frequency?.scheduled_at.split("-")[0];

  const dateFormat =
    goalDetailState?.update_frequency === null
      ? "00:01"
      : goalDetailState?.update_frequency?.interval !== "weekly"
      ? goalDetailState?.update_frequency?.scheduled_at
      : goalDetailState?.update_frequency?.scheduled_at.split("-")[1];

  const [anchorDay, setAnchorDay] = useState(null);
  const [anchorWeek, setAnchorWeek] = useState(null);
  const [anchorRemind, setAnchorRemind] = useState(null);
  const [day, setDay] = useState({
    day: scheduled,
    day_name: scheduled?.charAt(0).toUpperCase() + scheduled.slice(1),
    time_schedule: dateFormat,
    selected: true,
  });
  const [selectedDate, setSelectedDate] = useState(
    goalDetailState?.update_frequency === null
      ? moment("00:01", [moment.ISO_8601, "HH:mm"])
      : goalDetailState?.update_frequency?.interval !== "weekly"
      ? moment("00:01", [moment.ISO_8601, "HH:mm"])
      : moment(goalDetailState?.update_frequency?.scheduled_at.split("-")[1], [
          moment.ISO_8601,
          "HH:mm",
        ])
  );
  const [formatedDate, setFormatedDate] = useState(dateFormat);
  const [selected, setSelected] = useState([]);
  const [listAllWeek, setListAllWeek] = useState([]);
  const [locking, setLocking] = useState(is_locked);
  const [autoInsertResult, setAutoInsertResult] = useState(auto_insert);
  const [reminder, setReminder] = useState(false);
  const [allWeek, setAllWeek] = useState(false);
  const [remindMe, setRemindMe] = useState({
    id: "",
    name: "",
    selected: false,
    self: {
      rel: null,
    },
  });

  // // console.log("weeklyList", weeklyList);

  const isSelected = (id) => selected.indexOf(id) !== -1;

  useEffect(() => {
    setAutoInsertResult(auto_insert);
  }, [auto_insert]);

  useEffect(() => {
    // // console.log("weeklyList", weeklyList);

    const makeSelectedTrue =
      weeklyList.length > 0
        ? weeklyList?.map((el) => {
            return {
              ...el,
              selected: true,
            };
          })
        : [];

    setListAllWeek(makeSelectedTrue);

    const mapWeeks = makeSelectedTrue?.map((el) => el.week);

    setSelected(mapWeeks);

    const checkSelected =
      makeSelectedTrue?.length > 0
        ? makeSelectedTrue.filter((el) => el.selected === false)
        : ["empty"];

    if (checkSelected?.length === 0) {
      setAllWeek(true);
    } else {
      setAllWeek(false);
    }
  }, [weeklyList]);

  useEffect(() => {
    onWeeklyFreq({
      ...weeklyFreq,
      weekly_details: {
        selected_weeks: listAllWeek,
        input_start: goalDetailState.start_date,
        input_finish: goalDetailState.end_date,
      },
    });
  }, [listAllWeek, goalDetailState]);

  // // console.log("listAllWeek", listAllWeek);
  // // console.log("GDSTx", goalDetailState);

  const handleShowAnchorDay = (e) => {
    setAnchorDay(e.currentTarget);
  };
  const handleCloseAnchorDay = () => {
    setAnchorDay(null);
  };
  const handleShowAnchorWeek = (e) => {
    setAnchorWeek(e.currentTarget);
  };
  const handleCloseAnchorWeek = () => {
    setAnchorWeek(null);
  };
  const handleShowAnchorRemind = (e) => {
    setAnchorRemind(e.currentTarget);
  };
  const handleCloseAnchorRemind = () => {
    setAnchorRemind(null);
  };

  const handleDateChange = (date) => {
    const formatHour = moment(date).format("HH:mm");
    setSelectedDate(date);
    setFormatedDate(formatHour);

    onWeeklyFreq({
      ...weeklyFreq,
      scheduled_at: `${day.day}-${formatHour}`,
      input_start: goalDetailState.start_date,
      input_finish: goalDetailState.end_date,
    });
    onTrigger(Math.floor(Math.random() * 100));
  };

  const handleDay = (data) => {
    setDay(data);
    handleCloseAnchorDay();

    onWeeklyFreq({
      ...weeklyFreq,
      input_start: goalDetailState.start_date,
      input_finish: goalDetailState.end_date,
      scheduled_at: `${data.day}-${data.time_schedule}`,
    });

    // // console.log("DATA Day", data);

    onTrigger(Math.floor(Math.random() * 100));
  };

  const handleSelectAllClick = (event) => {
    setAllWeek(event.target.checked);

    if (event.target.checked) {
      const newSelectedsTrue = listAllWeek.map((n) => {
        return {
          ...n,
          selected: true,
        };
      });

      setListAllWeek(newSelectedsTrue);
    } else {
      const newSelectedsFalse = listAllWeek.map((n) => {
        return {
          ...n,
          selected: false,
        };
      });

      setListAllWeek(newSelectedsFalse);
    }
  };

  const handleLocking = (e) => {
    setLocking(e.target.checked);

    onWeeklyFreq({
      ...weeklyFreq,
      input_start: goalDetailState.start_date,
      input_finish: goalDetailState.end_date,
      result_locked: e.target.checked ? 1 : 0,
      is_locked: e.target.checked,
    });
  };

  const handleReminder = (e) => {
    setReminder(e.target.checked);
  };

  const handleAutoInsert = (e) => {
    setAutoInsertResult(e.target.checked);

    onWeeklyFreq({
      ...weeklyFreq,
      input_start: goalDetailState.start_date,
      input_finish: goalDetailState.end_date,
      auto_insert_result: e.target.checked ? "1" : "0",
    });
  };

  const handleRemind = (data) => {
    setRemindMe(data);
    handleCloseAnchorRemind();
  };

  const handleChooseWeek = (e, data) => {
    const checked = e.target.checked;

    // // console.log("CCC", data);

    const weekSelect =
      listAllWeek.length > 0
        ? listAllWeek.map((el) => {
            let check = el.selected;

            // // console.log("check month", check);

            if (el.week === data.week) {
              check = checked;
            } else {
              check = el.selected;
            }

            return {
              ...el,
              selected: check,
            };
          })
        : [];

    const checkSelected =
      weekSelect?.length > 0
        ? weekSelect.filter((el) => el.selected === false)
        : ["empty"];

    if (checkSelected?.length === 0) {
      setAllWeek(true);
    } else {
      setAllWeek(false);
    }

    setListAllWeek(weekSelect);

    onWeeklyFreq({
      ...weeklyFreq,
      input_start: goalDetailState.start_date,
      input_finish: goalDetailState.end_date,
      weekly_details: {
        selected_weeks: weekSelect,
      },
    });
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Scheduled on</b>
        </Typography>

        <Box ml={9}>
          <Button
            variant="outlined"
            size="small"
            className={classes.actionBtn}
            endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
            onClick={handleShowAnchorDay}
          >
            {day.day_name}
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorDay}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorDay)}
            onClose={handleCloseAnchorDay}
          >
            {dailyList.length > 0 &&
              dailyList.map((item, i) => {
                const isCurrent = item.day_name === day.day_name;

                return (
                  <ListItemDailyInWeek
                    key={i}
                    classes={classes}
                    isCurrent={isCurrent}
                    day={day}
                    item={item}
                    handleDay={handleDay}
                  />
                );
              })}
          </Menu>
        </Box>

        <Box ml={4}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>at</b>
          </Typography>
        </Box>

        <Box ml={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              value={selectedDate}
              onChange={handleDateChange}
              keyboardIcon={<WatchLaterIcon style={{ fontSize: 17 }} />}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
              style={{ width: 150 }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Update Week</b>
        </Typography>

        <Box ml={9}>
          <Button
            variant="outlined"
            size="small"
            className={classes.actionBtn}
            endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
            onClick={handleShowAnchorWeek}
          >
            {listAllWeek?.length > 0 &&
              listAllWeek?.filter((el) => el.selected === true).length}{" "}
            {listAllWeek?.length > 0 &&
            listAllWeek?.filter((el) => el.selected === true).length < 2
              ? "Week"
              : "Weeks"}{" "}
            selected
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorWeek}
            keepMounted
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
            open={Boolean(anchorWeek)}
            onClose={handleCloseAnchorWeek}
          >
            <Box display="flex" alignItems="center" ml={1} p={0.5}>
              <Checkbox
                checked={allWeek}
                className={classes.checkboxRoot}
                checkedIcon={
                  <span className={clsx(classes.icon, classes.checkedIcon)} />
                }
                icon={<span className={classes.icon} />}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
              <Typography variant="caption" className={classes.title333}>
                All weeks
              </Typography>
            </Box>
            {listAllWeek?.length > 0 &&
              listAllWeek?.map((item, i) => (
                <ListItemWeeklyInWeek
                  key={i}
                  classes={classes}
                  item={item}
                  index={i}
                  selected={selected}
                  setSelected={setSelected}
                  isSelected={isSelected}
                  onChooseWeek={handleChooseWeek}
                />
              ))}
          </Menu>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Locking</b>
        </Typography>

        <Box ml={13}>
          <Switch
            checked={locking}
            disabled={goalDetailState.direct_link_node_level === 0}
            onChange={handleLocking}
            color="primary"
          />
        </Box>

        {!locking && (
          <Typography variant="caption" className={classes.title}>
            <i>No update is allowed after schduled update is passed</i>
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title333}>
          <b>Auto Insert</b>
        </Typography>

        <Box ml={10}>
          <Switch
            disabled={
              calcMethidId === "3" ||
              goalDetailState.direct_link_node_level === 0
            }
            checked={calcMethidId === "3" ? true : autoInsertResult}
            onChange={handleAutoInsert}
            color="primary"
          />
        </Box>

        {!autoInsertResult && (
          <Typography variant="caption" className={classes.title}>
            <i>
              When user not inserted the result before frequency time, system
              will automatically insert result to 0.
            </i>
          </Typography>
        )}
      </Box>

      {/* <Box display="flex" alignItems="center" mt={4}>
        <Typography variant="subtitle1" className={classes.title}>
          <b>Add Reminder</b>
        </Typography>

        <Box ml={7}>
          <Switch
            checked={reminder}
            onChange={handleReminder}
            color="primary"
          />
        </Box>
      </Box> */}

      {reminder && (
        <Box display="flex" alignItems="center" mt={4}>
          <Typography variant="subtitle1" className={classes.title333}>
            <b>Reminder me</b>
          </Typography>

          <Box ml={9}>
            <Button
              variant="outlined"
              size="small"
              className={classes.actionBtn}
              endIcon={<ArrowDropDownIcon style={{ fontSize: 25 }} />}
              onClick={handleShowAnchorRemind}
            >
              {remindMe.name}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorRemind}
              keepMounted
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              getContentAnchorEl={null}
              open={Boolean(anchorRemind)}
              onClose={handleCloseAnchorRemind}
            >
              {remindList.length > 0 &&
                remindList.map((item, i) => {
                  const isCurrent = item.name === remindMe.name;

                  return (
                    <ListItemRemindInWeek
                      key={i}
                      classes={classes}
                      isCurrent={isCurrent}
                      remind={remindMe}
                      item={item}
                      handleRemind={handleRemind}
                    />
                  );
                })}
            </Menu>
          </Box>
        </Box>
      )}
    </>
  );
};

export default FreqWeekly;
