import React, {
  useLayoutEffect,
  useState,
  useEffect,
  useContext,
  useRef,
  Fragment,
} from "react";
import {
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { useIndexedDB } from "react-indexed-db";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import {
  DatePicker,
  MuiPickersUtilsProvider,
  DateTimePicker,
} from "@material-ui/pickers";

import ContextAllMA from "../context/AllMaContext";

import UploaderInGoalResultNEW from "../../Goal/ComponentsGoalResult/UploaderInGoalResultNEW";
// import UploaderInMaResult from "../../MeasureActivities/ComponentResultMA/UploaderInMaResult";

import DialogError from "../../../../components/DialogError";
import axiosConfig from "../../../../constants/config-axios";

import { URL_API } from "../../../../constants/config-api";
import Capitalize from "../../../../utilities/Capitalize";
import ConvertDataUrlToFile from "../../../../utilities/ConvertDataUrlToFile";

import "moment/locale/id";

class LocalizedUtils extends MomentUtils {
  getDatePickerHeaderText(date) {
    return this.format(date, "DD MMMM YYYY", { locale: this.locale });
  }
}

const DialogCreateAllMAResult = (props) => {
  const {
    classes,
    isModalCreateMAResult,
    setModalCreateMAResult,
    collectionMaResultList,
    setCollectionMaResultList,
    // setFireMaDetail,
    maDetailState,
  } = props;

  /*
          ````````
          USE REFF
  
          ````````
      */
  let textInputReff = useRef(null);

  const context = useContext(ContextAllMA);

  const { getAll } = useIndexedDB("video");

  /*
          ```````````````````
          COMPONENT DID MOUNT 
  
          ```````````````````
      */
  const [locale, setLocale] = useState("id");
  const userToken = localStorage.getItem("userToken");

  const [userTokenState, setUserTokenState] = useState("");
  const [measuredActivityId, setMeasuredActivityId] = useState("");

  const [maIsEvidenceRequired, setMaIsEvidenceRequired] = useState(null);
  const [disableAddResult, setDisableAddResult] = useState(false); // Disable BTN PREVILLAGE

  useEffect(() => {
    setUserTokenState(userToken);

    if (isModalCreateMAResult === true) {
      setSuccesCreateMaResult(false);
      context.setSuccessUploadFile(false);

      const allMaDetail = localStorage.getItem("allma_detail");
      const allMaDetailAfterParse = JSON.parse(allMaDetail);

      if (allMaDetailAfterParse !== null) {
        setMaIsEvidenceRequired(allMaDetailAfterParse.is_evidence_required);
      }

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      // localStorage.removeItem('data_pict_goal_result');

      setDisabledButton(false);

      setValueMAResult("");
      setKomentar("");
      // handleChangeDueDate(null)
      // // console.log("goalDetailAfterParse : ", goalDetailAfterParse);

      // setFireMaDetail(false);
      setMeasuredActivityId(allMaDetailAfterParse.id);

      if (userTokenState !== "") {
        setLoader(true);
        axiosConfig
          .get(
            `${URL_API}/swd/measured-activity/${maDetailState.id}/result/create`
          )
          .then((response) => {
            // // console.log("MAR Result FORM", response);
            setLoader(false);
          })
          .catch((error) => {
            setLoader(false);
            // console.log("error : ", error);

            if (error.response.status === 403) {
              setDisableAddResult(true);
            }
          });
      }
    }
  }, [isModalCreateMAResult]);

  /*
          `````````````````````````
          HANDLE CHANGE GOAL RESULT
  
          `````````````````````````
      */
  const [maResult, setValueMAResult] = useState("");
  const handleChangeValueMAResult = (e) => {
    // // console.log("GOAL RESULT : ", e.target.value);
    setValueMAResult(e.target.value);
  };

  /*
          ``````````````````````````````````
          HANDLE CHANGE DESCRIPTION/KOMENTAR
  
          ``````````````````````````````````
      */
  const [komentar, setKomentar] = useState("");
  const handleChangeKomentar = (e) => {
    e.preventDefault();
    setKomentar(e.target.value);
  };

  /*
          ````````
          DUE DATE
  
          ````````
      */
  const [selectedDueDate, handleChangeDueDate] = useState(moment());

  /*
          `````````````````````````
          HANDLE BUTTON SUBMIT GOAL
  
          `````````````````````````
      */
  const [loader, setLoader] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  // LIST DATA FROM INDEXDB
  const [listDataInsideIndexedDB, setListDataInsideIndexedDB] = useState([]);
  // INITIAL FIME
  const [initialFileMAR, setInitialFileMAR] = useState(null);

  function getAcceptedFiles(files) {
    setInitialFileMAR(files);
  }

  const handleSUBMIT = () => {
    /*
              ```````````````````````````````````
              handleCheckListFile()
  
                  - GET WHATEVER INSIDE indexedDB
  
              ```````````````````````````````````
          */
    getAll().then((item) => {
      // console.log("Items inside IndexedDB : ", item);
      setListDataInsideIndexedDB(item);
    });

    //*
    let dataPictReady = [];

    let dataPictGoalResult = localStorage.getItem("data_pict_ma_result");
    let dataPictGoalResultAfterParse = JSON.parse(dataPictGoalResult);

    if (dataPictGoalResultAfterParse !== null) {
      if (dataPictGoalResultAfterParse.length > 0) {
        dataPictGoalResultAfterParse.map((item) => {
          let pictFile = ConvertDataUrlToFile(item.img, item.title);

          dataPictReady.push(pictFile);
        });
      }
    }

    setLoader(true);
    setDisabledButton(true);

    // console.log("Initial File MAR : ", initialFileMAR);

    let data = new FormData();
    data.append("MeasuredActivityResult[result_value]", maResult);
    data.append("MeasuredActivityResult[description]", komentar);
    data.append(
      "MeasuredActivityResult[result_date]",
      moment(selectedDueDate).format("YYYY-MM-DD")
    );
    data.append(
      "MeasuredActivityResult[result_time]",
      moment(selectedDueDate).format("HH:mm:ss")
    ); //*NANTI DI TAMBAHIN DETIK JUGA ! (SETELAH BACK-END PUSH)

    if (maIsEvidenceRequired === 1) {
      if (initialFileMAR !== null) {
        data.append("MeasuredActivityResult[evidence]", initialFileMAR[0]);
      }
    }

    if (userTokenState !== "") {
      axiosConfig
        .post(
          URL_API + `/swd/measured-activity/${measuredActivityId}/result`,
          data
        )
        .then(function (response) {
          setLoader(false);
          setDisabledButton(false);
          // // console.log("Response Original : ", response);
          if (response.status === 200) {
            // setFireMaDetail(true);
            setMaResultId(response.data.data.id);
            setCollectionMaResultList([
              ...collectionMaResultList,
              response.data.data,
            ]); //* Ane ga pake karena sudah di panggil
            setSuccesCreateMaResult(true);
            // localStorage.removeItem('data_pict_ma_result');
            setInitialFileMAR(null);

            setTimeout(() => {
              context.setSuccessCreateMAR(Math.floor(Math.random() * 1000 + 1));
            }, 1000);

            if (maIsEvidenceRequired === 1) {
              setModalCreateMAResult(false);
              context.setLoaderEvidance(false);
              context.setModalResponse200(true);
              context.setMessages("berhasil :)");
            }
          }
        })
        .catch(function (error) {
          setLoader(false);
          setDisabledButton(false);
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              setErrorStatus(400);
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                }
                if (error.response.data.info.errors.result_value !== null) {
                  if (
                    error.response.data.info.errors.hasOwnProperty(
                      "result_value"
                    )
                  ) {
                    setTextErrorInformation(
                      `Error 400 : ${error.response.data.info.errors.result_value}`
                    );
                    setOpenDialogError(true);
                  }
                }
                if (error.response.data.info.errors.result_date !== null) {
                  if (
                    error.response.data.info.errors.hasOwnProperty(
                      "result_date"
                    )
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformation(
                      "Error 400 : " +
                        Capitalize(error.response.data.info.errors.result_date)
                    );
                  }
                }
                if (error.response.data.info.errors.result_time !== null) {
                  if (
                    error.response.data.info.errors.hasOwnProperty(
                      "result_time"
                    )
                  ) {
                    setOpenDialogError(true);
                    setTextErrorInformation(
                      "Error 400 : " +
                        error.response.data.info.errors.result_time
                    );
                  }
                }
              }
            }
            if (error.response.status == 422) {
              setErrorStatus(422);
              setListError(error.response.data.info.errors);
              setOpenDialogError(true);
            }
            if (error.response.status === 500) {
              setErrorStatus(500);
              setOpenDialogError(true);
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }
          localStorage.removeItem("data_pict_ma_result");
          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
          `````````````
          STATE SPECIAL
  
          `````````````
      */

  const [goalResultDataDetail, setValueMAResultDataDetail] = useState({
    goalResultEvidence: [],
  });

  /*
          ```````````````````
          HANDLE DIALOG ERROR
  
          ```````````````````
      */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  /*
          ```````````````````````````````````
          RUN NEW API TO UPLOAD IMAGE & VIDEO
  
          ```````````````````````````````````
      */

  const [maResultId, setMaResultId] = useState("");
  const [isSuccesCreateMaResult, setSuccesCreateMaResult] = useState(false);

  useLayoutEffect(() => {
    if (isSuccesCreateMaResult === true) {
      // console.log("Running Upload image or video...");

      let dataPictReady = [];

      /*
                  ```````````````````
                  PLAY WITH IndexedDB
  
                  ```````````````````
              */
      // console.log("listDataInsideIndexedDB : ", listDataInsideIndexedDB);
      if (listDataInsideIndexedDB.length > 0) {
        listDataInsideIndexedDB.map((item) => {
          let pictFile = ConvertDataUrlToFile(item.img, item.title);
          dataPictReady.push(pictFile);
        });
      }

      // // console.log("dataPictReady : ", dataPictReady);

      if (dataPictReady.length > 0) {
        sendSubmitListEvidences(dataPictReady);
      } else {
        setModalCreateMAResult(false);
      }
    }
  }, [isSuccesCreateMaResult]);

  const sendSubmitListEvidences = async (dataPictReady) => {
    if (maIsEvidenceRequired === 1) {
      const filterDataPict = await dataPictReady.filter(
        (item) => initialFileMAR[0].name !== item.name
      );

      if (filterDataPict.length > 0) {
        await Promise.all(filterDataPict.map((item) => sendEvidence(item)));

        // // console.log("status IER 1 : ", status);
      }
    }

    if (maIsEvidenceRequired === 0) {
      await Promise.all(dataPictReady.map((item) => sendEvidence(item)));

      // // console.log("status IER 0 : ", status);
    }
  };

  // SEND THE EVIDENCE
  const sendEvidence = (data) => {
    return new Promise(async (resolve, reject) => {
      context.setSuccessUploadFile(false);

      // resolve(`Upload evidence ${data}`);
      // // console.log("Data Resoleve : ", data);

      context.setLoaderEvidance(true);

      let newFormData = new FormData();

      newFormData.append(`MeasuredActivityResultEvidence[attachment]`, data);

      if (userTokenState !== "") {
        axiosConfig
          .post(
            `${URL_API}/swd/measured-activity-result/${maResultId}/evidence`,
            newFormData
          )
          .then(function (response) {
            setModalCreateMAResult(false); //*Next ini mesti refactor-man di jalanin relative tergantung pogress berhasil atau tidaknya File yang di upload ke server

            // // console.log("Response Original IMAGE/ VIDEO", response);
            // setFireGoalDetail(true);

            context.setLoaderEvidance(false);

            context.setSuccessUploadFile(true);

            context.setModalResponse200(true);
            context.setMessages("File bukti berhasil di tambahkan :)");
          })
          .catch(function (error) {
            // console.log("Error : ", error);

            if (error.response !== undefined) {
              if (error.response.status === 500) {
                context.setOpenSnackbeer(true);
                context.setMessages(
                  "Error 500, maaf sepertinya terjadi kesalahan di internal server kami !"
                );
              }

              if (error.response.status === 400) {
                context.setOpenSnackbeer(true);
                context.setMessages(
                  "Maaf sepertinya terjadi kesalahan saat upload file bukti. Silakan coba lagi !"
                );

                // // console.log("error.response : ", error.response.data);
                if (
                  error.response.data.info !== undefined &&
                  error.response.data.info !== null
                ) {
                  if (
                    error.response.data.info.errors !== undefined &&
                    error.response.data.info.errors !== null
                  ) {
                    if (error.response.data.info.errors.length > 0) {
                      context.setOpenSnackbeer(true);
                      context.setMessages(
                        `Attachment tidak support untuk file bmp`
                      );
                    }
                  }
                }
              }
            } else {
              context.setOpenSnackbeer(true);
              context.setMessages(
                "Maaf sepertinya terjadi kesalahan koneksi saat mencoba upload file bukti undefined. Silakan coba lagi !"
              );
            }

            setModalCreateMAResult(false);
            context.setLoaderEvidance(false);
          });
      }
    });
  };

  return (
    <Fragment>
      <Dialog
        open={isModalCreateMAResult}
        onClose={() => setModalCreateMAResult(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={
          context.loaderEvidance || loader === true ? true : false
        }
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography variant="h6" className={classes.title}>
            <b>
              Buat <i>MA</i> Result Baru
            </b>
          </Typography>
        </DialogTitle>

        <DialogContent style={{ textAlign: "left" }}>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="subtitle1" className={classes.title}>
              <b>Masukan data dengan nilai Angka</b>
            </Typography>
            <TextField
              inputRef={textInputReff}
              id="outlined-bare"
              className={classes.textField}
              onChange={handleChangeValueMAResult}
              // color='primary'
              // onKeyDown={handleEnterPress}
              value={maResult}
              variant="outlined"
              fullWidth
              type="number"
              inputProps={{
                className: classes.title,
              }}
            />
          </DialogContentText>

          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "grey" }}
          >
            <b>
              Tambahkan komentar/ keterangan status update <i>MA</i> Result Anda
            </b>
          </Typography>
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={handleChangeKomentar}
            // color='primary'
            // onKeyDown={handleEnterPress}
            value={komentar}
            variant="outlined"
            fullWidth
            inputProps={{
              className: classes.title,
            }}
          />
          <br />
          <br />

          <Grid container>
            <Grid item sm={12}>
              <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
                <ThemeProvider theme={theme}>
                  <Fragment>
                    <DateTimePicker
                      ampm={false}
                      value={selectedDueDate}
                      onChange={handleChangeDueDate}
                      label="Pilih tanggal & waktu"
                      disableToolbar={false}
                      format="DD MMMM YYYY HH:mm a"
                    />
                  </Fragment>
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12}>
              <UploaderInGoalResultNEW
                classes={classes} //*Next Refactorman "UploaderInGoalResultNEW" ganti nama menjadi "UploaderPreviewResult"
                goalResultDataDetail={goalResultDataDetail}
                loaderEvidance={context.loaderEvidance}
                getAcceptedFiles={getAcceptedFiles}
                disableAddResult={disableAddResult}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          {
            // komentar == '' ||
            maResult == "" ? (
              <Button
                // onClick={handleSUBMIT}
                disabled={disableAddResult}
                variant="contained"
                className={classes.buttonDisabled}
                fullWidth
                disableTouchRipple
              >
                Simpan
              </Button>
            ) : (
              <Button
                onClick={handleSUBMIT}
                variant="contained"
                className={classes.button}
                fullWidth
                disabled={disabledButton ? true : disableAddResult}
              >
                {loader === true ? (
                  <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                  `${
                    context.loaderEvidance === true
                      ? "Mengupload file bukti..."
                      : "Simpan !"
                  } `
                )}
              </Button>
            )
          }
        </DialogActions>
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        listError={listError}
        errorStatus={errorStatus}
      />
    </Fragment>
  );
};

export default DialogCreateAllMAResult;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Roboto",
    textTransform: "capitalize",
  },
  textfield: {
    width: 200,
  },
});

//   let fotoKaryawanFile =  ConvertDataUrlToFile(dataForSimpanNow.foto, dataForSimpanNow.foto_name);
//   const fileFoto = new Blob([fotoKaryawanFile]); //*Work
