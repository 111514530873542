import React, { Fragment, useContext } from "react";
import {
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import PictGAP from "../../../../assets/images/Group_2619.png";
import { VALUE_TYPE_PERCENTAGE } from "../../../../constants/config-value-type";
import ContextGoalDetail from "../Context/ContextGoalDetail";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import MyLang from "../../../../utilities/MyLang";
import { bahasa, english } from "../../../../constants/config-lang";

const IconGapValue = (props) => {
  const { classes, goalDetailState } = props;
  const context = useContext(ContextGoalDetail);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 16,
    },
  }))(Tooltip);

  return (
    <Fragment>
      <ListItemIcon style={{ marginLeft: 8 }}>
        <img src={PictGAP} style={{ width: 40, height: 40 }} alt="." />
      </ListItemIcon>

      <ListItemText
        className={classes.listItemtext}
        primary={
          <Typography
            variant="subtitle2"
            className={classes.title}
            style={{ color: "white" }}
          >
            {MyLang() ? bahasa.gap : english.gap}
          </Typography>
        }
        secondary={
          <LightTooltip
            placement="bottom-start"
            title={`Gap : ${FormatDecimal(
              goalDetailState.calculatedValue.resultOriented.gap_result_value
            )} (${FormatDecimal(
              goalDetailState.calculatedValue.resultOriented
                .gap_result_value_achievement * 100
            )} %)`}
          >
            {goalDetailState.valueType.code === VALUE_TYPE_PERCENTAGE ? (
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "white" }}
              >
                {goalDetailState.calculatedValue.resultOriented
                  .gap_result_value < 0 ||
                goalDetailState.calculatedValue.resultOriented
                  .gap_result_value_achievement === 0 ? (
                  <b>0</b>
                ) : (
                  <Fragment>
                    <b>
                      {FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .gap_result_value
                      )}
                      %
                    </b>
                    &nbsp;
                    <b>
                      (
                      {FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .gap_result_value_achievement * 100
                      )}
                      %)
                    </b>
                  </Fragment>
                )}
              </Typography>
            ) : (
              <Typography
                variant="subtitle2"
                className={classes.title}
                style={{ color: "white" }}
              >
                <b>
                  {context.modeRupiahOn === true
                    ? `Rp ${FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .gap_result_value
                      )}`
                    : FormatDecimal(
                        goalDetailState.calculatedValue.resultOriented
                          .gap_result_value
                      )}
                </b>
                &nbsp;
                <b>
                  (
                  {FormatDecimal(
                    goalDetailState.calculatedValue.resultOriented
                      .gap_result_value_achievement * 100
                  )}
                  %)
                </b>
              </Typography>
            )}
          </LightTooltip>
        }
      />
    </Fragment>
  );
};

export default IconGapValue;
