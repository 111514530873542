import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Container, Grid } from "@material-ui/core";

import styles from "./Style/landingStyle";

import HeaderNew from "./ComponentViewLandingPage/home/HeaderNew";
import NavRight from "./ComponentViewLandingPage/NaviRight";
import NavLeft from "./ComponentViewLandingPage/NavLeft";
import TouchUs from "./ComponentViewLandingPage/home/TouchUs";
import Footer from "./ComponentViewLandingPage/Footer";
import FloatingWhatsapp from "./ComponentViewLandingPage/components/FloatingWhatsapp";

import bgTouch from "../../assets/images/bgTouch.png";
import { navigate } from "hookrouter";

const dashboardRoutes = [];
const useStyles = makeStyles(styles);

const ViewContact = () => {
  const classes = useStyles();

  const handleTryFree = () => {
    navigate("/register");
    localStorage.setItem("trial", true);
  };

  return (
    <div>
      <HeaderNew
        color="transparent"
        routes={dashboardRoutes}
        brand="Performate"
        rightLinks={<NavRight origin="2" />}
        leftLinks={<NavLeft origin="2" />}
        fixed
        changeColorOnScroll={{
          height: 180,
          color: "white",
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box mt={20} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="h3"
            className={classes.title}
            style={{ color: "#333" }}
          >
            <b>Kontak kami</b>
          </Typography>
          <Typography
            variant="subtitle1"
            className={classes.heroDesc}
            style={{ color: "#333" }}
          >
            Hubungi kami untuk belajar lebih lanjut tentang Performate.id. Anda
            bisa mendiskusikan tentang studi kasus project anda, tantangan yang
            anda hadapi dan apa yang ingin anda tingkatkan dari performa
            organisasi anda.
          </Typography>

          <Box mt={4} className={classes.btnHero}>
            <Button
              variant="outlined"
              className={classes.btnSales2}
              onClick={() => navigate("/contact")}
            >
              Hubungi Sales Kami
            </Button>
            <Box mx={0.8} />
            <Button
              variant="outlined"
              className={classes.btnFree}
              onClick={handleTryFree}
            >
              Coba Gratis Sekarang
            </Button>
          </Box>
        </Box>
      </Box>

      <Box my={5} py={15}>
        <Container>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <Box p={5} borderRadius={10} bgcolor="#F2F4F7">
                <Typography varisnt="subtitle1" className={classes.title555}>
                  <b>Sales & Technical Support</b>
                </Typography>
                <Box mt={2} textAlign="left">
                  <Typography
                    varisnt="subtitle2"
                    className={classes.title333}
                    style={{ fontSize: 14, marginBottom: 5 }}
                  >
                    Email : cs@performate.id
                  </Typography>
                  <Typography
                    varisnt="subtitle2"
                    className={classes.title333}
                    style={{ fontSize: 14 }}
                  >
                    Nomor Telepon/WhatsApp : 0812-1203-0314
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box p={5} borderRadius={10} bgcolor="#F2F4F7">
                <Typography varisnt="subtitle1" className={classes.title555}>
                  <b>Alamat Kantor</b>
                </Typography>
                <Box mt={2} textAlign="left">
                  <Typography
                    varisnt="subtitle2"
                    className={classes.title333}
                    style={{ fontSize: 14, marginBottom: 5 }}
                  >
                    Jl. Pondok Kelapa Raya Blok C4/1B, Pondok Kelapa, Duren
                    Sawit Jakarta Timur
                  </Typography>
                  <Typography
                    varisnt="subtitle2"
                    className={classes.title333}
                    style={{ fontSize: 14 }}
                  >
                    Kode Pos : 13460, DKI Jakarta
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        style={{
          backgroundImage: "url(" + bgTouch + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Container>
          <TouchUs />
        </Container>
      </Box>

      <FloatingWhatsapp />

      <Footer />
    </div>
  );
};

export default ViewContact;
