import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  DialogActions,
  Button,
  LinearProgress,
  Box,
  CircularProgress,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import HelpTwoToneIcon from "@material-ui/icons/HelpTwoTone";

import pdf_icon from "../../../../assets/images/icons_file/PDF.png";
import excel_icon from "../../../../assets/images/icons_file/Excel.png";
import ppt_icon from "../../../../assets/images/icons_file/PowerPoint.png";
import word_icon from "../../../../assets/images/icons_file/Word.png";
import video_icon from "../../../../assets/images/icons_file/Video.png";
import text_icon from "../../../../assets/images/icons_file/Text.png";

import axios from "axios";
import axiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import DialogError from "../../../../components/DialogError";
import ContextGlobalDrive from "../../context/ContextDrive";

import { useDropzone } from "react-dropzone";
import { Gluejar } from "@charliewilco/gluejar";

import dragDropImg from "../../../../assets/images/dragToUpload.png";
import FormatBytes from "../../../../utilities/FormatBytes";
import PasteClipboard from "../../../../components/PasteClipboard";

function DialogUploadFile({
  openDialogUploadFile,
  setOpenDialogUploadFile,
  classes,
}) {
  const context = useContext(ContextGlobalDrive);
  const currentLoc = window.location.pathname;
  const maxSize = 31457280;
  // Cancellation
  // const cancelFileUpload = useRef(null);

  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getPathName, setGetPathName] = useState("");
  const [progress, setProgress] = useState(0);
  const [percentage, setPercentage] = useState("0");
  const [cancelUpload, setCancelUpload] = useState(null);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  useEffect(() => {
    if (openDialogUploadFile === true) {
      const pathArray = currentLoc.split("/");
      const getLastPathArr = pathArray[pathArray.length - 1];

      if (getLastPathArr !== undefined) {
        setGetPathName(getLastPathArr);
      } else {
        setGetPathName("");
      }

      // // console.log("Cek Parent", getPathName);
    }
  }, [openDialogUploadFile]);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      setFiles((prev) => {
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );

        return prev.concat(acceptedFiles);
      });

      // console.log("ACEP ACEP", acceptedFiles);

      fileRejections.forEach((file) => {
        // File is too large
        if (file.size > maxSize) {
          alert("Ukuran File tidak boleh melebihi 30 MB");
        }

        // Rejected File
        if (file) {
          // // console.log("file reject", file);
          alert(`File ${file.name}, tidak mendukung untuk di-upload!`);
        }
      });
    },
    maxSize: maxSize,
    accept:
      ".txt, .xlsx, .xls, .doc, .docx, .pdf, .ppt, .pptx, text/plain, text/csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,	application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpeg, image/png, image/gif, audio/mpeg, audio/wav, video/mp4, video/x-m4v, video/mpeg, video/ms-asf, video/x-ms-wmv, video/quicktime, video/x-msvideo, application/x-shockwave-flash, application/xml, text/xml",
  });

  // Handle Remove File From List
  const handleRemoveFile = (file) => {
    setFiles((prev) => prev.filter((item) => item.name !== file.name));
  };

  // sendUploadFile
  const handleUploadFile = () => {
    setProgress(0);
    setPercentage("0");

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setCancelUpload(source);

    return Promise.all(
      files.length > 0 &&
        files.map((file) => {
          setLoading(true);

          const dataFile = new FormData();

          const folderName = getPathName === "drive" ? "" : getPathName;

          dataFile.append("DriveFile[folder_id]", folderName);
          dataFile.append("DriveFile[name]", file.name);
          dataFile.append("DriveFile[content]", file);

          const config = {
            onUploadProgress: (eventProgress) => {
              const { loaded, total } = eventProgress;
              let percent = Math.round((loaded * 100) / total);

              // console.log(`${loaded}kb of ${total}kb | ${percent}%`);

              if (percent <= 100) {
                setPercentage(percent);
                setProgress((prev) => (prev = percent));
              }
            },
            cancelToken: source.token,
          };

          return axiosConfig.post(`${URL_API}/drive/file`, dataFile, config);
        })
    )
      .then((response) => {
        // console.log("Upload File Drive", response);

        context.setSuccessTriggerFile(Math.floor(Math.random() * 1000 + 1));
        context.setModalResponse200(true);
        context.setMessages("Upload Files successfully");

        context.setSelectedFolder([]); // Empty Selected Folder
        context.setSelectedFile([]); // Empty Selected File

        axiosConfig
          .get(
            `${URL_API}/drive/file?options[filter][folder_id]=${
              currentLoc === "/drive" ? "root" : getPathName
            }`
          )
          .then((response) => {
            // console.log("List Files", response);

            if (response.status === 200) {
              context.setListFiles(response.data.data);

              setOpenDialogUploadFile(false);
              setFiles((prev) => (prev = []));
              context.setModalResponse200(true);
              context.setMessages("Upload Files successfully");

              const dirName = response.data.data[0].directory_name.split("/");
              const brName = dirName.slice(1, -1);

              context.setFolderTitle(brName);
            }
            context.setLoadFiles(false);
            setLoading(false);
          })
          .catch((error) => {
            context.setLoadFiles(false);
            // console.log("Error", error);
          });
      })
      .catch((error) => {
        // console.log("Error : ", error.response);
        setLoading(false);
        setFiles((prev) => (prev = []));

        // error response
        if (error.response !== undefined) {
          let responseStatus = error.response.status;

          setErrorStatus(responseStatus);
          setOpenDialogError(true);

          if ([422, 400].includes(responseStatus)) {
            if (
              error.response.data.info.errors !== null &&
              error.response.data.info.errors !== undefined
            ) {
              if (error.response.data.info.errors.length > 0) {
                setOpenDialogError(true);
                setListError(error.response.data.info.errors);
              }
            }
          }

          if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
            if (error.response.data.hasOwnProperty("message")) {
              setTextErrorMessage(
                `Error ${responseStatus} : ` + error.response.data.message
              );
            }
          }
        } else {
          // setOpenDialogError(true);
          setTextErrorMessage("Whoops, something went wrong !");
        }
      });
  };

  const handleCancelUpload = () => {
    if (window.confirm("Cancel upload file(s) ?")) {
      cancelUpload.cancel();
    }
  };

  return (
    <Dialog
      open={openDialogUploadFile}
      onClose={() => setOpenDialogUploadFile(false)}
      maxWidth="xl"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <div className={classes.uploadContainer}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <img src={dragDropImg} width="75" height="75" />
            <p className={classes.title}>
              Drag 'n' drop some files here, or CTRL + V
            </p>
          </div>
        </div>

        {loading && (
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box>
              <Typography
                variant="body2"
                color="textSecondary"
              >{`${percentage}%`}</Typography>
            </Box>
            <Button
              size="small"
              className={classes.btnCancelUpload}
              onClick={handleCancelUpload}
            >
              Cancel
            </Button>
          </Box>
        )}

        <Typography className={classes.title} variant="subtitle1">
          <b>Files ({files.length})</b>
        </Typography>

        <List dense>
          {files.map((file) => {
            return (
              <ListItem key={file.name}>
                <ListItemAvatar>
                  {file.type.includes("image") ? (
                    <Avatar variant="square" src={file.preview} />
                  ) : file.type.includes("video") ? (
                    <Avatar variant="square" src={video_icon} />
                  ) : file.type.includes("pdf") ? (
                    <Avatar variant="square" src={pdf_icon} />
                  ) : file.type.includes("text") ? (
                    <Avatar variant="square" src={text_icon} />
                  ) : file.type === "application/msword" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <Avatar variant="square" src={word_icon} />
                  ) : file.type === "application/vnd.ms-excel" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
                    <Avatar variant="square" src={excel_icon} />
                  ) : file.type === "application/vnd.ms-powerpoint" ||
                    file.type ===
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                    <Avatar variant="square" src={ppt_icon} />
                  ) : (
                    file.type === "" && <HelpTwoToneIcon />
                  )}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography className={classes.title} variant="subtitle1">
                      {file.name}
                    </Typography>
                  }
                  secondary={
                    <Typography className={classes.title} variant="subtitle2">
                      {FormatBytes(Number(file.size))}
                    </Typography>
                  }
                />

                <ListItemSecondaryAction>
                  <IconButton
                    size="small"
                    disabled={loading}
                    onClick={() => handleRemoveFile(file)}
                  >
                    <HighlightOffRoundedIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
        <PasteClipboard onFiles={setFiles} />
      </DialogContent>

      <DialogActions
        style={{
          justifyContent: "center",
          marginTop: 5,
          marginBottom: 15,
        }}
      >
        {loading ? (
          <Button
            variant="contained"
            className={classes.button}
            style={{ width: 200 }}
          >
            <CircularProgress color="#fff" size={15} />
          </Button>
        ) : (
          <Button
            variant="contained"
            className={classes.button}
            disabled={files.length === 0}
            style={{ width: 200 }}
            onClick={handleUploadFile}
          >
            Upload
          </Button>
        )}
        {/* <Button
          variant="contained"
          className={classes.button}
          disabled={clipboards.length === 0}
          style={{ width: 200 }}
          onClick={handleUploadClipbord}>
          {loadClipboard ? (
            <CircularProgress color="#fff" size={15} />
          ) : (
            "Uploadx"
          )}
        </Button> */}
      </DialogActions>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Dialog>
  );
}

export default DialogUploadFile;
