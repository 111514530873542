import React, { useContext, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Typography,
} from "@material-ui/core";
import Paginating from "@material-ui/lab/Pagination";
import moment from "moment";

import ContextGlobalReports from "../../context/ContextReports";
import {
  GET_SYNC,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
} from "../../context/types";
import AxiosConfig from "../../../../constants/config-axios";
import { URL_API } from "../../../../constants/config-api";
import extractTable from "../global/extractTable";

const Pagination = ({ classes }) => {
  const { tableState, filterState, tableDispatch, reportDispatch } =
    useContext(ContextGlobalReports);

  const [page, setPage] = useState(1);

  // ~~~~~~~~~~~ Data Pagination From Context ~~~~~~~~~~~
  const paginating = tableState.pagination;
  const perPage = paginating.perPage;

  // ~~~~~~~~~~~ BODY For Data Table ~~~~~~~~~~~
  const dataPost = {
    name: filterState.dateRange.title,
    platform: "web",
    field_preset_id: tableState.fieldPresets.id,
    report_format: "table",
    filter_by: {
      entity: [
        { name: "goal", query: "asdfasdfds" },
        { name: "ma", query: "asdfasdfds" },
      ],
      date_range: filterState.dateRange.code,
      user: tableState.usersSelected,
      unit: tableState.unitsSelected,
      status: tableState.status,
      achievement: {
        value_type: tableState.valueType,
        cal_method: tableState.calMethod,
      },
    },
    filter_by_date_range_start: moment(filterState.dateRange.startDate).format(
      "YYYY-MM-DD"
    ),
    filter_by_date_range_end: moment(filterState.dateRange.endDate).format(
      "YYYY-MM-DD"
    ),
    entities: tableState.entity,
    group_by: {
      entity: ["goal"],
      date_time: [],
      user: false,
      unit: ["division", "branch"],
      orientation: [],
    },
    group_by_calculation: "1",
    order_by: tableState.selectedOrder,
    primary_column: "name",
    limit_type: "desc",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
  };

  const postWithCompare = {
    ...dataPost,
    comparisons: {
      date_range: tableState.comparison.code,
      fields: [
        "result_value",
        "overall_result_achievement",
        "expected_result_value",
      ],
    },
  };

  // ============================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Handle Pagination ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ============================================================================
  const handlePagination = (e, value) => {
    setPage(value);

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${value}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data;
        // // console.log("Data Load", result);

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  // ============================================================================
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Handle Select ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // ============================================================================
  const handleSelect = (e) => {
    // // console.log("Select", e.target.value);
    const valuePerPage = e.target.value;

    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${page}&per_page=${valuePerPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        // console.log("Data Load", result);

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        if (res.status === 200) {
          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography className={classes.title888} variant="caption">
        Records Per Page
      </Typography>
      <FormControl size="small" style={{ margin: "0 10px", minWidth: 50 }}>
        <NativeSelect
          defaultValue={10}
          onChange={handleSelect}
          inputProps={{
            name: "name",
            id: "uncontrolled-native",
          }}
        >
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
        </NativeSelect>
      </FormControl>

      <Paginating
        variant="text"
        shape="rounded"
        count={tableState.pagination && tableState.pagination.lastPage}
        page={tableState.pagination && tableState.pagination.currentPage}
        onChange={handlePagination}
      />
    </Box>
  );
};

export default Pagination;
