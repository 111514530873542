import React, { useState } from "react";

import ContextDrive from "./ContextDrive";

const ContextGlobalDrive = (props) => {
  // ============= Drive Root =============
  const [switchToListView, setSwitchToListView] = useState(false); // Change View Between List & Grid
  const [drivePath, setDrivePath] = useState("");
  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");
  const [toggleSelect, setToggleSelect] = useState(false);

  // ============= Folders =============
  const [listFolders, setListFolders] = useState([]);
  const [idFolder, setIdFolder] = useState("");
  const [loadFolders, setLoadFolders] = useState(false);
  const [successTriggerFolder, setSuccessTriggerFolder] = useState(null);
  const [folderTitle, setFolderTitle] = useState([]);
  const [folderId, setFolderId] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [detailFolder, setDetailFolder] = useState({});
  const [totalSize, setTotalSize] = useState(0);
  const [latestModifiedDate, setLatestModifiedDate] = useState("");
  const [listMemberUser, setListMemberUser] = useState([]);
  const [listMemberUnit, setListMemberUnit] = useState([]);
  // Folder Permission
  const [successTriggerPermission, setSuccessTriggerPermission] =
    useState(null);

  // ============= Files =============
  const [loadFiles, setLoadFiles] = useState(false);
  const [listFiles, setListFiles] = useState([]);
  const [successTriggerFile, setSuccessTriggerFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);

  // ============= Gallery GR =============
  const [listGalleryGR, setListGalleryGR] = useState([]);
  const [listGoalGallery, setListGoalGallery] = useState([]);
  const [loadGalleryGR, setLoadGalleryGR] = useState(false);
  const [listGalleryEvidence, setListGalleryEvidence] = useState([]);

  // ============= Gallery MAR =============
  const [listGalleryMAR, setListGalleryMAR] = useState([]);
  const [loadGalleryMAR, setLoadGalleryMAR] = useState(false);
  const [listGalleryEvidenceMAR, setListGalleryEvidenceMAR] = useState([]);

  // ============= Recycle Bin Folder =============
  const [loadRecycleBinFolder, setLoadRecycleBinFolder] = useState(false);
  const [listRecycleBinFolder, setListRecycleBinFolder] = useState([]);
  const [successTriggerFolderRB, setSuccessTriggerFolderRB] = useState(null);

  // ============= Recycle Bin File =============
  const [loadRecycleBinFile, setLoadRecycleBinFile] = useState(false);
  const [listRecycleBinFile, setListRecycleBinFile] = useState([]);
  const [successTriggerFileRB, setSuccessTriggerFileRB] = useState(null);

  // ============= Recycle Bin Folder =============
  const [loadRecoveredFolder, setLoadRecoveredFolder] = useState(false);
  const [listRecoveredFolder, setListRecoveredFolder] = useState([]);
  const [successTriggerRecoveredFolder, setSuccessTriggerRecoveredFolder] =
    useState(null);

  // ============= Recycle Bin File =============
  const [loadRecoveredFile, setLoadRecoveredFile] = useState(false);
  const [listRecoveredFile, setListRecoveredFile] = useState([]);
  const [successTriggerRecoveredFile, setSuccessTriggerRecoveredFile] =
    useState(null);

  // Restored Folders and Files
  const [folderRestored, setFolderRestored] = useState([]);
  const [fileRestored, setFileRestored] = useState([]);

  // ============= Status Goal Detail For Gallery =============
  const [statusActive, setStatusActive] = useState(true);
  const [statusOverdue, setStatusOverdue] = useState(true);
  const [statusCompleted, setStatusCompleted] = useState(false);
  const [statusArchived, setStatusArchived] = useState(false);

  return (
    <ContextDrive.Provider
      value={{
        switchToListView,
        setSwitchToListView,

        drivePath,
        setDrivePath,

        isModalResponse200,
        setModalResponse200,

        messages,
        setMessages,

        toggleSelect,
        setToggleSelect,

        listFolders,
        setListFolders,

        idFolder,
        setIdFolder,

        loadFolders,
        setLoadFolders,

        successTriggerFolder,
        setSuccessTriggerFolder,

        folderTitle,
        setFolderTitle,

        folderId,
        setFolderId,

        selectedFolder,
        setSelectedFolder,

        detailFolder,
        setDetailFolder,

        totalSize,
        setTotalSize,

        latestModifiedDate,
        setLatestModifiedDate,

        listMemberUser,
        setListMemberUser,

        listMemberUnit,
        setListMemberUnit,

        successTriggerPermission,
        setSuccessTriggerPermission,

        loadFiles,
        setLoadFiles,

        listFiles,
        setListFiles,

        successTriggerFile,
        setSuccessTriggerFile,

        selectedFile,
        setSelectedFile,

        listGalleryGR,
        setListGalleryGR,

        loadGalleryGR,
        setLoadGalleryGR,

        listGoalGallery,
        setListGoalGallery,

        listGalleryEvidence,
        setListGalleryEvidence,

        listGalleryMAR,
        setListGalleryMAR,

        loadGalleryMAR,
        setLoadGalleryMAR,

        listGalleryEvidenceMAR,
        setListGalleryEvidenceMAR,

        loadRecycleBinFolder,
        setLoadRecycleBinFolder,

        listRecycleBinFolder,
        setListRecycleBinFolder,

        successTriggerFolderRB,
        setSuccessTriggerFolderRB,

        loadRecycleBinFile,
        setLoadRecycleBinFile,

        listRecycleBinFile,
        setListRecycleBinFile,

        successTriggerFileRB,
        setSuccessTriggerFileRB,

        loadRecoveredFolder,
        setLoadRecoveredFolder,

        listRecoveredFolder,
        setListRecoveredFolder,

        successTriggerRecoveredFolder,
        setSuccessTriggerRecoveredFolder,

        loadRecoveredFile,
        setLoadRecoveredFile,

        listRecoveredFile,
        setListRecoveredFile,

        successTriggerRecoveredFile,
        setSuccessTriggerRecoveredFile,

        folderRestored,
        setFolderRestored,

        fileRestored,
        setFileRestored,

        statusActive,
        setStatusActive,

        statusOverdue,
        setStatusOverdue,

        statusCompleted,
        setStatusCompleted,

        statusArchived,
        setStatusArchived,
      }}>
      {props.children}
    </ContextDrive.Provider>
  );
};

export default ContextGlobalDrive;
