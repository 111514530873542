import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { FormatDecimal } from "../../../../../../utilities/FormatDecimal";
import TruncateTextCustom from "../../../../../../utilities/TruncateTextCustom";

const TableMA = ({ classes, mas, loading }) => {
  // console.log("MAS", mas);

  const SubRow = ({ detail }) => {
    const [openMA, setOpenMA] = React.useState(false);

    const handleToMaDetail = (data) => {
      const url = `/allma-detail/${data.entity_id}`;
      window.open(url, "_blank");
    };

    return (
      <>
        <TableRow>
          <TableCell className={classes.txtBody} align="left">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpenMA(!openMA)}
            >
              {openMA ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.txtBody} align="left">
            <b>{detail.goal?.name}</b>
          </TableCell>
          <TableCell className={classes.txtBody} align="left">
            <b>{FormatDecimal(detail.weighted_ora)}%</b>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openMA} timeout="auto" unmountOnExit>
              <Box m={1}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  <b>Details MA</b>
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell>MA Name</TableCell>
                      <TableCell>Achievement</TableCell>
                      <TableCell>Weight</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Weighted Achievement</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detail.details.map((ma, j) => {
                      const isNull = ma.weighted_ora === null;

                      return (
                        <TableRow key={j}>
                          <TableCell
                            className={classes.txtTitleGoal}
                            onClick={() => handleToMaDetail(ma)}
                          >
                            {TruncateTextCustom(ma.ma.name, 42)}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : `${FormatDecimal(ma.ora)}%`}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : `${FormatDecimal(ma.weight)}`}
                          </TableCell>
                          <TableCell>
                            {isNull ? "N/A" : `${ma.days} days`} days
                          </TableCell>
                          <TableCell>
                            {isNull
                              ? "N/A"
                              : `${FormatDecimal(ma.weighted_ora)}%`}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const Row = ({ row }) => {
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <TableRow>
          <TableCell className={classes.txtBody}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell className={classes.txtBody}>{row.full_name}</TableCell>
          <TableCell className={classes.txtBody}>
            {row.position_title}
          </TableCell>
          <TableCell className={classes.txtBody}>
            {FormatDecimal(row.weighted_ora)}%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box mt={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead className={classes.tableHeader}>
                    <TableCell
                      className={classes.txtHeader}
                      style={{ width: 50 }}
                    />
                    <TableCell className={classes.txtHeader}>
                      Nama Goal
                    </TableCell>
                    <TableCell className={classes.txtHeader}>
                      Weighted Achievement
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {row.details.map((detail, i) => (
                      <SubRow key={i} detail={detail} />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table size="small" aria-label="a dense table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.txtHeader} />
              <TableCell className={classes.txtHeader}>Full Name</TableCell>
              <TableCell className={classes.txtHeader}>Position</TableCell>
              <TableCell className={classes.txtHeader}>
                Weighted Achievement
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mas !== undefined &&
              mas.length > 0 &&
              mas.map((row, i) => <Row key={i} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>

      {/* LOADIER */}
      <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
        {loading && (
          <>
            <CircularProgress
              size={16}
              style={{ color: "#d64253", marginRight: 5 }}
            />
            <Typography variant="subtitle2" className={classes.title888}>
              Loading...
            </Typography>
          </>
        )}

        {mas !== undefined && mas.length === 0 && !loading && (
          <Typography variant="subtitle2" className={classes.title888}>
            Data not found
          </Typography>
        )}
      </Box>
    </>
  );
};

export default TableMA;
