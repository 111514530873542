import React from "react";
import { styles } from "../Style/StyleMyAttendance";

import {
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import moment from "moment";
import "moment-timezone";
import "moment/locale/id";
import Moment from "react-moment";

const useStyles = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#262261",
      light: "#6e6ecf",
      dark: "#0f0c29",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
});

const SuccessDialog = ({ handleClose, open, status, liveAttendanceValue }) => {
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {status}
        </DialogTitle>
        <DialogContent>
          <img
            src={liveAttendanceValue.check_image}
            alt="failed"
            width={150}
            style={{
              margin: "0 auto",
              display: "block",
              marginBottom: "1rem",
            }}
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "left",
                }}
              >
                User :{" "}
                <b>
                  {JSON.parse(localStorage.getItem("status_user_login"))
                    ?.member_first_name +
                    " " +
                    JSON.parse(localStorage.getItem("status_user_login"))
                      ?.member_last_name}
                </b>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "left",
                }}
              >
                Lokasi :{" "}
                <b>
                  {liveAttendanceValue.clock_in_location_status === 1
                    ? liveAttendanceValue.clock_in_location_status === "1"
                      ? liveAttendanceValue.location
                      : liveAttendanceValue.location
                    : liveAttendanceValue.location_address}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="body1"
                style={{
                  textAlign: "left",
                }}
              >
                Tanggal :{" "}
                <Moment
                  format="dddd, D MMMM YYYY"
                  locale="id"
                  interval={1}
                  unit="seconds"
                  style={{
                    fontWeight: "bold",
                  }}
                ></Moment>
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "left",
                }}
              >
                Pukul : <b>{liveAttendanceValue.time}</b>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default SuccessDialog;
