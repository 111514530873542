// DATA GET Form Membership !! Temporary !!
import React, { useEffect, useState } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  Grid,
  Box,
  Button,
  Breadcrumbs,
  Container,
  Card,
  CardMedia,
  Link,
  Divider,
  CardContent,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

import axios from "axios";
import { fromJS } from "immutable";
// import axiosConfig from "../../../constants/config-axios";
import Axios from "axios";

import DialogError from "../../../components/DialogError";

import DialogModalTransferOptionBerbayar from "./ComponentsMembershipStatus/DialogModalTransferOptionBerbayar";
import DialogHubungiAdministrator from "./ComponentsDialogModal/DialogHubungiAdministrator";
import DialogContactUs from "./ComponentsDialogModal/DialogContactUs";

import SnackbeerRED from "../../../components/SnackbeerRED";

import Redirect from "../../../utilities/Redirect";
import { ToCompletionProfile } from "../../../constants/config-redirect-url";

import { styles } from "./styles/StyleCompletionMembershipOptions";
import { URL_API } from "../../../constants/config-api";

import DialogPersonalPackage from "./ComponentsDialogModal/DialogPersonalPackage";
import DialogBussinessPackage from "./ComponentsDialogModal/DialogBussinessPackage";
import DialogFreeTrialOneMonth from "./ComponentsDialogModal/DialogFreeTrialOneMonth";
import DialogPayment from "./ComponentsDialogModal/DialogPayment";
import DialogNoUserToken from "./ComponentsDialogModal/DialogNoUserToken";

// Membership Constant
import {
  ACCOUNT_TYPE_BISNIS,
  ACCOUNT_TYPE_PERSONAL,
  PACKAGE_PERSONAL,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_PENDING,
} from "./constant-data";
import { navigate } from "hookrouter";

const useStyle = makeStyles(styles);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const ViewCompletionMembershipOptions = (props) => {
  //   const { classes } = props;
  const classes = useStyle();
  // const token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvYXBpLWRldi5wZXJmb3JtYXRlLmlkXC9hcGlcL3YxXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYxMDEwMDIzNiwiZXhwIjoxNjE2NjcwMjM2LCJuYmYiOjE2MTAxMDAyMzYsImp0aSI6IkFNemZLNUN5OVY3VEltb2siLCJzdWIiOiJlNDFmMmMxZS1iYWI3LTQ1NzYtOTcxNy0xZjU2M2ZjOTJjYWIiLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIiwiSzAiOiIxVmgxNVNqNiIsIkp0aTkxeGM5YzMiOiJrb2R4YWNvcDRlcGtja255IiwiS28xIjoiNzUiLCI1enhjYXNkbHAiOiJ2YTUiLCJ2ZXJzaW9uIjoiMS4xIn0.4WqrrlKvN-bepHf-F5Fg_00_ztcenHDYJaUFVXGJW_0";

  /*
        `````````````````````````````````````````````````````````````````````````
        COMPONENT DID MOUNT SPECIAL to delete data local storage "status_payment"

        ``````````````````````````````````````````````````````````````````````````
    */
  localStorage.removeItem("status_payment");

  // Just Temporary,
  // const membershipAccountTypes = data.data.membershipAccountTypeCollections;
  // const membershipPackages = data.data.membershipPackageCollections;
  // const membershipItemList = data.data.membershipItemCollections;
  // const membershipItemPrice = data.data.membershipItemPriceCollections;
  // const membershipPaymentMethods = data.data.membershipPaymentMethodCollections;

  /*
        ````````````````````
        COMPONENT DID MOUNT

        `````````````````````
    */
  const [isResponseError, setResponseError] = useState(false);
  const [infoResponse422, setiInfoResponse422] = useState("");
  const [infoResponse404, setInfoResponse404] = useState("");
  const [infoResponse400, setInfoResponse400] = useState("");

  const [userTokenState, setUserTokenState] = useState(undefined);

  // MEMBERSHIP PACKAGES STATE
  const [membershipAccountTypes, setMembershipAccountTypes] = useState([]);
  const [membershipPackages, setMembershipPackages] = useState([]);
  const [membershipItemList, setMembershipItemList] = useState([]);
  const [membershipItemPrice, setMembershipItemPrice] = useState([]);
  const [membershipPaymentMethods, setMembershipPaymentMethods] = useState([]);

  // Checkout & Payment State
  const [checkoutData, setCheckoutData] = useState({});
  const [openDialogPayment, setOpenDialogPayment] = useState(false);

  // MODAL
  const [isModalContactUs, setModalContactUs] = useState(false);
  const [openDialogPersonalPackage, setOpenDialogPersonalPackage] =
    useState(false);
  const [openDialogBussinessPackage, setOpenDialogBussinessPackage] =
    useState(false);
  const [openDialogNoUsertoken, setOpenDialogNoUserToken] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search.substring(1));
    const currentUrl = urlParams.get("redirect");
    // // console.log("currentUrl : ", currentUrl);
    // setUserTokenState(token);

    const user_token = localStorage.getItem("userToken");

    if (currentUrl === null) {
      // // console.log("cek token : ", user_token);
      setUserTokenState(user_token);
    }

    const data = {
      // redirect_url: 'http://115.124.65.74:4586/abangbisa-beta/api/v1/account-management/user/343362d7-cf4c-463e-a883-472cb9c1bbfe/verify-email/JQQKLzxb-277e4f4d9e0c6c51c61cb216235960eb-bkKDeuHQ'
      redirect_url: currentUrl,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      crossorigin: true,
      crossDomain: true,
    };

    axios.defaults.headers.common = headers;

    Axios.get(data.redirect_url)
      .then((response) => {
        // const immutableResponseOke = fromJS(response)
        // console.log("Original response : ", response);

        if (response.status === 200) {
          localStorage.setItem("userToken", response.data.data.access_token);
          setUserTokenState(response.data.data.access_token);
          localStorage.setItem(
            "status_user_login",
            JSON.stringify(response.data.data)
          );
        }
      })
      .catch((error) => {
        // console.log("Error response : ", error.response);
        const imutableError = fromJS(error.response);

        if (error.response !== undefined) {
          if (imutableError.getIn(["status"]) === 422) {
            // console.log(error.response.data.info.message);
            setResponseError(true);
            setiInfoResponse422(
              imutableError.getIn(["data", "info", "message"])
            );
          }

          if (imutableError.getIn(["status"]) === 404) {
            setResponseError(true);
            setInfoResponse404("Oops, Something went wrong !");
          }

          if (imutableError.getIn(["status"]) === 400) {
            setResponseError(true);
            setInfoResponse400("Oops, Something went wrong !");
          }
        } else {
          // setResponseError(true);
          // setInfoResponse400("Oops, Something went wrong !");
          // setOpenSnackbeer(true);
        }
      });
  }, []);

  const [nominalAfterDiscount, setNominalAfterDiscount] = useState(undefined);

  useEffect(() => {
    if (userTokenState !== undefined) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .get(`${URL_API}/account-management/membership:subscribe`)
        .then((response) => {
          const result = response.data.data;

          if (response.status === 200) {
            // console.log("GET Membership", response);

            setMembershipAccountTypes(result.membershipAccountTypeCollections);
            setMembershipPackages(result.membershipPackageCollections);
            setMembershipItemList(result.membershipItemCollections);
            setMembershipItemPrice(result.membershipItemPriceCollections);
            setMembershipPaymentMethods(
              result.membershipPaymentMethodCollections
            );
          }
        })
        .catch((error) => {
          // console.log("Error GET Mmbrshp", error));
        });

      axios
        .get(`${URL_API}/account-management/membership-payment/check-status`)
        .then((response) => {
          // console.log("Res Status Payment", response);
          const result2 = response.data.data;

          if (response.status === 200) {
            setCheckoutData(result2);

            if (result2.status_id === PAYMENT_STATUS_PENDING) {
              setOpenDialogPayment(true);
            }

            if (result2.status_id === PAYMENT_STATUS_PAID) {
              navigate(ToCompletionProfile);
            }
          }
        })
        .catch((error) => {
          // console.log("Error Stts Pymnt", error));
        });
    }
  }, [userTokenState, openDialogPayment]);

  // ==============================================
  // Filter Data For Free Trial One Month
  // ==============================================
  const freeTrialOneMonth = membershipPackages.filter(
    (pckg) => pckg.id === PACKAGE_PERSONAL
  );

  // ==============================================
  // Filter Data For Business Package
  // ==============================================
  const listBusinessPackage = membershipPackages.filter(
    (pckg) => pckg.accountType.id === ACCOUNT_TYPE_BISNIS
  );
  const listBusinessPrice = membershipItemPrice.filter(
    (pckg) => pckg.accountType.id === ACCOUNT_TYPE_BISNIS
  );

  // ==============================================
  // Filter Data For Personal Package
  // ==============================================
  const listPersonalPackage = membershipPackages.filter(
    (pckg) => pckg.accountType.id === ACCOUNT_TYPE_PERSONAL
  );
  const listPersonalPrice = membershipItemPrice.filter(
    (pckg) => pckg.accountType.id === ACCOUNT_TYPE_PERSONAL
  );

  // // console.log("tipe akun", membershipAccountTypes);
  // // console.log("paket akun", membershipPackages);

  /*     
        `````````````````````````
        MODAL TRIAL CONFIRMATION

        `````````````````````````
    */

  const [isModalTrialConfirmation, setModalTrialConfirmation] = useState(false);
  const handleModalTrialConfirmation = () => {
    if (userTokenState === undefined) {
      const tokenUser = localStorage.getItem("userToken");
      setUserTokenState(tokenUser);
      setModalTrialConfirmation(true);

      // // console.log("Yes, user token is undefined !");
    } else {
      setModalTrialConfirmation(true);
    }
  };

  /*

        `````````````````````````
        CONFRIM OKE TRIAL

        `````````````````````````
    */

  /*
        ```````````````````````````````
        HANDLE MODAL UPGRADE/BERBAYAR

        ``````````````````````````````
    */

  const [paramsData, setParamsData] = useState();

  const [isBerbayar, setBerbayar] = useState(false); // GANTI KE setBerbayar

  const [loaderDua, setLoaderDua] = useState(false);

  /*
        ```````````````````````````
        HANDLE LOADER WHEN BERBAYAR

        ```````````````````````````
    */
  const [loader, setLoader] = useState(false);

  /*
        ````````````````````
        HANDLE DIALOG ERROR

        ````````````````````
    */

  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  /*
        ```````````````
        HANDLE SNACKBAR

        ```````````````
    */
  const [isOpenSnackbeer, setOpenSnackbeer] = useState(false);

  return (
    <MuiThemeProvider theme={theme}>
      <br />
      <br />
      <Paper elevation={0}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={32}
        >
          <Breadcrumbs
            className={classes.breadcrumb}
            separator={<NavigateNextIcon fontSize="medium" />}
            aria-label="Breadcrumb"
          >
            <Typography color="primary" className={classes.title}>
              <b>Keanggotaan</b>
            </Typography>
            <Typography color="inherit" className={classes.title}>
              Profil
            </Typography>
            <Typography color="inherit" className={classes.title}>
              Struktur Perusahaan
            </Typography>
          </Breadcrumbs>
        </Grid>
      </Paper>

      <Paper className={classes.root} elevation={0}>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="h5" className={classes.title}>
              <b>Pilih jenis akun anda</b>
            </Typography>

            <Typography variant="subtitle1" className={classes.title}>
              Dapatkan layanan terlengkap dengan harga terbaik untuk anda dengan
              berlangganan Performate.id
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Container maxWidth="md">
        <Grid container>
          <Grid item md={6}>
            <Box margin={2} width="250">
              <Card
                variant="outlined"
                style={{ borderRadius: 20, backgroundColor: "#f7ffff" }}
              >
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  <CardMedia
                    src="https://picsum.photos/200/300/?blur=4"
                    component="img"
                    height="200"
                  />
                  <Typography
                    variant="h4"
                    className={classes.title}
                    style={{
                      left: 0,
                      position: "absolute",
                      top: 80,
                      width: "100%",
                    }}
                  >
                    <b>Personal</b>
                  </Typography>
                </Box>
                <CardContent>
                  <Box style={{ textAlign: "center" }} height={180}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ paddingTop: 15, paddingBottom: 25 }}
                    >
                      Pilihan terbaik untuk anda atau keluarga yang ingin
                      meningkatkan produktifitas
                    </Typography>
                    <Link
                      component="button"
                      variant="subtitle1"
                      className={classes.title}
                      onClick={handleModalTrialConfirmation}
                      style={{ paddingBottom: 15 }}
                    >
                      Coba gratis
                    </Link>
                    <Typography
                      variant="subtitle2"
                      style={{ color: "#888" }}
                      className={classes.title}
                    >
                      atau
                    </Typography>
                  </Box>

                  <Button
                    fullWidth
                    className={classes.buttonPackage}
                    onClick={() => setOpenDialogPersonalPackage(true)}
                  >
                    Berlangganan Paket Personal
                  </Button>

                  <Divider variant="fullWidth" style={{ margin: `15px 0` }} />

                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#333" }}
                  >
                    Dapatkan semua fitur termasuk :
                  </Typography>

                  <List dense={true}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon
                          fontSize="small"
                          style={{ color: "#d1354a" }}
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#888" }}
                          className={classes.title}
                        >
                          Gratis 1 Lisensi user selamanya
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>

          <Grid item md={6}>
            <Box margin={2} width="250">
              <Card variant="outlined" style={{ borderRadius: 20 }}>
                <Box
                  style={{
                    position: "relative",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  <CardMedia
                    src="https://picsum.photos/200/300/?grayscale"
                    component="img"
                    height="200"
                  />
                  <Typography
                    variant="h4"
                    className={classes.title}
                    style={{
                      left: 0,
                      position: "absolute",
                      top: 80,
                      width: "100%",
                    }}
                  >
                    <b>Bisnis</b>
                  </Typography>
                </Box>
                <CardContent>
                  <Box style={{ textAlign: "center" }} height={180}>
                    <Typography
                      variant="subtitle2"
                      className={classes.title}
                      style={{ padding: `15px 25px 0 25px` }}
                    >
                      Sesuai untuk perusahaan kelas menengah, UKM yang sedang
                      berkembang dan yang dalam proses meningkatkan omset
                    </Typography>
                  </Box>

                  <Button
                    fullWidth
                    className={classes.buttonPackage}
                    onClick={() => setOpenDialogBussinessPackage(true)}
                  >
                    Berlangganan Paket Bisnis
                  </Button>

                  <Divider variant="fullWidth" style={{ margin: `15px 0` }} />

                  <Typography
                    variant="subtitle2"
                    className={classes.title}
                    style={{ color: "#333" }}
                  >
                    Membantu bisnis anda lebih produktif dengan :
                  </Typography>

                  <List dense={true}>
                    {listPaketBisnis.map((item, i) => (
                      <ListItem key={i}>
                        <ListItemIcon>
                          <CheckIcon
                            fontSize="small"
                            style={{ color: "#d1354a" }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#888" }}
                            className={classes.title}
                          >
                            {item}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/*     
          ``````````````````````````````````
          DIALOG MODAL HUBUNGI ADMINISTRATOR

          ``````````````````````````````````
      */}

      <DialogHubungiAdministrator
        classes={classes}
        isResponseError={isResponseError}
        setResponseError={setResponseError}
        infoResponse400={infoResponse400}
        infoResponse422={infoResponse422}
        infoResponse404={infoResponse404}
      />

      {/* 
                ``````````
                SNACK BEER

                `````````            
            */}
      <SnackbeerRED
        classes={classes}
        isOpenSnackbeer={isOpenSnackbeer}
        setOpenSnackbeer={setOpenSnackbeer}
        messages={"Whoops, something went wrong !"}
      />

      {/*     
                `````````````````````````
                MODAL TRIAL CONFIRMATION

                `````````````````````````
            */}

      <DialogModalTransferOptionBerbayar
        classes={classes}
        isBerbayar={isBerbayar}
        setBerbayar={setBerbayar}
        userTokenState={userTokenState}
        nominalAfterDiscount={nominalAfterDiscount}
        setNominalAfterDiscount={setNominalAfterDiscount}
        paramsData={paramsData}
        setParamsData={setParamsData}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <DialogContactUs
        classes={classes}
        isModalContactUs={isModalContactUs}
        setModalContactUs={setModalContactUs}
      />

      <DialogFreeTrialOneMonth
        classes={classes}
        userToken={userTokenState}
        isModalTrialConfirmation={isModalTrialConfirmation}
        setModalTrialConfirmation={setModalTrialConfirmation}
        freeTrialOneMonth={freeTrialOneMonth}
      />

      <DialogPersonalPackage
        classes={classes}
        openDialogPersonalPackage={openDialogPersonalPackage}
        setOpenDialogPersonalPackage={setOpenDialogPersonalPackage}
        userToken={userTokenState}
        listPersonalPackage={listPersonalPackage}
        listPersonalPrice={listPersonalPrice}
        listPaymentMethods={membershipPaymentMethods}
      />

      <DialogBussinessPackage
        classes={classes}
        openDialogBussinessPackage={openDialogBussinessPackage}
        setOpenDialogBussinessPackage={setOpenDialogBussinessPackage}
        userToken={userTokenState}
        listBusinessPackage={listBusinessPackage}
        listBusinessPrice={listBusinessPrice}
        listPaymentMethods={membershipPaymentMethods}
      />

      <DialogPayment
        openDialogPayment={openDialogPayment}
        setOpenDialogPayment={setOpenDialogPayment}
        // setOpenDialogCheckoutReg={setOpenDialogCheckoutReg}
        // setOpenDialogPackage={setOpenDialogPackage}
        classes={classes}
        checkoutData={checkoutData}
      />

      <DialogNoUserToken
        openDialogNoUsertoken={openDialogNoUsertoken}
        setOpenDialogNoUserToken={setOpenDialogNoUserToken}
        classes={classes}
      />

      {/* 
            
                NEXT, BIKIN DIALOG MODAL RESPONSE 422 - "Anda sudah memakai paket ini !"

            */}
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ViewCompletionMembershipOptions);

const listPaketBisnis = [
  "Deployment (Workshop, Training, & KPI Setting)",
  "Consultant Visit",
  "Lisensi User",
];
