/*
    ``````````````````````````````````````
    NOW, 05 Desember 2019, 13.30. 
    
    Term "Employee" di ubah menjadi "User"
    
    ``````````````````````````````````````
*/
import React, { useEffect, useState, useRef, useContext } from "react";
import {
  createMuiTheme,
  withStyles,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import axios from "axios";

import ContextNewSO from "../Context/ContextNewSO";

import Capitalize from "../../../utilities/Capitalize";
import DialogError from "../../../components/DialogError";
import Snackbeer from "../../../components/Snackbeer";

import { styles } from "../Style/StyleDialogDetail";

import { URL_API } from "../../../constants/config-api";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const DialogCreateEmployeeInASSISTANT = (props) => {
  const { classes } = props;

  const context = useContext(ContextNewSO);

  let textInputReff = useRef(null);

  const [loader, setLoader] = useState(false);
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformationValue] = useState("");
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);

  const [roleId, setRoleId] = useState("");

  useEffect(() => {
    if (context.isOpenDialogCreateEmployeeInEditASSISTANT == true) {
      context.setFirstName("");
      context.setLastName("");

      //*Focus in Text Field
      setTimeout(() => {
        textInputReff.current.focus();
      }, 100);

      /*
                `````````
                GET LABEL

                `````````
            */
      if (context.userTokenState !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + context.userTokenState,
        };

        axios.defaults.headers.common = header;

        axios
          .get(URL_API + `/account-management/user/quick/create`)
          .then((response) => {
            // console.log("Response Original : ", response);

            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.roleCollections !== null) {
                if (response.data.data.roleCollections.length > 0) {
                  setRoleId(response.data.data.roleCollections[0].id);
                }
              }
            }
          })
          .catch((error) => {
            // console.log("Error : ", error.response);

            // error response
            if (error.response !== undefined) {
              let responseStatus = error.response.status;

              setErrorStatus(responseStatus);
              setOpenDialogError(true);

              if ([422, 400].includes(responseStatus)) {
                if (
                  error.response.data.info.errors !== null &&
                  error.response.data.info.errors !== undefined
                ) {
                  if (error.response.data.info.errors.length > 0) {
                    setOpenDialogError(true);
                    setListError(error.response.data.info.errors);
                  }
                }
              }

              if (
                [503, 500, 403, 429, 405, 404, 401].includes(responseStatus)
              ) {
                if (error.response.data.hasOwnProperty("message")) {
                  setTextErrorInformationValue(
                    `Error ${responseStatus} : ` + error.response.data.message
                  );
                }
              }
            }
          });
      }
    }
  }, [context.isOpenDialogCreateEmployeeInEditASSISTANT]);

  const handleBatal = () => {
    context.setOpenDialogEditNodeSOInASSISTANT(true);
    context.setOpenDialogCreateEmployeeInEditASSISTANT(false);
  };

  // const [ firstName, setFirstName ] = useState(''); //*Moved to Global State
  // const [ lastName, setLastName ] = useState('');
  // const [ email, setEmail ] = useState('');

  const handleSubmit = () => {
    setLoader(true);

    //

    let data = {
      User: {
        first_name: context.firstName,
        last_name: context.lastName !== "" ? context.lastName : "-",
        email: context.email,
        role_id: [roleId],
      },
    };

    // console.log("Data SUBMIT : ", data);

    if (context.userTokenState !== "") {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "bearer " + context.userTokenState,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + "/account-management/user/quick", data)
        .then(function (response) {
          setLoader(false);
          // console.log("Response Original : ", response);

          if (response.status == 200) {
            // window.open(response.data.data, "_blank").focus();

            context.setOpenDialogCreateEmployeeInEditASSISTANT(false);
            context.setOpenDialogEditNodeSOInASSISTANT(true);

            context.setSuccessCreateEmployee(true);

            setModalResponse200(true);
            setMessages("Berhasil membuat user baru :)");
          }
        })
        .catch(function (error) {
          setLoader(false);

          // error response
          if (error.response !== undefined) {
            let responseStatus = error.response.status;

            setErrorStatus(responseStatus);
            setOpenDialogError(true);

            if ([422, 400].includes(responseStatus)) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setOpenDialogError(true);
                  setListError(error.response.data.info.errors);
                }
              }
            }

            if ([503, 500, 403, 429, 405, 404, 401].includes(responseStatus)) {
              if (error.response.data.hasOwnProperty("message")) {
                setTextErrorInformationValue(
                  `Error ${responseStatus} : ` + error.response.data.message
                );
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformationValue("Whoops, something went wrong !");
          }

          // console.log("Error : ", error.response);
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */

  const [isModalResponse200, setModalResponse200] = useState(false);
  const [messages, setMessages] = useState("");

  return (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={context.isOpenDialogCreateEmployeeInEditASSISTANT}
        // onClose={() => setOpenDialogCreateEmployeeInEditASSISTANT(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography
            variant="subtitle1"
            className={classes.title}
            style={{ color: "black" }}
          >
            <b>
              Undang <i>User</i> baru
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputRef={textInputReff}
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setFirstName(e.target.value)}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="First name"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setLastName(e.target.value)}
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="Last name"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />

          <br />
          <br />
          <TextField
            id="outlined-bare"
            className={classes.textField}
            onChange={(e) => context.setEmail(e.target.value)}
            // onChange= {handleChangeBIGGoal}
            // color='primary'
            // onKeyDown={handleEnterPress}
            // value= {bigGoal}
            placeholder="Email"
            // value = {goalDetailState.name}
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions
          style={{ alignItems: "right", justifyContent: "flex-end" }}
        >
          <Box marginRight={theme.spacing(0.5)}>
            <Button
              // onClick={() => setOpenDialogCreateEmployeeInEditASSISTANT(false)}
              onClick={handleBatal}
              variant="outlined"
              size="small"
              className={classes.buttonOutlined}
            >
              Batal
            </Button>

            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
            >
              {loader !== true ? (
                " Undang "
              ) : (
                <CircularProgress size={20} style={{ color: "white" }} />
              )}
            </Button>
          </Box>
        </DialogActions>
        <br />
        <br />
      </Dialog>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages={messages}
      />
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(DialogCreateEmployeeInASSISTANT);
