import React from "react";
import { LinearProgress, Grid } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { easeQuadInOut } from "d3-ease";
import numeral from "numeral";
import { FormatDecimal } from "../../../../utilities/FormatDecimal";
import AnimatedProgressProvider from "../../../../components/AnimatedProgressProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  progressLabel: {
    position: "absolute",
    // width: "100%",
    height: "100%",
    zIndex: 1,
    maxHeight: 15, // borderlinearprogress root.height
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 40,
    "& span": {
      width: "100%",
      fontSize: 11,
      color: "#4d4d4d",
    },
  },
}));

const ResultBar = ({ item }) => {
  const classes = useStyles();
  const targetVal = Number(item.target);
  const actualVal = item.actual === null ? 0 : Number(item.actual);
  const percentage = (actualVal / targetVal) * 100;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 15,
      borderRadius: 10,
      content: "aw",
      color: "#333",
      width: "100%",
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 10,
      backgroundColor: "#74CB80",
    },
  }))(LinearProgress);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} justify="space-between">
        <Grid item xs={12} spacing={0}>
          <div className={classes.progressLabel}>
            <span>
              {isNaN(percentage)
                ? "0,00"
                : percentage > 100
                ? 100
                : FormatDecimal(percentage)}
              %
            </span>
          </div>
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={percentage}
            duration={3}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const newVal = numeral(value).format("00");

              return (
                <BorderLinearProgress
                  variant="determinate"
                  value={newVal > 100 ? 100 : newVal}
                />
              );
            }}
          </AnimatedProgressProvider>
        </Grid>
      </Grid>
    </div>
  );
};

export default ResultBar;
