import React, { useContext, useLayoutEffect, useRef } from "react";
import { Box, Typography, CircularProgress } from "@material-ui/core";

// Chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

// set chart animated
am4core.useTheme(am4themes_animated);

const PieChartDashboard = ({ classes, dataChart, loading }) => {
  const chartRef = useRef(null);

  // Process Color
  const pieData = dataChart.map((el) => {
    let color = "";
    if (el.status === "reached") color = am4core.color("#34cc80");
    if (el.status === "attention") color = am4core.color("#fab445");
    if (el.status === "not reached") color = am4core.color("#e15367");

    return { ...el, color };
  });
  useLayoutEffect(() => {
    let chart2 = am4core.create("chartPieDashboard", am4charts.PieChart);

    chart2.data = pieData;

    // Add and configure Series
    var pieSeries = chart2.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "ora_status";

    // Disable ticks and labels
    // pieSeries.ticks.template.disabled = true;
    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
    pieSeries.labels.template.radius = am4core.percent(-40);
    pieSeries.labels.template.fill = am4core.color("white");

    pieSeries.labels.template.adapter.add("radius", function (radius, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return 0;
      }
      return radius;
    });

    pieSeries.labels.template.adapter.add("fill", function (color, target) {
      if (target.dataItem && target.dataItem.values.value.percent < 10) {
        return am4core.color("#000");
      }
      return color;
    });

    //custom color
    pieSeries.slices.template.propertyFields.fill = "color";

    chartRef.current = chart2;

    return () => {
      chart2.dispose();
    };
  }, [dataChart, loading]);

  return (
    <Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress
            size={16}
            style={{ color: "#d64253", marginRight: 5 }}
          />
          <Typography className={classes.title888} variant="subtitle1">
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          <div
            id="chartPieDashboard"
            style={{ width: "100%", height: "300px" }}
          ></div>
          <Box display="flex" flexWrap="wrap" mt={2} justifyContent="center">
            {achieveStats.map((el, i) => (
              <Box display="flex" alignItems="center" key={i}>
                <div
                  style={{
                    backgroundColor: el.color,
                    height: 20,
                    width: 20,
                    borderRadius: 3,
                    marginRight: 5,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  className={classes.title555}
                  style={{ marginRight: 10 }}
                >
                  {el.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default PieChartDashboard;

const achieved = MyLang() ? bahasa.achieved : english.achieved;
const warning = MyLang() ? bahasa.warning : english.warning;
const notAchieved = MyLang() ? bahasa.notAchieved : english.notAchieved;

const achieveStats = [
  { color: "#34cc80", label: achieved },
  { color: "#fab445", label: warning },
  { color: "#e15367", label: notAchieved },
];
