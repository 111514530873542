import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Badge,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import StarRoundedIcon from "@material-ui/icons/StarRounded";

import ContextReports from "../../../../../context/ContextReports";
import {
  HIT_VALUE_TYPE,
  HIT_CAL_METHOD,
  HIT_DATA_PRESET,
  SET_PAGINATION,
  HIT_TOTAL_RECORDS,
  GET_SYNC,
} from "../../../../../context/types";
import extractTable from "../../../../global/extractTable";
import AxiosConfig from "../../../../../../../constants/config-axios";
import { URL_API } from "../../../../../../../constants/config-api";
import { value_type, cal_method } from "../../../../global/dataFilterReport";

import ListItemValueType from "./ListItemValueType";
import ListItemCalcMethod from "./ListItemCalcMethod";
import DialogError from "../../../../../../../components/DialogError";
import handleError from "../../../../global/handleError";

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#d1354a",
    color: "#fff",
  },
}))(Badge);

const AchievementFilter = ({ classes }) => {
  const { reportDispatch, filterState, tableState, tableDispatch } =
    useContext(ContextReports);

  const [anchorAchieve, setAnchorAchieve] = useState(null);
  const [valType, setValType] = useState({
    code: tableState.valueType.code,
    fields: tableState.valueType.fields,
  });
  const [selected, setSelected] = useState([]);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Show The Menu Achievement
  const handleShowAnchorAchieve = (event) => {
    setAnchorAchieve(event.currentTarget);
  };

  // Close The Menu Achievement
  const handleCloseAnchorAchieve = () => {
    setAnchorAchieve(null);
    setSelected([]);
  };

  // Handle TO Change Value Type
  const handleChangeValType = (data) => {
    // console.log("VAL TYPE", data);
    const { code, fields } = data;

    setValType({ code, fields });

    tableDispatch({
      type: HIT_VALUE_TYPE,
      payload: { valueType: { code, fields } },
    });
  };

  // Clear All Achievement Selection
  const handleNone = () => {
    setSelected([]);
  };
  // // console.log("ACHIEVEM", wrapSelected);

  const handleShowResult = () => {
    setAnchorAchieve(null);
    tableDispatch({
      type: HIT_DATA_PRESET,
      payload: { dataPresets: [], load: true },
    });

    const wrapSelected =
      selected.length > 0 &&
      selected.map((item) => {
        return {
          formula: item,
        };
      });

    tableDispatch({
      type: HIT_CAL_METHOD,
      payload: { selected: wrapSelected },
    });

    const dataPost = {
      name: filterState.dateRange.title,
      platform: "web",
      field_preset_id: tableState.fieldPresets.id,
      report_format: "table",
      filter_by: {
        entity: [
          { name: "goal", query: "asdfasdfds" },
          { name: "ma", query: "asdfasdfds" },
        ],
        date_range: filterState.dateRange.code,
        user: tableState.usersSelected,
        unit: tableState.unitsSelected,
        status: tableState.status,
        achievement: {
          value_type: valType,
          cal_method: wrapSelected,
        },
      },
      entities: tableState.entity,
      group_by: {
        entity: ["goal"],
        date_time: [],
        user: false,
        unit: ["division", "branch"],
        orientation: [],
      },
      group_by_calculation: "1",
      order_by: tableState.selectedOrder,
      primary_column: "name",
      limit_type: "desc",
      limit_count_number: 0,
      week_start: "monday",
      date_format: "d m Y",
      summary_only: false,
    };

    const postWithCompare = {
      ...dataPost,
      comparisons: {
        date_range: tableState.comparison.code,
        fields: [
          "result_value",
          "overall_result_achievement",
          "expected_result_value",
        ],
      },
    };

    const { currentPage, perPage } = tableState.pagination;

    // ================= GET INITIAL DATA TABLE =================
    AxiosConfig.post(
      `${URL_API}/reporting/widget/detail?page=${currentPage}&per_page=${perPage}`,
      tableState.comparison.code === "" ? dataPost : postWithCompare
    )
      .then((res) => {
        const result = res.data.data;
        const lastSync = res.data.info.data;
        // // console.log("Achievement Load", result);

        const DATATABLE = extractTable(result.results.data);
        const pagination = {
          currentPage: result.results.current_page,
          perPage: result.results.per_page,
          lastPage: result.results.last_page,
        };

        // // console.log("DATATABLE", DATATABLE);

        if (res.status === 200) {
          reportDispatch({
            type: GET_SYNC,
            payload: { lastSync },
          });

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: DATATABLE, load: false },
          });

          tableDispatch({
            type: SET_PAGINATION,
            payload: { pagination },
          });

          tableDispatch({
            type: HIT_TOTAL_RECORDS,
            payload: { totalRecords: result.results.total },
          });
        }
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);

        tableDispatch({
          type: HIT_DATA_PRESET,
          payload: { dataPresets: [], load: false },
        });
      });
  };

  return (
    <>
      <StyledBadge
        badgeContent={selected.length}
        children={
          <Button
            variant="outlined"
            size="small"
            startIcon={<StarRoundedIcon style={{ fontSize: 15 }} />}
            className={classes.actionBtn}
            disabled={tableState.loadTable || filterState.loadFilter}
            onClick={handleShowAnchorAchieve}
          >
            Achievements
          </Button>
        }
      />

      <Menu
        id="simple-menu"
        anchorEl={anchorAchieve}
        keepMounted
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        getContentAnchorEl={null}
        open={Boolean(anchorAchieve)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={200}
          marginBottom={1.5}
          marginTop={1.5}
          marginLeft={2}
          marginRight={2}
        >
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Value Type</b>
          </Typography>
          <Typography className={classes.btnTxt} onClick={handleNone}>
            None
          </Typography>
        </Box>

        {value_type.length > 0 &&
          value_type.map((item, i) => (
            <MenuItem key={i} style={{ padding: 0, paddingRight: 20 }} dense>
              <ListItemValueType
                classes={classes}
                item={item}
                valtype={valType}
                onChangeValType={handleChangeValType}
              />
            </MenuItem>
          ))}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box marginBottom={1.5} marginTop={1.5} marginLeft={2} marginRight={2}>
          <Typography variant="subtitle2" className={classes.title333}>
            <b>Range Value</b>
          </Typography>
        </Box>
        {cal_method.length > 0 &&
          cal_method.map((item, i) => (
            <MenuItem key={i} style={{ padding: 0, paddingRight: 20 }} dense>
              <ListItemCalcMethod
                classes={classes}
                item={item}
                index={i}
                selected={selected}
                setSelected={setSelected}
                isSelected={isSelected}
              />
            </MenuItem>
          ))}

        <Box margin={1.5}>
          <Divider />
        </Box>

        <Box
          marginRight={2}
          marginBottom={1}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            size="small"
            variant="outlined"
            className={classes.button0}
            onClick={handleCloseAnchorAchieve}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            className={classes.button1}
            style={{ marginLeft: 7.5 }}
            disabled={selected.length === 0}
            onClick={handleShowResult}
          >
            Show results
          </Button>
        </Box>
      </Menu>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </>
  );
};

export default AchievementFilter;
