import React, { useEffect, useState, useContext, Fragment } from "react";
import { IconButton, Fade, Checkbox } from "@material-ui/core";

import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer,
} from "recharts";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Switch,
  FormControlLabel,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import TimerRoundedIcon from "@material-ui/icons/TimerRounded";

import "moment/locale/id";
import axios from "axios";
import moment from "moment";
import clsx from "clsx";
import { URL_API } from "../../../../constants/config-api";
import ContextAllMA from "../context/AllMaContext";

import { FormatDecimal } from "../../../../utilities/FormatDecimal";

const PurpleSwitch = withStyles({
  switchBase: {
    color: "#DA5D6E",
    "&$checked": {
      color: "#d1354a",
    },
    "&$checked + $track": {
      backgroundColor: "#d1354a",
    },
  },
  checked: {},
  track: {},
})(Switch);

const DotLineChartMeasureActivityInDetail = (props) => {
  const {
    classes,
    isModalCreateMAResult,
    isModalEdit,
    maDetailState,
    triggerMaAll,
  } = props;

  const context = useContext(ContextAllMA);
  const locationPathMalId = window.location.pathname.split("/")[2];

  const userToken = localStorage.getItem("userToken");

  const [dataGoalResult, setDataGoalResult] = useState([]);
  const [interval, setIntervals] = useState("");
  const [anchorChart, setAnchorChart] = useState(null);
  const [showDate, setShowDate] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeHours, setActiveHours] = useState(false);

  const [runTimer, setRunTimer] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [gapMinute, setGapMinute] = useState(0);
  const [nextUpdateHourly, setNextUpdateHourly] = useState(null);
  const [nextUpdateDaily, setNextUpdateDaily] = useState(null);
  const [nextUpdateWeekly, setNextUpdateWeekly] = useState(null);
  const [nextUpdateMonthly, setNextUpdateMonthly] = useState(null);

  const [collectionGoalResultList, setCollectionGoalResultList] = useState([]);

  // // console.log("MA DET Check", maDetailState);

  useEffect(() => {
    if (isModalCreateMAResult === false || isModalEdit === false) {
      if (userToken !== undefined) {
        const header = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "bearer " + userToken,
        };

        setLoading(true);

        axios.defaults.headers.common = header;

        //* MA SELF - ONLY OWNER MA USER LOGIN
        axios
          .get(
            `${URL_API}/swd/measured-activity/${locationPathMalId}/result-value-graph?option=discrete&interval=${interval}&interval_filter=all_time&break_time=${activeHours}`
          )
          .then(function (resGraph) {
            // // console.log("ResGraph Original Line Chart: ", resGraph);

            if (resGraph.status === 200) {
              if (resGraph.data.data.dataPoints.length > 0) {
                let dataPoints = resGraph.data.data.dataPoints.map((item) => {
                  return {
                    x: item.x,
                    y: item.y,
                    tv: item.tv,
                    tooltip: item.tooltip,
                  };
                });
                setCollectionGoalResultList(dataPoints);
              }
            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            // console.log("Error : ", error.response);
          });
      } else {
        // console.log("No Access Token available!");
      }
    }
  }, [
    isModalCreateMAResult,
    isModalEdit,
    context.isSuccessCreateMAR,
    interval,
    activeHours,
    triggerMaAll,
  ]);

  useEffect(() => {
    let dataGoalResultLet = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        let items = {
          // name: moment(item.result_date).format('DD MMMM YYYY'),
          // Pencapaian: parseInt(numeral(item.result_value).format('0[.]00000'))
          name: item.x,
          Pencapaian: item.y,
          Target: item.tv,
          tooltip: item.tooltip,
        };

        dataGoalResultLet.push(items);
      });

      setDataGoalResult(dataGoalResultLet);
    }
  }, [collectionGoalResultList]);

  useEffect(() => {
    if (
      maDetailState?.update_frequency !== null &&
      maDetailState?.update_frequency !== undefined
    ) {
      setIntervals(maDetailState?.update_frequency?.interval);
    }
  }, [maDetailState]);

  const [max, setMax] = useState(0);
  const [min, setMin] = useState(0);

  useEffect(() => {
    let list = [];

    if (collectionGoalResultList.length > 0) {
      collectionGoalResultList.map((item, i) => {
        list.push(parseInt(item.y));
      });

      // // console.log("List in", list)

      setMax(Math.max(...list));
      setMin(Math.min(...list));
    }
  }, [collectionGoalResultList]);

  const handleCloseAnchorChart = () => {
    setAnchorChart(null);
  };

  const handleShowAnchoeChart = (event) => {
    setAnchorChart(event.currentTarget);
  };

  const handleShowDate = () => {
    setShowDate((prev) => !prev);
  };

  const handleActiveHours = (e) => {
    setActiveHours(e.target.checked);
  };

  // ===================================================================
  // ~~~~~~~~~~~~~~~~ Handle Next Update ~~~~~~~~~~~~~~~~
  // ===================================================================
  let DAY_FOR_WEEKLY;

  const scheduled_at = maDetailState?.update_frequency?.scheduled_at;
  const dayWeekly = maDetailState?.update_frequency?.scheduled_at.split("-")[0];
  const hourWeekly =
    maDetailState?.update_frequency?.scheduled_at.split("-")[1];
  const date = new Date();
  const getHourInterval = moment.duration(scheduled_at, "m");

  const hour = moment(date).format(
    `YYYY-MM-DD HH:${getHourInterval.minutes()}`
  ); // Target Hour
  const daily = moment(date).format(`YYYY-MM-DD ${scheduled_at}`);
  const weekly = moment(date).format(`YYYY-MM-DD ${hourWeekly}`);

  // Convert to IDN
  if (dayWeekly === "monday") DAY_FOR_WEEKLY = "Senin";
  if (dayWeekly === "tuesday") DAY_FOR_WEEKLY = "Selasa";
  if (dayWeekly === "wednesday") DAY_FOR_WEEKLY = "Rabu";
  if (dayWeekly === "thursday") DAY_FOR_WEEKLY = "Kamis";
  if (dayWeekly === "friday") DAY_FOR_WEEKLY = "Jumat";
  if (dayWeekly === "saturday") DAY_FOR_WEEKLY = "Sabtu";
  if (dayWeekly === "sunday") DAY_FOR_WEEKLY = "Minggu";

  const weeklyNow = moment().isoWeekday(DAY_FOR_WEEKLY);
  const isDayInWeekly = moment(weeklyNow).isSame(new Date(), "days");

  // ================================== | NEW Config next Update | ==================================
  const next_update = maDetailState?.update_frequency_next_period;
  const _gr = next_update?.gr;
  const _max = next_update?.max;
  const _min = next_update?.min;

  // ================================== | For Countdown | ==================================

  useEffect(() => {
    let secTimer = setInterval(() => {
      if (scheduled_at !== undefined) {
        var date = moment().format("YYYY-MM-DD HH:mm");
        let compare;

        if (interval === "hourly") {
          // if (moment(_max).diff(moment(date), "m") < 1) {
          const addHour = moment(_max).add(1, "hours");
          compare = moment(addHour).diff(moment(date), "m");

          setNextUpdateHourly(moment(_max).format("HH:mm")); // next
          // } else {
          //   compare = moment(hour).diff(moment(date), "m");
          //   setNextUpdateHourly(hourInterval); // now
          // }
        }

        if (interval === "daily") {
          compare = moment(_max).diff(moment(date), "m");

          // set next update
          // if (moment(daily).diff(moment(date), "m") < 1) {
          setNextUpdateDaily(moment(_max).format("dddd HH:mm")); // next
          // setNextUpdateDaily(getDayInterval); // next
          // } else {
          //   setNextUpdateDaily(dayInerval); // now
          // }
        }

        if (interval === "weekly") {
          compare = moment(_max).diff(moment(date), "m");

          // set next update
          // if (moment(weekly).diff(moment(date), "m") < 1) {
          setNextUpdateWeekly(moment(_max).format("dddd, DD/MMM HH:mm")); // next
          // setNextUpdateWeekly(getWeekInterval); // next
          // } else {
          //   setNextUpdateWeekly(weekInterval); // now
          // }
        }

        if (interval === "monthly") {
          compare = moment(_max).diff(moment(date), "m");

          // set next update
          // if (moment(monthly).diff(moment(date), "m") < 1) {
          setNextUpdateMonthly(moment(_max).format("DD/MMM HH:mm")); // next
        }

        // // console.log("COMPARE", compare);
        // // console.log("INTRVL", interval);
        // // console.log("DATE", date);
        // // console.log("hour", hour);

        setGapMinute(compare);

        if (compare <= 15 && compare > 0) {
          setRunTimer(true);
        } else {
          setRunTimer(false);
        }
      }
    }, 1000);

    return () => clearInterval(secTimer);
  }, [interval, hour, daily, weekly]);

  useEffect(() => {
    if (scheduled_at !== undefined) {
      let timerId;

      if (runTimer) {
        setCountDown(60 * gapMinute);
        timerId = setInterval(() => {
          setCountDown((countDown) => countDown - 1);
        }, 1000);
      } else {
        clearInterval(timerId);
        setCountDown(0);
      }

      return () => clearInterval(timerId);
    }
  }, [runTimer, gapMinute]);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props;

    // // console.log("Payload : ", payload);

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
          style={{ fontSize: 12 }}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // // console.log("paypaY", payload);

      return (
        <Box
          bgcolor="#fff"
          border="1px solid #cfcfcf"
          borderRadius={5}
          p={2}
          display="flex"
          flexDirection="column"
        >
          <Typography variant="subtitle1" className="label">
            <b>{payload[0]?.payload?.tooltip}</b>
          </Typography>
          <Typography variant="subtitle2" className="intro">
            Pencapaian : {FormatDecimal(payload[0]?.value)}
          </Typography>
          <Typography variant="subtitle2" className="intro">
            Target : {FormatDecimal(payload[1]?.value)}
          </Typography>
        </Box>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Box
        mb={2}
        ml={3}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <PurpleSwitch
              checked={showDate}
              onChange={handleShowDate}
              name="showDate"
              size="small"
            />
          }
          style={{ marginRight: 0 }}
          label={
            <Typography variant="caption" className={classes.title}>
              Show Date
            </Typography>
          }
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkboxRoot}
              icon={<span className={classes.icon} />}
              checkedIcon={
                <span className={clsx(classes.icon, classes.checkedIcon)} />
              }
              checked={activeHours}
              onChange={handleActiveHours}
            />
          }
          label={
            <Typography variant="subtitle2" className={classes.title888}>
              Display All Data
            </Typography>
          }
        />
        {loading && (
          <Box display="flex" alignItems="center" ml={4}>
            <CircularProgress
              size={14}
              style={{ color: "#d1354a", marginRight: 7 }}
            />
            <Typography variant="caption" className={classes.title888}>
              Loading chart...
            </Typography>
          </Box>
        )}
      </Box>
      {collectionGoalResultList.length > 0 && (
        <ResponsiveContainer width="98%" height={300}>
          <Fade in={true}>
            <LineChart
              // width={730}
              // height={250}
              data={dataGoalResult}
              margin={{ top: 8, right: 0, left: 24, bottom: 8 }}
            >
              <XAxis
                dataKey={showDate ? "name" : ""}
                height={showDate ? 60 : 5}
                tick={<CustomizedAxisTick />}
              />
              <YAxis
                domain={[min, max]}

                /*
                        ``````````````````````````````````````````````````````````````````````
                        https://stackoverflow.com/questions/50078787/recharts-set-y-axis-range

                        ``````````````````````````````````````````````````````````````````````
                      */
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip content={<CustomTooltip />} />
              <Line type="linear" dataKey="Pencapaian" stroke="#d1354a" />
              <Line type="linear" dataKey="Target" stroke="#00b894" />
              {/* <Area type="monotone" dataKey="pv" stroke="#d1354a" fillOpacity={1} fill="url(#Pencapaian)" /> */}
            </LineChart>
          </Fade>
        </ResponsiveContainer>
      )}

      {interval === "hourly" ? (
        <Box ml={4} my={2} display="flex" justifyContent="space-between">
          <Box>
            {maDetailState?.update_frequency !== null && (
              <Typography variant="subtitle2" className={classes.title555}>
                <b>Days</b> :{" "}
                {maDetailState?.update_frequency?.selected_days_sorted}
              </Typography>
            )}
            <Box display="flex" flexWrap="wrap">
              <Typography variant="subtitle2" className={classes.title555}>
                <b>Range</b> :
              </Typography>
              <Box mx={0.5} />
              {maDetailState?.update_frequency?.hourly_details?.length > 0
                ? maDetailState?.update_frequency?.hourly_details?.map(
                    (item, i) => {
                      return (
                        <Typography
                          key={i}
                          variant="subtitle2"
                          className={classes.title555}
                          style={{ marginRight: 8 }}
                        >
                          {item?.time_start} - {item?.time_finish},
                        </Typography>
                      );
                    }
                  )
                : null}
            </Box>
          </Box>

          <Box mr={2} textAlign="right">
            {maDetailState?.update_frequency_next_period?.gr === null && (
              <Typography variant="subtitle2" className={classes.title555}>
                <b>
                  Next Update (
                  {moment(
                    maDetailState?.update_frequency_next_period?.max
                  ).format("HH:mm")}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <TimerRoundedIcon style={{ fontSize: 25, color: "#555" }} />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === "daily" ? (
        <Box ml={4} my={2} display="flex" justifyContent="space-between">
          <Box display="flex" flexWrap="wrap">
            <Typography variant="subtitle2" className={classes.title555}>
              <b>Days</b> :
              {maDetailState?.update_frequency?.selected_days_sorted}
            </Typography>
            <Box mx={0.5} />
          </Box>

          <Box mr={2} textAlign="right">
            {maDetailState?.update_frequency_next_period?.gr === null && (
              <Typography variant="subtitle2" className={classes.title555}>
                <b>
                  Next Update (
                  {moment(
                    maDetailState?.update_frequency_next_period?.max
                  ).format("dddd - HH:mm")}
                  )
                </b>{" "}
              </Typography>
            )}

            {runTimer && (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <TimerRoundedIcon style={{ fontSize: 25, color: "#555" }} />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === "weekly" ? (
        <Box ml={4} my={2} display="flex" justifyContent="space-between">
          <Box>
            {/* <Box display="flex" flexWrap="wrap">
              <Typography variant="subtitle2" className={classes.title555}>
                <b>Days</b> :
                {maDetailState?.update_frequency?.selected_days_sorted}
              </Typography>
              <Box mx={0.5} />
            </Box> */}
          </Box>

          <Box mr={2} textAlign="right">
            {maDetailState?.update_frequency_next_period?.gr === null && (
              <Typography variant="subtitle2" className={classes.title555}>
                <b>
                  Next Update (
                  {moment(
                    maDetailState?.update_frequency_next_period?.max
                  ).format("dddd, (DD) MMM - HH:mm")}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <TimerRoundedIcon style={{ fontSize: 25, color: "#555" }} />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      {interval === "monthly" ? (
        <Box ml={4} my={2} display="flex" justifyContent="space-between">
          <Box></Box>

          <Box mr={2} textAlign="right">
            {maDetailState?.update_frequency_next_period?.gr === null && (
              <Typography variant="subtitle2" className={classes.title555}>
                <b>
                  Next Update (
                  {moment(
                    maDetailState?.update_frequency_next_period?.max
                  ).format("DD/MMM - HH:mm")}
                  )
                </b>
              </Typography>
            )}

            {runTimer && (
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <TimerRoundedIcon style={{ fontSize: 25, color: "#555" }} />
                <Box mx={0.5} />
                <Typography variant="h5" className={classes.title555}>
                  {minutes}:{seconds}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      ) : null}
    </Fragment>
  );
};

export default DotLineChartMeasureActivityInDetail;
