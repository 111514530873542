import React, { useContext, useEffect, useState } from "react";
import { Box, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";

import AxiosConfig from "../../constants/config-axios";
import { URL_API } from "../../constants/config-api";
import {
  entities,
  status,
  order_by,
} from "./components/global/dataFilterReport";

import { styles } from "./style/styleReports";
import {
  //comparison
  GET_COMPARISONS,
  // detailed
  GET_FILTER_DATA,
  GET_REPORT_CATEGORY,
  GET_FIELD_PRESETS,
  HIT_DATA_PRESET,
  HIT_TOTAL_RECORDS,
  SET_PAGINATION,
  // summary
  GET_SYNC,
  SELECT_DATE_RANGE,
  SET_DATE_RANGE,
} from "./context/types";
import ContextReports from "./context/ContextReports";
import handleError from "./components/global/handleError";

import Grouping from "./components/report-type/detailed-type/grouping";
import Comparison from "./components/comparison";
import ReportType from "./components/report-type";
import SummaryType from "./components/report-type/summary-type";
import DetailedType from "./components/report-type/detailed-type";
import extractTable from "./components/global/extractTable";
import DialogError from "../../components/DialogError";

import "./style/table.css";

const useStyles = makeStyles(styles);
// const fetcher = (url) => AxiosConfig.get(url).then((r) => r.data.data);

const ViewReports = () => {
  const classes = useStyles();
  const {
    reportState,
    filterState,
    tableState,
    tableDispatch,
    reportDispatch,
    filterDispatch,
    summaryDispatch,
  } = useContext(ContextReports);

  // ERROR STATE
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [errorStatus, setErrorStatus] = useState(undefined);
  const [listError, setListError] = useState([]);
  const [textErrorMessage, setTextErrorMessage] = useState(
    "whoops something went wrong"
  );

  // ===================================================================
  // ~ Pepare Body For Detailed Report ~
  // ~~~~~~~~~~~ BODY For Data Table ~~~~~~~~~~~
  const dataPost = {
    name: "Last Seven Days",
    platform: "web",
    field_preset_id: 1,
    report_format: "table",
    filter_by: {
      entity: [
        { name: "goal", query: "asdfasdfds" },
        { name: "ma", query: "asdfasdfds" },
      ],
      date_range: "last_seven_days",
      user: tableState.usersSelected,
      unit: [],
      status: tableState.status,
      achievement: {
        value_type: tableState.valueType,
        cal_method: tableState.calMethod,
      },
    },
    entities: ["goal", "ma"],
    group_by: {
      entity: ["goal"],
      date_time: [],
      user: false,
      unit: ["division", "branch"],
      orientation: [],
    },
    group_by_calculation: "1",
    order_by: tableState.selectedOrder,
    primary_column: "name",
    limit_type: "desc",
    limit_count_number: 0,
    week_start: "monday",
    date_format: "d m Y",
    summary_only: false,
    comparisons: {
      date_range: "previous_seven_days",
      fields: [
        "result_value",
        "overall_result_achievement",
        "expected_result_value",
      ],
    },
  };

  // // console.log("DatSum", dataSummary);

  // ~~~~~~~~~~~ PAGINATION Data For Table ~~~~~~~~~~~
  const paginating = tableState.pagination;
  const page = paginating.currentPage;
  const perPage = paginating.perPage;

  // ===================================================================
  // ~ SIDE EFFECTS ~
  useEffect(() => {
    // ================= GET REPORT CATEGORIES =================
    AxiosConfig.get(`${URL_API}/reporting/master-category`)
      .then((res) => {
        const result = res.data.data;

        reportDispatch({
          type: GET_REPORT_CATEGORY,
          payload: { data: result },
        });
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });

    // ================= GET REPORT ENTITIES =================
    AxiosConfig.get(`${URL_API}/reporting/master-group`)
      .then((res) => {
        const result = res.data.data;
        // // console.log("RES master GRoup", result);
      })
      .catch((error) => {
        // console.log("Error : ", error);

        // Handle Error
        const { listError, dialogErr, resStatus, errorMessage } =
          handleError(error);
        // Error State
        setOpenDialogError(dialogErr);
        setTextErrorMessage(errorMessage);
        setListError(listError);
        setErrorStatus(resStatus);
      });
  }, []);

  useEffect(() => {
    // ================= GET ALL INITIAL DATA CREATION =================
    filterDispatch({
      type: GET_FILTER_DATA,
      payload: {
        tablePresets: [],
        entities: [],
        users: [],
        units: [],
        filterRanges: [],
        status: [],
        orderBy: [],
        loadFilter: true,
      },
    });

    AxiosConfig.get(
      `${URL_API}/reporting/page/${reportState.group}/${reportState.category}?compare_range=${filterState.dateRange.category}&platform=web`
    ).then((res) => {
      const result = res.data.data;
      // console.log("RES report Page", result);

      const settings = result.layout[0].widget.setting;
      const filterBy = settings.filter_by.default_value;
      const comparisons = settings.comparisons.default_value;
      const field_preset_id = settings.field_preset_id.default_value;

      // // console.log("Base Layout", settings);

      const byUser = filterBy.user;
      const byUnit = filterBy.unit;
      const byRanges = filterBy.date_range;

      const defaultPreset = field_preset_id.filter(
        (item) => item.name === "Result Simple"
      );

      // Set The user List to Local Storage
      localStorage.setItem("filter_user", JSON.stringify(byUser));

      // Just GET presets
      tableDispatch({
        type: GET_FIELD_PRESETS,
        payload: {
          fieldPresets: defaultPreset[0],
        },
      });

      // Load All data For Filter
      filterDispatch({
        type: GET_FILTER_DATA,
        payload: {
          tablePresets: field_preset_id,
          entities,
          users: byUser,
          units: byUnit,
          status: status,
          filterRanges: byRanges,
          orderBy: order_by,
          loadFilter: false,
        },
      });

      const pickLast7Days = byRanges.find(
        (el) => el.code === "last_seven_days"
      );
      // // console.log("pickLast7Days", pickLast7Days);

      filterDispatch({
        type: SELECT_DATE_RANGE,
        payload: {
          date_range: {
            title: pickLast7Days.name,
            code: pickLast7Days.code,
            startDate: moment(pickLast7Days.date_option.start_date).format(
              "DD MMM YYYY"
            ),
            endDate: moment(pickLast7Days.date_option.finish_date).format(
              "DD MMM YYYY"
            ),
            category: "weekly",
          },
        },
      });

      summaryDispatch({
        type: SET_DATE_RANGE,
        payload: {
          dateRange: {
            title: pickLast7Days.name,
            code: pickLast7Days.code,
            startDate: moment(pickLast7Days.date_option.start_date).format(
              "DD MMM YYYY"
            ),
            endDate: moment(pickLast7Days.date_option.finish_date).format(
              "DD MMM YYYY"
            ),
          },
        },
      });

      // GET Comparison initial Data
      filterDispatch({
        type: GET_COMPARISONS,
        payload: {
          comparisonList: comparisons.date_range,
          comparisonFields: comparisons.fields,
        },
      });

      tableDispatch({
        type: HIT_DATA_PRESET,
        payload: { dataPresets: [], load: true },
      });

      // ================= GET INITIAL DATA TABLE DETAILED =================
      AxiosConfig.post(
        `${URL_API}/reporting/widget/detail?page=${page}&per_page=${perPage}`,
        dataPost
      )
        .then((res) => {
          const result = res.data.data;
          const lastSync = res.data.info.data;

          const DATATABLE = extractTable(result.results.data);
          const pagination = {
            currentPage: result.results.current_page,
            perPage: result.results.per_page,
            lastPage: result.results.last_page,
          };

          // // console.log("Data First Load", DATATABLE);
          // // console.log("Detailed First Load", res);
          // // console.log("result First Load", result);

          if (res.status === 200) {
            reportDispatch({
              type: GET_SYNC,
              payload: { lastSync },
            });

            tableDispatch({
              type: HIT_DATA_PRESET,
              payload: { dataPresets: DATATABLE, load: false },
            });

            tableDispatch({
              type: SET_PAGINATION,
              payload: { pagination },
            });

            tableDispatch({
              type: HIT_TOTAL_RECORDS,
              payload: { totalRecords: result.results.total },
            });
          }
        })
        .catch((error) => {
          // console.log("Error : ", error);

          // Handle Error
          const { listError, dialogErr, resStatus, errorMessage } =
            handleError(error);
          // Error State
          setOpenDialogError(dialogErr);
          setTextErrorMessage(errorMessage);
          setListError(listError);
          setErrorStatus(resStatus);

          tableDispatch({
            type: HIT_DATA_PRESET,
            payload: { dataPresets: [], load: false },
          });
        });
    });
  }, []);

  return (
    <Box className={classes.root}>
      <Box marginBottom={2} className={classes.layoutMarginLeftAndTop}>
        <ReportType classes={classes} />
      </Box>

      {reportState.valueTab === 1 && <Divider orientation="horizontal" />}

      {reportState.valueTab === 1 && (
        <div
          style={{
            backgroundColor: "#fff",
            padding: `20px 0`,
          }}
        >
          <Box className={classes.layoutMarginLeft}>
            {/* BOX Container Grouping */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginRight={2}
            >
              <Grouping classes={classes} />

              <Comparison classes={classes} />
            </Box>
          </Box>
        </div>
      )}

      <div className={classes.dataArea}>
        <Box className={classes.layoutMarginLeft}>
          {/* Box Button Compare */}

          {/* BOX Body Reports */}
          <Box marginTop={1.5} marginRight={2}>
            {reportState.valueTab === 0 && <SummaryType classes={classes} />}
            {reportState.valueTab === 1 && <DetailedType classes={classes} />}
          </Box>
        </Box>
      </div>

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorMessage}
        errorStatus={errorStatus}
        listError={listError}
      />
    </Box>
  );
};

export default ViewReports;
