import React from "react";
import { Paper, Box, Typography } from "@material-ui/core";

import moment from "moment";

import DateRangeIcon from "@material-ui/icons/DateRange";
import TruncateTextCustom from "../../../../../../utilities/TruncateTextCustom";
import MyLang from "../../../../../../utilities/MyLang";
import { bahasa, english } from "../../../../../../constants/config-lang";

const ListItemTodoList = ({ item, classes }) => {
  return (
    <Paper className={classes.cardList}>
      <Box mb={1.5} p={1} display="flex" alignItems="center">
        <Box ml={2}>
          <Typography className={classes.title555} variant="subtitle2">
            <b>{TruncateTextCustom(item.title, 60)}</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <span
              style={{
                backgroundColor: "#888888",
                color: "#fff",
                fontSize: 11,
                borderRadius: 15,
                padding: "2px 7px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: 5,
              }}
            >
              To Do
            </span>
            <DateRangeIcon
              style={{ color: "#444", fontSize: 15, marginRight: 5 }}
            />
            <Typography className={classes.title888} variant="caption">
              {MyLang() ? bahasa.created : english.created}{" "}
              {moment(item.created_at).fromNow()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default ListItemTodoList;
