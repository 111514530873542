export const globalEnglish = {
  // Button Text
  btnClose: "Close",
  btnCancel: "Cancel",
  btnApply: "Apply",
  btnOk: "OK",
  btnSave: "Save",
  btnCreate: "Create",
  btnUpload: "Upload",
  btnCreating: "Creating...",
  btnSaving: "Saving...",
  btnUploading: "Uploading...",
  btnApplying: "Applying...",
  btnUpdate: "Update",
  btnUpdating: "Updating...",

  // TERM
  add: "Add",
  period: "Period",
  create: "Create",
  edit: "Edit",
  export: "Export",
  search: "Search",
  achievement: "Achievement",
  filter: "Filter",
  sort: "Sort",
  employee: "Employee",
  user: "User",
  setting: "Setting",
  top: "Top",
  achieved: "Achieved",
  warning: "Warning",
  notAchieved: "Not Achieved",
  overallAchievement: "Overall Achievement",
  generalSetting: "General Setting",
  totalRecords: "Total records",
  perPage: "Per page",
  average: "Average",
  or: "or",
  and: "dan",
  lastUpdate: "Last Update",
  update: "Update",
  extend: "Extend",
  duration: "Duration",
  price: "Price",
  bussines: "Bussines",
  use: "Use",
  payment: "Payment",
  discount: "Discount",
  pay: "Pay",
  choose: "Choose",
  adjusment: "Adjusment",
  additional: "Additional",
  pending: "Pending",
  name: "Name",
  role: "Role",
  delete: "Delete",
  upgrade: "Upgrade",
  available: "Available",
  company: "Company",
  loading: "Loading",
  noData: "No Data",
  overall: "Overall",
  progress: "Progress",
  created: "Created",
  perform: "Performance",
  delimiter: "Delimiter",
  semicolon: "Semicolon",
  comma: "Comma",
  floating: "Floating",
  dot: "Dot",
  archive: "ArChive",
  setAsComplete: "Set as Completed",
  marked: "Marked",
  duplicate: "Duplicate",
  actual: "Actual",
  default: "Default",
  result: "Result",
  cumulative: "Cumulative",
  discrete: "Discrete",
  chart: "Chart",
  range: "Range",
  direct: "Direct",
  link: "Link",
  clear: "Clear",
  stop: "Stop",
  lock: "Lock",
  unlock: "Unlock",
  next: "Next",

  // Date
  hour: "Hour",
  day: "Day",
  week: "Week",
  month: "Month",
  year: "Year",
  hourly: "Hourly",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",

  // dasboard
  notHavePeriod: "Not have Period",
  companyProgress: "Company Progress",
  youHaveReached: "You have reached",
  fromAllGoal: "From All your Goal",
  fromYourTarget: "From your Target",
  noAchievements: "No Achievements",
  youDontHave: "You don't have",

  // ma / goal
  startVal: "Start Value",
  lastVal: "Last Value",
  targetVal: "Target Value",
  actualVal: "Actual Value",
  resultVal: "Result Value",
  targetRangeVal: "Target Range Value",
  startDate: "Start Date",
  endDate: "End Date",
  dueDate: "Due Date",
  description: "Description",
  achievementType: "Achievement Type",
  valueType: "Value Type",
  calcMethod: "Calculation Method",
  targetType: "Target Type",
  owner: "Owner",
  status: "Status",
  approved: "Approved",
  active: "Actif",
  overdue: "Overdue",
  completed: "Completed",
  eveidenceRequired: "Evidence Required",
  ora: "Overall Result Achievement",
  timeFrame: "Time Frame",
  setDataZero: "Set no data as 0",
  searchOwner: "Search owner",
  initialVal: "What is your initial value?",
  finalScore: "What was your final score?",
  placeholderDesc: "Enter your Goal description",
  chooseDate: "Choose Date",
  noPeriod: "No Period",
  noOwner: "No owner",
  searchPeriod: "Search period",
  placeholderTitle: "e.g Turnover up, Open a branch...",
  setWeight: "Set weight",
  addWeightGoal: "Add Weight Goal for",
  tooltipWeightGoal:
    "The maximum value of the overall weight on the Goal is 100%. Every time there is a change in the Goal, it will be required to readjust the weight value.",
  currentWeight: "Current weight",
  noDisplayedGoal: "No Goals can be displayed at this time ;(",
  noDisplayedMA: "No MA can be displayed at this time ;(",
  overviewHistory: "Overvie History",
  gapToTV: "Gap to Target Value",
  displayAllData: "Display All Data",
  showDate: "Show Date",
  nextUpdate: "Next Update",
  evidence: "Evidence",
  groupResult: "Group Results",
  addResult: "Add Result",
  latestGoalResult: "Latest Goal Result",
  activityLog: "Activity Log",
  goalResult: "Goal Result",
  explanation: "Explanation",
  subGoals: "Sub Goals",
  noSubGoalDisplayed:
    "There are no sub goals that can be displayed at this time ;(",
  directLink: "Direct Link",
  dateAndTime: "Date & Time",
  updateFreq: "Update Frequency",
  fromTotal: "from total",
  row: "Row",
  more: "More",
  less: "Less",
  sum: "Sum",
  numeric: "Numeric",
  increment: "Increment",
  percentage: "Percentage",
  final: "Final",
  structureUnit: "Structure Unit",
  gap: "Gap",
  expectedVal: "Expected Value",
  remainingTime: "Remaining Time",
  overview: "Overview",
  removeFrom: "Remove from",
  missMatchSubGoal: "Miss match with Sub-Goal",
  toProject: "Go to Project",
  backToOri: "back to Originally",
  activateMode: "Activate mode",
  indirect: "Indirect",
  moreDetail: "More detail",
  addEvidence: "Add Evidence",
  uploadEvidence: "Upload Evidence",
  dragDropCopas: " Drag 'and' Drop file here, or CTRL + V",
  fileDriveSelected: "File Drive selected",
  pasteLink: "Paste the link here",
  voiceRecorder: "Voice Recorder",
  record: "Record",
  placeholderRecord: "Input audio title",
  plainDocument: "Plain Document",
  listEvidence: "List Evidence",
  addGoalResult: "Add Goal Result",
  insertValNum: "Insert value number",
  comment: "Comment",
  placeholderAddComment: "Add comment...",
  chooseDateTime: "Choose date & time",
  fileManager: "File Manager",
  chooseOwner: "Choose Owner",
  therIsNoUser: "There are no users at this time",
  createDirectSubGoal: "",
  hideResultNoData: "Hide result no data",

  // More TERM
  showMore: "Show More",
  showLess: "Show Less",
  advencedOption: "Advanced Options",
  viewMore: "View More",

  // TASK
  priority: "Priority",
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",

  // GENERAL SETTING
  descGeneralSetting:
    "Manage the general configuration of your Goals, achievements, periods and status limits",
  accSetting: "Account Setting",
  notifSetting: "Notification Setting",
  descNotif:
    "Manage your notifications, so the system can notify you at any time",
  descAccSetting:
    "Manage your company account, user, and membership subscription.",
  language: "English",
  titleLang: "english",
  descLang: "Select the language you want to use",
  descAchievement:
    "This achievement information will be displayed based on your settings",
  titleConfirmSetting: "Apply Settings",
  descConfirmSetting:
    "Update Max Achievement and Min Achievement of Goal/MA will change all achievement values of Goal/MA according to the value set here. Are you sure?",
  alertConfirmSetting: "New settings applied successfully :)",
  min: "Min",
  max: "Max",
  helperTextMin: "Value must be less than Max value",
  helperTextMax: "Value must be greater than Min value",
  enable: "Enabled",
  defaultPeriod: "Default Period",
  descDefaultPeriod: "Choose a period to set as default",
  overallPerform: "Overall Performance",
  descOverallPerform:
    "Set your Goal or MA weights. The highest value should pay more attention",
  factor: "Factor",

  // ACCOUNT SETTING
  password: "Password",
  profile: "Profile",
  changePwd: "Change Password",
  descChangePwd: "It is recommended not to change the password too often",
  changePhoto: "Change Photo",
  personal: "Personal",
  organization: "Organization",
  companyProfile: "Company Profile",
  membership: "Membership",
  // mpany profile
  companyName: "Company Name",
  companyLogo: "Company Logo",
  totalEmployee: "Total Employee",
  location: "Location",
  address: "Address",
  visionMission: "Vision & Mission",
  activateEditMode: "Activate Edit Mode",
  updateLocation: "Update Location",
  descLocation: "You have not updated your company address location.",
  // membership
  manageMembership: "Manage Membership",
  descMembership1: "Your current membership package is",
  descMembership2: "with max duration",
  descMembership3: "User license",
  deploySession: "Deployment Session (Seminars & Workshops)",
  detailpackage: "Package Detail",
  detailDeploy: "Deployment Detail",
  userLicense: "User License",
  deployment: "Deployment",
  totalActiveTime: "Total Active Time",
  runningTime: "Running Time",
  consultantVisit: "Consultant visit",
  remainingDuration: "Remaining duration",
  extendMembership: "Extend Membership",
  descExtend1: "Your account's active period will expire on",
  descExtend2: "Extend membership to stay subscribed",
  updateMembership: "Update Membership",
  descUpdateMember:
    "Change your subscription options with Upgrade and downgrade membership packages",
  cancelMembership: "Cancel Membership",
  checkOrder: "Check your order",
  descCheckOrder:
    "Please double check your order, make sure you choose the membership duration according to your needs",
  addedDuration: "Added Duration",
  paymentMethod: "Payment Method",
  vaCheck: "Virtual Account (Automatic check)",
  totalPrice: "Total Price",
  additionalDuration: "Additional duration",
  totalPayment: "Total Payment",
  choosePackage: "Choose Package",
  buyDeployTicket: "Buy Deployment Tickets",
  updateMembershipForm: "Update Membership Form",
  descUpdateMemberForm1: "Your last membership package is",
  descUpdateMemberForm2: "with cycle duration",
  packageChanges: "Package changes",
  totalLicense: "Total license",
  changeOfCounsultantVisit: "Change of consultant visit",
  totalConsultantVisit: "Total consultant visits",
  changeDuration: "Change duration",
  updateFee: "Update Fee",
  totalUpdateFee: "Total Update Fee",
  // USer Setting
  userSetting: "User Setting",
  inviteUser: "Invite User",
  jobTitle: "Job Title",
  descUserSetting1: "You have used",
  descUserSetting2: "user out of",
  //   Notofocation Setting
  createNotifSetting: "Create Notification Setting",
  moduls: "Moduls",
  entity: "Entity",
  event: "Event",
  notifyTo: "Notify ke",
  recipients: "Recipients",
};
