import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import {
  Paper,
  Typography,
  Grid,
  TextField,
  CircularProgress,
  Box,
  Button,
  MenuItem,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";

import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useToggle } from "react-use";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import EditIcon from "@material-ui/icons/Edit";
import BorderColorIcon from "@material-ui/icons/BorderColor";

import ContextCompanySetting from "../Context/ContextCompanySetting";
import DialogEditLocation from "../Components/DialogEditLocation";

import Snackbeer from "../../../../components/Snackbeer";
import Capitalize from "../../../../utilities/Capitalize";
import DialogError from "../../../../components/DialogError";
import { URL_API } from "../../../../constants/config-api";
import { grey } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#cc0707", //#cc0707, #c62828
      light: "#ff5f52",
      dark: "#8e0000",
      contrastText: "#ffffff",
    },
  },
});

const FormEditCompanySetting = (props) => {
  const {
    classes,
    fields,
    memberCountCollections,
    updatedAt,
    fieldsMemberCount,
    setFieldsMemberCount,
  } = props;

  const context = useContext(ContextCompanySetting);
  let textInputReff = useRef(null);

  /*
        ```````````````````
        COMPONENT DID MOUNT

        ```````````````````
    */

  //*
  const [fieldsName, setFieldsName] = useState(null);
  const [fieldsTaxIdNumber, setFieldsTaxIdNumber] = useState(null);

  // const [ fieldsMemberCount, setFieldsMemberCount ] = useState({

  //     employee: `${fields.member_count}`
  // });

  const handleChangeDropdownJumlahKaryawan = (name) => (event) => {
    setFieldsMemberCount({ ...fieldsMemberCount, [name]: event.target.value });

    // console.log(event.target.value);
  };

  const [fieldsAlamatDetail, setFieldsAlamatDetail] = useState(null);
  const [fieldsVisionAndMission, setFieldsVisionAndMission] = useState(null);

  /*
       ```````````````````````````````
       HANDLE POST COMPLETION PROFILE

       ``````````````````````````````
    */
  const handleSubmitPUT = () => {
    context.setFireUpdate(false);
    context.setLoader(true);
    const userToken = localStorage.getItem("userToken");

    //*
    let file = undefined;

    if (contentFile !== undefined) {
      file = new Blob([contentFile[0]], { type: "image/png" }); //*Work
    }

    //*
    let dataImage = new FormData();
    dataImage.append("_method", "put");

    dataImage.append(
      "GroupProfile[name]",
      fieldsName !== null ? fieldsName : fields.name
    );
    dataImage.append(
      "GroupProfile[tax_id_number]",
      fieldsTaxIdNumber !== null ? fieldsTaxIdNumber : fields.tax_id_number
    );
    dataImage.append("GroupProfile[member_count]", fieldsMemberCount.employee);
    dataImage.append(
      "GroupProfile[vision_and_mission]",
      fieldsVisionAndMission !== null
        ? fieldsVisionAndMission
        : fields.vision_and_mission
    );
    dataImage.append("GroupProfile[country_id]", fields.country_id);
    dataImage.append(
      "GroupProfile[province_id]",
      context.fieldsProvinceId !== null
        ? context.fieldsProvinceId
        : fields.province_id
    );
    dataImage.append(
      "GroupProfile[city_id]",
      context.fieldsCityId !== null ? context.fieldsCityId : fields.city_id
    );
    dataImage.append(
      "GroupProfile[postal_code_id]",
      context.fieldsPostalCodeId !== null
        ? context.fieldsPostalCodeId
        : fields.postal_code_id
    );
    dataImage.append(
      "GroupProfile[detail]",
      fieldsAlamatDetail !== null
        ? fieldsAlamatDetail
        : `${fields.alamat_detail !== "" ? fields.alamat_detail : "-"}`
    );
    dataImage.append("GroupProfile[updated_at]", updatedAt);
    dataImage.append("GroupProfile[photo_logo_icon]", file);

    // let data = {

    //     GroupProfile: {

    //         name: fieldsName !== null ? fieldsName :  fields.name,
    //         tax_id_number:  fieldsTaxIdNumber !== null ? fieldsTaxIdNumber :  fields.tax_id_number,
    //         member_count: fieldsMemberCount.employee,
    //         vision_and_mission:  fieldsVisionAndMission !== null ? fieldsVisionAndMission : fields.vision_and_mission,
    //         country_id: fields.country_id,
    //         province_id: context.fieldsProvinceId !== null ? context.fieldsProvinceId :  fields.province_id,
    //         city_id: context.fieldsCityId !== null ? context.fieldsCityId : fields.city_id,
    //         postal_code_id:  context.fieldsPostalCodeId !== null ?  context.fieldsPostalCodeId : fields.postal_code_id,
    //         detail: fieldsAlamatDetail !== null ? fieldsAlamatDetail : `${fields.alamat_detail !== '' ? fields.alamat_detail : "-" }`,
    //         updated_at: updatedAt

    //     },
    //     // _method: 'put'
    // };

    // console.log(`Data  : `, data);

    if (userToken !== undefined && userToken !== null) {
      const header = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "bearer " + userToken,
      };

      axios.defaults.headers.common = header;

      axios
        .post(URL_API + `/group/profile`, dataImage)
        // .put(URL_API + `/group/profile`, data)
        .then(function (response) {
          context.setLoader(false);
          // console.log("Response Original ", response);
          setModalResponse200(true);
          context.setFireUpdate(true);

          toggle(false);

          //*
          let dataLocation = {
            country: "Indonesia",
            province: response.data.data.address.province.name,
            city: response.data.data.address.city.name,
            postalCode: response.data.data.address.postalCode.name,
          };

          localStorage.setItem(
            "location_company",
            JSON.stringify(dataLocation)
          );
        })
        .catch(function (error) {
          // console.log("Error : ", error.response);
          context.setLoader(false);
          toggle(true);

          if (error.response !== undefined) {
            if (error.response.status === 400) {
              if (
                error.response.data.info.errors !== null &&
                error.response.data.info.errors !== undefined
              ) {
                if (error.response.data.info.errors.length > 0) {
                  setListError(error.response.data.info.errors);
                  setOpenDialogError(true);
                  setErrorStatus(400);
                }
              } else {
                setOpenDialogError(true);
                setTextErrorInformation(
                  "Error 400 : " +
                    Capitalize(error.response.data.info.errors[0].description)
                );
              }
            }

            if (error.response.status == 500) {
              setErrorStatus(500);
              if (error.response.data.hasOwnProperty("message")) {
                setOpenDialogError(true);
                setTextErrorInformation(
                  "Error 500 : " + Capitalize(error.response.data.message)
                );
              }
            }

            if (error.response.status == 409) {
              setErrorStatus(409);
              if (error.response.data.hasOwnProperty("info")) {
                if (error.response.data.info.hasOwnProperty("message")) {
                  setOpenDialogError(true);
                  setTextErrorInformation(
                    "Error 409 : " +
                      Capitalize(error.response.data.info.message)
                  );
                  setErrorStatus(error.response.status);
                }
              }
            }
          } else {
            setOpenDialogError(true);
            setTextErrorInformation("Whoops, something went wrong !");
          }
        });
    } else {
      // console.log("No Access Token available!");
    }
  };

  /*
        ```````````
        HANDLE FOTO

        ```````````
    */
  const [imageBinaryPreviewUrl, setImageBinaryPreviewUrl] = useState("");
  const [contentFile, setContentFile] = useState(undefined);

  const onDrop = useCallback((acceptedFiles) => {
    setFotoQuery("");

    // Do something with the files
    setContentFile(acceptedFiles);

    //*
    const reader = new FileReader();

    reader.onabort = () =>
      // console.log("file reading was aborted");
      (reader.onerror = () =>
        // console.log("file reading has failed");
        (reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
          // // console.log("Binary String : ",binaryStr);

          setImageBinaryPreviewUrl(binaryStr);
          // setImgSrcExt(extractImageFileExtensionFromBase64(binaryStr))
        }));

    // acceptedFiles.forEach(file => reader.readAsBinaryString(file))
    acceptedFiles.forEach((file) => reader.readAsDataURL(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  //*Handle fotp part-2
  const [fotoQuery, setFotoQuery] = useState(fields.photo_logo_icon);

  useEffect(() => {
    setFotoQuery(fields.photo_logo_icon);
  }, [fields]);

  /*
        ```````````````````
        HANDLE DIALOG ERROR

        ```````````````````
    */
  const [isOpenDialogError, setOpenDialogError] = useState(false);
  const [textErrorInformation, setTextErrorInformation] = useState("");
  const [errorStatus, setErrorStatus] = useState(null);
  const [listError, setListError] = useState([]);

  /*
        ````````````````````````````
        HANDLE DIALOG EDIT LOCATION

        ```````````````````````````
    */
  const [isOpenDialogEditLocation, setOpenDialogEditLocation] = useState(false);

  /*
        ````````````````
        HANDLE SNACKBEER

        ````````````````
    */
  const [isModalResponse200, setModalResponse200] = useState(false);

  /*
        ````````````````````````````````````````````````````````````````````````````````````````````````````````
        HANDLE VIEW LOCATOIN FROM LOCAL STORAGE (i put on local storage, karena SERING-nya perubahan interaksi ) 

        ````````````````````````````````````````````````````````````````````````````````````````````````````````
    */
  const [locationLocalStorage, setLocationLocalStorage] = useState({
    city: "",
    province: null,
    postalCode: "",
    country: "Indonesia",
  });

  useEffect(() => {
    const locationCompany = localStorage.getItem("location_company");
    const locationCompanyAfterParse = JSON.parse(locationCompany);

    // console.log("locationCompanyAfterParse : ", locationCompanyAfterParse);
    if (locationCompanyAfterParse !== null) {
      setLocationLocalStorage(locationCompanyAfterParse);
    }
  }, []);

  useEffect(() => {
    if (context.fireUpdate === true) {
      const locationCompany = localStorage.getItem("location_company");
      const locationCompanyAfterParse = JSON.parse(locationCompany);

      // console.log("locationCompanyAfterParse : ", locationCompanyAfterParse);
      if (locationCompanyAfterParse !== null) {
        setLocationLocalStorage(locationCompanyAfterParse);
      }
    }
  }, [context.fireUpdate]);

  useEffect(() => {
    if (context.isUpdateLocationStorage === true) {
      const locationCompany = localStorage.getItem("location_company");
      const locationCompanyAfterParse = JSON.parse(locationCompany);

      // console.log("locationCompanyAfterParse : ", locationCompanyAfterParse);
      if (locationCompanyAfterParse !== null) {
        setLocationLocalStorage(locationCompanyAfterParse);
      }
    }
  }, [context.isUpdateLocationStorage]);

  /*
        ```````````````````````````````
        HANDLE TOGGLE MODE VIEW/ UPDATE

        ```````````````````````````````
    */
  const [modeEditOn, toggle] = useToggle(false);

  useEffect(() => {
    if (modeEditOn === true) {
      setTimeout(() => {
        textInputReff.current.focus();
      }, 200);
    }
  }, [modeEditOn]);

  return (
    <Grid container>
      <Grid item md={5} style={{ textAlign: "left" }}>
        <Box style={{ paddingTop: 10, paddingLeft: 60 }}>
          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Nama Perusahaan</b>
            </Typography>
            <TextField
              id="outlined-bare"
              className={classes.textField}
              onChange={(e) => setFieldsName(e.target.value)}
              value={fieldsName !== null ? fieldsName : fields.name}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
              disabled={modeEditOn === true ? false : true}
              inputRef={textInputReff}
            />
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>NPWP</b>
            </Typography>
            <TextField
              id="outlined-bare"
              className={classes.textField}
              onChange={(e) => setFieldsTaxIdNumber(e.target.value)}
              // onChange= {(e) => console.log(e.target.value)}
              value={
                fieldsTaxIdNumber !== null
                  ? fieldsTaxIdNumber
                  : fields.tax_id_number
              }
              placeholder="-"
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
              disabled={modeEditOn === true ? false : true}
            />
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Jumlah Karyawan</b>
            </Typography>
            <TextField
              id="outlined-bare"
              select
              className={classes.textField}
              onChange={handleChangeDropdownJumlahKaryawan("employee")}
              value={fieldsMemberCount.employee}
              variant="outlined"
              fullWidth
              inputProps={{
                className: classes.title,
              }}
              disabled={modeEditOn === true ? false : true}
            >
              <MenuItem value={fieldsMemberCount.employee}>
                {`${fieldsMemberCount.employee} Karyawan`}
              </MenuItem>

              {memberCountCollections.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Lokasi</b>
            </Typography>

            {locationLocalStorage.province !== null &&
            locationLocalStorage.province !== "" ? (
              <Fragment>
                <Paper elevation={0} style={{ backgroundColor: grey[100] }}>
                  <Box padding={1}>
                    <Typography variant="subtitle2" className={classes.title}>
                      {`${locationLocalStorage.province}, ${locationLocalStorage.city}, ${locationLocalStorage.country} - ${locationLocalStorage.postalCode}`}
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b
                        onClick={
                          modeEditOn === true
                            ? () => setOpenDialogEditLocation(true)
                            : null
                        }
                      >
                        <i style={{ color: "red", cursor: "pointer" }}>
                          <u>Perbaharui Lokasi</u>
                        </i>
                      </b>
                    </Typography>
                  </Box>
                </Paper>
              </Fragment>
            ) : (
              <Typography variant="subtitle2" className={classes.title}>
                Anda belum memperbaharui lokasi alamat perusahaan Anda.
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b onClick={() => setOpenDialogEditLocation(true)}>
                  <i style={{ color: "red", cursor: "pointer" }}>
                    <u>Perbaharui Lokasi</u>
                  </i>
                </b>
              </Typography>
            )}
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Alamat Lengkap</b>
            </Typography>
            <TextField
              onChange={(e) => setFieldsAlamatDetail(e.target.value)}
              value={
                fieldsAlamatDetail !== null
                  ? fieldsAlamatDetail
                  : fields.alamat_detail
              }
              style={{ marginTop: 0 }}
              id="outlined-multiline-static"
              multiline
              rows="4"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="-"
              inputProps={{
                className: classes.title,
              }}
              disabled={modeEditOn === true ? false : true}
            />
          </Box>

          <Box style={{ marginTop: 35 }}>
            <Typography variant="subtitle1" className={classes.title}>
              <b>Visi & Misi</b>
            </Typography>
            <TextField
              onChange={(e) => setFieldsVisionAndMission(e.target.value)}
              value={
                fieldsVisionAndMission !== null
                  ? fieldsVisionAndMission
                  : fields.vision_and_mission
              }
              style={{ marginTop: 0 }}
              id="outlined-multiline-static"
              multiline
              rows="6"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              fullWidth
              placeholder="-"
              inputProps={{
                className: classes.title,
              }}
              disabled={modeEditOn === true ? false : true}
            />
          </Box>

          {modeEditOn === true ? (
            <Box style={{ marginTop: 35 }}>
              <Button
                variant="outlined"
                // onClick={
                //     () => {
                //         setOpenDialogHelp(true);
                //         setOpenDialogError(false);
                //     }
                // }
                onClick={() => toggle(false)}
                color="primary"
                size="small"
                className={classes.title}
                style={{
                  marginRight: 8,
                  borderWidth: 0,
                }}
              >
                <span style={{ fontSize: 12 }}>Batal</span>
              </Button>

              <Button
                variant="contained"
                // onClick={() => setOpenDialogError(false)}
                onClick={handleSubmitPUT}
                color="primary"
                size="small"
                className={classes.title}
              >
                {context.loader !== true ? (
                  <span style={{ fontSize: 12 }}>Simpan</span>
                ) : (
                  <CircularProgress size={16} style={{ color: "white" }} />
                )}
              </Button>
            </Box>
          ) : null}
        </Box>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Grid>

      <Grid item md={5} style={{ textAlign: "center" }}>
        <Box marginLeft={20} marginRight={20} marginTop={8}>
          <Paper elevation={1} style={{ backgroundColor: "transparent" }}>
            <Box paddingTop={4}>
              <Typography variant="subtitle2" className={classes.title}>
                <b>Logo Perusahaan</b>
              </Typography>
            </Box>

            <Box paddingTop={4} paddingBottom={8}>
              {/* <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                                    imageBinaryPreviewUrl ? (
                                        
                                        <Avatar alt="You" src={ imageBinaryPreviewUrl} className={classes.realAvatar}  />

                                    ) : (
                                        <AddAPhotoIcon fontSize='large'/>
                                    )
                                }
                            </div> */}
              <IconButton aria-haspopup="true" color="inherit">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {fotoQuery !== "" &&
                  fotoQuery !== undefined &&
                  fotoQuery !== null ? (
                    <img
                      src={
                        URL_API +
                        "/group/profile/photo-logo-icon?name=" +
                        fotoQuery +
                        "&token=" +
                        context.userTokenStateGlobal
                      }
                      className={classes.superBigRealFoto}
                      alt="Foto"
                    />
                  ) : (
                    <Fragment>
                      {imageBinaryPreviewUrl ? (
                        <Avatar
                          alt="You"
                          src={imageBinaryPreviewUrl}
                          className={classes.superBigRealFoto}
                        />
                      ) : (
                        <AddAPhotoIcon fontSize="large" />
                      )}
                    </Fragment>
                  )}
                </div>
              </IconButton>
            </Box>
          </Paper>
        </Box>

        <Box marginTop={2}>
          <Button
            variant="outlined"
            // onClick={() => setOpenDialogError(false)}
            // onClick={handleSubmitPUT}
            color="primary"
            size="small"
            className={classes.buttonOutlined}
            onClick={toggle}
            endIcon={
              modeEditOn !== true ? (
                <EditIcon />
              ) : (
                <BorderColorIcon
                  style={{
                    backgroundColor: "transparent",
                    color: "transparent",
                  }}
                />
              )
            }
          >
            <Typography variant="subtitle2" className={classes.title}>
              {modeEditOn !== true ? "Aktifkan mode Edit" : ""}
            </Typography>
          </Button>
        </Box>

        {/* <div>{modeEditOn !== true ? 'Hanya lihat' : 'Mode Edit Active'}</div>
                <button onClick={ toggle }>Toggle</button> */}
      </Grid>

      <DialogEditLocation
        classes={classes}
        isOpenDialogEditLocation={isOpenDialogEditLocation}
        setOpenDialogEditLocation={setOpenDialogEditLocation}
        fields={fields}
      />

      <DialogError
        classes={classes}
        isOpenDialogError={isOpenDialogError}
        setOpenDialogError={setOpenDialogError}
        textErrorInformation={textErrorInformation}
        errorStatus={errorStatus}
        listError={listError}
      />

      <Snackbeer
        classes={classes}
        isModalResponse200={isModalResponse200}
        setModalResponse200={setModalResponse200}
        messages="Berhasil memperbaharui profil perusahaan :) "
      />
    </Grid>
  );
};

export default FormEditCompanySetting;
